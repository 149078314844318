import { Component, Input, OnInit } from '@angular/core';

import { Property, Visit } from '@housekeep/infra';

import { VisitChanges } from 'models/visit-changes';

@Component({
  selector: 'rkt-only-scheduled-visit',
  templateUrl: './rkt-only-scheduled-visit.component.html'
})
export class RktOnlyScheduledVisit implements OnInit {
  @Input() public property: Property;
  @Input() public visit: Visit;
  @Input() public visitChanges: VisitChanges;
  @Input() public showExtraPrecautions: boolean;
  @Input() public showVisitInfo: boolean;

  public ngOnInit() {
    this.property = this.visit.property;
  }
}
