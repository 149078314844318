import { Injectable } from '@angular/core';

import { round } from 'lodash-es';

import { DateTime, Duration } from '@housekeep/infra';

import { CacheStatus } from 'util/error';

import { DemoService } from './demo-service';
import { ErrorService } from './error-service';
import { HkSentryBreadcrumbCategory, SentryService } from './sentry-service';
import { StorageService } from './storage-service';
import { TimeService } from './time-service';

const CACHE_STORAGE_PREFIX = 'CACHE__';

interface CacheOpts {
  duration?: Duration;
  merge?: boolean;
  trackBy?: string;
}

export interface CacheEntry {
  data: any;
  expires?: number;
}

@Injectable({ providedIn: 'root' })
class CacheService {
  private _cache: { [key: string]: CacheEntry } = {};

  constructor(
    private demoService: DemoService,
    private errorService: ErrorService,
    private sentryService: SentryService,
    private storageService: StorageService,
    private timeService: TimeService
  ) {}

  public async ready(): Promise<void> {
    await this.storageService.ready();
  }

  /**
   * Attempt to return resolve the given key from the cache.
   * @param  {string} key
   * @return {Promise<any>} - The parsed cached data
   */
  get(key: string): Promise<any> {
    if (this.demoService.isOn) {
      return Promise.reject(CacheStatus.Unset);
    }

    if (this._cache[key] && !this._isExpired(this._cache[key])) {
      return Promise.resolve(this._cache[key].data);
    } else {
      return this._getStorage(key).then(jsonString => {
        if (!jsonString) {
          throw CacheStatus.Unset;
        }

        const entry: CacheEntry = JSON.parse(jsonString);

        if (this._isExpired(entry)) {
          return this.remove(key).then(() => {
            throw CacheStatus.Unset;
          });
        } else {
          this._cache[key] = entry;
          return entry.data;
        }
      });
    }
  }

  /**
   * Saves data to the cache, referenced by key
   * @param {string} key
   * @param {any} data - Must be JSON serializable
   * @param {CacheOpts} [opts]
   * @param {Duration} [opts.duration]
   * How long from now this data should expire
   * @param {boolean} [opts.merge]
   * Set to true to merge this data into the existing data for this key. In
   * this case, data can be either a single object, or an array.
   * @param {string} [opts.trackBy]
   * When merging, this determines which key in the objects should be used to
   * compare them. Only used when opts.merge is true.
   *
   * @returns Promise<boolean>
   * The resolution indicates whether the entry was successfully or not. A
   * false value may be an indication that the user does not have enough
   * storage on their device to be able to cache the entry. Promise rejections
   * only occur as a result of unexpected errors.
   */
  public set(key: string, data: any, opts: CacheOpts = {}): Promise<boolean> {
    const { duration, merge, trackBy } = opts;

    if (this.demoService.isOn) {
      return Promise.resolve(false);
    }

    // If we've been passed a page to cache, just grab the data and ignore the page info
    if (data.per_page) {
      data = data.results;
    }

    if (merge) {
      if (!this._cache[key]) {
        this._cache[key] = { data: [] };
      }

      if (!Array.isArray(data)) {
        data = [data];
      }

      data.forEach(newValue => {
        const found = this._cache[key].data.find((oldValue, oldIndex) => {
          if (oldValue[trackBy] === newValue[trackBy]) {
            this._cache[key].data[oldIndex] = newValue;
            return true;
          }
        });

        if (!found) {
          this._cache[key].data.push(newValue);
        }
      });
    } else {
      this._cache[key] = { data };
    }

    // If we update a child, we don't want to update the expiry for all of
    // its siblings, so we keep the parent's expiry as is.
    if (duration || (merge && this._cache[key].expires)) {
      if (duration) {
        const expires = this.timeService.now().add(duration.toSeconds(), 'seconds');
        this._cache[key].expires = this._getExpiryValue(expires);
      }

      return this._setStorage(key, this._cache[key]);
    } else {
      return Promise.resolve(true);
    }
  }

  /**
   * Remove a single entry from the cache
   */
  public remove(key: string): Promise<any> {
    this._cache[key] = null;
    return this._removeStorage(key);
  }

  /**
   * Get the keys of all entries stored in the cache
   * @return {Promise<string[]>} Resolves with an array of the keys
   */
  public keys(): Promise<string[]> {
    if (this.demoService.isOn) {
      return Promise.resolve([]);
    }

    return this.storageService.keys().then(keys => {
      return keys
        .filter(key => key.indexOf(CACHE_STORAGE_PREFIX) === 0)
        .map(key => key.substring(CACHE_STORAGE_PREFIX.length));
    });
  }

  /**
   * Clear both the local cache and disk (storage) cache
   */
  public clear(): Promise<any> {
    this._cache = {};

    this.sentryService.captureBreadcrumb({
      message: 'Cache cleared',
      category: HkSentryBreadcrumbCategory.Cache
    });

    return this.keys().then(cacheKeys => {
      return Promise.all(cacheKeys.map(key => this.remove(key)));
    });
  }

  /**
   * Prefix all cache keys for use in storage.
   * This allows us to differentiate the cache from all other device storage.
   */
  private _getStorageKey(key: string): string {
    return `${CACHE_STORAGE_PREFIX}${key}`;
  }

  private _getStorage(key: string): Promise<any> {
    return this.storageService.get(this._getStorageKey(key));
  }

  /**
   * Attempt to store the given key, value pair into storage.
   *
   * The request might fail if the user has exceeded their storage quota on
   * their device. In most cases this should be ~10MB, and so should not happen
   * unless services aren't clearing their cached data appropriately. We
   * respond to quota exceptions by clearing the cache and trying to cache the
   * given key-value pair once more.
   */
  private _setStorage(key: string, value: any, secondAttempt?: boolean): Promise<boolean> {
    const serialized = JSON.stringify(value);

    return this.storageService
      .set(this._getStorageKey(key), serialized)
      .then(() => true)
      .catch(err => {
        // Re-raise all non-quota related errors
        if (!this.errorService.isStorageQuotaExceededError(err)) {
          throw err;
        }

        // Only attempt to clear the cache once
        if (secondAttempt) {
          return false;
        }

        // Report the quote exception, clear the cache, and try once more.
        return this._getTotalSizeMegabytes().then(sizeMB => {
          this.sentryService.log({
            message: 'Storage quota exceeded.',
            level: 'debug',
            extras: { cacheSize: `${sizeMB}MB` }
          });

          return this.clear().then(() => this._setStorage(key, value, true));
        });
      });
  }

  private _removeStorage(key: string): Promise<any> {
    return this.storageService.remove(this._getStorageKey(key));
  }

  /**
   * Translate an expiry date-time to a unix millisecond timestamp.
   *
   * This is expressed in milliseconds for legacy reasons. Changing to seconds
   * would require migrating existing entries after the release.
   */
  private _getExpiryValue(dateTime: DateTime): number {
    return dateTime.unix() * 1000;
  }

  /**
   * Return whether the given cached entry should be considered expired.
   */
  private _isExpired(entry: CacheEntry): boolean {
    if (this.demoService.isOn) {
      return true;
    }

    return typeof entry.expires === 'undefined' || entry.expires < this._getExpiryValue(this.timeService.now());
  }

  /**
   * Return a promise which will resolve with the size of the cached entry in bytes.
   */
  private _getKeySizeBytes(key: string): Promise<number> {
    return this._getStorage(key).then(rawValue => key.length + rawValue.length);
  }

  /**
   * Return a promise which will resolve with the total size of the cache in Megabytes.
   */
  private _getTotalSizeMegabytes(): Promise<number> {
    return this.keys()
      .then(keys => {
        return Promise.all(keys.map(key => this._getKeySizeBytes(key)));
      })
      .then(keySizes => {
        const totalBytes = keySizes.reduce((a, b) => a + b, 0);
        return round(totalBytes / Math.pow(1000, 2), 2);
      });
  }
}

export { CacheService, CACHE_STORAGE_PREFIX };
