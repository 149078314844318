import { Component, Input } from '@angular/core';

import { Worker } from '@housekeep/infra';

@Component({
  selector: 'hk-avatar',
  templateUrl: './hk-avatar.component.html',
  styleUrls: ['./hk-avatar.component.scss']
})
export class HkAvatar {
  @Input() user: Worker;
  @Input() isHousekeep = false;
}
