import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'asAverageRating' })
export class AsAverageRating implements PipeTransform {
  public transform(rating: any): string {
    const ratingFloat = parseFloat(rating);
    return !isNaN(ratingFloat) ? ratingFloat.toFixed(1) : 'No ratings';
  }
}

export const ratingPipes = [AsAverageRating];
