import { Pipe, PipeTransform } from '@angular/core';

/** This shows pounds with either zero or two decimal places. */
@Pipe({ name: 'asGbp' })
export class AsGbp implements PipeTransform {
  public transform(pounds: number | string, alwaysShowPence: boolean = false): string {
    if (pounds === undefined || pounds === null) {
      return '';
    }

    let poundsNum;

    if (typeof pounds === 'string') {
      poundsNum = parseFloat(pounds as string);
    } else {
      poundsNum = pounds;
    }

    let poundsNeg = false;
    if (poundsNum < 0) {
      poundsNeg = true;
      poundsNum = poundsNum * -1;
    }

    const currencySymbol = '£';
    let amountString;

    const hasPence = poundsNum % 1 !== 0;

    if (hasPence || alwaysShowPence) {
      amountString = poundsNum.toFixed(2);
    } else {
      amountString = poundsNum.toString();
    }

    if (!poundsNeg) {
      return `${currencySymbol}${amountString}`;
    } else {
      return `-${currencySymbol}${amountString}`;
    }
  }
}

export const moneyPipes = [AsGbp];
