import { Component, EventEmitter, Input, Output } from '@angular/core';

import {
  ColorBrandBlue500,
  ColorGreen500,
  ColorGrey100,
  ColorOrange500,
  ColorRed500,
  ColorWhite
} from '@housekeep/design-tokens';
import { FulfilmentStates, WorkerMetrics } from '@housekeep/infra';

interface FulfilmentOption {
  color: string;
  rate: number;
  title: string;
  content: string;
}

@Component({
  selector: 'hk-fulfilment-card',
  templateUrl: './hk-fulfilment-card.component.html',
  styleUrls: ['./hk-fulfilment-card.component.scss']
})
export class HkFulfilmentCardComponent {
  @Input() metrics: WorkerMetrics;
  @Input() tappable = false;
  @Output() tap: EventEmitter<null> = new EventEmitter();

  public readonly fulfilmentCardOptions: Record<FulfilmentStates, FulfilmentOption> = {
    [FulfilmentStates.Low]: {
      color: ColorRed500,
      rate: 0.33,
      title: "You've booked lots of time off recently",
      content: 'Complete more cleans in your schedule to get new customers.'
    },
    [FulfilmentStates.Ok]: {
      color: ColorOrange500,
      rate: 0.66,
      title: "You've booked lots of time off recently",
      content: 'Complete more cleans in your schedule to keep getting new customers'
    },
    [FulfilmentStates.Good]: {
      color: ColorGreen500,
      rate: 1,
      title: 'Keep it up',
      content: 'Continue to attend your jobs to keep your customers happy'
    },
    [FulfilmentStates.None]: {
      color: ColorGreen500,
      rate: 1,
      title: 'Keep it up',
      content: 'Continue to attend your jobs to keep your customers happy'
    }
  };

  public readonly PROGRESS_COLOR = ColorBrandBlue500;
  public readonly FULFILMENT_STATES = FulfilmentStates;
  public readonly COLOR_WHITE = ColorWhite;
  public readonly COLOR_GREY = ColorGrey100;

  public get fulfilmentValues(): FulfilmentOption {
    return this.metrics?.fulfilmentState
      ? this.fulfilmentCardOptions[this.metrics?.fulfilmentState]
      : {
          color: ColorBrandBlue500,
          rate: 0,
          title: '',
          content: ''
        };
  }
}
