<div>
  <div *ngIf="reliability >= RELIABILITY_THRESHOLD_FOR_FULL_STREAK">
    <hk-round-progress
      [current]="TARGET_DAYS_RELIABLE"
      [max]="TARGET_DAYS_RELIABLE"
      [color]="COLOR_GOOD"
      [stroke]="25"
      [responsive]="true"
      [metricValue]="TARGET_DAYS_RELIABLE.toString()"
      metricLabel="Day Streak"
      [upperTargetLabel]="TARGET_DAYS_RELIABLE + ' days'"
    ></hk-round-progress>
  </div>
  <div *ngIf="reliability < RELIABILITY_THRESHOLD_FOR_FULL_STREAK">
    <hk-round-progress
      [current]="daysReliable"
      [max]="TARGET_DAYS_RELIABLE"
      [color]="COLOR_GOOD"
      [stroke]="25"
      [responsive]="true"
      [metricValue]="daysReliable.toString()"
      metricLabel="Day Streak"
      [upperTargetLabel]="TARGET_DAYS_RELIABLE + ' days'"
    ></hk-round-progress>
  </div>
</div>
