export const CUSTOMER_KEYS_DATA = [
  {
    next_visit: {},
    account: {
      name: 'Matt Matthers',
      initial_customer: {
        preferred_name: 'Matt',
        account_id: 'lnb7adh36bem',
        id: '6jovgns2pope'
      },
      id: 'lnb7adh36bem'
    },
    active_properties_same_account: 1,
    key: {
      code: '0381',
      location: 'worker',
      property_id: '6jovgpcrwope',
      holder_id: 'abcdef123456',
      transfer_in_progress: false,
      id: '59oy7vijloxr'
    },
    finished_visits_count: 28,
    property: {
      line_1: '10 Putney Embankment',
      line_2: 'Putney',
      line_3: '',
      city: 'London',
      postcode: {
        outward: 'SW15',
        inward: '1LB'
      },
      account_id: 'lnb7adh36bem',
      id: '6jovgpcrwope'
    }
  },
  {
    next_visit: {},
    account: {
      name: 'Tracy Donard',
      initial_customer: {
        preferred_name: 'Tracy',
        account_id: 'lnb7adh36bem',
        id: '6jovgns2pope'
      },
      id: 'lnb7adh36bem'
    },
    active_properties_same_account: 2,
    key: {
      code: '0007',
      location: 'customer',
      property_id: '6jovgpcrwope',
      holder_id: null,
      transfer_in_progress: true,
      id: '59oy7vijloxr'
    },
    finished_visits_count: 45,
    property: {
      line_1: 'Flat C4',
      line_2: '18 Flowers Hill',
      line_3: '',
      city: 'London',
      postcode: {
        outward: 'SW3',
        inward: '5XB'
      },
      account_id: 'lnb7adh36bem',
      id: '6jovgpcrwope'
    }
  },
  {
    next_visit: {},
    account: {
      name: 'Dennis Donard',
      initial_customer: {
        preferred_name: 'Dennis',
        account_id: 'lnb7adh36bem',
        id: '6jovgns2pope'
      },
      id: 'lnb7adh36bem'
    },
    active_properties_same_account: 2,
    key: null,
    finished_visits_count: 65,
    property: {
      line_1: '2 Basil Street',
      line_2: 'Knightsbridge',
      line_3: '',
      city: 'London',
      postcode: {
        outward: 'SW3',
        inward: '1AA'
      },
      account_id: 'lnb7adh36bem',
      id: '6jovgpcrwope'
    }
  },
  {
    next_visit: {},
    account: {
      name: 'Tara Sturridge',
      initial_customer: {
        preferred_name: 'Tara',
        account_id: 'xb6ymh27dkop',
        id: '2jovgns3pope'
      },
      id: 'xb6ymh27dkop'
    },
    active_properties_same_account: 1,
    key: {
      code: '3456',
      location: 'worker',
      property_id: 'xowxycwekmz2',
      holder_id: null,
      transfer_in_progress: true,
      remote_key_transfer_in_progress: true,
      id: 'dnb5rpfwa2o4'
    },
    finished_visits_count: 65,
    property: {
      line_1: '91 Bolingbroke Grove',
      line_2: 'Wandsworth',
      line_3: '',
      city: 'London',
      postcode: {
        outward: 'SW11',
        inward: '1DB'
      },
      account_id: 'xb6ymh27dkop',
      id: 'xowxycwekmz2'
    }
  }
];
