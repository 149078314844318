import { Date, defineModelProperties, Key, Model, model, Time, Visit } from '@housekeep/infra';

import { VisitNotInDay } from 'util/error';

import { Period, PeriodType } from './period';

export enum DayState {
  NotWorking = 'not_working',
  NoVisits = 'no_visits',
  Visits = 'visits'
}

interface WorkingDayMixin {
  getStartedVisit: () => Visit | null;
  hasChanges: (visit: Visit) => boolean;
  hasShortVisits: () => boolean;
  hasVisits: boolean;
  keysRequired: Key[];
  requiresProducts: boolean;
  state: DayState;
  visits: Visit[];
  visitNumber: (visit: Visit) => number;
}

export interface WorkingDay extends Model, WorkingDayMixin {
  activities: Period[];
  availabilityOverridden?: boolean;
  day: Date;
  endTime: Time;
  isFeasible: boolean;
  skippedVisits: Visit[];
  startTime: Time;
  totalHours?: number;
  working: boolean;
}

export const workingDay: WorkingDay = model.extend({
  modelName: 'workingDay',

  visitNumber(visit): number {
    const index = this.visits.map(v => v.jobId).indexOf(visit.jobId);

    if (index === -1) {
      throw new VisitNotInDay();
    } else {
      return index + 1;
    }
  },

  getStartedVisit() {
    return this.visits.find(visit => visit.isStarted);
  },

  hasChanges(visit) {
    return this.activities
      .filter(period => period.type === PeriodType.Work)
      .some(period => period.visit === visit && period.changes);
  },

  /**
   * For work periods smaller than an hour calendar view will not display correctly
   */
  hasShortVisits() {
    return this.activities.some(activity => activity.type === PeriodType.Work && activity.duration.toHours() < 1);
  }
});

defineModelProperties<WorkingDay>(workingDay, {
  visits: {
    get(): Visit[] {
      return this.activities.filter(period => period.type === PeriodType.Work).map(period => period.visit);
    }
  },

  hasVisits: {
    get(): boolean {
      return this.visits.length !== 0;
    }
  },

  keysRequired: {
    get(): Key[] {
      return this.getOrCache('keysRequired', () => {
        const requiredKeys = this.visits.map(visit => visit.requiredKeys);
        return [].concat(...requiredKeys).filter(key => key);
      });
    }
  },

  requiresProducts: {
    get(): boolean {
      return this.visits.some(visit => visit.requiresProducts);
    }
  },

  state: {
    get(): DayState {
      if (this.working) {
        if (this.visits.length) {
          return DayState.Visits;
        } else {
          return DayState.NoVisits;
        }
      } else {
        return DayState.NotWorking;
      }
    }
  }
});
