import { Injectable } from '@angular/core';

import { BehaviorSubject, from, Observable, Subscription, timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { nowNaive, RequestOpts, subtractDaysFromDate } from '@housekeep/infra';

import { CLOSED_STATUSES, OPEN_STATUSES, Ticket } from 'models/ticket';
import { TicketComment } from 'models/ticket-comment';

import { ticketSerializer } from 'serializers/ticket';
import { ticketCommentSerializer } from 'serializers/ticket-comment';

import { RequestService } from './request-service';
import { TimeService } from './time-service';

const TICKETS_ENDPOINT = 'integrations/zendesk/ticket-links/';
const TICKET_ENDPOINT_ROOT = 'integrations/zendesk/ticket-link/';
const TICKETS_COUNT_ENDPOINT = `${TICKETS_ENDPOINT}count/`;

interface CommentOnTicketPayload {
  body: string;
}

export interface TicketCountResponse {
  count: number;
  unreadCount: number;
}

@Injectable({ providedIn: 'root' })
class TicketService {
  private _ticketCountSource$: Observable<TicketCountResponse>;
  private _inProgressTicketsCount$ = new BehaviorSubject<number>(null);
  private _unreadInProgressTicketCount$ = new BehaviorSubject<number>(null);
  private inProgressCountSubscription: Subscription;
  private unreadInProgressCountSubscription: Subscription;

  constructor(private requestService: RequestService, protected timeService: TimeService) {}

  public getTicket(ticketId: string): Promise<Ticket> {
    const url = `${TICKET_ENDPOINT_ROOT}${ticketId}/`;
    return this.requestService.getInstance(url, ticketSerializer);
  }

  public getInProgressTicketCount(): Observable<number> {
    return this._inProgressTicketsCount$.asObservable();
  }

  public getUnreadInProgressTicketCount(): Observable<number> {
    return this._unreadInProgressTicketCount$.asObservable();
  }

  public initTicketCountPoll(): void {
    this._ticketCountSource$ = timer(0, 60000).pipe(
      switchMap(() => {
        return from(this.getTicketCount({ status: OPEN_STATUSES }));
      })
    );
    this.inProgressCountSubscription = this._ticketCountSource$.subscribe(counts =>
      this._inProgressTicketsCount$.next(counts ? counts.count : 0)
    );
    this.unreadInProgressCountSubscription = this._ticketCountSource$.subscribe(counts =>
      this._unreadInProgressTicketCount$.next(counts ? counts.unreadCount : 0)
    );
  }

  public updateInProgressTicketCount(count: number): void {
    this._inProgressTicketsCount$.next(count);
  }

  public updateUnreadInProgressTicketCount(unreadCount: number): void {
    this._unreadInProgressTicketCount$.next(unreadCount);
  }

  /**
   * Kill-switch for the 1-minute polling timer for ticket count.
   */
  public killTicketCountSubscription(): void {
    this.inProgressCountSubscription.unsubscribe();
    this.unreadInProgressCountSubscription.unsubscribe();
  }

  public getOpenTickets(): Observable<Ticket> {
    return this.getAllTickets({
      status: OPEN_STATUSES,
      ordering: '-last_updated_at'
    });
  }

  public getClosedTickets(): Observable<Ticket> {
    const lastUpdatedSince = subtractDaysFromDate(nowNaive(), 14).startOf('day').utc().toISOString();
    return this.getAllTickets({
      status: CLOSED_STATUSES,
      ordering: '-last_updated_at',
      updatedSince: lastUpdatedSince
    });
  }

  public getCommentsForTicket(ticket: Ticket): Observable<TicketComment> {
    const url = `${TICKET_ENDPOINT_ROOT}${ticket.ticketId}/comments/`;
    const opts: RequestOpts = {
      params: {
        expand: ['html_body', 'from_party', 'agent_name'],
        ordering: 'created_at'
      },
      serializer: ticketCommentSerializer,
      serializerKwargs: { many: true }
    };
    return this.requestService.getAllPages(url, opts);
  }

  public commentOnTicket(ticket: Ticket, comment: string): Promise<CommentOnTicketPayload> {
    const url = `${TICKET_ENDPOINT_ROOT}${ticket.ticketId}/comment/`;
    return this.requestService.post(url, { body: comment });
  }

  public markCommentsAsRead(ticket: Ticket): Promise<void> {
    const readTime = this.timeService.now();
    const url = `${TICKET_ENDPOINT_ROOT}${ticket.ticketId}/mark-comments-read/`;
    return this.requestService.post(url, { readAt: readTime });
  }

  public getTicketCount(params?: {}): Promise<TicketCountResponse> {
    return this.requestService.get(TICKETS_COUNT_ENDPOINT, {
      params,
      suppressTimeoutErrors: true
    });
  }

  private getAllTickets(params: {}): Observable<Ticket> {
    const opts: RequestOpts = {
      params: params,
      serializer: ticketSerializer,
      serializerKwargs: { many: true }
    };
    return this.requestService.getAllPages(TICKETS_ENDPOINT, opts);
  }
}

export { TicketService };
