// One week's worth of response data for the `working-days` endpoint with
// placeholder dates and `visit__job_id`s instead of `visit`s:
const workingDayTemplates = [
  {
    activities: [
      {
        seconds: 32400,
        start: '{{ date }}T00:00:00',
        end: '{{ date }}T09:00:00',
        type: 'BreakPeriod',
        clash: null
      },
      {
        end: '{{ date }}T11:30:00',
        clash: null,
        seconds: 9000,
        visit__job_id: '2z4l6twj9lo4',
        start: '{{ date }}T09:00:00',
        type: 'WorkPeriod'
      },
      {
        seconds: 900,
        start: '{{ date }}T11:30:00',
        end: '{{ date }}T12:00:00',
        type: 'IdlePeriod'
      },
      {
        end: '{{ date }}T15:00:00',
        changes: true,
        clash: null,
        seconds: 10800,
        visit__job_id: 'thechangesvisit',
        start: '{{ date }}T12:00:00',
        type: 'WorkPeriod'
      },
      {
        seconds: 7200,
        start: '{{ date }}T15:00:00',
        end: '{{ date }}T17:00:00',
        type: 'IdlePeriod'
      },
      {
        seconds: 25199,
        start: '{{ date }}T17:00:00',
        end: '{{ date }}T23:59:59',
        type: 'BreakPeriod',
        clash: null
      }
    ],
    is_feasible: true,
    skipped_visits: [],
    working: true,
    availability_overridden: false,
    start_time: '09:00:00',
    on_break: false,
    total_hours: 8.5,
    too_much_work: false,
    end_time: '18:30:00',
    total_distance: 1996.600719394376,
    day: '{{ date }}',
    route_overridden: false
  },
  {
    activities: [
      {
        seconds: 32400,
        start: '{{ date }}T00:00:00',
        end: '{{ date }}T09:00:00',
        type: 'BreakPeriod',
        clash: null
      },
      {
        end: '{{ date }}T14:30:00',
        clash: null,
        seconds: 28800,
        visit__job_id: 'wbkjxt93nnz7',
        start: '{{ date }}T09:00:00',
        type: 'WorkPeriod'
      },
      {
        end: '{{ date }}T15:30:00',
        seconds: 3600,
        start: '{{ date }}T14:30:00',
        type: 'IdlePeriod'
      },
      {
        clash: null,
        seconds: 3600,
        visit__job_id: '84kdxt93wnz7',
        start: '{{ date }}T15:30:00',
        end: '{{ date }}T16:30:00',
        type: 'WorkPeriod'
      },
      {
        seconds: 19799,
        start: '{{ date }}T16:30:00',
        end: '{{ date }}T23:59:59',
        type: 'BreakPeriod',
        clash: null
      }
    ],
    is_feasible: true,
    skipped_visits: [],
    working: true,
    availability_overridden: false,
    start_time: '09:00:00',
    on_break: false,
    total_hours: 6.5,
    too_much_work: false,
    end_time: '18:30:00',
    total_distance: 1637.9003864833303,
    day: '{{ date }}',
    route_overridden: false
  },
  {
    activities: [
      {
        seconds: 32400,
        start: '{{ date }}T00:00:00',
        end: '{{ date }}T09:00:00',
        type: 'BreakPeriod',
        clash: null
      },
      {
        end: '{{ date }}T18:30:00',
        clash: null,
        seconds: 32400,
        start: '{{ date }}T09:00:00',
        type: 'IdlePeriod'
      },
      {
        seconds: 19799,
        start: '{{ date }}T18:30:00',
        end: '{{ date }}T23:59:59',
        type: 'BreakPeriod',
        clash: null
      }
    ],
    is_feasible: true,
    skipped_visits: [],
    working: true,
    availability_overridden: false,
    start_time: '09:00:00',
    on_break: false,
    total_hours: 0.0,
    too_much_work: false,
    end_time: '18:30:00',
    total_distance: 2397.3677914191003,
    day: '{{ date }}',
    route_overridden: false
  },
  {
    activities: [
      {
        seconds: 32400,
        start: '{{ date }}T00:00:00',
        end: '{{ date }}T09:00:00',
        type: 'BreakPeriod',
        clash: null
      },
      {
        end: '{{ date }}T14:00:00',
        clash: null,
        seconds: 18000,
        visit__job_id: '9oygmtpjvlox',
        start: '{{ date }}T09:00:00',
        type: 'WorkPeriod'
      },
      {
        start: '{{ date }}T14:00:00',
        end: '{{ date }}T16:45:00',
        seconds: 6300,
        clash: null,
        type: 'IdlePeriod'
      },
      {
        clash: null,
        seconds: 900,
        visit__job_id: 'vz9p2hxl88bq',
        start: '{{ date }}T16:45:00',
        end: '{{ date }}T17:00:00',
        type: 'WorkPeriod'
      },
      {
        seconds: 25199,
        start: '{{ date }}T17:00:00',
        end: '{{ date }}T23:59:59',
        type: 'BreakPeriod',
        clash: null
      }
    ],
    is_feasible: true,
    skipped_visits: [
      {
        account: {
          id: 'vjze8xhqwnzn',
          name: 'Kate Bird',
          initial_customer: {
            account_id: 'vjze8xhqwnzn',
            preferred_name: 'Kate',
            id: 'vxowx4svq4z2'
          }
        },
        skipped_by_user_role: 'customers',
        property_id: 'nqzxwpcverok',
        time_slot: {
          start: '09:00:00',
          end: '12:00:00'
        },
        finished: false,
        property: {
          id: 'nqzxwpcverok',
          bedrooms: 2,
          line_1: 'Studio 6',
          postcode: {
            location: {
              longitude: '1.0',
              latitude: '1.0'
            },
            outward: 'SW3',
            inward: '6ED'
          },
          line_3: '',
          line_2: 'Stuart station',
          pets: {
            cats: true
          },
          city: '',
          account_id: 'vjze8xhqwnzn',
          access: 'keyhiddn',
          bathrooms: 2
        },
        tip_amount_pounds: null,
        customer_can_alter: false,
        any_worker: false,
        week_beginning: '2018-11-12',
        account_id: 'vjze8xhqwnzn',
        tasks: [
          {
            type: 'late-skip',
            hours: null
          }
        ],
        access: 'keyhiddn',
        started_at: null,
        is_first_visit: false,
        is_reviewable: false,
        job_id: 'kz3patl543br',
        customer_billed_for_skip_after: '2018-11-13T18:00:00Z',
        worker_property_access: 'can_access',
        finished_at: null,
        scheduled_date: '2018-11-14',
        access_display: 'Key hidden',
        customer_can_skip: true,
        id: 'dznvwirea3o5',
        skipped: true,
        frequency: 7.0,
        actual_date: '2018-11-14'
      }
    ],
    working: true,
    availability_overridden: false,
    start_time: '09:00:00',
    on_break: false,
    total_hours: 5,
    too_much_work: false,
    end_time: '18:30:00',
    total_distance: 2540.5710302713596,
    day: '{{ date }}',
    route_overridden: false
  },
  {
    activities: [
      {
        seconds: 32400,
        start: '{{ date }}T00:00:00',
        end: '{{ date }}T09:00:00',
        type: 'BreakPeriod',
        clash: null
      },
      {
        end: '{{ date }}T12:00:00',
        clash: null,
        seconds: 10800,
        visit__job_id: 'rom4qtkr75oy',
        start: '{{ date }}T09:00:00',
        type: 'WorkPeriod'
      },
      {
        seconds: 7200,
        start: '{{ date }}T12:00:00',
        end: '{{ date }}T14:00:00',
        type: 'BreakPeriod',
        clash: null
      },
      {
        seconds: 5400,
        start: '{{ date }}T14:00:00',
        end: '{{ date }}T15:30:00',
        type: 'IdlePeriod'
      },
      {
        end: '{{ date }}T18:30:00',
        clash: null,
        seconds: 10800,
        visit__job_id: 'jze8dt2geqzn',
        start: '{{ date }}T15:30:00',
        type: 'WorkPeriod'
      },
      {
        seconds: 19799,
        start: '{{ date }}T18:30:00',
        end: '{{ date }}T23:59:59',
        type: 'BreakPeriod',
        clash: null
      }
    ],
    is_feasible: true,
    skipped_visits: [],
    working: true,
    availability_overridden: false,
    start_time: '09:00:00',
    on_break: false,
    total_hours: 6.0,
    too_much_work: false,
    end_time: '18:30:00',
    total_distance: 3474.769798330561,
    day: '{{ date }}',
    route_overridden: false
  },
  {
    activities: [
      {
        seconds: 32400,
        start: '{{ date }}T00:00:00',
        end: '{{ date }}T09:00:00',
        type: 'BreakPeriod',
        clash: null
      },
      {
        end: '{{ date }}T12:00:00',
        clash: null,
        seconds: 10800,
        visit__job_id: 'norwatg2n7b2',
        start: '{{ date }}T09:00:00',
        type: 'WorkPeriod'
      },
      {
        seconds: 7200,
        start: '{{ date }}T12:00:00',
        end: '{{ date }}T14:00:00',
        type: 'BreakPeriod',
        clash: null
      },
      {
        end: '{{ date }}T17:00:00',
        clash: null,
        seconds: 10800,
        visit__job_id: 'nb7axtdqn6be',
        start: '{{ date }}T14:00:00',
        type: 'WorkPeriod'
      },
      {
        seconds: 25199,
        start: '{{ date }}T17:00:00',
        end: '{{ date }}T23:59:59',
        type: 'BreakPeriod',
        clash: null
      }
    ],
    is_feasible: true,
    skipped_visits: [],
    working: true,
    availability_overridden: false,
    start_time: '09:00:00',
    on_break: false,
    total_hours: 6.0,
    too_much_work: false,
    end_time: '17:00:00',
    total_distance: 3826.4324120164197,
    day: '{{ date }}',
    route_overridden: false
  },
  {
    activities: [
      {
        seconds: 86399,
        start: '{{ date }}T00:00:00',
        end: '{{ date }}T23:59:59',
        type: 'BreakPeriod',
        clash: null
      }
    ],
    availability_overridden: false,
    day: '{{ date }}',
    end_time: null,
    is_feasible: true,
    on_break: false,
    route_overridden: false,
    skipped_visits: [],
    start_time: null,
    too_much_work: false,
    total_distance: 0,
    total_hours: 0,
    working: false
  }
];

export { workingDayTemplates };
