import { model, Model, Visit } from '@housekeep/infra';

export enum WorkerVisitRatingChoice {
  Bad = 1,
  Okay = 3,
  Good = 5
}

export enum WorkerRatingIssue {
  CleaningEquipment = 'cleaning_equipment',
  CleaningProducts = 'cleaning_products',
  NotEnoughTime = 'not_enough_time',
  NeedsDeepClean = 'needs_deep_clean',
  NeedsEotClean = 'needs_eot_clean',
  CustomerAttitude = 'customer_attitude',
  Access = 'access',
  JobLocation = 'job_location'
}

export interface WorkerVisitRatingIssue extends Model {
  /** The ratings for which the issue applies. */
  ratings: WorkerVisitRatingChoice[];
  /** The key for the issue. */
  issue: WorkerRatingIssue;
  /** The human-readable name for the issue */
  issueDisplay: string;
  /** If the housekeeper let the customer know about the issue. */
  letCustomerKnow: boolean;
  /** If the housekeeper brought cleaning products. */
  broughtCleaningProducts: boolean;
}

export const workerVisitRatingIssue: WorkerVisitRatingIssue = model.extend({
  modelName: 'workerVisitRatingIssue'
});

export interface WorkerVisitRating extends Model {
  blockCustomer: boolean;
  issues: WorkerVisitRatingIssue[];
  moreInfo: string;
  rating: WorkerVisitRatingChoice;
  visit?: Visit;
}

export const workerVisitRating: WorkerVisitRating = model.extend({
  modelName: 'workerVisitRating'
});
