<ion-header [class.hide]="!hasRated()">
  <ion-toolbar>
    <ion-title class="mood-sampling-modal__modal-title">
      {{ title || 'Working with Housekeep' }}
    </ion-title>
    <ion-buttons slot="start">
      <ion-button (click)="dismiss()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ng-template #content>
  <div class="worker-rating-modal mood-sampling-modal inner-content ion-padding">
    <h1 *ngIf="!hasRated()" class="mood-sampling-modal__title">
      {{ title || 'Working with Housekeep' }}
    </h1>
    <h2
      class="mood-sampling-modal__question"
      [class.mood-sampling-modal__question__selected]="hasRated()"
    >
      {{ question }}
    </h2>
    <ion-grid
      class="mood-sampling-modal__rate-icons"
      [class.mood-sampling-modal__rate-icons__selected]="hasRated()"
    >
      <ion-row class="ion-justify-content-evenly">
        <ion-col size="4">
          <div
            class="radio-button"
            (click)="setRatingBad()"
            [class.danger]="isRatingBad()"
          >
            <hk-wc-icon [name]="negativeIcon || 'face-sad'" size="50"></hk-wc-icon>
            <p class="strong">
              {{ negativeAnswer || 'bad' }}
            </p>
          </div>
        </ion-col>
        <ion-col size="4">
          <div
            class="radio-button"
            (click)="setRatingGood()"
            [class.success]="isRatingGood()"
          >
            <hk-wc-icon [name]="positiveIcon || 'face-happy'" size="50"></hk-wc-icon>
            <p class="strong">
              {{ positiveAnswer || 'good' }}
            </p>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
    <div *ngIf="hasRated()">
      <form #rateJobForm="ngForm" (submit)="submitRating()">
        <ion-button
          expand="block"
          class="ion-margin-top"
          type="submit"
          [disabled]="!rateJobForm.form.valid || hasSubmitted"
        >
          Submit
        </ion-button>
      </form>
    </div>
  </div>
</ng-template>

<ion-content *ngIf="hasRated(); else content" [scrollY]="true">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</ion-content>
