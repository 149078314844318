import { Injectable } from '@angular/core';

import { LoadingController } from '@ionic/angular';
import { LoadingOptions } from '@ionic/core';

import { noop } from 'lodash-es';

const DEFAULT_OPTS: LoadingOptions = {
  duration: 30000
};

@Injectable({ providedIn: 'root' })
export class LoadingService {
  private loaderPromise: Promise<HTMLIonLoadingElement>;

  constructor(public loadingCtrl: LoadingController) {}

  /**
   * Show Ionic's loading overlay
   * @param {Object} opts - passed straight through to Ionic's loader
   */
  public async showLoader(opts: LoadingOptions = {}): Promise<void> {
    await this.dismissLoader();

    const loaderOpts = Object.assign({}, DEFAULT_OPTS, opts);

    this.loaderPromise = this.loadingCtrl.create(loaderOpts);
    const loader = await this.loaderPromise;
    await loader.present();
  }

  /**
   * Change the loader's message.
   * @param {string} message The new loader message
   */
  public async changeLoaderMessage(message: string): Promise<void> {
    if (this.loaderPromise) {
      const loader = await this.loaderPromise;
      loader.message = message;
    }
  }

  /**
   * Dismiss an overlay if there is one
   */
  public async dismissLoader(): Promise<void> {
    if (this.loaderPromise) {
      const loader = await this.loaderPromise;
      loader.dismiss().catch(noop);
      this.loaderPromise = null;
    }
  }
}
