import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';

import { Visit } from '@housekeep/infra';

import { TimeService } from 'services/time-service';

/**
 * Displays a visit block within the schedule.
 */
@Component({
  selector: 'visit-block',
  templateUrl: './visit-block.component.html',
  styleUrls: ['./visit-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VisitBlock {
  @Input() hasChanges: boolean;
  @Input() visit: Visit;
  @Input() visitNumber: number;

  constructor(private router: Router, private timeService: TimeService) {}
  /*
   * Modify customer name to make suitable for app display
   */
  public getDisplayName(customerName: string): string {
    const MAX_LENGTH = 15;
    const ELLIPSIS = '...';
    customerName = customerName.split('@')[0]; // Email provider not relevant
    if (customerName.length < MAX_LENGTH) {
      return customerName;
    }

    const customerNames = customerName.split(' ').filter(Boolean);
    let displayName = '';
    for (const name of customerNames) {
      displayName = displayName.concat(name);
      if (displayName.length === MAX_LENGTH) {
        return displayName;
      } else if (displayName.length > MAX_LENGTH) {
        return displayName.substring(0, MAX_LENGTH - ELLIPSIS.length).concat(ELLIPSIS);
      }
      displayName = displayName.concat(' ');
    }
    return customerName;
  }

  public visitIsToday(): boolean {
    return this.visit.actualDate.isSame(this.timeService.today());
  }

  public async goToVisit(visit): Promise<void> {
    await this.router.navigate([
      '/app-housekeepers/schedule/visit',
      visit.jobId,
      visit.scheduledDate.format('YYYY-MM-DD'),
      visit.actualDate.format('YYYY-MM-DD')
    ]);
  }

  /**
   * Returns the appropriate start time slot as a nicely formatted string.
   */
  public get getStartTimeDisplayValue(): string {
    if (this.visit.recommendedStartTimeSlot) {
      return this.visit.recommendedStartTimeSlot.startTimeDisplay({ includeSharp: false });
    }
    return this.visit.timeSlot.startTimeDisplay({ includeSharp: false });
  }
}
