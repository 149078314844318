import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';

import { HttpParams } from '@capacitor/core';

import { BehaviorSubject, interval, Observable, Observer, Subscription } from 'rxjs';
import { filter, timeout } from 'rxjs/operators';

import {
  ApiUrlService,
  DeleteOpts,
  ModelSerializer,
  PaginatedResponse,
  paginatedResponse,
  parseParams,
  serializer,
  sleep
} from '@housekeep/infra';

import { Unauthorized } from 'util/error';
import { RequestOptsWithOfflineHandling } from 'util/http';

import { AuthService } from './auth-service';
import { CacheService } from './cache-service';
import { DemoService } from './demo-service';
import { ErrorService } from './error-service';
import { OfflineEvent, OfflineEventService } from './offline-event-service';
import { OnlineService } from './online-service';
import { ToastService } from './toast-service';
import { VERSION_ENDPOINT } from './version-service';

/**
 * The default timeout in ms for GET requests.
 */
const DEFAULT_GET_TIMEOUT = 25000;
/**
 * The default timeout in ms for POST/PUT requests etc.
 * This is even higher than the timeout imposed in the backend
 * to accommodate slow network speeds.
 */
export const DEFAULT_UPDATE_TIMEOUT = 35000;

/**
 * Service to provide common HTTP request functions.
 */
@Injectable({ providedIn: 'root' })
export class RequestService implements OnDestroy {
  private readonly offlineEventProcessingInterval$: Observable<number>;
  private readonly offlineEventProcessingIntervalSubscription: Subscription;

  private readonly processingOfflineEventsSubject = new BehaviorSubject<boolean>(false);

  constructor(
    private apiUrlService: ApiUrlService,
    private authService: AuthService,
    private cacheService: CacheService,
    private demoService: DemoService,
    private errorService: ErrorService,
    private toastService: ToastService,
    private http: HttpClient,
    private offlineEventService: OfflineEventService,
    private onlineService: OnlineService
  ) {
    // Process offline events every 5 seconds.
    this.offlineEventProcessingInterval$ = interval(5000);
    this.offlineEventProcessingIntervalSubscription = this.offlineEventProcessingInterval$.subscribe(() => {
      this.processOfflineEvents();
    });
  }

  // Observable to track whether we are processing offline events.
  private get processingOfflineEvents$(): Observable<boolean> {
    return this.processingOfflineEventsSubject.asObservable();
  }

  // Get whether we are currently processing offline events.
  private get processingOfflineEvents(): boolean {
    return this.processingOfflineEventsSubject.value;
  }

  // Set whether we are currently processing offline events.
  private set processingOfflineEvents(value: boolean) {
    this.processingOfflineEventsSubject.next(value);
  }

  public ngOnDestroy(): void {
    // Unsubscribe from the interval when the service is destroyed.
    this.offlineEventProcessingIntervalSubscription.unsubscribe();
  }

  /**
   * Retrieve the given endpoint's data from the server.
   * In most cases, the getInstance() and getList() methods should be used.
   * @param endpoint - The endpoint to send a GET request to.
   * @param opts - Additional options for processing this request.
   * @return Response data.
   */
  public async get(endpoint: string, opts: RequestOptsWithOfflineHandling = {}): Promise<any> {
    // Wait for offline events to be processed before making the request if
    // passed in the options.
    if (opts.waitForOfflineEvents) {
      if (this.processingOfflineEvents) {
        // If we're already processing offline events, wait for it to finish.
        // No need to rerun processing after this has resolved.
        await this.processingOfflineEvents$.pipe(filter(processing => processing === false)).toPromise();
      } else {
        // Events haven't been processed - process them now.
        await this.processOfflineEvents();
      }
    }
    return this._getRequest(endpoint, opts).then(response => this._handleResponse(response, opts));
  }

  /**
   * Helper method to retrieve a single instance.
   * @param endpoint - The endpoint to send a GET request to.
   * @param serializer - The serializer to use to process the response.
   * @param opts - Additional options for processing this request.
   * @return Response data.
   */
  public getInstance<T>(
    endpoint: string,
    serializer: ModelSerializer<T>,
    opts: RequestOptsWithOfflineHandling = {}
  ): Promise<T> {
    opts.serializer = serializer;
    return this.get(endpoint, opts);
  }

  /**
   * Helper method to retrieve a list of instances.
   * @param endpoint - The endpoint to send a GET request to.
   * @param serializer - The serializer to use to process the response.
   * @param opts - Additional options for processing this request.
   * @return Response data.
   */
  public getList<T>(
    endpoint: string,
    serializer: ModelSerializer<T>,
    opts: RequestOptsWithOfflineHandling = {}
  ): Promise<T[]> {
    const serializerKwargs = opts.serializerKwargs || {};
    serializerKwargs.many = true;
    opts.serializer = serializer;
    opts.serializerKwargs = serializerKwargs;
    return this.get(endpoint, opts);
  }

  /**
   * Send a PATCH request to an endpoint.
   * @param endpoint - The endpoint to send a PATCH request to.
   * @param data - Payload to send in the request.
   * @param opts - Additional options for processing this request.
   * @return Response data.
   */
  public async patch(endpoint: string, data: any, opts?: RequestOptsWithOfflineHandling) {
    await this.processOfflineEvents();
    return this._updateRequest('patch', endpoint, data, opts);
  }

  /**
   * Send a POST request to an endpoint.
   * @param endpoint - The endpoint to send a POST request to.
   * @param data - Payload to send in the request.
   * @param opts - Additional options for processing this request.
   * @return Response data.
   */
  public async post(endpoint: string, data: any, opts?: RequestOptsWithOfflineHandling) {
    await this.processOfflineEvents();
    return this._updateRequest('post', endpoint, data, opts);
  }

  /**
   * Send a PUT request to an endpoint.
   * @param endpoint - The endpoint to send a PUT request to.
   * @param data - Payload to send in the request.
   * @param opts - Additional options for processing this request.
   * @return Response data.
   */
  public async put(endpoint: string, data: any, opts?: RequestOptsWithOfflineHandling) {
    await this.processOfflineEvents();
    return this._updateRequest('put', endpoint, data, opts);
  }

  /**
   * Send a DELETE request to an endpoint.
   * @param endpoint - The endpoint to send a DELETE request to.
   * @param opts - Additional options for processing and sending this request.
   * @return Response data.
   */
  public delete(endpoint: string, opts: DeleteOpts = {}) {
    opts.headers = this.authService.requestHeaders;

    return this._httpOrDemoService
      .delete(this.apiUrlService.normaliseUrl(endpoint), opts)
      .pipe(timeout(opts.timeout || DEFAULT_UPDATE_TIMEOUT))
      .toPromise();
  }

  /**
   * Query the server and expect a paginated response.
   * The paginated response instance is responsible for serializing results,
   * and provides methods to return additional pages.
   * @param endpoint - The endpoint to query
   * @param opts - Additional options for processing this request.
   * @param pageNumber - The page number to retrieve.
   * @return Response data.
   */
  public getPage(
    endpoint: string,
    opts: RequestOptsWithOfflineHandling,
    pageNumber?: number
  ): Promise<PaginatedResponse<any>> {
    const { serializer, serializerKwargs = {} } = opts;

    serializerKwargs.many = true;

    if (pageNumber) {
      opts.params = Object.assign(opts.params || {}, { page: pageNumber });
    }

    return this._getRequest(endpoint, opts).then(json => {
      this.toastService.dismissOfflineToast().then();

      return paginatedResponse.create({
        requestFn: this.getPage.bind(this, endpoint, opts),
        json,
        serializer,
        serializerKwargs
      });
    });
  }

  /**
   * Query a paged endpoint and return results from all pages through an Observable.
   * Note that it returns single model instances at a time, not pages.
   * On encountering an error with a retrieving a page in the sequence,
   * it will stop fetching pages and return the last error through the
   * Observer.
   * @param endpoint - The endpoint to query
   * @param opts - Additional options for processing this request.
   * @return Response data.
   **/
  public getAllPages<T>(endpoint: string, opts: RequestOptsWithOfflineHandling): Observable<T> {
    return new Observable<T>(subscriber => {
      this.fetchAllPages(subscriber, endpoint, opts);
    });
  }

  /**
   * Handle execution of previously enqueued offline requests
   */
  public async processOfflineEvents(): Promise<void> {
    // Only allow one processing "loop" at a time
    if (this.processingOfflineEvents) {
      return;
    }

    // If there are no offline events queued, return. Prevents unnecessary
    // updates to the `processingOfflineEvents` state
    if (!(await this.offlineEventService.offlineQueuePopulated())) {
      return;
    }

    this.processingOfflineEvents = true;

    const offlineEvent = await this.offlineEventService.getNextEventFromQueue();
    // Return out if there are no offline events to process
    if (offlineEvent === undefined) {
      this.processingOfflineEvents = false;
      return;
    }

    // Use the version endpoint to check if requests can be made.
    try {
      await this.get(VERSION_ENDPOINT);
      this.onlineService.setOnlineState(true);
    } catch (err) {
      this.onlineService.setOnlineState(false);
      // There's no connectivity to the backend - return
      this.processingOfflineEvents = false;
      return;
    }

    offlineEvent.opts.noTransform = true; // transformed when initially enqueued
    offlineEvent.payload._offline = {
      offline_event: true,
      event_timestamp: offlineEvent.eventRegisteredAt
    };

    // Attempt to process the fetched offline event.
    try {
      await this._updateRequest(offlineEvent.method, offlineEvent.endpoint, offlineEvent.payload, offlineEvent.opts);

      await this.offlineEventService.deleteEventByUUID(offlineEvent.uuid);

      // Wait for a short period to give breathing room between requests.
      await sleep(500);

      // Recursively call the function to process the next event until the
      // queue is empty.
      return this.processOfflineEvents();
    } catch (err) {
      // If the request fails due to a network error, do nothing -
      // the event will be reprocessed later.
      if (this.errorService.isNoInternetError(err) || this.errorService.isDemoModeError(err)) {
        return;
      }

      // If the request fails due to a permissions error, purge the whole queue.
      if (this.errorService.isHttp401UnauthorizedError(err)) {
        await this.offlineEventService.resetOfflineEventQueue();
        return;
      }

      // If the request failed for other reasons - delete it from the queue.
      if (this.errorService.isHttp4xxError(err) || this.errorService.isHttp500ServerError(err)) {
        await this.offlineEventService.deleteEventByUUID(offlineEvent.uuid);
      }

      const errorHandled = await this.offlineEventService.onOfflineRequestError(err, offlineEvent);

      if (!errorHandled) {
        this._onRequestError(err);
      }
    } finally {
      // Ensure that the processing state is reset so the loop can continue.
      this.processingOfflineEvents = false;
    }
  }

  private async fetchAllPages<T>(observer: Observer<T>, endpoint: string, opts: RequestOptsWithOfflineHandling) {
    let allPagesFetched = false;
    let pageNumber = 1;
    while (!allPagesFetched) {
      try {
        const page = await this.getPage(endpoint, opts, pageNumber);
        const results = page.results;
        for (const result of results) {
          observer.next(result);
        }
        if (!page.hasNext()) {
          allPagesFetched = true;
        }
      } catch (error) {
        // Need to bail out as we don't know the next page to fetch
        allPagesFetched = true;
        observer.error(error);
      }
      pageNumber++;
    }
    observer.complete();
  }

  private get _httpOrDemoService(): HttpClient | DemoService {
    return this.demoService.isOn ? this.demoService : this.http;
  }

  /**
   * Handle a single request.
   * @param endpoint - The endpoint to send the request to.
   * @param [opts] - Additional options for processing this request.
   * @return Response data.
   */
  private _getRequest(endpoint: string, opts: RequestOptsWithOfflineHandling = {}): Promise<any> {
    const url = this.apiUrlService.normaliseUrl(endpoint);
    const { params, paramsSerializer, noTransform } = opts;
    const optionsArgs = {
      headers: this.authService.requestHeaders,
      params: parseParams({ params, paramsSerializer, noTransform }) as unknown as HttpParams,
      withCredentials: this.authService.hasSession
    };
    return this._httpOrDemoService
      .get(url, optionsArgs)
      .pipe(timeout(opts.timeout || DEFAULT_GET_TIMEOUT))
      .toPromise()
      .catch(err => this._onRequestError(err, opts))
      .then(json => this._cacheIfRequired(json, endpoint, opts));
  }

  /**
   * Helper method to post/put/patch data to the server.
   * @param method - The HTTP method to use.
   * @param endpoint - The endpoint to send the request to.
   * @param data - The payload to send in the request.
   * @param [opts] - Additional options for processing this request.
   * @return Response data.
   */
  private _updateRequest(
    method: 'patch' | 'post' | 'put',
    endpoint: string,
    data: any,
    opts: RequestOptsWithOfflineHandling = {}
  ): Promise<any> {
    const url = this.apiUrlService.normaliseUrl(endpoint);
    const requestSerializer = opts.requestSerializer || opts.serializer;
    const serializerKwargs = opts.serializerKwargs || {};
    const { params, paramsSerializer, noTransform } = opts;
    const optionsArgs = {
      headers: this.authService.requestHeaders,
      params: parseParams({ params, paramsSerializer, noTransform }) as unknown as HttpParams,
      withCredentials: this.authService.hasSession
    };
    let serialized: any;

    if (noTransform) {
      serialized = data;
    } else if (requestSerializer) {
      serialized = requestSerializer.serialize(data, serializerKwargs);
    } else if (data) {
      serialized = serializer.serialize(data, serializerKwargs);
    }
    return this._httpOrDemoService[method](url, serialized, optionsArgs)
      .pipe(timeout(opts.timeout || DEFAULT_UPDATE_TIMEOUT))
      .toPromise()
      .catch(async err => {
        if (this.errorService.isNoInternetError(err)) {
          err.offlineEvent = await this._enqueueOfflineRequestOnError(method, endpoint, serialized, opts);
        }

        this._onRequestError(err, opts);

        if (err.offlineEvent) {
          return err.offlineEvent.offlineResponsePayload;
        }
      })
      .then(json => this._handleResponse(json, opts));
  }

  /**
   * Handle a server response.
   * If a serializer is provided, use that to deserialize the response.
   * Otherwise, perform basic transformations on the data (camelCase keys).
   * @param json - The JSON serialized response
   * @param [opts] - Additional options for processing this request.
   * @return The transformed data.
   */
  private _handleResponse(json: any, opts: RequestOptsWithOfflineHandling = {}): any {
    this.toastService.dismissOfflineToast();
    this.onlineService.setOnlineState(true);

    const responseSerializer = opts.responseSerializer || opts.serializer;

    if (json === null || json === undefined) {
      return;
    }
    if (json._offline === undefined) {
      // Not an enqueued offline response - device is online!
      this.processOfflineEvents();
    } else if (json._offline.isOfflineEvent) {
      // A mock response - device is offline!
      return json;
    }

    if (responseSerializer) {
      return responseSerializer.deserialize(json, opts.serializerKwargs);
    } else if (!opts.noTransform && json) {
      return serializer.deserialize(json, opts.serializerKwargs);
    } else {
      return json;
    }
  }

  /**
   * If a cache duration property is set, attempt to store the JSON result
   * using the cache service.
   * @param json - The JSON response to cache.
   * @param endpoint - The endpoint that was queried.
   * @param opts - Options that were used to process the request.
   * @return Promise that fulfills once complete with the initial JSON.
   */
  private _cacheIfRequired(json: any, endpoint: string, opts: RequestOptsWithOfflineHandling): Promise<any> {
    if (opts.cache && !this.demoService.isOn) {
      return this.cacheService.ready().then(() => {
        this.cacheService.set(opts.cache.key, json, opts.cache);
        return json;
      });
    } else {
      return Promise.resolve(json);
    }
  }

  /**
   * Handle an error response from the server.
   * Rethrows the error if necessary.
   * @param err - The error response from the server.
   * @param opts - Additional options for processing this request.
   */
  private _onRequestError(err: Response, opts?: RequestOptsWithOfflineHandling): void {
    // Suppress the error if it's a timeout error that we have decided we
    // should suppress.
    if (
      this.errorService.isTimeoutError(err) &&
      (opts?.suppressTimeoutErrors || this.onlineService.shouldSuppressTimeoutErrors())
    ) {
      return;
    }

    if (this.errorService.isNoInternetError(err) || this.errorService.isTimeoutError(err)) {
      this.onlineService.setOnlineState(false);

      // Only hide the offline toast if we've explicitly set the option to do so.
      if (!(opts?.offline?.raiseOfflineError === false)) {
        this.toastService.showOfflineToast();
      }

      // If we're adding the request onto the offline events queue, we don't
      // want to throw an error.
      if (opts?.offline?.enqueueIfOffline) {
        return;
      }
    } else if (this.errorService.isDemoModeError(err)) {
      this.demoService.showErrorMessage();
    } else if (this.errorService.isHttp401UnauthorizedError(err)) {
      this.authService.logout('unauthorized');
      throw new Unauthorized('Session expired');
    }

    throw err;
  }

  /**
   * Enqueue a request if it was not processed due to lack of connectivity
   * @param method - The HTTP method to use.
   * @param endpoint - The endpoint to send the request to.
   * @param serialized - The serialized payload.
   * @param opts - Additional options for processing this request.
   * @return The offline event that was enqueued.
   */
  private async _enqueueOfflineRequestOnError(
    method: 'patch' | 'post' | 'put',
    endpoint: string,
    serialized: any,
    opts: RequestOptsWithOfflineHandling = {}
  ): Promise<OfflineEvent> {
    if (opts.offline && opts.offline.enqueueIfOffline) {
      const offlineEvent = await this.offlineEventService.registerOfflineEvent(method, endpoint, serialized, opts);

      for (const cacheItem of offlineEvent.offlineCachePayload) {
        await this.cacheService.set(cacheItem.key, cacheItem.data, cacheItem.opts);
      }

      return offlineEvent;
    }
    return undefined;
  }
}
