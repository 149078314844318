import { DemoEndpoint } from '../../../demo-service';
import { createKeyEndpoint } from './key-create';
import { transferKeyEndpoint } from './key-transfer';

const propertyEndpoint: DemoEndpoint = {
  path: 'property/[a-z0-9]+',
  endpoints: [createKeyEndpoint, transferKeyEndpoint]
};

export { propertyEndpoint };
