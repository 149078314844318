import { Environment } from 'interfaces/environment';

import { devEnvironment } from './environment.dev';
import { prodEnvironment } from './environment.prod';
import { stagingEnvironment } from './environment.staging';

const storedEnvironmentId = localStorage.getItem('environment');

const environments: Record<string, Environment> = {
  [devEnvironment.ID]: devEnvironment,
  [stagingEnvironment.ID]: stagingEnvironment
};

export const environment = environments[storedEnvironmentId] || prodEnvironment;

export const isDevEnv = environment.ID === devEnvironment.ID;
export const isStagingEnv = environment.ID === stagingEnvironment.ID;
