export const EXTRA_JOBS_ONE_OFF = {
  num_pages: 1,
  current_page: 1,
  results: [
    {
      tip_amount_pounds: null,
      tasks: [
        {
          type: 'cleaning',
          hours: 2.0
        }
      ],
      any_worker: true,
      finished_at: null,
      customer_billed_for_skip_after: '{{ date }}T18:00:00Z',
      account_id: 'jze8xjfumdzn',
      frequency: 14.0,
      started_at: null,
      id: 'qolx2cmljmfoo',
      property_id: 'xowx45fewlz2',
      skipped_by_user_role: null,
      access: 'at home',
      time_slot: {
        start: '13:00:00',
        end: '15:00:00'
      },
      skipped: false,
      is_first_visit: false,
      finished: false,
      scheduled_date: '{{ date }}',
      actual_date: '{{ date + 5 }}',
      job_id: 'jze8dt2geqzn',
      week_beginning: '2018-11-19',
      customer_can_skip: true,
      access_display: 'Client at home',
      customer_can_alter: true,
      is_reviewable: false,
      worker_property_access: 'can_access',
      instructions: [
        {
          instruction: 'Clean all surfaces in the bathroom.',
          task_type: 'cleaning',
          id: 'nb7nyf82ayoe'
        }
      ],
      property: {
        accessInformation: {},
        city: 'London',
        bathrooms: 1,
        account_id: 'jzelkbd7mdzn',
        bedrooms: 2,
        access: 'at home',
        pets: {
          have_pets: true,
          cats: true,
          dogs: null,
          other_pets: null,
          other_pets_type: '',
          pets_type: 'cats',
          pet_instructions: '',
          pet_names: 'Catwell',
          property_id: 'xowxyc77yr62'
        },
        postcode: {
          inward: '6GF',
          outward: 'SW1E',
          location: {
            latitude: '51.4503998252',
            longitude: '0.0716499772458'
          }
        },
        line_1: '70 Mangrove Gardens',
        line_3: '',
        line_2: '',
        id: 'xowxyc77yr62'
      }
    },
    {
      tip_amount_pounds: null,
      tasks: [
        {
          type: 'cleaning',
          hours: 2.0
        }
      ],
      any_worker: true,
      finished_at: null,
      customer_billed_for_skip_after: '{{ date }}T18:00:00Z',
      account_id: 'jze8xjfumdzn',
      frequency: 14.0,
      started_at: null,
      id: 'qolx2cmljmfoo',
      property_id: 'xowx45fewlz2',
      skipped_by_user_role: null,
      access: 'at home',
      time_slot: {
        start: '09:30:00',
        end: '11:30:00'
      },
      skipped: false,
      is_first_visit: false,
      finished: false,
      scheduled_date: '{{ date }}',
      actual_date: '{{ date + 1 }}',
      job_id: 'jze8dt2geqzn',
      week_beginning: '2018-11-19',
      customer_can_skip: true,
      access_display: 'Client at home',
      customer_can_alter: true,
      is_reviewable: false,
      worker_property_access: 'can_access',
      instructions: [
        {
          instruction: 'Clean all surfaces in the kitchen.',
          task_type: 'cleaning',
          id: 'nb7nyf82ayoe'
        }
      ],
      property: {
        accessInformation: {},
        city: 'London',
        bathrooms: 1,
        account_id: 'jzelkbd7mdzn',
        bedrooms: 2,
        access: 'at home',
        pets: {
          have_pets: true,
          cats: null,
          dogs: true,
          other_pets: null,
          other_pets_type: '',
          pets_type: 'dogs',
          pet_instructions: 'Friendly puppy',
          pet_names: 'Maxwell',
          property_id: 'xowxyc77yr62'
        },
        postcode: {
          inward: '8BH',
          outward: 'WC1A',
          location: {
            latitude: '51.4503998252',
            longitude: '0.0716499772458'
          }
        },
        line_1: '71 Mangrove Gardens',
        line_3: '',
        line_2: '',
        id: 'xowxyc77yr62'
      }
    },
    {
      tip_amount_pounds: null,
      tasks: [
        {
          type: 'cleaning',
          hours: 3.0
        }
      ],
      any_worker: true,
      finished_at: null,
      customer_billed_for_skip_after: '{{ date }}T18:00:00Z',
      account_id: 'jze8xjfumdzn',
      frequency: 14.0,
      started_at: null,
      id: 'qolx2cmljmfoo',
      property_id: 'xowx45fewlz2',
      skipped_by_user_role: null,
      access: 'at home',
      time_slot: {
        start: '12:30:00',
        end: '15:30:00'
      },
      skipped: false,
      is_first_visit: false,
      finished: false,
      scheduled_date: '{{ date }}',
      actual_date: '{{ date + 2 }}',
      job_id: 'jze8dt2geqzn',
      week_beginning: '2018-11-19',
      customer_can_skip: true,
      access_display: 'Client at home',
      customer_can_alter: true,
      is_reviewable: false,
      worker_property_access: 'can_access',
      instructions: [
        {
          instruction: 'Clean the bedroom floor.',
          task_type: 'cleaning',
          id: 'nb7nyf82ayoe'
        }
      ],
      property: {
        accessInformation: {},
        city: 'London',
        bathrooms: 1,
        account_id: 'jzelkbd7mdzn',
        bedrooms: 2,
        access: 'at home',
        pets: {
          have_pets: false,
          cats: null,
          dogs: null,
          other_pets: null,
          other_pets_type: '',
          pets_type: 'dogs',
          pet_instructions: '',
          pet_names: '',
          property_id: 'xowxyc77yr62'
        },
        postcode: {
          inward: '1SE',
          outward: 'SW1E',
          location: {
            latitude: '51.4503998252',
            longitude: '0.0716499772458'
          }
        },
        line_1: '72 Mangrove Gardens',
        line_3: '',
        line_2: '',
        id: 'xowxyc77yr62'
      }
    },
    {
      tip_amount_pounds: null,
      tasks: [
        {
          type: 'cleaning',
          hours: 6.0
        }
      ],
      any_worker: true,
      finished_at: null,
      customer_billed_for_skip_after: '{{ date }}T18:00:00Z',
      account_id: 'jze8xjfumdzn',
      frequency: 14.0,
      started_at: null,
      id: 'qolx2cmljmfoo',
      property_id: 'xowx45fewlz2',
      skipped_by_user_role: null,
      access: 'at home',
      time_slot: {
        start: '09:30:00',
        end: '15:30:00'
      },
      skipped: false,
      is_first_visit: false,
      finished: false,
      scheduled_date: '{{ date }}',
      actual_date: '{{ date + 3 }}',
      job_id: 'jze8dt2geqzn',
      week_beginning: '2018-11-19',
      customer_can_skip: true,
      access_display: 'Client at home',
      customer_can_alter: true,
      is_reviewable: false,
      worker_property_access: 'can_access',
      instructions: [
        {
          instruction: 'Hoover the staircase.',
          task_type: 'cleaning',
          id: 'nb7nyf82ayoe'
        }
      ],
      property: {
        accessInformation: {},
        city: 'London',
        bathrooms: 1,
        account_id: 'jzelkbd7mdzn',
        bedrooms: 2,
        access: 'at home',
        pets: {
          have_pets: true,
          cats: null,
          dogs: true,
          other_pets: null,
          other_pets_type: '',
          pets_type: 'dogs',
          pet_instructions: '',
          pet_names: '',
          property_id: 'xowxyc77yr62'
        },
        postcode: {
          inward: '5TR',
          outward: 'SW1E',
          location: {
            latitude: '51.4503998252',
            longitude: '0.0716499772458'
          }
        },
        line_1: '73 Mangrove Gardens',
        line_3: '',
        line_2: '',
        id: 'xowxyc77yr62'
      }
    },
    {
      tip_amount_pounds: null,
      tasks: [
        {
          type: 'cleaning',
          hours: 4.0
        }
      ],
      any_worker: true,
      finished_at: null,
      customer_billed_for_skip_after: '{{ date }}T18:00:00Z',
      account_id: 'jze8xjfumdzn',
      frequency: 14.0,
      started_at: null,
      id: 'qolx2cmljmfoo',
      property_id: 'xowx45fewlz2',
      skipped_by_user_role: null,
      access: 'at home',
      time_slot: {
        start: '13:00:00',
        end: '17:00:00'
      },
      skipped: false,
      is_first_visit: false,
      finished: false,
      scheduled_date: '{{ date }}',
      actual_date: '{{ date + 4 }}',
      job_id: 'jze8dt2geqzn',
      week_beginning: '2018-11-19',
      customer_can_skip: true,
      access_display: 'Client at home',
      customer_can_alter: true,
      is_reviewable: false,
      worker_property_access: 'can_access',
      instructions: [
        {
          instruction: 'Clean the back room surfaces.',
          task_type: 'cleaning',
          id: 'nb7nyf82ayoe'
        }
      ],
      property: {
        accessInformation: {},
        city: 'London',
        bathrooms: 1,
        account_id: 'jzelkbd7mdzn',
        bedrooms: 2,
        access: 'at home',
        pets: {
          have_pets: true,
          cats: null,
          dogs: true,
          other_pets: null,
          other_pets_type: '',
          pets_type: 'dogs',
          pet_instructions: 'Friendly puppy',
          pet_names: 'Hippo',
          property_id: 'xowxyc77yr62'
        },
        postcode: {
          inward: '6GF',
          outward: 'WC1A',
          location: {
            latitude: '51.4503998252',
            longitude: '0.0716499772458'
          }
        },
        line_1: '74 Mangrove Gardens',
        line_3: '',
        line_2: '',
        id: 'xowxyc77yr62'
      }
    }
  ],
  per_page: 5,
  url_previous_page: null,
  num_results: 1,
  url_next_page: null
};

export const EXTRA_JOBS_REGULAR = {
  num_pages: 1,
  current_page: 1,
  results: [
    {
      job: {
        tasks: [
          {
            type: 'cleaning',
            hours: 2.0
          },
          {
            type: 'products',
            hours: null
          }
        ],
        time_slot_any_time_switch_date: null,
        next_visit_date: '{{ date }}',
        end_date: null,
        job_state: 'c',
        instructions: [
          {
            instruction: 'Clean all surfaces in the bathroom.',
            task_type: 'cleaning',
            id: 'nb7nyf82ayoe'
          }
        ],
        property: {
          accessInformation: {},
          city: 'London',
          bathrooms: 1,
          account_id: 'joermhm8ekop',
          bedrooms: 1,
          access: 'at home',
          pets: {
            have_pets: true,
            cats: true,
            dogs: true,
            other_pets: null,
            other_pets_type: '',
            pets_type: 'dogs',
            pet_instructions: '',
            pet_names: '',
            property_id: 'xowxyc77yr62'
          },
          postcode: {
            inward: '9FD',
            outward: 'SW1E',
            location: {
              latitude: '51.5521821448',
              longitude: '-0.229185960517'
            }
          },
          line_1: 'First Floor Flat',
          line_3: '',
          line_2: '44 Chancery Lane',
          id: 'rom4pcefdyoy'
        },
        frequency: 14.0,
        account_id: 'jokfhhm8ekop',
        base_date: '{{ date + 1 }}',
        time_slot: {
          start: '07:30:00',
          end: '09:30:00'
        },
        id: 'vz9p7t2llebq',
        property_id: 'rom4pcefdyoy',
        any_worker: true
      },
      availability_changes: [
        {
          start: '07:30:00',
          end: '18:30:00'
        }
      ]
    },
    {
      job: {
        tasks: [
          {
            type: 'cleaning',
            hours: 3.0
          }
        ],
        time_slot_any_time_switch_date: null,
        next_visit_date: '{{ date + 1 }}',
        end_date: null,
        job_state: 'c',
        instructions: [
          {
            instruction: 'Clean under the office desk.',
            task_type: 'cleaning',
            id: 'nb7nyf82ayoe'
          }
        ],
        property: {
          accessInformation: {},
          city: 'London',
          bathrooms: 1,
          account_id: 'joermhm8ekop',
          bedrooms: 2,
          access: 'at home',
          pets: {
            have_pets: false,
            cats: null,
            dogs: null,
            other_pets: null,
            other_pets_type: '',
            pets_type: '',
            pet_instructions: '',
            pet_names: '',
            property_id: 'xowxyc77yr62'
          },
          postcode: {
            inward: '3KF',
            outward: 'WC1A',
            location: {
              latitude: '51.5521821448',
              longitude: '-0.229185960517'
            }
          },
          line_1: 'First Floor Flat',
          line_3: '',
          line_2: '45 Chancery Lane',
          id: 'rom4pcefdyoy'
        },
        frequency: 14.0,
        account_id: 'jokfhhm8ekop',
        base_date: '{{ date + 2 }}',
        time_slot: {
          start: '12:30:00',
          end: '15:30:00'
        },
        id: 'vz9p7t2llebq',
        property_id: 'rom4pcefdyoy',
        any_worker: true
      },
      availability_changes: [
        {
          start: '07:30:00',
          end: '18:30:00'
        }
      ]
    },
    {
      job: {
        tasks: [
          {
            type: 'cleaning',
            hours: 4.0
          }
        ],
        time_slot_any_time_switch_date: null,
        next_visit_date: '{{ date + 3 }}',
        end_date: null,
        job_state: 'c',
        instructions: [
          {
            instruction: 'Wipe the mirrors clean.',
            task_type: 'cleaning',
            id: 'nb7nyf82ayoe'
          }
        ],
        property: {
          accessInformation: {},
          city: 'London',
          bathrooms: 1,
          account_id: 'joermhm8ekop',
          bedrooms: 1,
          access: 'at home',
          pets: {
            have_pets: true,
            cats: null,
            dogs: true,
            other_pets: null,
            other_pets_type: '',
            pets_type: 'dogs',
            pet_instructions: 'He loves playing fetch',
            pet_names: 'Jasper',
            property_id: 'xowxyc77yr62'
          },
          postcode: {
            inward: '6TR',
            outward: 'SW1E',
            location: {
              latitude: '51.5521821448',
              longitude: '-0.229185960517'
            }
          },
          line_1: 'First Floor Flat',
          line_3: '',
          line_2: '46 Chancery Lane',
          id: 'rom4pcefdyoy'
        },
        frequency: 14.0,
        account_id: 'jokfhhm8ekop',
        base_date: '{{ date + 3 }}',
        time_slot: {
          start: '15:30:00',
          end: '19:00:00'
        },
        id: 'vz9p7t2llebq',
        property_id: 'rom4pcefdyoy',
        any_worker: true
      },
      availability_changes: [
        {
          start: '07:30:00',
          end: '18:30:00'
        }
      ]
    },
    {
      job: {
        tasks: [
          {
            type: 'cleaning',
            hours: 6.0
          },
          {
            type: 'products',
            hours: null
          }
        ],
        time_slot_any_time_switch_date: null,
        next_visit_date: '{{ date + 3 }}',
        end_date: null,
        job_state: 'c',
        instructions: [
          {
            instruction: 'Dust the skirting boards.',
            task_type: 'cleaning',
            id: 'nb7nyf82ayoe'
          }
        ],
        property: {
          accessInformation: {},
          city: 'London',
          bathrooms: 1,
          account_id: 'joermhm8ekop',
          bedrooms: 1,
          access: 'at home',
          pets: {
            have_pets: true,
            cats: true,
            dogs: false,
            other_pets: null,
            other_pets_type: '',
            pets_type: 'dogs',
            pet_instructions: '',
            pet_names: 'Cornelius',
            property_id: 'xowxyc77yr62'
          },
          postcode: {
            inward: '9UY',
            outward: 'SW1E',
            location: {
              latitude: '51.5521821448',
              longitude: '-0.229185960517'
            }
          },
          line_1: 'First Floor Flat',
          line_3: '',
          line_2: '47 Chancery Lane',
          id: 'rom4pcefdyoy'
        },
        frequency: 14.0,
        account_id: 'jokfhhm8ekop',
        base_date: '{{ date + 2 }}',
        time_slot: {
          start: '11:30:00',
          end: '17:30:00'
        },
        id: 'vz9p7t2llebq',
        property_id: 'rom4pcefdyoy',
        any_worker: true
      },
      availability_changes: [
        {
          start: '07:30:00',
          end: '18:30:00'
        }
      ]
    },
    {
      job: {
        tasks: [
          {
            type: 'cleaning',
            hours: 3.0
          }
        ],
        time_slot_any_time_switch_date: null,
        next_visit_date: '{{ date + 2 }}',
        end_date: null,
        job_state: 'c',
        instructions: [
          {
            instruction: 'Clean the inside of the front door.',
            task_type: 'cleaning',
            id: 'nb7nyf82ayoe'
          }
        ],
        property: {
          accessInformation: {},
          city: 'London',
          bathrooms: 1,
          account_id: 'joermhm8ekop',
          bedrooms: 1,
          access: 'at home',
          pets: {
            have_pets: true,
            cats: null,
            dogs: true,
            other_pets: null,
            other_pets_type: '',
            pets_type: 'dogs',
            pet_instructions: "He's scared of the hoover",
            pet_names: 'Bean',
            property_id: 'xowxyc77yr62'
          },
          postcode: {
            inward: '7GH',
            outward: 'SW1E',
            location: {
              latitude: '51.5521821448',
              longitude: '-0.229185960517'
            }
          },
          line_1: 'First Floor Flat',
          line_3: '',
          line_2: '48 Chancery Lane',
          id: 'rom4pcefdyoy'
        },
        frequency: 14.0,
        account_id: 'jokfhhm8ekop',
        base_date: '{{ date + 2 }}',
        time_slot: {
          start: '11:30:00',
          end: '14:30:00'
        },
        id: 'vz9p7t2llebq',
        property_id: 'rom4pcefdyoy',
        any_worker: true
      },
      availability_changes: [
        {
          start: '07:30:00',
          end: '18:30:00'
        }
      ]
    },
    {
      job: {
        tasks: [
          {
            type: 'cleaning',
            hours: 6.0
          }
        ],
        time_slot_any_time_switch_date: null,
        next_visit_date: '{{ date + 2 }}',
        end_date: null,
        job_state: 'c',
        instructions: [
          {
            instruction: 'Hoover the entrance rug.',
            task_type: 'cleaning',
            id: 'nb7nyf82ayoe'
          }
        ],
        property: {
          accessInformation: {},
          city: 'London',
          bathrooms: 1,
          account_id: 'joermhm8ekop',
          bedrooms: 1,
          access: 'at home',
          pets: {
            have_pets: true,
            cats: null,
            dogs: true,
            other_pets: null,
            other_pets_type: '',
            pets_type: 'dogs',
            pet_instructions: '',
            pet_names: 'Aspen',
            property_id: 'xowxyc77yr62'
          },
          postcode: {
            inward: '7UY',
            outward: 'WC1A',
            location: {
              latitude: '51.5521821448',
              longitude: '-0.229185960517'
            }
          },
          line_1: 'First Floor Flat',
          line_3: '',
          line_2: '49 Chancery Lane',
          id: 'rom4pcefdyoy'
        },
        frequency: 14.0,
        account_id: 'jokfhhm8ekop',
        base_date: '{{ date + 2 }}',
        time_slot: {
          start: '07:30:00',
          end: '13:30:00'
        },
        id: 'vz9p7t2llebq',
        property_id: 'rom4pcefdyoy',
        any_worker: true
      },
      availability_changes: [
        {
          start: '07:30:00',
          end: '18:30:00'
        }
      ]
    }
  ],
  per_page: 5,
  url_previous_page: null,
  num_results: 6,
  url_next_page: null
};
