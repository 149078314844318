import { DemoEndpoint } from '../../../demo-service';

const customerCallEndpoint: DemoEndpoint = {
  path: 'request-customer-call',
  handlers: [
    {
      method: 'get',
      handle() {
        return {
          reasons: [
            ['late', `I'll be late`, { icon: 'time', hide_after_start: true }],
            ['lost', `I'm lost`, { icon: 'compass', hide_after_start: true }],
            ['stuck-outside', `I'm stuck outside`, { icon: 'lock', hide_after_start: true }],
            ['cleaning-help', 'I need help with instructions', { icon: 'help' }],
            ['equipment-help', 'I need help with the equipment/products', { icon: 'hk-cleaning-products' }],
            ['duration-help', 'I need more/less time', { icon: 'clock' }],
            ['other', 'Other', { icon: 'chatboxes' }]
          ]
        };
      }
    },
    {
      method: 'post',
      handle(state, path) {
        return { telephone_number: '07700900000' }; // Ofcom reserved fake #
      }
    }
  ]
};

export { customerCallEndpoint };
