<ion-row>
  <ion-col size="auto">
    <ion-text [color]="iconColor">
      <hk-wc-icon
        [name]="iconName"
        [size]="iconSize"
        [style.margin-left.px]="marginX"
        [style.margin-right.px]="marginX"
      ></hk-wc-icon>
    </ion-text>
  </ion-col>
  <ion-col>
    <ng-content></ng-content>
  </ion-col>
</ion-row>
