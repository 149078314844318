import { Component, HostListener, Input, OnInit } from '@angular/core';

import { Platform } from '@ionic/angular';

import { Property } from '@housekeep/infra';

import { environment } from 'environment/environment';

import { Environment } from 'interfaces/environment';

import { LocationService } from 'services/location-service';

import { ImageDimensions } from '../../types/dimensions';

const MAP_RATIO = 2.5;

@Component({
  selector: 'hk-property-map',
  templateUrl: './hk-property-map.component.html',
  styleUrls: ['./hk-property-map.component.scss']
})
export class HkPropertyMap implements OnInit {
  @Input() public property: Property;
  @Input() public outsideArea: boolean = false;

  public destinationAddress: string;
  public mapImgDimensions: ImageDimensions;
  public environment: Environment;

  constructor(private locationService: LocationService, private platform: Platform) {
    this.environment = environment;
  }

  public ngOnInit() {
    this.setDestinationAddress();
    this.calculateMapDimensions();
  }

  public openInExternalMapApp() {
    const mapUri = this.locationService.getMapsUrlForAddress(this.destinationAddress);
    window.open(mapUri, '_system');
  }

  public setDestinationAddress() {
    // Helps Google find the address by removing first lines that contain 'Flat X'
    this.destinationAddress = encodeURIComponent(this.property.fullAddress.replace(/^Flat \d+, /, ''));
  }

  private calculateMapDimensions() {
    const cardMargin = this.platform.is('ios') ? 12 : 10;

    // Cap the screen width so the map doesn't get too big on desktop/tablet
    const screenWidth = Math.min(window.innerWidth, 1080 + cardMargin * 2);

    // The image fills the card, so its width is the same as the card's.
    const imgWidth = screenWidth - cardMargin * 2;
    const imgHeight = Math.round(imgWidth / MAP_RATIO);

    this.mapImgDimensions = {
      width: imgWidth,
      height: imgHeight
    };
  }

  @HostListener('window:resize', ['$event'])
  private onWindowResize() {
    this.calculateMapDimensions();
  }
}
