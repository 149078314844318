import { Component, Input } from '@angular/core';

import { Visit } from '@housekeep/infra';

@Component({
  selector: 'rkt-key-handover',
  templateUrl: './rkt-key-handover.component.html',
  styleUrls: ['./rkt-key-handover.component.scss']
})
export class RktKeyHandover {
  @Input() public visit: Visit;
  @Input() public hasKeyCollectionChanges = false;
}
