<section *ngIf="user" class="profile-summary__bio">
  <div class="profile-summary__bio__link" (click)="goToBadgePage()" tappable>
    <div
      class="profile-summary__bio__img"
      [class.profile-summary__bio__img--has-image]="user.image"
      [ngStyle]="{
        backgroundImage: user.image && 'url(' + user.image + ')'
      }"
    >
    <img
      *ngIf="!user.image"
      class="profile-summary__bio__img__avatar"
      src="assets/design-tokens/images/user-avatar.svg"
      [alt]="user.firstNames + user.lastName" />
    </div>
    <div class="profile-summary__bio__id">
      <span>ID</span>
    </div>
  </div>
  <div class="profile-summary__bio__name" (click)="goToProfilePage()" tappable>
    <strong>
      {{ user.fullName }}
    </strong>
  </div>
  <div class="profile-summary__bio__rating" (click)="goToRatingsPage()" tappable>
    <hk-wc-icon size="22" name="star"></hk-wc-icon>
    <strong>
      {{ user.currentRating | asAverageRating }}
    </strong>
  </div>
</section>
