import { DemoEndpoint } from 'services/demo-service';

import { getVisitDataFromPath } from '../../../util';

const visitUnstartEndpoint: DemoEndpoint = {
  path: 'unstart',
  handlers: [
    {
      method: 'patch',
      handle(state, path) {
        const visitData = getVisitDataFromPath(path, state);
        visitData.started_at = null;
        return visitData;
      }
    }
  ]
};

export { visitUnstartEndpoint };
