import { DemoEndpoint } from '../../../demo-service';

const paymentDetailsEndpoint: DemoEndpoint = {
  path: 'payment-details',
  handlers: [
    {
      method: 'get',
      handle(state) {
        return {
          account_number: state.worker.account_number,
          name_on_account: state.worker.name_on_account,
          pending_payment_period_exists: false,
          sort_code: state.worker.sort_code,
          worker_id: 'abcdef123456'
        };
      }
    },
    {
      method: 'patch',
      handle(state, path, parameters, headers, body) {
        state.worker.account_number = body.account_number;
        state.worker.name_on_account = body.name_on_account;
        state.worker.sort_code = body.sort_code;

        return {
          account_number: state.worker.account_number,
          name_on_account: state.worker.name_on_account,
          pending_payment_period_exists: false,
          sort_code: state.worker.sort_code,
          worker_id: 'abcdef123456'
        };
      }
    }
  ]
};

export { paymentDetailsEndpoint };
