import { Inject, Injectable } from '@angular/core';

import { Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';

import { SecureStorage } from '@awesome-cordova-plugins/secure-storage/ngx';

import { noop } from 'lodash-es';

import { Deferred } from '@housekeep/infra';

import { APP_CONFIG, AppConfig } from './app-config';

interface GenericStorage {
  create?: (namespace) => Promise<any>;
  get: (key) => Promise<any>;
  set: (key, value) => Promise<any>;
  remove: (key) => Promise<any>;
}

const DEVICE_DETAILS = 'device-details';
const DEVICE_FCM_TOKEN = 'device-firebase-token';
const DEVICE_EXTERNAL_ID = 'device-external-key';
const DEVICE_LAST_SYNC_DATE = 'device-last-sync-date';
const NOTIFICATION_PERMISSION_REQUESTED_KEY = 'notification-permission-requested';
const OFFLINE_EVENTS_KEY = 'offline-events';
const WHATS_NEW_LAST_APP_SEEN = 'whats-new-last-seen-app-version';

const PERMANENT_KEYS = [DEVICE_DETAILS, NOTIFICATION_PERMISSION_REQUESTED_KEY, WHATS_NEW_LAST_APP_SEEN];

/**
 * Service to handle storing information.
 * Sensitive information is stored used the SecureStorage plugin, whilst other
 * data is stored using the vanilla Ionic 2 Storage.
 */
@Injectable({ providedIn: 'root' })
class StorageService {
  private _authTokenKey: string = 'auth';
  private _isReadyDeferred: Deferred<any> = new Deferred();
  private secureStorage: GenericStorage;

  constructor(
    public platform: Platform,
    public storage: Storage,
    private secureStoragePlugin: SecureStorage,
    @Inject(APP_CONFIG) private config: AppConfig
  ) {
    this.platform.ready().then(() => {
      this.initializeStorage();
    });
  }

  /**
   * Return a promise which resolves when the service is ready.
   * @return {Promise}
   */
  public ready(): Promise<void> {
    return this._isReadyDeferred.promise;
  }

  /**
   * Get a value from storage by key
   */
  public get(key: string): Promise<any> {
    return this.storage.get(key);
  }

  public getMany(...keys: string[]): Promise<any> {
    return Promise.all(keys.map(key => this.storage.get(key)));
  }

  /**
   * Store value in storage by key
   * @param {string} key
   * @param {any} value
   * @return {Promise}
   */
  public set(key: string, value: any): Promise<any> {
    return this.storage.set(key, value);
  }

  /**
   * Remove an item from storage
   * @param {string} key
   * @return {Promise}
   */
  public remove(key: string): Promise<any> {
    return this.storage.remove(key);
  }

  /**
   * Return a promise which passes through all the keys currently in storage
   * @param {string} key
   * @return {Promise}
   */
  public keys(): Promise<any> {
    return this.storage.keys();
  }

  /**
   * Remove everything from storage and secure storage
   * @return {Promise}
   */
  public clearAllStorage(): Promise<any> {
    const secure = this.unsetAuthToken();
    const storage = this.storage.clear();

    return Promise.all([storage, secure]);
  }

  /**
   * Remove all non-persistent data from storage.
   */
  public clearSessionStorage(): Promise<any> {
    const secure = this.unsetAuthToken();

    const storage = this.storage.keys().then(keys => {
      return Promise.all(keys.filter(key => !PERMANENT_KEYS.includes(key)).map(key => this.remove(key)));
    });

    return Promise.all([storage, secure]);
  }

  /**
   * Get the auth token from secure storage
   * @return {Promise}
   */
  public getAuthToken(): Promise<any> {
    return this.secureStorage.get(this._authTokenKey);
  }

  /**
   * Set an auth token, to be used to authenticate during server requests.
   * @param {string} value - The token to set
   * @return {Promise} - Will resolve once the token has been set
   */
  public setAuthToken(value): Promise<any> {
    return this.secureStorage.set(this._authTokenKey, value);
  }

  /**
   * Attempt to remove the auth token from the (secure) storage.
   *
   * As iOS and Android raise different exceptions, simply ignore all - in
   * practice this should only occur due to the token not being present.
   */
  public unsetAuthToken(): Promise<any> {
    return this.secureStorage.remove(this._authTokenKey).catch(noop);
  }

  /**
   * Initialize a secure storage backend to handle auth tokens.
   */
  private initializeSecureStorage(): Promise<any> {
    // In browser, create returns a storage object with a mock SecureStorage instance
    // for no useful reason. We have no way of being able to determine if it is a mock
    // as any variables we could use to determine this are private. They decided to make
    // this change in a minor point version. Thanks Ionic!
    if (this.platform.is('cordova')) {
      return this.secureStoragePlugin
        .create(this.config.STORAGE_NAMESPACE)
        .then(storage => (this.secureStorage = storage))
        .catch(() => this.onSecureStorageUnavailable());
    } else {
      return this.onSecureStorageUnavailable();
    }
  }

  private onSecureStorageUnavailable(): Promise<any> {
    this.secureStorage = this.storage;
    return this.storage.ready();
  }

  /**
   * Initialize both ordinary and secure storage engines.
   */
  private initializeStorage() {
    const storage = this.storage.ready();
    const secureStorage = this.initializeSecureStorage();

    return Promise.all([storage, secureStorage]).then(() => {
      this._isReadyDeferred.resolve();
    });
  }
}

export {
  DEVICE_DETAILS,
  DEVICE_FCM_TOKEN,
  DEVICE_EXTERNAL_ID,
  DEVICE_LAST_SYNC_DATE,
  NOTIFICATION_PERMISSION_REQUESTED_KEY,
  OFFLINE_EVENTS_KEY,
  WHATS_NEW_LAST_APP_SEEN,
  StorageService
};
