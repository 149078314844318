<div *ngIf="!loading && allowed" class="contact-housekeep">
  <div class="contact-housekeep__send-button">
    <ion-button color="primary" expand="block" (click)="contactHousekeep()">
      <hk-wc-icon name="envelope" size="24" slot="start"></hk-wc-icon>
      Contact Housekeep
    </ion-button>
  </div>
</div>
<div *ngIf="!loading && !allowed" class="chat-unavailable">
  Try to contact your customer before contacting Housekeep
</div>
