import { DemoEndpoint } from '../../../demo-service';
import { getExtraJobsOneOffData, getExtraJobsRegularData } from '../../util';

const reassignableVisitsEndpoint: DemoEndpoint = {
  path: 'reassignable-visits/',
  handlers: [
    {
      method: 'get',
      handle(state, path) {
        return getExtraJobsOneOffData(state);
      }
    }
  ]
};

const reassignableJobsEndpoints: DemoEndpoint = {
  path: 'reassignable-regular-jobs/',
  handlers: [
    {
      method: 'get',
      handle(state, path) {
        return getExtraJobsRegularData(state);
      }
    }
  ]
};

export { reassignableJobsEndpoints, reassignableVisitsEndpoint };
