import { HttpErrorResponse } from '@angular/common/http';

import { dateToStr } from '@housekeep/infra';

import { DemoEndpoint } from 'services/demo-service';

import { getVisitDataFromPath } from '../../../util';

const visitStartEndpoint: DemoEndpoint = {
  path: 'start',
  handlers: [
    {
      method: 'patch',
      handle(state, path) {
        const visitData = getVisitDataFromPath(path, state);

        if (visitData.actual_date !== dateToStr(state.today())) {
          throw new HttpErrorResponse({
            status: 400,
            statusText: 'Bad Request',
            error: JSON.stringify({
              error_code: 'validation_error',
              error_message: 'Can only start visit on the day',
              success: false
            })
          });
        }

        // Start the visit
        visitData.started_at = state.now().utc().format();
        return visitData;
      }
    }
  ]
};

export { visitStartEndpoint };
