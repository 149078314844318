import { DemoEndpoint } from '../../../demo-service';

const featureIsActiveForUserEndpoint: DemoEndpoint = {
  path: 'feature/[a-z0-9-_]+',
  handlers: [
    {
      method: 'get',
      handle() {
        return {
          active: false
        };
      }
    }
  ]
};

export { featureIsActiveForUserEndpoint };
