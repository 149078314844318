export const CANCELLATIONS_HELP_CENTRE_URL =
  'https://housekeep.zendesk.com/hc/en-gb/articles/360032860551-Booking-Time-Off';

export const CANCELLATION_FEES_INFO_URL = 'https://app-housekeepers.housekeep.com/content/cancellation-fees.html';

// Coronavirus: URL to COVID-19 guidance in Zendesk Help Centre
export const COVID_19_HELP_CENTRE_URL =
  'https://housekeep.zendesk.com/hc/en-gb/articles/360007304718-Working-during-coronavirus-COVID-19-outbreak-FAQ';

export const FULFILMENT_HELP_CENTRE_URL =
  'https://housekeep.zendesk.com/hc/en-gb/articles/8705047663005-What-is-my-fulfilment-rate-and-how-does-it-work-';

export const HOW_DOES_MY_RELIABILITY_STREAK_WORK_URL =
  'https://housekeep.zendesk.com/hc/en-gb/articles/4410423143313-How-does-my-Reliability-Streak-work-';

export const LIVE_SERVICE_UPDATES_URL = 'https://housekeep.com/live-service-updates/';

export const PRIVACY_NOTICE_URL = 'https://housekeep.com/housekeeper-privacy-notice/';

export const TIME_OFF_HELP_CENTRE_URL = 'https://housekeep.zendesk.com/hc/en-gb/articles/4402722412177';
