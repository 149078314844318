import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'hk-list-item',
  templateUrl: './hk-list-item.component.html',
  styleUrls: ['./hk-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HkListItem {
  readonly MAX_ICON_SIZE = 20;

  @Input() iconName: string;
  @Input() iconColor: string;
  @Input() iconSize = 20;

  /**
   * Calculate left and right margin if iconSize is less than 20 pixels
   * so that the icon is aligned correctly.
   */
  public get marginX(): number {
    return (this.MAX_ICON_SIZE - this.iconSize) / 2;
  }
}
