import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import * as sanitizeHtml from 'sanitize-html';

interface Attribs {
  [attributeName: string]: string;
}

interface TagNameAndAttribs {
  tagName: string;
  attribs: Attribs;
}

interface TransformTags {
  [tagName: string]: (tagName: string, attribs: Attribs) => TagNameAndAttribs;
}

const ALLOWED_SCHEMES = ['http', 'https', 'mailto', 'tel'];

const DEFAULT_ALLOWED_TAGS = ['a', 'b', 'i', 'em', 'strong', 'br', 'blockquote', 'img'];

const DEFAULT_TRANSFORM_TAGS = {
  a: (tagName: string, attribs: Attribs): TagNameAndAttribs => {
    // Don't change anchor elements without an href
    if (!attribs.href) {
      return { tagName, attribs };
    }

    // Remove hkadmin links (can cut this some time after [#171702817] is live)
    if (attribs.href && attribs.href.includes('/hkadmin/')) {
      return { tagName: 'span', attribs: {} };
    }

    // Ensure links open in the system browser even if whitelisted
    return {
      tagName,
      attribs: {
        ...attribs,
        target: '_system'
      }
    };
  }
};

@Pipe({ name: 'sanitizeHtml' })
export class SanitizeHtml implements PipeTransform {
  transform(
    html: string,
    allowedTags: string[] = DEFAULT_ALLOWED_TAGS,
    transformTags: TransformTags = DEFAULT_TRANSFORM_TAGS
  ): string {
    return sanitizeHtml(html, {
      allowedTags,
      transformTags,
      allowedSchemes: ALLOWED_SCHEMES
    });
  }
}

/**
 * HTML pipe utility which bypasses the Angular HTML sanitizer
 * WARN: Only to be used on trusted data sources!
 */
@Pipe({ name: 'markHtmlSafe' })
export class MarkHtmlSafe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) {}

  transform(value) {
    return this.sanitized.bypassSecurityTrustHtml(value);
  }
}

export const sanitizeHtmlPipes = [SanitizeHtml, MarkHtmlSafe];
