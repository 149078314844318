import { Injectable } from '@angular/core';

import { Platform } from '@ionic/angular';
import { Platforms } from '@ionic/core';

import * as semver from 'semver';

import { Deferred } from '@housekeep/infra';

import { isDevEnv } from 'environment/environment';

import { WHATS_NEW_MAP } from './data/whats-new-service';
import { StorageService, WHATS_NEW_LAST_APP_SEEN } from './storage-service';
import { VersionService } from './version-service';

type WhatsNewContext = 'launch' | 'menu';
type WhatsNewSlidePlatform = 'android' | 'ios' | 'mobileweb' | 'desktop';
type WhatsNewSlideFormat = 'fullBlue' | 'split' | 'splitBlue' | undefined;

/**
 * A map of platform name to string value,
 * used to vary the slide title/description/image
 * based on the device platform.
 */
interface WhatsNewSlidePlatformMap {
  /** The value to use on Android. */
  android?: string;
  /** The value to use on iOS. */
  ios?: string;
  /** The value to use on mobile web. */
  mobileweb?: string;
  /** The value to use on desktop. */
  core?: string;
  /** The default value to use. */
  default?: string;
}

interface WhatsNewSlide {
  /** The slide title. */
  title: string | WhatsNewSlidePlatformMap;
  /** The HTML slide description. */
  description: string | WhatsNewSlidePlatformMap;
  /** The URL of an image to display in the side. */
  imageUri: string | WhatsNewSlidePlatformMap;
  /** The format of the slide. */
  format?: WhatsNewSlideFormat;
  /** Optional CSS styles for the image. */
  imageStyles?: { [key: string]: string };
  /** Optional array of platforms on which this slide should be shown. */
  platforms?: WhatsNewSlidePlatform[];
  /** Optional array of contexts in which this slide should be shown. */
  contexts?: WhatsNewContext[];
  /** Optional badge in the slide. */
  badge?: {
    color: 'warning' | 'danger' | 'success' | 'primary';
    badgeText: string;
  };
  /** Optional primary action settings in the slide. */
  primaryAction?: {
    /** Page url of the new primary action */
    url: string;
    buttonText: string;
  };
  /** Optional secondary action settings in the slide. */
  secondaryAction?: {
    buttonText: string;
  };
}

@Injectable({ providedIn: 'root' })
class WhatsNewService {
  public deviceVersion: string;
  public isDevEnv: boolean = isDevEnv;
  public whatsNewMap = WHATS_NEW_MAP;

  private _isReadyDeferred: Deferred<any> = new Deferred();

  public constructor(
    public platform: Platform,
    public storageService: StorageService,
    public versionService: VersionService
  ) {
    this.storageService.ready();
    this.versionService.ready().then(() => this.initialise());
  }

  public ready(): Promise<void> {
    return this._isReadyDeferred.promise;
  }

  public initialise() {
    if (!this.isDevEnv) {
      this.deviceVersion = this.versionService.getAppVersion().split('-')[0];
    } else {
      this.deviceVersion = this.whatsNewMap.keys().next().value; // Latest object
    }
    this._isReadyDeferred.resolve();
  }

  /**
   * Upon app init check to see if any news slides should be shown to the user.
   * @return {Promise<boolean>} resolving to `true` if slides are to be shown
   */
  public shouldShowNewsOnInit(): Promise<boolean> {
    return this.hasSeenLatestNews()
      .then(upToDate => !upToDate)
      .catch(e => false);
  }

  /**
   * Returns all slides for display.
   * @param {WhatsNewContext} context
   * @return {WhatsNewSlide[]}
   */
  public buildSlideDeck(context: WhatsNewContext): WhatsNewSlide[] {
    const slideDeck = [];
    if (context === 'launch') {
      slideDeck.push(...this.getSlidesForVersion(this.deviceVersion, context));
    } else {
      slideDeck.push(...this.getSlidesForAllVersions(context));
    }

    return slideDeck.map(slide => this.buildSlide(slide));
  }

  /**
   * Update the stored version string of the release news seen on device
   * @return {Promise}
   */
  public setLatestNewsSeen() {
    return this.storageService.set(WHATS_NEW_LAST_APP_SEEN, this.deviceVersion);
  }

  /**
   * Returns a platform-suitable `WhatsNewSlide`.
   * @param {WhatsNewSlide} slide
   * @return {WhatsNewSlide}
   */
  private buildSlide(slide: WhatsNewSlide): WhatsNewSlide {
    return {
      ...slide,
      title: this.getSlideValueForPlatform(slide.title),
      description: this.getSlideValueForPlatform(slide.description),
      imageUri: this.getSlideValueForPlatform(slide.imageUri)
    };
  }

  private getSlideValueForPlatform(value: string | WhatsNewSlidePlatformMap): string {
    if (typeof value === 'string') {
      return value;
    }

    // Return a platform-specific value if defined
    for (const platform of Object.keys(value) as Platforms[]) {
      if (this.platform.is(platform)) {
        return value[platform];
      }
    }

    // Return the default value if defined
    if (value.default !== undefined) {
      return value.default;
    }

    return null;
  }

  /**
   * Returns slides for all app versions, filtered by platform and context.
   * @param {WhatsNewContext} context
   * @return {WhatsNewSlide[]} an array of slides for all app versions
   */
  private getSlidesForAllVersions(context: WhatsNewContext) {
    const slides = [];
    for (const version of Array.from(this.whatsNewMap.keys())) {
      slides.push(...this.getSlidesForVersion(version, context));
    }
    return slides;
  }

  /**
   * Returns slides for the specified app version, filtered by platform and context.
   * @param {string} version
   * @param {WhatsNewContext} context
   * @return {WhatsNewSlide[]} a shallow clone of the array of slides for that version
   */
  private getSlidesForVersion(version: string, context: WhatsNewContext) {
    const slides = this.whatsNewMap.get(version);

    if (!slides) {
      return [];
    }

    return slides.filter(slide => this.filterForContext(slide, context)).filter(slide => this.filterForPlatform(slide));
  }

  /**
   * Return `true` if the given slide should be shown on this platform (e.g. ios),
   * and `false` otherwise.
   * @param {WhatsNewSlide} slide
   * @return {boolean}
   */
  private filterForPlatform(slide: WhatsNewSlide) {
    // Include slides that aren't filtered by platform
    if (!slide.platforms || !slide.platforms.length) {
      return true;
    }

    // Include slides that match the device's platform
    for (const platform of slide.platforms) {
      if (this.platform.is(platform)) {
        return true;
      }
    }

    return false;
  }

  /**
   * Return `true` if the given slide should be shown in the given context,
   * and `false` otherwise.
   * @param {WhatsNewSlide} slide
   * @param {WhatsNewContext} context
   * @return {boolean}
   */
  private filterForContext(slide: WhatsNewSlide, context: WhatsNewContext) {
    return slide.contexts ? slide.contexts.indexOf(context) !== -1 : true;
  }

  /*
   * Returns if the app version is up to date with latest news
   */
  private hasSeenLatestNews(): Promise<boolean> {
    return this.getLatestNewsSeen()
      .then(latestSeenVersion => {
        if (!semver.valid(latestSeenVersion) || !semver.valid(this.deviceVersion)) {
          return true;
        }
        return semver.gte(latestSeenVersion, this.deviceVersion);
      })
      .catch(e => true);
  }

  /*
   * Returns a string of the latest version of news the device has seeen
   */
  private getLatestNewsSeen(): Promise<string> {
    return this.storageService
      .get(WHATS_NEW_LAST_APP_SEEN)
      .then(latestSeenVersionStored => latestSeenVersionStored || '1.0.0')
      .catch(e => '1.0.0');
  }
}

export { WhatsNewContext, WhatsNewService, WhatsNewSlide };
