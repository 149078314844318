import { DemoEndpoint } from '../../demo-service';

const analyticsEndpoint: DemoEndpoint = {
  path: 'analytics',
  handlers: [
    {
      method: 'post',
      handle(state, path, parameters, headers, body) {
        return {
          app_version: 'dev',
          context: 'housekeeperapp',
          device: null,
          user: 1,
          ...body
        };
      }
    }
  ]
};

export { analyticsEndpoint };
