import { DemoEndpoint } from '../../../demo-service';
import { HOUSEKEEPER_REFERRALS } from '../../data/referrals';
import { cloneAndReplaceDates } from '../../util';

const workerReferralsEndpoint: DemoEndpoint = {
  path: 'referrals',
  handlers: [
    {
      method: 'get',
      handle(state) {
        return cloneAndReplaceDates(HOUSEKEEPER_REFERRALS, state.today());
      }
    }
  ]
};

export { workerReferralsEndpoint };
