import { dayOfWeek, toDate } from '@housekeep/infra';

import { DemoEndpoint } from '../../../demo-service';
import { getWorkerAvailabilityChangesData, getWorkerAvailabilityData } from '../../util';

const workerAvailabilityEndpoint: DemoEndpoint = {
  path: 'availability/$',
  handlers: [
    {
      method: 'get',
      handle(state) {
        return getWorkerAvailabilityData(state);
      }
    }
  ]
};

const workerDayAvailabilityEndpoint: DemoEndpoint = {
  path: 'availability/\\d{4}-\\d{2}-\\d{2}',
  handlers: [
    {
      method: 'get',
      handle(state, path) {
        const day = path.match(/availability\/(\d{4}-\d{2}-\d{2})/)[1];
        const dayOfTheWeek = dayOfWeek(toDate(day));

        const dayAvailability = getWorkerAvailabilityData(state).find(d => d.day_of_week === dayOfTheWeek);

        return {
          day,
          availability: dayAvailability.availability,
          worker_id: dayAvailability.worker_id,
          reason: null
        };
      }
    }
  ]
};

const workerAvailabilityChangesEndpoint: DemoEndpoint = {
  path: 'availability/changes',
  handlers: [
    {
      method: 'get',
      handle(state) {
        return getWorkerAvailabilityChangesData(state);
      }
    },
    {
      method: 'post',
      handle(state, path, parameters, headers, body) {
        const newTimeOffRequest = {
          end_date: body.end_date,
          category: body.category,
          reason: body.reason,
          availability: [],
          start_date: body.start_date,
          requires_ops: false
        };
        state.workerAvailabilityData.push(newTimeOffRequest);

        return newTimeOffRequest;
      }
    }
  ]
};

export { workerAvailabilityEndpoint, workerDayAvailabilityEndpoint, workerAvailabilityChangesEndpoint };
