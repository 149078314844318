import { nowNaive } from '@housekeep/infra';

import { DemoEndpoint } from '../../../demo-service';

const transferKeyEndpoint: DemoEndpoint = {
  path: 'key-transfer',
  handlers: [
    {
      method: 'post',
      handle(state, path, parameters, headers, body) {
        if (body.to_location === 'worker') {
          return {
            transfer: {
              confirmed_at: nowNaive().format(),
              confirmed_by: 'jkf82c42gzzn',
              from_location: 'With Housekeeper',
              from_holder: null,
              to_location: 'With Housekeeper',
              cancelled: false,
              key_id: 'dmz2gef4vxok',
              id: 'jovkrum9gvzp',
              key: {
                code: '3456',
                location: 'worker',
                property_id: 'xb6y5cwenmop',
                holder_id: 'kvz954h4g3bq',
                transfer_in_progress: true,
                id: 'dmz2gef4vxok'
              }
            },
            success: true
          };
        } else {
          return {
            transfer: {
              confirmed_at: null,
              confirmed_by: null,
              from_location: 'With customer',
              from_holder: null,
              to_location: 'With Housekeeper',
              cancelled: false,
              key_id: 'dmz2gef4vxok',
              id: 'jovkrum9gvzp',
              key: {
                code: '13003',
                location: 'customer',
                property_id: 'xb6y5cwenmop',
                holder_id: null,
                transfer_in_progress: true,
                id: 'dmz2gef4vxok'
              }
            },
            success: true
          };
        }
      }
    }
  ]
};

export { transferKeyEndpoint };
