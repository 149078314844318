import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

import { Ticket } from 'models/ticket';

import { ticketSerializer } from 'serializers/ticket';

@Component({
  selector: 'help-request-ticket',
  templateUrl: './help-request-ticket.component.html',
  styleUrls: ['./help-request-ticket.component.scss']
})
export class HelpRequestTicketComponent {
  @Input() public ticket: Ticket;

  public constructor(private router: Router) {}

  public async openTicketDetail(): Promise<void> {
    await this.router.navigate(['/app-housekeepers/help/help-request-activity', this.ticket.ticketId], {
      state: {
        ticket: ticketSerializer.serialize(this.ticket)
      }
    });
  }
}
