import { Component, Input, OnInit } from '@angular/core';

import { PropertyAccess, PropertyAccessInformation } from '@housekeep/infra';

import { VisitChanges } from 'models/visit-changes';

@Component({
  selector: 'hk-visit-access',
  templateUrl: './hk-visit-access.component.html',
  styleUrls: ['./hk-visit-access.component.scss']
})
export class HkVisitAccess implements OnInit {
  @Input() public access: string;
  @Input() public accessDisplay: string;
  @Input() public accessInformation: PropertyAccessInformation;
  @Input() public showSensitive: boolean = false;
  @Input() public visitChanges: VisitChanges;
  @Input() public isVisitToday: boolean = false;

  public readonly PROPERTY_ACCESS = PropertyAccess;
  public availableOnVisitDateMsg = 'Only available on day of clean';

  public hasAccessChanges: boolean = false;

  public ngOnInit(): void {
    if (this.accessInformation && this.visitChanges) {
      // Any change to access is relevant EXCEPT those specifically related to finishing
      this.hasAccessChanges = this.visitChanges.hasPropertyAccessChanges(this.accessInformation);
    }
  }

  public showNumKeys(): boolean {
    return (
      this.accessInformation.numKeys &&
      this.access !== PropertyAccess.AtHome &&
      this.access !== PropertyAccess.Supervisor
    );
  }

  public keyCode(): string | undefined {
    if (this.accessInformation.key) {
      return this.accessInformation.key.code;
    }
  }
}
