<div *ngIf="!loading && allowed && message" class="contact-customer">
  <div class="contact-customer__preview">
    <div class="contact-customer__preview__message">
      {{ message }}
    </div>
  </div>
  <ion-button
    color="primary"
    expand="block"
    class="contact-customer__send-button"
    (click)="goToChatWithMessage()"
  >
    <hk-wc-icon name="chat" size="24" slot="start"></hk-wc-icon>
    Send this message
  </ion-button>
</div>
<div *ngIf="!loading && !allowed" class="chat-unavailable">Chat is not available</div>
<ion-grid class="contact-customer__communicate-buttons">
  <ion-row>
    <ion-col>
      <ion-button color="primary" expand="block" (click)="requestCustomerCall()">
        <hk-wc-icon name="phone" size="24" slot="start"></hk-wc-icon>
        Call
      </ion-button>
    </ion-col>
    <ion-col>
      <ion-button
        color="primary"
        expand="block"
        [disabled]="!allowed"
        (click)="goToChatWithoutMessage()"
      >
        <hk-wc-icon name="chat-double" size="24" slot="start"></hk-wc-icon>
        Open chat
      </ion-button>
    </ion-col>
  </ion-row>
</ion-grid>
