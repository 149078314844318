import { InjectionToken } from '@angular/core';

const APP_CONFIG = new InjectionToken<AppConfig>('app.config');

// Note: Currently generates warnings under test due to http://bit.ly/2ncKPTP
interface AppConfig {
  API_ENDPOINTS: {
    OBTAIN_TOKEN: string;
    START_ONBOARDING: string;
    QUEUED_RESPONSE: string;
    RELEASE_HIJACK: string;
    RESET_PASSWORD: string;
    SET_PASSWORD: string;
    USER_DETAILS: string;
  };
  POLL_INTERVAL_MS: number;
  STORAGE_NAMESPACE: string;
  FEATURES: {
    /**
     * The name of the feature that disables the option to
     * request a call back when switched off by senior ops.
     */
    CONTACT_CUSTOMER_SERVICE_HOUSEKEEPER_CALLBACK: string;
    /**
     * The name of the feature that disables the option to
     * email in when switched off by senior ops.
     */
    CONTACT_ALLOW_HOUSEKEEPER_TO_EMAIL_CS_DURING_OPEN_HOURS: string;
  };
}

const INJECTED_APP_CONFIG: AppConfig = {
  API_ENDPOINTS: {
    OBTAIN_TOKEN: 'auth/obtain-token/',
    START_ONBOARDING: 'auth/start-onboarding/',
    QUEUED_RESPONSE: 'infrastructure/rq-job-state/',
    RELEASE_HIJACK: 'infrastructure/release-hijack/',
    RESET_PASSWORD: 'auth/reset-password/',
    SET_PASSWORD: 'auth/set-password/',
    USER_DETAILS: 'auth/'
  },
  FEATURES: {
    CONTACT_CUSTOMER_SERVICE_HOUSEKEEPER_CALLBACK: 'contact-cs-housekeeper-callback',
    CONTACT_ALLOW_HOUSEKEEPER_TO_EMAIL_CS_DURING_OPEN_HOURS: 'contact-cs-housekeeper-email-in-open-hours'
  },
  POLL_INTERVAL_MS: 2000,
  STORAGE_NAMESPACE: 'housekeep_professionals'
};

export { AppConfig, APP_CONFIG, INJECTED_APP_CONFIG };
