import { ExternalId, Model, model } from '@housekeep/infra';

interface PaymentDetails extends Model {
  workerId: ExternalId;
  nameOnAccount: string;
  accountNumber: string;
  sortCode: string;
  nationalInsuranceNumber: string;
  pendingPaymentPeriodExists?: boolean;
}

const paymentDetails: PaymentDetails = model.extend({
  modelName: 'paymentDetails'
});

export { paymentDetails, PaymentDetails };
