<div class="debug-menu">
  <p>
    This is the debugging menu for the Housekeep development team.
    Do not touch anything here if you do not know what you're doing!
  </p>
  <p>The current environment is <strong>{{ currentEnv }}</strong>.</p>
  <ion-button class="debug-menu__button" expand="block"
          (click)="setProdEnv()">
    Use production environment
  </ion-button>
  <ion-button class="debug-menu__button" expand="block"
          (click)="setStagingEnv()">
    Use staging environment
  </ion-button>
  <ion-button class="debug-menu__button" expand="block"
          (click)="setDevEnv()">
    Use development environment
  </ion-button>
</div>
