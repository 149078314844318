import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';

import { Visit } from '@housekeep/infra';

import { AnalyticsService, TapElementName } from 'services/analytics-service';
import { ChatInfoService } from 'services/chat-info-service';
import { ContactService } from 'services/contact-service';

/**
 * Displays a preview of a chat message that can be sent to a customer.
 */
@Component({
  selector: 'contact-customer',
  templateUrl: './contact-customer.component.html',
  styleUrls: ['./contact-customer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactCustomer implements OnInit {
  @Input() visit: Visit;
  @Input() message?: string;
  @Input() helpCategory: string;
  @Input() chatTrackingName?: TapElementName;
  @Input() callTrackingName?: TapElementName;
  @Input() sendMessageTrackingName?: TapElementName;

  @Output() chatting = new EventEmitter<string>();

  public loading = true;
  public allowed: boolean;

  constructor(
    protected analyticsService: AnalyticsService,
    private chatInfoService: ChatInfoService,
    private contactService: ContactService,
    private cdRef: ChangeDetectorRef
  ) {}

  async ngOnInit(): Promise<void> {
    try {
      this.allowed = await this.chatInfoService.customerChatIsAllowed(this.visit);
    } catch (err) {
      this.allowed = false;
    }

    this.loading = false;
    this.cdRef.markForCheck();
  }

  public async goToChatWithMessage(): Promise<void> {
    if (this.sendMessageTrackingName) {
      await this.analyticsService.trackTap(this.sendMessageTrackingName);
    }
    this.goToChat(this.message);
  }

  public async goToChatWithoutMessage(): Promise<void> {
    if (this.chatTrackingName) {
      await this.analyticsService.trackTap(this.chatTrackingName);
    }
    this.goToChat('');
  }

  public async requestCustomerCall(): Promise<void> {
    if (this.callTrackingName) {
      await this.analyticsService.trackTap(this.callTrackingName);
    }
    await this.contactService.requestCustomerCall(this.visit, this.helpCategory);
  }

  protected async goToChat(message: string): Promise<void> {
    this.chatting.emit(message);
  }
}
