import { Component, Input } from '@angular/core';

import { Key } from '@housekeep/infra';

@Component({
  selector: 'key-collection-help-block',
  templateUrl: './key-collection-help-block.component.html'
})
export class KeyCollectionHelpBlock {
  @Input() public key: Key;
}
