import { DemoEndpoint } from '../../../demo-service';
import { getWorkerDailyStatsData } from '../../util';

export const dailyStatsEndpoint: DemoEndpoint = {
  path: 'daily-stats',
  handlers: [
    {
      method: 'get',
      handle(state) {
        return getWorkerDailyStatsData(state);
      }
    }
  ]
};
