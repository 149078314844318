import { ModelSerializer, modelSerializer, serializerField, visitSerializer } from '@housekeep/infra';

import {
  workerVisitRating,
  WorkerVisitRating,
  workerVisitRatingIssue,
  WorkerVisitRatingIssue
} from 'models/worker-visit-rating';

export const workerVisitRatingSerializer = modelSerializer.extend({
  model: workerVisitRating,
  fields: {
    visit: serializerField.create({
      serializer: visitSerializer,
      optional: true
    })
  }
}) as ModelSerializer<WorkerVisitRating>;

export const workerVisitRatingIssueSerializer = modelSerializer.extend({
  model: workerVisitRatingIssue
}) as ModelSerializer<WorkerVisitRatingIssue>;
