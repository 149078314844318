import { DemoEndpoint } from '../../../demo-service';

const chatIsAllowedEndpoint: DemoEndpoint = {
  path: 'chat/allowed',
  handlers: [
    {
      method: 'get',
      handle() {
        return { allowed: false };
      }
    }
  ]
};

export { chatIsAllowedEndpoint };
