import { Pipe, PipeTransform } from '@angular/core';

import { range } from 'lodash-es';

/**
 * Pipe to return an Array representing a range between 0 and the number given.
 */
@Pipe({ name: 'range' })
export class Range implements PipeTransform {
  public transform(count: number): Array<number> {
    return range(count);
  }
}

@Pipe({ name: 'asNumber' })
export class AsNumber implements PipeTransform {
  public transform(numeric: number): string {
    if (numeric === undefined || numeric === null) {
      return '';
    }

    const parts = numeric.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
  }
}

@Pipe({ name: 'asPercent' })
export class Percent implements PipeTransform {
  public transform(decimal: number): string {
    if (decimal === undefined || decimal === null) {
      return '';
    }

    return Math.floor(decimal * 100) + '%';
  }
}

export const numberPipes = [Range, AsNumber, Percent];
