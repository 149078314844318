import { Injectable } from '@angular/core';

import { Duration, RequestOpts } from '@housekeep/infra';

import { CacheService } from './cache-service';
import { ErrorService } from './error-service';
import { RequestService } from './request-service';

/** The name of a Housekeep feature. */
type FeatureName = string;
/** The name of a Housekeep setting. */
type SettingName = string;

const CONSTANTS_ENDPOINT = 'infrastructure/constants/';
const FEATURE_IS_ACTIVE_ENDPOINT = 'infrastructure/feature-generally-active/';
const FEATURE_IS_ACTIVE_FOR_USER_ENDPOINT = 'infrastructure/feature/';
const SETTING_VALUE_ENDPOINT = 'infrastructure/setting-value/';

const DEFAULT_FEATURE_CACHE_DURATION = new Duration({ days: 1 });

export interface InfrastructureConstants {
  cleaningMaterialsWorker: number;
  triallingTargetVisitCount: number;
}

@Injectable({ providedIn: 'root' })
export class InfrastructureService {
  constructor(
    private requestService: RequestService,
    private cacheService: CacheService,
    private errorService: ErrorService
  ) {}

  /**
   * Gets the value of the specified setting from the backend.
   * @param setting The name of the setting
   * @param defaultValue The default value to return if the setting has no value
   */
  public async getSettingValue<T extends string | number | boolean>(
    setting: SettingName,
    defaultValue: T = null
  ): Promise<T> {
    const settingValue = (await this.requestService.get(`${SETTING_VALUE_ENDPOINT}${setting}/`)).value;
    return settingValue === null ? defaultValue : settingValue;
  }

  /**
   * Gets the state of the specified feature from the backend,
   * and returns `True` if the feature is enabled, and `False` otherwise.
   * @param feature The name of the feature
   */
  public async isFeatureActive(feature: FeatureName): Promise<boolean> {
    return (await this.requestService.get(`${FEATURE_IS_ACTIVE_ENDPOINT}${feature}/`)).active;
  }

  /**
   * Gets the state (for the current user) of the specified feature from the backend,
   * and returns `True` if the feature is enabled, and `False` otherwise.
   *
   * This endpoint should only be used for features that are intended to be
   * enabled/disabled for specific users. It _will_ work for features that are globally
   * enabled/disabled, but it is more efficient to use `isFeatureActive` as this benefits
   * from CDN caching.
   * @param feature The name of the feature
   */
  public async isFeatureActiveForUser(feature: FeatureName, opts?: RequestOpts): Promise<boolean> {
    return (await this.requestService.get(`${FEATURE_IS_ACTIVE_FOR_USER_ENDPOINT}${feature}/`, opts)).active;
  }

  /**
   * Sets the state (for the current user) of the specified feature from the backend,
   * and returns `true` if the feature is set successfully.
   * @param feature The name of the feature
   */
  public async setFeatureActiveForUser(feature: FeatureName): Promise<boolean> {
    return (await this.requestService.post(`${FEATURE_IS_ACTIVE_FOR_USER_ENDPOINT}${feature}/`, {})).active;
  }

  /**
   * Gets whether the given feature is enabled `specifically for this user` as
   * opposed to being generally active.
   *
   * Unlike `isFeatureActiveForUser`, this will save to and return from the cache. If the `forceRefresh` parameter is
   * `true`, this will update the cached value to the latest retrieved from the backend.
   */
  public async cachedIsFeatureActiveForUser(feature: FeatureName, forceRefresh: boolean = false): Promise<boolean> {
    const isFeatureActivePromise = this.isFeatureActiveForUser(feature, {
      cache: {
        key: feature,
        duration: DEFAULT_FEATURE_CACHE_DURATION
      }
    });

    if (forceRefresh) {
      return isFeatureActivePromise;
    }

    return this.cacheService
      .get(feature)
      .then(feature => feature.active)
      .catch(err => {
        if (this.errorService.isCacheError(err)) {
          return isFeatureActivePromise;
        } else {
          throw err;
        }
      });
  }

  public async constants(): Promise<InfrastructureConstants> {
    // TODO: cache the results for an hour;
    return await this.requestService.get(`${CONSTANTS_ENDPOINT}`);
  }
}
