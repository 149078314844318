<div>
  <ion-card>
    <ion-card-content
      class="ion-no-padding ion-text-center"
      [ngStyle]="{ height: mapImgDimensions.height + 'px' }"
    >
      <img
        src="https://maps.googleapis.com/maps/api/staticmap?center={{destinationAddress}}&markers={{destinationAddress}}&size={{mapImgDimensions.width}}x{{mapImgDimensions.height}}&key={{environment.GOOGLE_MAPS_API_KEY}}"
        width="{{ mapImgDimensions.width }}"
        height="{{ mapImgDimensions.height }}"
        *ngIf="mapImgDimensions"
      />
    </ion-card-content>
  </ion-card>
  <ion-fab horizontal="end" vertical="top" slot="fixed">
    <ion-fab-button (click)="openInExternalMapApp()">
      <hk-wc-icon name="navigation-arrow" size="32"></hk-wc-icon>
    </ion-fab-button>
  </ion-fab>
</div>
