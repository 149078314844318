<ion-card *ngIf="access" [class.changes]="hasAccessChanges">
  <ion-card-header>
    <ion-card-title>
      Access
      <ion-badge *ngIf="hasAccessChanges" color="warning" class="changed-badge">
        <hk-wc-icon name="recurring-arrow" size="16"></hk-wc-icon>
        Updated
      </ion-badge>
    </ion-card-title>
  </ion-card-header>

  <ion-card-content>
    <div class="access-item access-item-access" *ngIf="accessDisplay">
      <p class="access-item-label">Method</p>
      <p
        class="access-item-value"
        [class.changes]="visitChanges?.hasPropertyChanges('access')"
      >
        {{ accessDisplay | capitalise }}
      </p>
    </div>

    <ng-container *ngIf="showSensitive && accessInformation">
      <!-- KeyNest displayed before finding entrance, might require travel -->
      <ng-container *ngIf="access === PROPERTY_ACCESS.KeyNest">
        <div class="access-item access-item-key-nest-location">
          <p class="access-item-label">KeyNest location</p>
          <p
            class="access-item-value"
            [class.changes]="visitChanges?.hasPropertyChanges('keyNestLocation')"
          >
            {{ accessInformation.keyNestLocation | capitaliseSentence }}
          </p>
        </div>
        <div class="access-item access-item-key-nest-code">
          <p class="access-item-label">KeyNest code</p>
          <p
            class="access-item-value"
            [class.changes]="visitChanges?.hasPropertyChanges('keyNestCode')"
          >
            {{ isVisitToday ? accessInformation.keyNestCode : availableOnVisitDateMsg }}
          </p>
        </div>
      </ng-container>

      <div
        *ngIf="accessInformation.findingEntranceInstructions"
        class="access-item access-item-finding-entrance"
      >
        <p class="access-item-label">Finding the entrance</p>
        <p
          class="access-item-value"
          [class.changes]="
            visitChanges?.hasPropertyChanges('findingEntranceInstructions')
          "
        >
          {{ accessInformation.findingEntranceInstructions | capitaliseSentence }}
        </p>
      </div>

      <div
        *ngIf="access === PROPERTY_ACCESS.Concierge"
        class="access-item access-item-concierge-location"
      >
        <p class="access-item-label">Concierge location</p>
        <p
          class="access-item-value"
          [class.changes]="visitChanges?.hasPropertyChanges('conciergeLocation')"
        >
          {{ accessInformation.conciergeLocation | capitaliseSentence }}
        </p>
      </div>

      <ng-container *ngIf="access === PROPERTY_ACCESS.KeySafe">
        <div class="access-item access-item-key-safe-location">
          <p class="access-item-label">Safe location</p>
          <p
            class="access-item-value"
            [class.changes]="visitChanges?.hasPropertyChanges('keySafeLocation')"
          >
            {{ accessInformation.keySafeLocation | capitaliseSentence }}
          </p>
        </div>
        <div class="access-item access-item-key-safe-code">
          <p class="access-item-label">Safe code</p>
          <p
            class="access-item-value"
            [class.changes]="visitChanges?.hasPropertyChanges('keySafeCode')"
          >
            {{ isVisitToday ? accessInformation.keySafeCode : availableOnVisitDateMsg }}
          </p>
        </div>
      </ng-container>

      <div
        *ngIf="access === PROPERTY_ACCESS.KeyHidden"
        class="access-item access-item-hidden-key-location"
      >
        <p class="access-item-label">Key location</p>
        <p
          class="access-item-value"
          [class.changes]="visitChanges?.hasPropertyChanges('hiddenKeyLocation')"
        >
          {{
            (isVisitToday ? accessInformation.hiddenKeyLocation : availableOnVisitDateMsg)
              | capitaliseSentence
          }}
        </p>
      </div>

      <div
        *ngIf="access === PROPERTY_ACCESS.Keys"
        class="access-item access-item-key-code"
      >
        <p class="access-item-label">Key number</p>
        <p class="access-item-value" [class.text--danger]="!keyCode()">
          {{ keyCode() || 'Unknown' }}
        </p>
      </div>

      <div *ngIf="showNumKeys()" class="access-item access-item-num-keys">
        <p class="access-item-label">Number of keys</p>
        <p
          class="access-item-value"
          [class.changes]="visitChanges?.hasPropertyChanges('numKeys')"
        >
          {{ accessInformation.numKeys }}
        </p>
      </div>

      <ng-container
        *ngIf="access !== PROPERTY_ACCESS.AtHome && accessInformation.alarm"
        class="visit-arriving__alarm"
      >
        <div class="access-item access-item-alarm-instructions">
          <p class="access-item-label">Alarm instructions</p>
          <p
            class="access-item-value"
            [class.changes]="visitChanges?.hasPropertyChanges('alarmEntryInstructions')"
          >
            {{
              (isVisitToday
                ? accessInformation.alarmEntryInstructions
                : availableOnVisitDateMsg
              ) | capitaliseSentence
            }}
          </p>
        </div>
      </ng-container>

      <div
        *ngIf="access !== PROPERTY_ACCESS.AtHome && accessInformation.openingInstructions"
        class="access-item access-item-opening-instructions"
      >
        <p class="access-item-label">Opening instructions</p>
        <p
          class="access-item-value"
          [class.changes]="visitChanges?.hasPropertyChanges('openingInstructions')"
        >
          {{ accessInformation.openingInstructions | capitaliseSentence }}
        </p>
      </div>
    </ng-container>
  </ion-card-content>
</ion-card>
