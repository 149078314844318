import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { noop } from 'lodash-es';

import { interval } from 'rxjs';
import { timeout } from 'rxjs/operators';

import {
  ApiUrlService,
  Date,
  DateTime,
  dateTimeField,
  Deferred,
  Duration,
  nowNaive,
  serializer,
  toDate
} from '@housekeep/infra';

import { OnlineService } from './online-service';
import { StorageService } from './storage-service';

const SYNC_ENDPOINT = 'infrastructure/sync-time/';
const SYNC_TIMEOUT = 1500;
const syncSerializer = serializer.extend({
  fields: { now: dateTimeField.create() }
});

const STORAGE_KEY_TZ_OFFSET_MINS = 'TIME_SERVICE__TZ_OFFSET_MINS';
const STORAGE_KEY_DIFF_MS = 'TIME_SERVICE__DIFF_MS';

const REFRESH_EVERY = new Duration({ minutes: 30 });

@Injectable({ providedIn: 'root' })
class TimeService {
  public Duration = Duration;
  private _isReadyDeferred: Deferred<any> = new Deferred();

  private _diffMs: number = 0;
  private _tzOffsetMins: number = 0;

  constructor(
    private apiUrlService: ApiUrlService,
    private http: HttpClient,
    private onlineService: OnlineService,
    private storageService: StorageService
  ) {
    this.storageService.ready().then(() => this._initialize());
  }

  public ready(): Promise<void> {
    return this._isReadyDeferred.promise;
  }

  /**
   * Retrieve the current time from the server and sync the app's time with it.
   */
  public syncTime(): Promise<DateTime> {
    return this._syncTimeServer()
      .catch(() => this._syncTimeStorage())
      .then(() => this.now());
  }

  /**
   * Use the given datetime to update the definition of "now"
   */
  public setNow(now: DateTime) {
    this._diffMs = now.diff(nowNaive());
    this._tzOffsetMins = now.parseZone().utcOffset();
    this.storageService.set(STORAGE_KEY_DIFF_MS, this._diffMs);
    this.storageService.set(STORAGE_KEY_TZ_OFFSET_MINS, this._tzOffsetMins);
  }

  /**
   * Returns "today" from the perspective of the server
   */
  public today(): Date {
    return toDate(this.now());
  }

  /**
   * Returns "now" from the perspective of the server
   */
  public now(): DateTime {
    return nowNaive().add(this._diffMs).utcOffset(this._tzOffsetMins);
  }

  private _initialize() {
    this.syncTime().then(() => this._isReadyDeferred.resolve());

    interval(REFRESH_EVERY.toMilliseconds()).subscribe(() => this.syncTime());
  }

  /**
   * Attempt to synchronise the definition of "now" by retrieving the time from
   * the Housekeep server.
   */
  private _syncTimeServer(): Promise<void> {
    return this.http
      .get(this.apiUrlService.normaliseUrl(SYNC_ENDPOINT))
      .pipe(timeout(SYNC_TIMEOUT))
      .toPromise()
      .then(response => {
        this.onlineService.setOnlineState(true);
        const { now } = syncSerializer.deserialize(response);
        return this.setNow(now);
      });
  }

  /**
   * Attempt to synchronise the definition of "now" by retrieving from storage
   * the last captured difference between the server and the device in
   * milliseconds, and the server's timezone offset.
   */
  private _syncTimeStorage(): Promise<void> {
    return this.storageService
      .getMany(STORAGE_KEY_DIFF_MS, STORAGE_KEY_TZ_OFFSET_MINS)
      .then(([diffMs, tzOffsetMins]) => {
        if (diffMs && tzOffsetMins) {
          this._diffMs = parseInt(diffMs, 10);
          this._tzOffsetMins = parseInt(tzOffsetMins, 10);
        }
      })
      .catch(noop);
  }
}

export { TimeService, STORAGE_KEY_DIFF_MS, STORAGE_KEY_TZ_OFFSET_MINS, REFRESH_EVERY, SYNC_ENDPOINT };
