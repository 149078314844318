import { Injectable } from '@angular/core';

import { noop } from 'lodash-es';

import { Observable } from 'rxjs';

import {
  Date,
  dateField,
  dateToStr,
  Duration,
  Job,
  jobSerializer,
  Key,
  PaginatedResponse,
  RequestOpts,
  serializer,
  serializerField,
  toDate,
  Visit,
  visitSerializer
} from '@housekeep/infra';

import { AssignableJob } from 'models/assignable-job';
import { AvailabilityPeriod } from 'models/mixin.availability';

import { assignableJobSerializer } from 'serializers/assignable-job';
import { availabilitySerializer } from 'serializers/availability';

import { RequestOptsWithOfflineHandling } from 'util/http';

import { SCHEDULE_CACHE_KEY } from 'var/cache';
import { OfflineEventActionTypes, OfflineEventObjectTypes } from 'var/offline-events';

import { CacheService } from './cache-service';
import { DemoService } from './demo-service';
import { ErrorService } from './error-service';
import { CONFIG as EXTRA_JOBS } from './extra-jobs-service.util';
import { LocationService } from './location-service';
import { RequestService } from './request-service';
import { TimeService } from './time-service';
import { ToastService } from './toast-service';
import { UserService } from './user-service';

const CACHE_KEY = 'VISIT_';

const REASSIGNABLE_VISITS_PARAMS_SERIALIZER = serializer.extend({
  fields: {
    day: dateField.create()
  }
});

const ASSIGN_JOB_REQUEST_PARAMS_SERIALIZER = serializer.extend({
  fields: {
    availability_changes: serializerField.create({
      serializer: availabilitySerializer,
      serializerKwargs: { many: true },
      optional: true
    })
  }
});

export interface VisitFinishDetails {
  keysTaskComplete?: boolean;
  completedDuration?: number;
  customerAgreedDurationChanges?: boolean;
  durationChangesMessage?: string;
  changeTab?: 'arriving' | 'cleaning' | 'finishing';
}

/**
 * Service to handle retrieving visit information from the public API.
 */
@Injectable({ providedIn: 'root' })
class VisitService {
  private _cacheDuration = new Duration({ days: 1 });

  public constructor(
    public cacheService: CacheService,
    public demoService: DemoService,
    public errorService: ErrorService,
    public locationService: LocationService,
    public requestService: RequestService,
    public timeService: TimeService,
    public toastService: ToastService,
    public userService: UserService
  ) {}

  /**
   * Return a subscription to get the visit with the given job ID and scheduled date.
   *
   * The default behaviour is to immediately attempt to resolve the cached
   * visit, and then make a request for the latest data. Cache misses are
   * ignored, but failed requests propagate.
   *
   * The result of this process is that users are always given up to date
   * information when available, but are able to handle no internet exceptions
   * by falling back to the cached data.
   */
  public getVisit(jobId: string, scheduledDate: Date, opts: RequestOpts = {}): Observable<any> {
    const endpoint = this._getVisitEndpoint(jobId, scheduledDate);
    const defaultOpts: RequestOptsWithOfflineHandling = {
      waitForOfflineEvents: false,
      cache: {
        duration: this._cacheDuration,
        key: this._getCacheKey(jobId, scheduledDate)
      },
      params: {
        expand: [
          'account__initial_customer',
          'instructions',
          'property__access_information',
          'property__cleaning_equipment',
          'property__pets',
          'remote_key_transfer_keys',
          'remote_key_transfers',
          'worker_can_call_customer',
          'recommended_start_time_slot'
        ]
      }
    };

    // If called with opts `params.expand`, concatenate with the default expand array.
    if ('params' in opts && 'expand' in opts.params) {
      opts.params.expand = [...defaultOpts.params.expand, ...opts.params.expand];
    }
    opts = Object.assign(defaultOpts, opts);
    return new Observable<Visit>(observer => {
      if (opts.cache) {
        this.cacheService
          .get(opts.cache.key)
          .then(json => observer.next(visitSerializer.deserialize(json)))
          .catch(() => true);
      }

      this.userService
        .getUser()
        .then(() => this.requestService.getInstance(endpoint, visitSerializer, opts))
        .then(visit => {
          observer.next(visit);
          observer.complete();
        })
        .catch(err => observer.error(err));
    });
  }

  /**
   * @param visit The visit to be cached
   * @param force Force fresh fetch of data to update cache
   */
  public cacheVisit(visit: Visit, force: boolean = false) {
    return this.isCached(visit).then(isCached => {
      if (!isCached || force) {
        // getVisit will do the caching for us
        const observable = this.getVisit(visit.jobId, visit.scheduledDate);
        return observable.toPromise();
      }
    });
  }

  public uncacheVisit(visit: Visit): Promise<any> {
    const cacheKey = this._getCacheKey(visit.jobId, visit.scheduledDate);
    return this.cacheService.remove(cacheKey);
  }

  /**
   * Removes past visits from the cache
   */
  public uncachePastVisits(): Promise<void> {
    return this.cacheService.keys().then(keys => {
      const visitKeys = keys.filter(key => key.includes(CACHE_KEY));
      const todayDate = this.timeService.today();

      visitKeys.map(key => {
        const splitKey = key.split('_');
        const date = toDate(splitKey[splitKey.length - 1]);

        if (todayDate.diff(date) > 0) {
          this.cacheService.remove(key);
        }
      });
    });
  }

  public isCached(visit: Visit): Promise<boolean> {
    const key = this._getCacheKey(visit.jobId, visit.scheduledDate);

    return this.cacheService
      .get(key)
      .then(() => true)
      .catch(() => false);
  }

  /**
   * Notify the server that the visit has started.
   *
   * @param {Visit} visit
   * @return {Promise} - Resolves once the visit is started
   */
  public startVisit(visit: Visit): Promise<Visit> {
    const visitEndpoint = this._getVisitEndpoint(visit.jobId, visit.scheduledDate);

    return this.userService
      .getUser()
      .then(() => {
        return this.requestService.patch(
          `${visitEndpoint}start/`,
          {},
          {
            offline: {
              expiryTimestamp: this.timeService.today().add(1, 'days').startOf('day').unix(),
              enqueueIfOffline: true,
              objectId: visit.id,
              objectType: OfflineEventObjectTypes.Visit,
              offlineResponsePayload: visitSerializer.serialize({ ...visit }),
              raiseOfflineError: false,
              requestFailedMessage: 'Failed to start visit',
              type: OfflineEventActionTypes.StartVisit
            },
            responseSerializer: visitSerializer
          }
        );
      })
      .then(async () => {
        visit.start(this.timeService.now());
        this._sendLocation('startedLocation', visit);

        const mockChanges = {
          started: true,
          startedAt: this.timeService.now()
        };

        this.cacheService.set(
          this._getCacheKey(visit.jobId, visit.scheduledDate),
          visitSerializer.serialize({
            ...visit,
            ...mockChanges
          }),
          { duration: this._cacheDuration }
        );

        await this._patchScheduleCacheOnVisitChange(visit, { ...mockChanges });

        return visit;
      });
  }

  /**
   * Notify the server that the visit should be unstarted.
   */
  public unstartVisit(visit: Visit): Promise<Visit> {
    const visitEndpoint = this._getVisitEndpoint(visit.jobId, visit.scheduledDate);

    return this.userService
      .getUser()
      .then(() => {
        return this.requestService.patch(
          `${visitEndpoint}unstart/`,
          {},
          {
            offline: {
              expiryTimestamp: this.timeService.today().add(1, 'days').startOf('day').unix(),
              enqueueIfOffline: true,
              objectId: visit.id,
              objectType: OfflineEventObjectTypes.Visit,
              offlineResponsePayload: visitSerializer.serialize({ ...visit }),
              raiseOfflineError: false,
              requestFailedMessage: 'Failed to unstart visit',
              type: OfflineEventActionTypes.UnstartVisit
            },
            responseSerializer: visitSerializer
          }
        );
      })
      .then(async () => {
        visit.unstart();

        const mockChanges = {
          finished: false,
          finishedAt: null,
          started: false,
          startedAt: null
        };

        this.cacheService.set(
          this._getCacheKey(visit.jobId, visit.scheduledDate),
          visitSerializer.serialize({
            ...visit,
            ...mockChanges
          }),
          { duration: this._cacheDuration }
        );

        await this._patchScheduleCacheOnVisitChange(visit, { ...mockChanges });

        return visit;
      });
  }

  /**
   * Notify the server that the visit has finished.
   *
   * @param {Visit} visit
   * @param {VisitFinishDetails} data
   * @return {Promise} - Resolves once the visit is finished
   */
  public finishVisit(visit: Visit, data: VisitFinishDetails): Promise<Visit> {
    const visitEndpoint = this._getVisitEndpoint(visit.jobId, visit.scheduledDate);

    return this.userService
      .getUser()
      .then(() => {
        return this.requestService.patch(`${visitEndpoint}finish/`, data, {
          offline: {
            expiryTimestamp: this.timeService.today().add(1, 'days').startOf('day').unix(),
            enqueueIfOffline: true,
            objectId: visit.id,
            objectType: OfflineEventObjectTypes.Visit,
            offlineResponsePayload: visitSerializer.serialize({ ...visit }),
            raiseOfflineError: false,
            requestFailedMessage: 'Failed to finish visit',
            type: OfflineEventActionTypes.FinishVisit
          },
          responseSerializer: visitSerializer
        });
      })
      .then(async () => {
        visit.finish(this.timeService.now());
        this._sendLocation('finishedLocation', visit);

        const mockChanges: Partial<Visit> = {
          finished: true,
          finishedAt: this.timeService.now()
        };

        this.cacheService.set(
          this._getCacheKey(visit.jobId, visit.scheduledDate),
          visitSerializer.serialize({
            ...visit,
            ...mockChanges
          }),
          { duration: this._cacheDuration }
        );

        await this._patchScheduleCacheOnVisitChange(visit, { ...mockChanges });

        return visit;
      });
  }

  public getReassignableVisits(day: Date, areaCodes?: string[]): Promise<PaginatedResponse<Visit>> {
    return this.requestService.getPage('work/reassignable-visits/', {
      params: { day, areas: areaCodes, pageSize: EXTRA_JOBS.BATCH_LIMIT },
      paramsSerializer: REASSIGNABLE_VISITS_PARAMS_SERIALIZER,
      serializer: visitSerializer
    });
  }

  public getReassignableRegularJobs(
    dayOfWeek: number,
    areaCodes?: string[]
  ): Promise<PaginatedResponse<AssignableJob>> {
    return this.requestService.getPage('work/reassignable-regular-jobs/', {
      params: { dayOfWeek, areas: areaCodes, pageSize: EXTRA_JOBS.BATCH_LIMIT },
      serializer: assignableJobSerializer
    });
  }

  /**
   * Return whether the given object represents an error from the server caused
   * by a reassignable visits being requested where the date is outside of the
   * acceptable range.
   */
  public isDayOutOfRangeError(err: any): boolean {
    if (!this.errorService.isValidationError(err)) {
      return false;
    }

    return 'day' in this.errorService.getErrorDetails(err);
  }

  public assignToVisit(visit: Visit): Promise<Visit> {
    return this.userService.getUser().then(user => {
      const visitEndpoint = this._getVisitEndpoint(visit.jobId, visit.scheduledDate);
      const data = { workers: [{ id: user.id }] };
      const opts: RequestOpts = { responseSerializer: visitSerializer };
      return this.requestService.patch(visitEndpoint, data, opts);
    });
  }

  public assignToJob(job: Job, availabilityChanges?: AvailabilityPeriod[]): Promise<Job> {
    return this.userService.getUser().then(user => {
      const jobEndpoint = `work/jobs/${job.id}/`;
      const data = {
        availabilityChanges,
        workers: [{ id: user.id }]
      };
      const opts: RequestOpts = {
        requestSerializer: ASSIGN_JOB_REQUEST_PARAMS_SERIALIZER,
        responseSerializer: jobSerializer
      };
      return this.requestService.patch(jobEndpoint, data, opts);
    });
  }

  /**
   * Confirms a transfer of one or many keys from Housekeepers to the user.
   * @param  visit  Visit on which to complete remote key transfers
   * @param  keysToTransfer  Keys which have been selected to be transferred
   */
  public completeRemoteKeyTransfersOnVisit(visit: Visit, keysToTransfer: Key[]): Promise<Visit> {
    const endPoint = this._getVisitEndpoint(visit.jobId, visit.scheduledDate) + 'complete-remote-key-transfers/';

    return this.userService
      .getUser()
      .then(() => {
        return this.requestService.patch(
          endPoint,
          { keysToTransfer: keysToTransfer },
          { responseSerializer: visitSerializer }
        );
      })
      .then(async updatedVisit => {
        return updatedVisit;
      })
      .catch(error => {
        const message = this.errorService.getErrorMessage(error);
        if (message) {
          this.toastService.showToast({
            message,
            theme: 'danger',
            buttons: [
              {
                text: 'Close',
                role: 'cancel'
              }
            ]
          });
        }
        throw error;
      });
  }

  /**
   * Patch the schedule cache for the visit with the merge properties provided
   * @param visit
   * @param mergeObj
   */
  private async _patchScheduleCacheOnVisitChange(visit: Visit, mergeObj: Partial<Visit>): Promise<void | Visit> {
    if (this.demoService.isOn) {
      // Fake request to server if demo mode is active
      return this.getVisit(visit.jobId, visit.scheduledDate).toPromise();
    }

    const scheduleWeek = await this.cacheService.get(SCHEDULE_CACHE_KEY);

    const workingDayIndex = scheduleWeek.findIndex(
      workingDay => workingDay.day === visit.actualDate.format('YYYY-MM-DD')
    );

    const activityIndex = scheduleWeek[workingDayIndex].activities.findIndex(
      activity => activity.type === 'WorkPeriod' && activity.visit && activity.visit.job_id === visit.jobId
    );

    scheduleWeek[workingDayIndex].activities[activityIndex].visit = {
      ...scheduleWeek[workingDayIndex].activities[activityIndex].visit,
      ...mergeObj
    };

    this.cacheService.set(SCHEDULE_CACHE_KEY, scheduleWeek, {
      duration: new Duration({ hours: 12 })
    });
  }

  private _sendLocation(parameterName: string, visit: Visit): Promise<Visit> {
    const visitEndpoint = this._getVisitEndpoint(visit.jobId, visit.scheduledDate);

    return this.locationService
      .getLocation()
      .then(location => {
        return this.requestService.patch(
          visitEndpoint,
          {
            [parameterName]: location.coords,
            [parameterName + 'AccuracyMeters']: Math.round(location.accuracy)
          },
          {
            offline: {
              expiryTimestamp: this.timeService.today().add(1, 'days').startOf('day').unix(),
              enqueueIfOffline: true,
              objectId: visit.id,
              objectType: OfflineEventObjectTypes.VisitGeoLocation,
              raiseOfflineError: false,
              type: OfflineEventActionTypes.RegisterVisitGeoLocation
            }
          }
        );
      })
      .catch(noop);
  }

  /**
   * Return the root endpoint for the visit
   */
  private _getVisitEndpoint(jobId: string, date: Date): string {
    return `work/jobs/${jobId}/visits/${dateToStr(date)}/`;
  }

  /**
   * Return the key used to cache a visit
   */
  private _getCacheKey(jobId: string, date: Date): string {
    const dateStr = dateToStr(date);
    return `${CACHE_KEY}${jobId}_${dateStr}`;
  }
}

export { VisitService, CACHE_KEY, REASSIGNABLE_VISITS_PARAMS_SERIALIZER };
