import { Component, Input } from '@angular/core';

import { WorkerVisitRatingChoice } from 'models/worker-visit-rating';

import { ModalBase } from '../modal-base/modal-base';

export const CLASS_ALERT = 'worker-rating-alert';
export const CLASS_MODAL = 'worker-rating-modal';
export const CLASS_HIDE = 'hide';

/**
 * Abstract class for modals which accept a user rating, be it for a job or the user's overall
 * mood. Allows for a Good, Bad and Okay rating. It uses the ratings defined in the WorkerVisitRating
 * model, but these can be translated to equivalent types as necessary.
 *
 * Requires the child class to provide:
 * - AnalyticsService as analyticsService
 * - ModalController as modalController
 */
@Component({
  template: ''
})
export abstract class ModalBaseRating extends ModalBase {
  @Input() onSelectRating: () => void;
  @Input() onSubmit: () => void;

  public rating: WorkerVisitRatingChoice;

  /**
   * @return {boolean} true if a rating has been selected, false otherwise.
   */
  public hasRated(): boolean {
    return !!this.rating;
  }

  /**
   * @return {boolean} true if the "bad" rating has been selected, false otherwise.
   */
  public isRatingBad(): boolean {
    return this.rating === WorkerVisitRatingChoice.Bad;
  }

  /**
   * @return {boolean} true if the "okay" rating has been selected, false otherwise.
   */
  public isRatingOkay(): boolean {
    return this.rating === WorkerVisitRatingChoice.Okay;
  }

  /**
   * @return {boolean} true if the "good" rating has been selected, false otherwise.
   */
  public isRatingGood(): boolean {
    return this.rating === WorkerVisitRatingChoice.Good;
  }

  /**
   * Set the rating and optionally submit it immediately.
   *
   * If `submit` is `true`, the modal will be hidden (to prevent any
   * UI "glitching" or further user input) then the rating will be submitted.
   *
   * @param rating WorkerVisitRatingChoice
   * @param submit boolean
   */
  protected setRating(rating: WorkerVisitRatingChoice, submit = false): void {
    if (submit) {
      this.onSubmit();
      this.rating = rating;
      this.submitRating();
    } else {
      this.rating = rating;
      this.onSelectRating();
    }
  }

  /**
   * Mark the "bad" rating as selected.
   */
  public setRatingBad(): void {
    this.setRating(WorkerVisitRatingChoice.Bad);
  }

  /**
   * Mark the "okay" rating as selected.
   */
  public setRatingOkay(): void {
    this.setRating(WorkerVisitRatingChoice.Okay);
  }

  /**
   * Mark the "good" rating as selected.
   */
  public setRatingGood(): void {
    this.setRating(WorkerVisitRatingChoice.Good);
  }

  /**
   * Submit the rating plus any associated information
   */
  public abstract submitRating();
}
