import { ModelSerializer, modelSerializer } from '@housekeep/infra';

import {
  workerExtraJobSignalAvailability,
  WorkerExtraJobSignalAvailability
} from 'models/worker-extra-job-signal-availability';

export const workerExtraJobSignalAvailabilitySerializer = modelSerializer.extend({
  model: workerExtraJobSignalAvailability
}) as ModelSerializer<WorkerExtraJobSignalAvailability>;
