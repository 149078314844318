<ion-card class="ion-margin-vertical">
  <ion-card-header>
    <ion-item>
      <hk-avatar
        slot="start"
        [user]="user"
        [isHousekeep]="comment.fromParty == FROM_PARTY_HOUSEKEEP"
      ></hk-avatar>
      <ion-label>
        <h2>From: {{ commenterDisplay() }}</h2>
        <p>
          {{ comment.createdAt.toDate() | date : 'EEE dd MMM yyyy' }}
        </p>
        <p>
          {{ comment.createdAt.toDate() | date : 'HH:mm' }}
        </p>
      </ion-label>
    </ion-item>
  </ion-card-header>
  <ion-card-content>
    <div [innerHtml]="comment.htmlBody | sanitizeHtml" class="comment-body"></div>
  </ion-card-content>
</ion-card>
