export const WORKER_AVAILABILITY = [
  {
    worker_id: 'abcdef123456',
    availability: [{ start: '08:00:00', end: '18:00:00' }],
    day_of_week: 0
  },
  {
    worker_id: 'abcdef123456',
    availability: [{ start: '08:00:00', end: '18:00:00' }],
    day_of_week: 1
  },
  {
    worker_id: 'abcdef123456',
    availability: [{ start: '08:00:00', end: '18:00:00' }],
    day_of_week: 2
  },
  {
    worker_id: 'abcdef123456',
    availability: [{ start: '08:00:00', end: '18:00:00' }],
    day_of_week: 3
  },
  {
    worker_id: 'abcdef123456',
    availability: [{ start: '08:00:00', end: '18:00:00' }],
    day_of_week: 4
  },
  {
    worker_id: 'abcdef123456',
    availability: [{ start: '10:00:00', end: '16:00:00' }],
    day_of_week: 5
  },
  {
    worker_id: 'abcdef123456',
    availability: [],
    day_of_week: 6
  }
];

export const WORKER_AVAILABILITY_CHANGES = [
  {
    end_date: '{{ date + 69 }}',
    reason: 'holiday',
    availability: [],
    start_date: '{{ date + 62 }}',
    notice_category: 'good',
    total_affected_cleans: 12,
    total_affected_hours: 32
  }
];
