<p>Thanks for letting us know about the keys.</p>
<p>We have sent you an email. Please now:</p>
<ol>
  <li>
    Add a key tag with the
    <strong [class.customer-key-table__transfer-pending]="key.transferInProgress">
      key code
      <span *ngIf="key.code">{{ key.code }}</span>
    </strong>
  </li>
  <li>Remove any other identification from the keys</li>
  <li>Reply to the email we have sent you with a picture of the keys and key tag</li>
</ol>
<p>We will then register these keys for you in the system.</p>
