import { Injectable, Injector } from '@angular/core';

import { AlertController } from '@ionic/angular';
import { AlertOptions } from '@ionic/core';

import { AnalyticsService } from './analytics-service';

export interface AlertOptionsWithTracking extends AlertOptions {
  trackingName?: string;
  trackingParams?: any;
}

@Injectable({ providedIn: 'root' })
export class AlertService {
  constructor(private alertCtrl: AlertController, private injector: Injector) {}

  /**
   * Create and return an alert. This can be presented to the user
   * by calling `alert.present()`.
   *
   * When the alert is presented, an `alert_view` event will be
   * tracked via the `AnalyticsService`. The event will have an
   * `alert_name` property whose value is taken from
   * `opts.trackingName` (falling back to `opts.title` or 'Untitled Alert').
   * Additional tracking properties can be passed in `opts.trackingParams`.
   *
   * @see {AlertController}
   * @param {AlertOptionsWithTracking} opts Alert and tracking options
   * @returns {Alert}
   */
  public async create(opts?: AlertOptionsWithTracking): Promise<HTMLIonAlertElement> {
    // Get AnalyticsService here to avoid circular dependencies
    const analyticsService = this.injector.get(AnalyticsService);

    const alert = await this.alertCtrl.create(opts);

    // Track the alert view when the alert is presented
    alert.addEventListener('ionAlertDidPresent', () => {
      analyticsService.trackAlertView(opts.trackingName || opts.header || 'Untitled Alert', opts.trackingParams || {});
    });

    return alert;
  }
}
