import { Pipe, PipeTransform } from '@angular/core';

import {
  Date,
  DateTime,
  dateToReadableStr,
  dayDateToReadableStr,
  Duration,
  humanReadableDurationString,
  Time,
  timeToReadableStr,
  toLocal
} from '@housekeep/infra';

import { TimeService } from 'services/time-service';

@Pipe({ name: 'toLocal' })
export class ToLocal implements PipeTransform {
  transform(momentObject: DateTime): DateTime {
    return toLocal(momentObject);
  }
}

@Pipe({ name: 'asDay' })
export class AsDay implements PipeTransform {
  constructor(public timeService: TimeService) {}

  transform(momentObject: Date): string {
    return dateToReadableStr(momentObject, this.timeService.today());
  }
}

@Pipe({ name: 'asDayWithYear' })
export class AsDayWithYear implements PipeTransform {
  constructor(public timeService: TimeService) {}

  transform(momentObject: Date | DateTime): string {
    return dateToReadableStr(momentObject, this.timeService.today(), true);
  }
}

@Pipe({ name: 'asDayDate' })
export class AsDayDate implements PipeTransform {
  constructor(public timeService: TimeService) {}

  transform(momentObject: DateTime | Date): string {
    return dayDateToReadableStr(momentObject);
  }
}

@Pipe({ name: 'asTime' })
export class AsTime implements PipeTransform {
  transform(momentObject: Time | DateTime): string {
    return timeToReadableStr(momentObject);
  }
}

@Pipe({ name: 'asWeek' })
export class AsWeek implements PipeTransform {
  constructor(public timeService: TimeService) {}

  transform(weekBeginning: Date, concise: boolean = false): string {
    const thisWeekBeginning = this.timeService.now().startOf('isoWeek');
    const daysDiff = weekBeginning.diff(thisWeekBeginning, 'days');

    if (daysDiff === 0) {
      return 'This week';
    } else if (daysDiff === 7) {
      return 'Next week';
    } else if (daysDiff === -7) {
      return 'Last week';
    } else {
      const starting = concise ? 'Week' : 'Week starting';
      const readableDate = dateToReadableStr(weekBeginning, this.timeService.today());
      return `${starting} ${readableDate}`;
    }
  }
}

@Pipe({ name: 'asDuration' })
export class AsDuration implements PipeTransform {
  constructor(public timeService: TimeService) {}

  transform(duration: Duration): string {
    return humanReadableDurationString(duration);
  }
}

export const momentPipes = [AsDay, AsDayWithYear, AsDayDate, AsDuration, AsTime, AsWeek, ToLocal];
