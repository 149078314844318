<ion-card
  class="arrival-items pad-y-15"
  [class.changes]="visitChanges.hasVisitChanges('timeSlot')"
>
  <ion-item *ngIf="preferredName && showSensitive" class="visit-info" lines="none">
    <hk-wc-icon name="person" size="38" slot="start"></hk-wc-icon>
    <ion-label>
      <p>{{ preferredName | titlecase }}</p>
    </ion-label>
  </ion-item>

  <ng-container *ngIf="timeSlot || recommendedStartTimeSlot">
    <ng-container *ngIf="showBookedArrivalTime(); else recommendedStartTime">
      <ion-item
        class="visit-info"
        id="startTime"
        [class.changes]="visitChanges.hasVisitChanges('timeSlot')"
        lines="none"
      >
        <hk-wc-icon name="clock" size="38" slot="start"></hk-wc-icon>
        <ion-label>
          <h2>
            Booked arrival time
            <ng-container *ngTemplateOutlet="timeslotChangesBadge"></ng-container>
          </h2>
          <p>{{ timeSlot.startTimeDisplay() }}</p>
        </ion-label>
      </ion-item>
    </ng-container>
    <ng-template #recommendedStartTime>
      <ion-item
        class="visit-info ion-align-items-start"
        id="startTime"
        [class.changes]="visitChanges.hasVisitChanges('timeSlot')"
        lines="none"
      >
        <hk-wc-icon class="mar-t-10" name="clock" size="38" slot="start"></hk-wc-icon>
        <ion-label>
          <h2>
            Recommended start time*
            <ng-container *ngTemplateOutlet="timeslotChangesBadge"></ng-container>
          </h2>
          <p>{{ recommendedStartTimeSlot.startTimeDisplay() }}</p>
          <!--prettier-ignore-->
          <span class="note">
            *to fit all jobs within your hours today.
            <br />
            Booked arrival time is <strong>{{ timeSlot.startTimeDisplay() }}</strong>.
          </span>
        </ion-label>
      </ion-item>
    </ng-template>
  </ng-container>

  <ion-item *ngIf="duration" class="visit-info visit-info--duration" lines="none">
    <hk-wc-icon name="hourglass" size="38" slot="start"></hk-wc-icon>
    <ion-label>
      <h2>Cleaning time</h2>
      <p>{{ duration.toHours() | asHours }} hours</p>
    </ion-label>
  </ion-item>

  <ng-container *ngIf="property">
    <ion-item
      *ngIf="showSensitive"
      (click)="addressTapped()"
      class="visit-info"
      lines="none"
    >
      <hk-wc-icon name="location-pin" size="38" slot="start"></hk-wc-icon>
      <ion-label>
        <h2 class="ion-text-wrap">Address</h2>
        <p class="ion-text-wrap" id="copyaddress">{{ property.fullAddress }}</p>
      </ion-label>
      <ion-button
        *ngIf="isClipboardSupported()"
        slot="end"
        size="small"
        fill="clear"
        class="text--muted"
        #copyButton
      >
        <hk-wc-icon name="document-stack" size="18" slot="start"></hk-wc-icon>
        Copy
      </ion-button>
    </ion-item>

    <ion-item *ngIf="showVisitInfo" class="visit-info" lines="none">
      <hk-wc-icon name="house" size="38" slot="start"></hk-wc-icon>
      <ion-label>
        <h2>Property size</h2>
        <p>
          {{ property.bedrooms }} bedroom{{ property.bedrooms | plural }},
          {{ property.bathrooms }} bathroom{{ property.bathrooms | plural }}
        </p>
      </ion-label>
    </ion-item>

    <ion-item
      *ngIf="bringCleaningProducts"
      (click)="openCleaningProductsModal.emit()"
      class="visit-info visit-info--cleaning-products"
      lines="none"
      tappable
    >
      <hk-wc-icon name="spray-bottle" size="38" slot="start"></hk-wc-icon>
      <ion-label>
        <p>Bring cleaning products</p>
      </ion-label>
    </ion-item>
  </ng-container>
</ion-card>

<ng-template #timeslotChangesBadge>
  <ion-badge
    *ngIf="visitChanges.hasVisitChanges('timeSlot')"
    color="warning"
    slot="end"
    class="ion-float-right"
  >
    <hk-wc-icon name="recurring-arrow" size="16"></hk-wc-icon>
    Updated
  </ion-badge>
</ng-template>
