<div
  *ngIf="visit"
  class="visit-block visit-block--extra-jobs"
  [class.visit-block--outside-area]="outsideArea"
>
  <ion-grid class="ion-no-padding">
    <ion-row>
      <ion-col size="auto">
        <p class="strong">{{ visit.actualDate | asDay }}</p>
        <p>{{ visit.timeSlot.startTimeDisplay() }}</p>
        <p>
          <hk-wc-icon
            *ngIf="visit.requiresProducts"
            name="spray-bottle"
            size="20"
          ></hk-wc-icon>
          + {{ visit.requiredDuration.toHours() | asHours }} hours
        </p>
      </ion-col>

      <ion-col class="ion-text-right">
        <p>
          <hk-wc-icon name="location-pin" size="20"></hk-wc-icon>
          <strong>{{ visit.property.postcode.full }}</strong>
        </p>
        <p>{{ areaDisplayName }}</p>
        <p>One-off</p>
      </ion-col>
    </ion-row>

    <ion-row *ngIf="extraJobAppSignalEnabled && appSignalDaysLookaheadHours">
      <ion-col class="ion-text-center" *ngIf="visit.isWithinExtraJobsSignal">
        <ion-badge class="icon-badge" color="warning">
          <hk-wc-icon name="timer" size="16"></hk-wc-icon>
          <span>{{ appSignalDaysLookaheadHours }} hr</span>
        </ion-badge>
      </ion-col>
    </ion-row>

    <ion-row class="ion-margin-top" *ngIf="showActions">
      <ion-col>
        <ion-button size="small" (click)="openExtraJobDetail()">
          See Details
          <ion-icon name="arrow-forward" slot="end"></ion-icon>
        </ion-button>
      </ion-col>
      <ion-col class="ion-text-right">
        <ion-button size="small" (click)="doAdd()">
          Add
          <ion-icon name="add" slot="end"></ion-icon>
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>
