<ion-card
  [class.visit-changed]="hasTaskChanges"
  *ngIf="!isKeysOnlyVisit && workerTasks.length > 0"
>
  <ion-card-header>
    <ion-card-title>
      Tasks
      <ion-badge *ngIf="hasTaskChanges" color="warning" class="changed-badge">
        <hk-wc-icon name="recurring-arrow" size="16"></hk-wc-icon>
        Updated
      </ion-badge>
    </ion-card-title>
  </ion-card-header>

  <ion-card-content>
    <ol class="tasks">
      <li
        *ngFor="let task of workerTasks"
        [ngSwitch]="task.isTimed"
        class="tasks__task"
        [class.changes]="visitChanges.hasTaskChanges(task)"
      >
        <hk-wc-icon [name]="task.typeIcon" size="20"></hk-wc-icon>
        <span *ngSwitchCase="true">
          {{ task.hours | asHours }} hours {{ task.typeLabel.toLowerCase() }}
        </span>
        <span *ngSwitchCase="false">
          <span *ngIf="visitHasSingleTask">{{ task.hours | asHours }} hours</span>
          {{ task.typeLabel }}
        </span>
      </li>
    </ol>
  </ion-card-content>
</ion-card>
