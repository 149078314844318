import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { TimeoutError } from 'rxjs';

import { CacheStatus, ErrorCode, HttpStatus } from 'util/error';

import { ToastService } from './toast-service';

const DEFAULT_ERROR_MESSAGE =
  'Sorry, there was a problem. ' + 'Please try again, and contact us if the problem persists.';

@Injectable({ providedIn: 'root' })
class ErrorService {
  constructor(private toastService: ToastService) {}

  public isNoInternetError(err: any): boolean {
    return this.isHttpError(err, HttpStatus.NoInternet);
  }

  public isDemoModeError(err: any): boolean {
    return this.isHttpError(err, HttpStatus.DemoModeFeatureMissing);
  }

  public isHttp400BadRequestError(err: any): boolean {
    return this.isHttpError(err, HttpStatus.BadRequest);
  }

  public isHttp401UnauthorizedError(err: any): boolean {
    if (this.isHttpError(err, HttpStatus.Unauthorized)) {
      return true;
    }
    if (err.message) {
      return err.message.toLowerCase().includes('session expired');
    }
    return false;
  }

  public isHttp403ForbiddenError(err: any): boolean {
    return this.isHttpError(err, HttpStatus.Forbidden);
  }

  public isHttp404NotFoundError(err: any): boolean {
    return this.isHttpError(err, HttpStatus.NotFound);
  }

  public isHttp429TooManyRequestsError(err: any): boolean {
    return this.isHttpError(err, HttpStatus.TooManyRequests);
  }

  public isHttp500ServerError(err: any): boolean {
    return this.isHttpError(err, HttpStatus.ServerError);
  }

  public isHttp4xxError(err: any): boolean {
    if (!(err instanceof HttpErrorResponse)) {
      return false;
    }

    const statusStr = err.status.toString();
    return statusStr.length === 3 && statusStr.startsWith('4');
  }

  /**
   * Return whether the given object is a standard Housekeep error.
   * Such errors are objects including "error_code" and "error_message" values.
   */
  public isStandardServerError(err: any): boolean {
    return !!(
      err instanceof HttpErrorResponse &&
      err.error &&
      err.error.error_code &&
      err.error.error_message !== undefined
    );
  }

  public isCacheUnsetError(err: any): boolean {
    return err === CacheStatus.Unset;
  }

  public isPermissionDeniedError(err: any): boolean {
    return !!(this.isHttp403ForbiddenError(err) && this.getErrorCode(err) === ErrorCode.PermissionDenied);
  }

  public isValidationError(err: any): boolean {
    return !!(this.isHttp400BadRequestError(err) && this.getErrorCode(err) === ErrorCode.ValidationError);
  }

  public isCacheError(err: any): boolean {
    return this.isCacheUnsetError(err);
  }

  public isStorageQuotaExceededError(err: any): boolean {
    return ['message', 'name'].some(propertyKey => {
      let text;

      try {
        text = err[propertyKey] || '';
      } catch (e) {
        return false;
      }

      return text.toLowerCase().indexOf('quota') !== -1;
    });
  }

  public isTimeoutError(err: any): boolean {
    return err instanceof TimeoutError;
  }

  /**
   * Attempt to return an error code from within a HTTP error response.
   */
  public getErrorCode(err: any): string | null {
    return err && err.error ? err.error.error_code : null;
  }

  /**
   * Get an appropriate error message for the given server error response.
   *
   * For most anticipated errors, the server should return a JSON-response,
   * along with an "error_message" key, which can be shown to the user. When
   * this is not the case (e.g. 500 errors), we return the default error
   * message, which can be set by the caller.
   *
   * Null is returned in cases where no error message should be displayed.
   * Currently this is the case for "no internet" exceptions, which are handled
   * by displaying a separate toast (see `RequestService._onRequestError`).
   */
  public getErrorMessage(err, defaultMsg = DEFAULT_ERROR_MESSAGE): string | null {
    if (this.isDemoModeError(err) || this.isNoInternetError(err)) {
      return null;
    }

    // Attempt to retrieve a standard error-message from the server
    if (err && err.error && err.error.error_message) {
      return err.error.error_message;
    }
    return defaultMsg;
  }

  /**
   * Attempt to return the details for a 400 bad request.
   */
  public getErrorDetails(err: any) {
    if (err && err.error && err.error.extra && err.error.extra.detail) {
      return err.error.extra.detail;
    }

    return {};
  }

  /**
   * Show toast for an error.
   */
  public showError(error): void {
    const errorMessage = this.getErrorMessage(error);

    if (errorMessage) {
      this.toastService.showToast({ message: errorMessage, theme: 'danger' });
    }
  }

  /**
   * Return whether the given error is an HTTP error with the given status code.
   */
  private isHttpError(err: any, expectedStatus: number): boolean {
    return err instanceof HttpErrorResponse && err.status === expectedStatus;
  }
}

export { DEFAULT_ERROR_MESSAGE, ErrorService };
