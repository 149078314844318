import { Injectable } from '@angular/core';

import { BehaviorSubject, combineLatest, Observable, of, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { TwilioChatService } from '@housekeep/hk-chat-client';
import { RemoteKeyTransfer, RequestOpts, Visit } from '@housekeep/infra';

import { RequestService } from './request-service';
import { UserService } from './user-service';

enum ApiEndpoint {
  RequestChat = 'contact/request-chat-v2/',
  RequestWorkerChat = 'contact/request-chat-v2/worker/'
}

interface ChatResponse {
  friendlyName: string;
  uniqueName: string;
  chatIdentity: string;
  permitted: boolean;
  notPermittedReason?: string;
  supportMediaMessages?: boolean;
}

interface CommsByUserResponse {
  hasChatted: boolean;
  hasCalled: boolean;
}

interface ChatIsAllowedResponse {
  allowed: boolean;
}

interface ChatInfoRequestParams {
  actualDate: string;
  job: string;
  reason?: string;
  transfer?: string;
}

@Injectable({ providedIn: 'root' })
class ChatInfoService {
  private _totalUnreadMessagesCount$ = new BehaviorSubject<number>(0);
  private totalUnreadMessagesSubscription: Subscription;

  constructor(
    private requestService: RequestService,
    private userService: UserService,
    private twilioChatService: TwilioChatService
  ) {}

  initUnreadMessagesCount(): void {
    this.totalUnreadMessagesSubscription = this.twilioChatService
      .getUnreadMessagesAllSubscribedConversationsObservable()
      .subscribe(next => this._totalUnreadMessagesCount$.next(next));
  }

  getUnreadMessagesCount(): Observable<number> {
    return this._totalUnreadMessagesCount$.asObservable();
  }

  destroyUnreadMessagesCount(): void {
    this.totalUnreadMessagesSubscription.unsubscribe();
  }

  unreadMessagesObservableForVisits(visits: Visit[]): Promise<Observable<number>> {
    const observablePromises = Promise.all(
      visits.map(visit => {
        return this.getChatUniqueName(visit).then(uniqueName => {
          return this.twilioChatService.getUnreadMessagesObservable(uniqueName).pipe(startWith(0));
        });
      })
    );
    return observablePromises.then(observables => {
      if (!observables.length) {
        return of(0);
      }
      return combineLatest(observables).pipe(map(unreadCounts => unreadCounts.reduce((a, b) => a + b)));
    });
  }

  /**
   * Get the information required to start chatting.
   *
   * @param visit
   * @param helpCategory
   */
  getCustomerChatInfo(visit: Visit, helpCategory?: string): Promise<ChatResponse> {
    const params = this.createRequestParams(visit, helpCategory);

    // Parameters for this endpoint are almost but not quite standard:
    const translatedParams = {
      jobId: params.job,
      actualDate: params.actualDate,
      reason: params.reason
    };
    return this.requestService.post(ApiEndpoint.RequestChat, translatedParams);
  }

  getWorkerChatInfo(transferId: string): Promise<ChatResponse> {
    return this.requestService.post(ApiEndpoint.RequestWorkerChat, {
      remoteKeyTransferId: transferId
    });
  }

  /**
   * Query API to find out whether we can chat to customer for the given visit.
   *
   * @param visit
   */
  customerChatIsAllowed(visit: Visit): Promise<boolean> {
    const requestOpts: RequestOpts = {
      params: this.createRequestParams(visit)
    };
    return this.requestService
      .get('contact/chat/allowed/', requestOpts)
      .then((response: ChatIsAllowedResponse) => response.allowed);
  }

  /**
   * Query API to find out whether we can chat to a worker for the given transfer.
   *
   * @param RemoteKeyTransfer
   */
  workerChatIsAllowed(transfer: RemoteKeyTransfer): Promise<boolean> {
    const requestOpts: RequestOpts = {
      params: { transfer: transfer.id }
    };
    return this.requestService
      .get('contact/chat/allowed/worker/', requestOpts)
      .then((response: ChatIsAllowedResponse) => response.allowed);
  }

  /**
   * Query the API to find out whether the logged in worker has communicated with
   * their customer about a particular category of issue on a visit.
   *
   * @param visit
   * @param helpCategory
   */
  userHasCommunicated(visit: Visit, helpCategory?: string): Promise<boolean> {
    const requestOpts: RequestOpts = {
      params: this.createRequestParams(visit, helpCategory)
    };
    return this.requestService
      .get('contact/comms-by-user-for-visit/', requestOpts)
      .then((response: CommsByUserResponse) => response.hasChatted || response.hasCalled);
  }

  getChatUniqueName(visit: Visit): Promise<string> {
    return this.userService.getUser().then(worker => {
      if (visit.account && visit.account.initialCustomer) {
        return `chat-${visit.account.initialCustomer.id}-${worker.id}`;
      } else {
        throw Error('Need customer to get chat unique name');
      }
    });
  }

  getRktChatUniqueName(transfer: RemoteKeyTransfer, isRktTraveller: boolean): Promise<string> {
    return this.userService.getUser().then(user => {
      if (isRktTraveller) {
        return `chat-${transfer.otherWorker.id}-${user.id}`;
      } else {
        return `chat-${user.id}-${transfer.otherWorker.id}`;
      }
    });
  }

  private createRequestParams(visit: Visit, reason?: string): ChatInfoRequestParams {
    const params: ChatInfoRequestParams = {
      job: visit.jobId,
      actualDate: visit.actualDate.format('YYYY-MM-DD')
    };
    if (reason) {
      params.reason = reason;
    }
    return params;
  }
}

export { ChatInfoService, ChatResponse };
