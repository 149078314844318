import { Injectable, NgZone } from '@angular/core';

import { ModalController } from '@ionic/angular';

import { Notification } from '@capacitor-firebase/messaging';

import { toMoment } from '@housekeep/infra';

import { ModalContentInputs } from 'components/modal-content/modal-content.component';
import {
  ModalMoodSampling,
  ModalMoodSamplingInputs
} from 'components/modal-mood-sampling/modal-mood-sampling.component';

import { AnalyticsEvent, AnalyticsParam, AnalyticsService } from './analytics-service';
import { InAppMessagingService, ModalContentCtaAction, ModalContentCtaActionList } from './in-app-messaging-service';
import { TimeService } from './time-service';

export enum Action {
  ContentModal = 'content_modal',
  MoodSample = 'mood_sample',
  InterventionSample = 'intervention_sample',
  TrackEvent = 'track_event'
}

/**
 * Data-only push.
 */
export interface DataPush extends Notification {
  data: {
    action: string;
    expires_at?: string;
  };
}

/**
 * A data push that opens a content modal.
 */
export interface ContentModalDataPush extends DataPush {
  data: {
    action: Action.ContentModal;
    body: string;
    cta_actions?: ModalContentCtaActionList;
    cta_color?: string;
    cta_fill?: 'outline' | 'solid';
    cta_target?: 'iab' | 'system';
    cta_text?: string;
    cta_url?: string;
    delay?: string;
    dismiss_text?: string;
    heading: string;
    icon?: string;
    image?: string;
    layout?: 'card' | 'modal';
    priority?: '0' | '1' | '2' | '3';
    show_in_demo_mode?: 'true' | 'false';
    show_tip_helpful_section?: 'true' | 'false';
  };
}

/**
 * Data-only push used to trigger the mood sampling modal.
 */
export interface SampleDataPush extends DataPush {
  data: {
    action: Action.MoodSample | Action.InterventionSample;
    mood_sample_id?: string;
    intervention_sample_id?: string;
    question: string;
    title?: string;
    positive_icon?: string;
    negative_icon?: string;
    positive_answer?: string;
    negative_answer?: string;
  };
}

/**
 * A type for strings that are always prefixed with "param:".
 * Used for "TrackEvent" data pushes.
 */
const trackEventParamPrefix = 'param:';
type TrackEventParam = `${typeof trackEventParamPrefix}${AnalyticsParam}`;
type TrackEventParams = {
  data: {
    [Param in TrackEventParam]?: string;
  };
};

interface TrackEventDataPushWithoutParams extends DataPush {
  data: {
    action: Action.TrackEvent;
    event: AnalyticsEvent;
  };
}

/**
 * Data-only push used to track an analytics event with optional params.
 */
export type TrackEventDataPush = TrackEventDataPushWithoutParams & TrackEventParams;

@Injectable({ providedIn: 'root' })
export class DataPushService {
  private dataPushHistory: string[] = [];

  constructor(
    private analyticsService: AnalyticsService,
    private inAppMessagingService: InAppMessagingService,
    private modalCtrl: ModalController,
    private ngZone: NgZone,
    private timeService: TimeService
  ) {}

  public onDataPushReceived(data: DataPush, openCallback?: () => void): void {
    if (this.hasDataPushExpired(data) || this.isDuplicateDataPush(data)) {
      return;
    }

    if (DataPushService.isContentModalDataPush(data)) {
      this.onContentModalRequest(data, openCallback);
    } else if (DataPushService.isSampleDataPush(data)) {
      this.onSampleRequest(data, openCallback);
    } else if (DataPushService.isTrackEventDataPush(data)) {
      this.onTrackEventRequest(data);
    }

    if (data.id) {
      this.dataPushHistory.push(data.id);
    }
  }

  private hasDataPushExpired({ data }: DataPush): boolean {
    if (data.expires_at) {
      const expiresAt = toMoment(data.expires_at);
      return expiresAt.isBefore(this.timeService.now());
    }

    return false;
  }

  private isDuplicateDataPush(data: DataPush): boolean {
    return this.dataPushHistory.some(pushId => pushId === data.id);
  }

  private onContentModalRequest({ data }: ContentModalDataPush, openCallback?: () => void): void {
    const inputs: ModalContentInputs = {
      body: data.body,
      ctaActions: data.cta_actions ? (data.cta_actions.split(',') as ModalContentCtaAction[]) : [],
      ctaColor: data.cta_color,
      ctaFill: data.cta_fill,
      ctaText: data.cta_text,
      ctaUrl: data.cta_url,
      ctaTarget: data.cta_target,
      dismissText: data.dismiss_text,
      heading: data.heading,
      icon: data.icon,
      image: data.image,
      layout: data.layout,
      showTipHelpfulSection: data.show_tip_helpful_section === 'true'
    };
    this.inAppMessagingService.showContentModal(
      inputs,
      data.priority ? Number(data.priority) : null,
      data.delay ? Number(data.delay) : 0,
      data.show_in_demo_mode === 'true',
      openCallback
    );
  }

  private onSampleRequest({ data }: SampleDataPush, openCallback?: () => void): void {
    const isIntervention = data.action === Action.InterventionSample;
    const inputs: Partial<ModalMoodSamplingInputs> = {
      sampleId: isIntervention ? data.intervention_sample_id : data.mood_sample_id,
      question: data.question,
      title: data.title,
      positiveIcon: data.positive_icon,
      negativeIcon: data.negative_icon,
      positiveAnswer: data.positive_answer,
      negativeAnswer: data.negative_answer,
      isIntervention: isIntervention
    };

    this.ngZone.run(async () => {
      const modal = await this.modalCtrl.create({
        component: ModalMoodSampling,
        componentProps: {
          ...inputs,
          onSelectRating: () => {
            modal.cssClass = ModalMoodSampling.CLASS_MODAL;
          },
          onSubmit: () => {
            modal.cssClass = ModalMoodSampling.CLASS_HIDE;
          }
        },
        cssClass: ModalMoodSampling.CLASS_ALERT
      });
      if (openCallback) {
        openCallback();
      }
      await modal.present();
    });
  }

  private onTrackEventRequest({ data }: TrackEventDataPush): void {
    // The push data may contain event params, each having a "param:" prefix
    const params: Record<string, string> = {};
    for (const [prop, value] of Object.entries(data)) {
      if (prop.startsWith(trackEventParamPrefix)) {
        params[prop.substring(trackEventParamPrefix.length)] = value;
      }
    }
    this.analyticsService.trackEvent(data.event, params).then();
  }

  private static isContentModalDataPush(push: DataPush): push is ContentModalDataPush {
    return push.data.action === Action.ContentModal;
  }

  private static isSampleDataPush(push: DataPush): push is SampleDataPush {
    return push.data.action === Action.MoodSample || push.data.action === Action.InterventionSample;
  }

  private static isTrackEventDataPush(push: DataPush): push is TrackEventDataPush {
    return push.data.action === Action.TrackEvent;
  }
}
