import { DemoEndpoint } from '../../../demo-service';
import { CUSTOMER_KEYS_DATA } from '../../data/customer-keys';

export const customerKeysEndpoint: DemoEndpoint = {
  path: 'customers-and-keys',
  handlers: [
    {
      method: 'get',
      handle(state) {
        return CUSTOMER_KEYS_DATA;
      }
    }
  ]
};
