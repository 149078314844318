import { Component, Input } from '@angular/core';

@Component({
  selector: 'hk-alert',
  templateUrl: './hk-alert.component.html',
  styleUrls: ['./hk-alert.component.scss']
})
export class HkAlert {
  @Input() type: 'info' | 'warning' | 'danger' | 'success';
  @Input() icon?: string;
  @Input() iconSize: number = 20;
  @Input() extraClasses: string = '';
}
