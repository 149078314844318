import { Component, Input, OnInit } from '@angular/core';

import { Instruction, TaskType, Visit } from '@housekeep/infra';

import { VisitChanges } from 'models/visit-changes';

@Component({
  selector: 'hk-visit-instructions',
  templateUrl: './hk-visit-instructions.component.html',
  styleUrls: ['./hk-visit-instructions.component.scss']
})
export class HkVisitInstructions implements OnInit {
  @Input() public instructions: Instruction[];
  @Input() public visit: Visit;
  @Input() public visitChanges: VisitChanges;

  public hasInstructionChanges: boolean;

  public ngOnInit(): void {
    this.instructions = this.getInstructions();

    // Any change to a "general" instruction is relevant for this tab
    this.hasInstructionChanges =
      this.instructions.length && this.visitChanges.hasInstructionChanges(...this.instructions);
  }

  /**
   * Return a list of "general" instructions for this visit.
   */
  private getInstructions(): Instruction[] {
    return this.instructions.filter(
      (instruction: Instruction) => ![TaskType.KeyCollection, TaskType.KeyReturn].includes(instruction.taskType)
    );
  }
}
