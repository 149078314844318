import { DateTime, Model, model } from '@housekeep/infra';

export enum FromParty {
  Housekeep = 'housekeep',
  Worker = 'worker'
}
export interface TicketComment extends Model {
  ticketLink: string;
  createdAt: DateTime;
  body: string;
  htmlBody: string;
  fromParty: string;
  agentName: string;
}

export const ticketComment: TicketComment = model.extend({
  modelName: 'ticketComment'
});
