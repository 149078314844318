import { WhatsNewSlide } from '../../whats-new-service';

export const LATEST_VERSION = '3.19.0';

const IMAGE_BASE_PATH = `/releases/${LATEST_VERSION}/whats-new/`;

/**
 * Return an image URL for the given filename, relative to the image base path.
 * @param filename
 */
function getImageUrl(filename): string {
  return `https://app-housekeepers.housekeep.com${IMAGE_BASE_PATH}${filename}`;
}

export const LATEST_SLIDES: WhatsNewSlide[] = [
  {
    format: 'fullBlue',
    title: '',
    description: '',
    imageUri: getImageUrl('1.png'),
    imageStyles: {
      'max-width': '300px'
    }
  },
  {
    format: 'split',
    title: 'You can now share photos over chat',
    description: `
      <p>
        If your customer can receive photos, you'll see this option in chat
      </p>
    `,
    imageUri: getImageUrl('2.png'),
    imageStyles: {
      width: 'auto',
      'margin-top': 'calc(40px + var(--ion-safe-area-top, 0))'
    }
  },
  {
    format: 'split',
    title: 'If you finish a product …',
    description: `
      <p>
        … why not tell your customer they need to buy more?
      </p>
    `,
    imageUri: getImageUrl('3.png'),
    imageStyles: {
      width: 'auto',
      'margin-top': 'calc(40px + var(--ion-safe-area-top, 0))'
    }
  },
  {
    format: 'split',
    title: 'We hope you like this update!',
    description: `
      <p>We've made some bug fixes and other improvements so that the app is easier to use.</p>
      <p>Let the team know if you have any app issues.</p>
    `,
    imageUri: getImageUrl('4.png'),
    imageStyles: {
      'max-width': '300px'
    }
  }
];
