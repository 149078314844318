import { DemoEndpoint } from '../../../demo-service';

const openCallRequestsEndpoint: DemoEndpoint = {
  path: 'open-call-requests',
  handlers: [
    {
      method: 'get',
      handle() {
        return {
          has_open_requests: false
        };
      }
    }
  ]
};

export { openCallRequestsEndpoint };
