import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';

import { TRAVEL_PREFERENCE_OPTIONS, TravelPreference } from '@housekeep/infra';

@Component({
  selector: 'travel-preferences-form',
  templateUrl: './travel-preferences-form.component.html',
  styleUrls: ['./travel-preferences-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TravelPreferencesForm {
  @ViewChild('nationalInsuranceNumberForm', { static: true }) public nationalInsuranceNumberForm: NgForm;

  @Input() travelPreferences: TravelPreference[] = [];
  @Output() onPreferenceChange: EventEmitter<TravelPreference[]> = new EventEmitter<TravelPreference[]>();

  public travelPreferencesForm: FormGroup;

  public readonly TRAVEL_PREFERENCE_OPTIONS = TRAVEL_PREFERENCE_OPTIONS;

  public async ngOnInit(): Promise<void> {
    this.travelPreferencesForm = new FormGroup({
      travelPreferences: new FormArray([], Validators.compose([Validators.required, Validators.minLength(1)]))
    });

    const travelPreferencesArray = this.travelPreferencesForm.get('travelPreferences') as FormArray;
    this.travelPreferences?.forEach(preference => travelPreferencesArray.push(new FormControl(preference)));
  }

  public onTravelPreferenceChange(value: string): void {
    const travelPreferencesArray = this.travelPreferencesForm.get('travelPreferences') as FormArray;
    const index = travelPreferencesArray.value.indexOf(value);
    index === -1 ? travelPreferencesArray.push(new FormControl(value)) : travelPreferencesArray.removeAt(index);
    this.onPreferenceChange.emit(travelPreferencesArray.value);
  }

  public isTravelPreferenceSelected(value: string): boolean {
    const index = this.travelPreferencesForm.get('travelPreferences').value.indexOf(value);
    return index !== -1;
  }
}
