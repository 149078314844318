import { DemoEndpoint } from '../../../demo-service';
import { getWorkingDayData } from '../../util';

const workingDayEndpoint: DemoEndpoint = {
  path: 'working-day/\\d\\d\\d\\d-\\d\\d-\\d\\d',
  handlers: [
    {
      method: 'get',
      handle(state, path) {
        // Get the date from the path in `YYYY-MM-DD` format.
        const date = path.match(/.*\/(\d{4}-\d{2}-\d{2})/)[1];
        return getWorkingDayData(state, date);
      }
    }
  ]
};

export { workingDayEndpoint };
