import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';

import { Visit } from '@housekeep/infra';

import { AlertService } from 'services/alert-service';
import { ChatInfoService } from 'services/chat-info-service';
import { ErrorService } from 'services/error-service';
import { HelpService } from 'services/help-service';
import { LoadingService } from 'services/loading-service';
import { ToastService } from 'services/toast-service';

/**
 * Displays a preview of a chat message that can be sent to a customer.
 */
@Component({
  selector: 'contact-housekeep',
  templateUrl: './contact-housekeep.component.html',
  styleUrls: ['./contact-housekeep.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactHousekeep implements OnInit {
  @Input() visit: Visit;
  @Input() helpCategory: string;
  @Input() helpServiceContactMethod: any;
  @Input() isHelpWithRkt: boolean | null;

  @Output() contact = new EventEmitter<null>();

  protected loading = true;
  protected allowed: boolean;

  constructor(
    protected alertService: AlertService,
    private chatInfoService: ChatInfoService,
    protected errorService: ErrorService,
    protected helpService: HelpService,
    protected loadingService: LoadingService,
    protected toastService: ToastService,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.chatInfoService
      .userHasCommunicated(this.visit, this.helpCategory)
      .then((hasCommunicated: boolean) => {
        this.loading = false;
        // Help with RKT should be activated as default. Other help for
        // travellers should be activated as they have no customer to contact
        this.allowed = hasCommunicated || this.visit.isRktOnly || this.isHelpWithRkt;
        this.cdRef.markForCheck();
      })
      .catch(() => {
        this.loading = false;
        // Default to safe: hit an unexpected error so allow comms.
        this.allowed = true;
        this.cdRef.markForCheck();
      });
  }

  public contactHousekeep() {
    this.contact.emit();
  }
}
