import { dateTimeField, ModelSerializer, modelSerializer } from '@housekeep/infra';

import { ticket, Ticket } from 'models/ticket';

export const ticketSerializer = modelSerializer.extend({
  model: ticket,
  fields: {
    created_at: dateTimeField.create(),
    last_updated_at: dateTimeField.create()
  }
}) as ModelSerializer<Ticket>;
