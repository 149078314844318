import { environment } from 'environment/environment';

import { Level } from 'interfaces/verbosity';

class HkConsole implements Partial<Console> {
  private verbosity: Level;

  constructor(verbosity: Level) {
    this.verbosity = verbosity;
  }

  public debug(message?: any, ...optionalParams: any[]): void {
    if (this.verbosity <= Level.Debug) {
      window.console.debug(...Array.from(optionalParams));
    }
  }

  public info(message?: any, ...optionalParams: any[]): void {
    if (this.verbosity <= Level.Info) {
      window.console.info(...Array.from(optionalParams));
    }
  }

  public log(message?: any, ...optionalParams: any[]): void {
    if (this.verbosity <= Level.Log) {
      window.console.log(...Array.from(optionalParams));
    }
  }

  public warn(message?: any, ...optionalParams: any[]): void {
    if (this.verbosity <= Level.Warn) {
      window.console.warn(...Array.from(optionalParams));
    }
  }

  public error(message?: any, ...optionalParams: any[]): void {
    if (this.verbosity <= Level.Error) {
      window.console.error(...Array.from(optionalParams));
    }
  }

  public setVerbosity(verbosity: Level): void {
    this.verbosity = verbosity;
  }
}

export const console = new HkConsole(environment.CONSOLE_VERBOSITY);
