import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

import hexRgb from 'hex-rgb';

import { ColorBrandBlue500, ColorGreen500, ColorGrey100 } from '@housekeep/design-tokens';

const DEFAULT_ANIMATION = 'easeOutCubic';
const DEFAULT_BACKGROUND = ColorGrey100;
const DEFAULT_COLOR = ColorBrandBlue500;
const DEFAULT_DURATION = 500;
const DEFAULT_RADIUS = 125;
const DEFAULT_STROKE = 15;
const colorGreen500Rgb = hexRgb(ColorGreen500);
const DEFAULT_TARGET_COLOR = `rgba(${colorGreen500Rgb.red}, ${colorGreen500Rgb.green}, ${colorGreen500Rgb.blue}, 0.5)`;
const DEFAULT_TARGET_STROKE = 40;

@Component({
  selector: 'hk-round-progress',
  templateUrl: './hk-round-progress.component.html',
  styleUrls: ['./hk-round-progress.component.scss']
})
export class HkRoundProgress {
  // Angular SVG round progressbar inputs:
  @Input() public current: number;
  @Input() public max: number;
  @Input() public radius?: number = DEFAULT_RADIUS;
  @Input() public color?: string = DEFAULT_COLOR;
  @Input() public background?: string = DEFAULT_BACKGROUND;
  @Input() public stroke?: number = DEFAULT_STROKE;
  @Input() public rounded?: boolean = false;
  @Input() public duration?: number = DEFAULT_DURATION;
  @Input() public animationDelay?: number = null;
  @Input() public animation?: string = DEFAULT_ANIMATION;

  // Custom Housekeep inputs:
  @Input() public lowerTarget?: number;
  @Input() public lowerTargetLabel?: string;
  @Input() public upperTarget? = true;
  @Input() public upperTargetLabel?: string;
  @Input() public targetColor?: string = DEFAULT_TARGET_COLOR;
  @Input() public targetStroke?: number = DEFAULT_TARGET_STROKE;
  @Input() public metricValue?: string;
  @Input() public metricLabel?: string;
  @Input() public padding: boolean = true;

  @Output() public onRender: EventEmitter<null> = new EventEmitter();
  @HostBinding('class') get hostClass() {
    let classNames = this.upperTarget ? 'padding-top' : '';
    classNames += this.padding || this.lowerTarget ? ' padding-left' : '';
    return classNames;
  }

  public doRender($event) {
    this.onRender.emit($event);
  }

  public getIndicatorStyle() {
    const degrees = this.getIndicatorRotation();
    const transform = `rotate(${degrees}deg)`;

    return {
      transform,
      '-ms-transform': transform,
      '-webkit-transform': transform
    };
  }

  public getIndicatorLabelStyle() {
    const degrees = this.getIndicatorRotation();
    let transform = `rotate(${-degrees}deg)`;

    // Move the label to the left or right if there is a risk of the text overlapping
    // the indicator line
    if (degrees > 75 && degrees < 105) {
      transform += ' translateX(5px)';
    } else if (degrees > 255 && degrees < 285) {
      transform += ' translateX(-5px)';
    }

    return {
      transform,
      '-ms-transform': transform,
      '-webkit-transform': transform
    };
  }

  private getIndicatorRotation() {
    return Math.round((360 * this.lowerTarget) / this.max);
  }
}
