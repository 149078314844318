<ion-item (click)="openTicketDetail()" detail="false" lines="none" tappable>
  <div class="icon-container" slot="start">
    <!--Red circle for unread messages -->
    <span class="badge-unread-indicator" [class.unread]="ticket.hasUnreadComments"></span>
    <hk-wc-icon
      *ngIf="ticket.hasUnreadComments && !ticket.isClosedToUser"
      name="envelope"
      [class.text--warning]="!ticket.isClosedToUser"
    ></hk-wc-icon>
    <hk-wc-icon
      *ngIf="ticket.isClosedToUser"
      name="tick-circle"
      class="text--success"
    ></hk-wc-icon>
    <hk-wc-icon
      *ngIf="!ticket.hasUnreadComments && !ticket.isClosedToUser"
      name="envelope-open"
      [class.text--warning]="!ticket.isClosedToUser"
      class="unread-ticket"
    ></hk-wc-icon>
  </div>
  <ion-label>
    <h2>{{ ticket.subject }}</h2>
    <p>Last Activity: {{ ticket.lastUpdatedAt.toDate() | date : 'EEE dd MMM' }}</p>
  </ion-label>
</ion-item>
