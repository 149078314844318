import { Injectable } from '@angular/core';

import { noop } from 'lodash-es';

import { v4 as uuidv4 } from 'uuid';

import { DateTime, RequestOpts } from '@housekeep/infra';

import { OfflineEventActionTypes, OfflineEventObjectTypes } from 'var/offline-events';

import { AppContextService } from './app-context-service';
import { RequestService } from './request-service';
import { TimeService } from './time-service';

const CREATE_ENDPOINT = 'analytics/';

// Instantiate at run-time, giving each session a unique key
const SESSION_ID = uuidv4();

@Injectable({ providedIn: 'root' })
export class HKAnalyticsService {
  constructor(
    private appContextService: AppContextService,
    private requestService: RequestService,
    private timeService: TimeService
  ) {}

  /**
   * Create a new AnalyticsRecord object on the HK backend
   * @param  recordKey      The key for this record type
   * @param  blob           JSON serializable data blob
   * @param  groupUuid      Optional UUID key to group related events
   * @param  sourceCreated  Optional ISO timestamp for the event
   * @param  opts           Optional request params
   */
  public createHKAnalyticsRecord(
    recordKey: string,
    blob: any,
    groupUuid?: string,
    sourceCreated?: DateTime,
    opts?: RequestOpts
  ): Promise<void> {
    blob = {
      appLaunchContext: this.appContextService.launchContext,
      appLaunchContextBlob: this.appContextService.launchContextBlob,
      appLaunchedAt: this.appContextService.launchedAt,
      ...blob
    };

    const data = {
      blob,
      groupUuid,
      recordKey,
      sessionId: SESSION_ID,
      sourceCreated: sourceCreated || this.timeService.now()
    };

    const requestOpts = {
      offline: {
        enqueueIfOffline: true,
        expiryTimestamp: this.timeService.today().add(1, 'days').startOf('day').unix(),
        objectId: '',
        objectType: OfflineEventObjectTypes.AnalyticsRecord,
        offlineResponsePayload: {},
        raiseOfflineError: false,
        type: OfflineEventActionTypes.AnalyticsRecordCreate
      },
      ...(opts || {})
    };

    return this.requestService
      .post(CREATE_ENDPOINT, data, requestOpts)
      .then(response => response)
      .catch(noop);
  }
}
