import { Component, Input } from '@angular/core';

import { Property } from '@housekeep/infra';

import { LocationService } from 'services/location-service';

@Component({
  selector: 'hk-check-directions',
  templateUrl: './hk-check-directions.component.html',
  styleUrls: ['./hk-check-directions.component.scss']
})
export class HkCheckDirections {
  @Input() public property: Property;

  constructor(private locationService: LocationService) {}

  public openInExternalMapApp() {
    // Helps Google find the address by removing first lines that contain 'Flat X'
    const destinationAddress = encodeURIComponent(this.property.fullAddress.replace(/^Flat \d+, /, ''));
    const mapUri = this.locationService.getMapsUrlForAddress(destinationAddress);
    window.open(mapUri, '_system');
  }
}
