import { Injectable } from '@angular/core';

import { Duration, Visit } from '@housekeep/infra';

import {
  BILLING_PERIOD_HOUR_INCREMENTS,
  EARLY_GRACE_PERIOD_MINUTES,
  LATE_GRACE_PERIOD_MINUTES,
  MINIMUM_BILLABLE_HOURS
} from 'var/visit';

import { TimeService } from './time-service';

/**
 * Service to handle visit duration queries.
 */
@Injectable({ providedIn: 'root' })
class VisitDurationService {
  public constructor(public timeService: TimeService) {}

  /**
   * Return the duration of the visit.
   */
  public visitDuration(visit: Visit): Duration {
    if (!visit.startedAt) {
      return null;
    }

    return new Duration({
      milliseconds: (visit.finishedAt || this.timeService.now()).diff(visit.startedAt)
    });
  }

  /**
   * Return the difference between the actual and expected duration of the visit.
   */
  public getDifferenceFromRequired(visit: Visit): Duration {
    return visit.requiredDuration.subtract(this.visitDuration(visit));
  }

  public fewerHoursThanRequired(visit: Visit): boolean {
    const difference: Duration = this.getDifferenceFromRequired(visit);
    const minutesWithGrace: number = difference.toMinutes() - EARLY_GRACE_PERIOD_MINUTES;
    return minutesWithGrace > 0;
  }

  /**
   * If the visit was finished now, how many hours would be billable?
   *
   * We never bill the customer more than the requested hours, but can reduce
   * the hours if the worker agrees to it.
   */
  public currentPayableHours(visit: Visit): number {
    const requiredHours = visit.requiredDuration.toHours();
    const actualHours = this.visitDuration(visit).toHours();

    if (actualHours >= requiredHours) {
      return requiredHours;
    } else if (actualHours < MINIMUM_BILLABLE_HOURS) {
      return MINIMUM_BILLABLE_HOURS;
    } else {
      const billableAmounts = Math.ceil(actualHours / BILLING_PERIOD_HOUR_INCREMENTS);
      return billableAmounts * BILLING_PERIOD_HOUR_INCREMENTS;
    }
  }

  public canReduceBilling(visit: Visit): boolean {
    return this.currentPayableHours(visit) < visit.requiredDuration.toHours();
  }

  public moreHoursThanRequired(visit: Visit): boolean {
    const difference: Duration = this.getDifferenceFromRequired(visit);
    const minutesWithGrace: number = difference.toMinutes() + LATE_GRACE_PERIOD_MINUTES;
    return minutesWithGrace < 0;
  }
}

export { VisitDurationService };
