import { DemoEndpoint } from '../../../demo-service';
import { visitChangesEndpoint } from './visits/changes';
import { visitFinishEndpoint } from './visits/finish';
import { visitStartEndpoint } from './visits/start';
import { visitUnstartEndpoint } from './visits/unstart';
import { visitEndpoint } from './visits/visit';

const visitsEndpoint: DemoEndpoint = {
  path: 'jobs/[a-z0-9]+/visits/\\d\\d\\d\\d-\\d\\d-\\d\\d',
  endpoints: [visitChangesEndpoint, visitFinishEndpoint, visitStartEndpoint, visitUnstartEndpoint, visitEndpoint]
};

export { visitsEndpoint };
