export const HOUSEKEEPER_REFERRALS = {
  referral_url: 'https://housekeep.com/recruit/apply/?referrer=HiReFRIEnDS1',
  referral_amount_pounds: '50.00',
  referrals: [
    { name: 'Maria H', state: 'applied', payment: null },
    { name: 'Andreaa G', state: 'trialling', payment: null },
    { name: 'Robert F', state: 'trialling', payment: null },
    {
      name: 'Natasha R',
      state: 'successful',
      payment: { amount_pounds: 50.0, due_date: '{{ date # 26 }}' }
    },
    {
      name: 'Camelia B',
      state: 'successful',
      payment: { amount_pounds: 50.0, due_date: '{{ date # 26 }}' }
    },
    {
      name: 'Irena E',
      state: 'successful',
      payment: { amount_pounds: 50.0, due_date: '{{ date # 33 }}' }
    },
    { name: 'Alexandra D', state: 'unsuccessful', payment: null },
    {
      name: 'Ana C',
      state: 'successful',
      payment: { amount_pounds: 50.0, due_date: '{{ date # 33 }}' }
    },
    {
      name: 'Nicola B',
      state: 'successful',
      payment: { amount_pounds: 50.0, due_date: '{{ date # 33 }}' }
    },
    {
      name: 'Charlie A',
      state: 'successful',
      payment: { amount_pounds: 50.0, due_date: '{{ date # 47 }}' }
    }
  ]
};
