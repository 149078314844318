import { Injectable } from '@angular/core';

import { Platform } from '@ionic/angular';

import { Geolocation, PositionError } from '@awesome-cordova-plugins/geolocation/ngx';

import { from } from 'rxjs';
import { timeout } from 'rxjs/operators';

import { AnalyticsService, USER_PROPERTY_LOCATION_ACTIVE } from './analytics-service';
import { StorageService } from './storage-service';

export interface LocationCoords {
  latitude: number;
  longitude: number;
}

export interface Location {
  accuracy: number;
  coords: LocationCoords;
}

interface LocationOptions {
  accuracyThreshold?: number;
  timeout?: number;
}

const PERMISSION_DENIED = 1;

const HAS_LOCATION_PERMISSION_STORAGE_KEY = 'has_location_permission';

@Injectable({ providedIn: 'root' })
export class LocationService {
  /**
   * We use the StorageService to remember if the user has granted location permission, so that we can update
   * the user property using the Analytics Service only if it changes. To avoid repeatedly checking local storage,
   * we also cache this boolean in memory.
   */
  private hasLocationPermission: boolean;

  constructor(
    private analyticsService: AnalyticsService,
    private geolocation: Geolocation,
    private platform: Platform,
    private storageService: StorageService
  ) {
    this.initialize().then();
  }

  /*
   * Return platform maps routing for string address
   */
  public getMapsUrlForAddress(destinationAddress: string): string {
    if (this.platform.is('ios')) {
      const mapPrefix = 'maps://';
      return `${mapPrefix}?daddr=${destinationAddress}&dirflg=r`;
    } else {
      const mapPrefix = 'https://www.google.com/maps/dir/';
      const query = '?api=1&travelmode=transit&origin=current+location&destination=';
      return `${mapPrefix}${query}${destinationAddress}`;
    }
  }

  /**
   * @param  {LocationOptions} opts
   *         {number} [opts.accuracyThreshold] - Set this to reject any results which
   *                  are less accurate than this. Represents distance in metres.
   *         {number} [opts.timeout] - ms to wait before giving up on getting location
   * @return {Promise<any>}
   */
  public getLocation(opts: LocationOptions = { timeout: 30000 }): Promise<Location> {
    // There is a bug in iOS where the timeout is not respected
    // so we manually enforce a timeout by creating an observable
    // with a timeout
    return from(
      this.geolocation
        .getCurrentPosition({
          enableHighAccuracy: true,
          timeout: opts.timeout
        })
        .then(response => {
          this.cacheHasPermissionAndUpdateAnalytics(true);

          const accuracySufficient =
            typeof opts.accuracyThreshold === 'undefined' || response.coords.accuracy <= opts.accuracyThreshold;

          if (accuracySufficient) {
            return {
              coords: {
                latitude: response.coords.latitude,
                longitude: response.coords.longitude
              },
              accuracy: response.coords.accuracy
            };
          } else {
            throw {
              code: -1,
              message: 'Coordinates not accurate enough'
            };
          }
        })
    )
      .pipe(timeout(opts.timeout))
      .toPromise()
      .catch(err => {
        if (this.permissionIsDeniedByUser(err)) {
          this.cacheHasPermissionAndUpdateAnalytics(false);
        }
        throw err;
      });
  }

  /**
   * Returns true if the given error indicates that the user denied location access.
   * @param posErr an error object compliant with the W3C Geolocation API
   */
  public permissionIsDeniedByUser(posErr: PositionError): boolean {
    return posErr.code === PERMISSION_DENIED;
  }

  /**
   * When the permission state changes from undefined to allowed/denied, allowed to denied, or denied to allowed,
   * cache the value in memory and in storage, and update the analytics user property.
   * @param hasPermission
   */
  private cacheHasPermissionAndUpdateAnalytics(hasPermission: boolean): void {
    if (hasPermission !== this.hasLocationPermission) {
      this.hasLocationPermission = hasPermission;
      this.storageService.set(HAS_LOCATION_PERMISSION_STORAGE_KEY, hasPermission).then();
      this.analyticsService
        .setUserProperties({
          [USER_PROPERTY_LOCATION_ACTIVE]: hasPermission
        })
        .then();
    }
  }

  private async initialize(): Promise<void> {
    try {
      await this.storageService.ready();
      this.hasLocationPermission = await this.storageService.get(HAS_LOCATION_PERMISSION_STORAGE_KEY);
    } catch (err) {
      // Ignore
    }
  }
}
