import { RoundprogressModule } from 'angular-svg-round-progressbar';

import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { HousekeeperAppDirectivesModule } from 'directives/directives.module';

import { HousekeeperAppPipesModule } from 'pipes/pipes.module';

import { ContactCustomer } from './contact-customer/contact-customer.component';
import { ContactHousekeep } from './contact-housekeep/contact-housekeep.component';
import { ContactSupport } from './contact-support/contact-support.component';
import { ContactWorker } from './contact-worker/contact-worker.component';
import { CustomerKeyTable } from './customer-key-table/customer-key-table.component';
import { DebugMenuComponent } from './debug-menu/debug-menu.component';
import { HelpRequestTicketCommentComponent } from './help-request-ticket-comment/help-request-ticket-comment.component';
import { HelpRequestTicketComponent } from './help-request-ticket/help-request-ticket.component';
import { HkAccordionBlock } from './hk-accordion-block/hk-accordion-block.component';
import { HkAccordion } from './hk-accordion/hk-accordion.component';
import { HkAlert } from './hk-alert/hk-alert.component';
import { HkArrivalTime } from './hk-arrival-time/hk-arrival-time.component';
import { HkAvatar } from './hk-avatar/hk-avatar.component';
import { HkCheckDirections } from './hk-check-directions/hk-check-directions.component';
import { HkCompliment } from './hk-compliment/hk-compliment.component';
import { HkConnectionFailure } from './hk-connection-failure/hk-connection-failure.component';
import { HkExtraJobsRegularJob } from './hk-extra-jobs-regular-job/hk-extra-jobs-regular-job.component';
import { HkExtraJobsVisit } from './hk-extra-jobs-visit/hk-extra-jobs-visit.component';
import { HkFulfilmentCardComponent } from './hk-fulfilment-card/hk-fulfilment-card.component';
import { HkListItem } from './hk-list-item/hk-list-item.component';
import { HkPropertyMap } from './hk-property-map/hk-property-map.component';
import { HkPropertyPets } from './hk-property-pets/hk-property-pets.component';
import { HkReliabilityRoundProgress } from './hk-reliability-round-progress/hk-reliability-round-progress.component';
import { HkRoundProgress } from './hk-round-progress/hk-round-progress.component';
import { HkVisitAccess } from './hk-visit-access/hk-visit-access.component';
import { HkVisitEquipment } from './hk-visit-equipment/hk-visit-equipment.component';
import { HkVisitInstructions } from './hk-visit-instructions/hk-visit-instructions.component';
import { HkVisitSummary } from './hk-visit-summary/hk-visit-summary.component';
import { HkVisitTasks } from './hk-visit-tasks/hk-visit-tasks.component';
import { KeyCollectionHelpBlock } from './key-collection-help-block/key-collection-help-block.component';
import { MessageInput } from './message-input/message-input.component';
import { ModalContent } from './modal-content/modal-content.component';
import { ModalMoodSampling } from './modal-mood-sampling/modal-mood-sampling.component';
import { NationalInsuranceNumberForm } from './national-insurance-number-form/national-insurance-number-form.component';
import { ProfileSummaryComponent } from './profile-summary/profile-summary.component';
import { RktKeyHandover } from './rkt-key-handover/rkt-key-handover.component';
import { RktOnlyScheduledVisit } from './rkt-only-scheduled-visit/rkt-only-scheduled-visit.component';
import { RktUnreadChats } from './rkt-unread-chats/rkt-unread-chats.component';
import { ScheduleListDayComponent } from './schedule-list-day/schedule-list-day.component';
import { ScheduleListComponent } from './schedule-list/schedule-list.component';
import { TimeLineComponent } from './time-line/time-line.component';
import { TipBadgeComponent } from './tip-badge/tip-badge.component';
import { TravelPreferencesForm } from './travel-preferences-form/travel-preferences-form.component';
import { VisitBlock } from './visit-block/visit-block.component';
import { VisitUnreadChatsComponent } from './visit-unread-chats/visit-unread-chats.component';

const components = [
  ContactCustomer,
  ContactHousekeep,
  ContactSupport,
  ContactWorker,
  CustomerKeyTable,
  DebugMenuComponent,
  HelpRequestTicketCommentComponent,
  HelpRequestTicketComponent,
  HkAccordion,
  HkAccordionBlock,
  HkAlert,
  HkArrivalTime,
  HkAvatar,
  HkCheckDirections,
  HkCompliment,
  HkConnectionFailure,
  HkExtraJobsRegularJob,
  HkExtraJobsVisit,
  HkFulfilmentCardComponent,
  HkListItem,
  HkPropertyMap,
  HkPropertyPets,
  HkReliabilityRoundProgress,
  HkRoundProgress,
  HkVisitAccess,
  HkVisitEquipment,
  HkVisitInstructions,
  HkVisitSummary,
  HkVisitTasks,
  KeyCollectionHelpBlock,
  MessageInput,
  ModalContent,
  ModalMoodSampling,
  NationalInsuranceNumberForm,
  ProfileSummaryComponent,
  RktKeyHandover,
  RktOnlyScheduledVisit,
  RktUnreadChats,
  ScheduleListComponent,
  ScheduleListDayComponent,
  TimeLineComponent,
  TipBadgeComponent,
  TravelPreferencesForm,
  VisitBlock,
  VisitUnreadChatsComponent
];

@NgModule({
  declarations: components,
  exports: components,
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    RouterModule,
    HousekeeperAppDirectivesModule,
    HousekeeperAppPipesModule,
    RoundprogressModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class HousekeeperAppComponentsModule {}
