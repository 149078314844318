import { Component, Input, OnInit } from '@angular/core';

import { Property } from '@housekeep/infra';

import { VisitChanges } from 'models/visit-changes';

import { KNOWN_PET_TYPES } from 'var/petsTypes';

@Component({
  selector: 'hk-property-pets',
  templateUrl: './hk-property-pets.component.html',
  styleUrls: ['./hk-property-pets.component.scss']
})
export class HkPropertyPets implements OnInit {
  @Input() public property: Property;
  @Input() public visitChanges: VisitChanges;

  public hasPetChanges = false;

  public ngOnInit(): void {
    // Any change to the property's pets is relevant to this tab
    this.hasPetChanges = this.visitChanges.hasPetsChanges(this.property.pets);
  }

  public hasPets(): boolean {
    return this.property.pets.cats || this.property.pets.dogs || this.property.pets.otherPets;
  }

  public getPetsTypesNotIncludingOtherPets(): string[] {
    return KNOWN_PET_TYPES.filter(type => this.property.pets[type]);
  }
}
