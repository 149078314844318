import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';

import { PaymentDetails } from 'models/payment-details';

import { ErrorService } from 'services/error-service';
import { InAppBrowserConfigService } from 'services/in-app-browser-config-service';
import { LoadingService } from 'services/loading-service';
import { PaymentDetailsService } from 'services/payment-details-service';
import { ToastService } from 'services/toast-service';

@Component({
  selector: 'national-insurance-number-form',
  templateUrl: './national-insurance-number-form.component.html',
  styleUrls: ['./national-insurance-number-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NationalInsuranceNumberForm {
  @ViewChild('nationalInsuranceNumberForm', { static: true }) public nationalInsuranceNumberForm: NgForm;

  @Input() nationalInsuranceNumber: string;
  @Input() isOnboardingFlow: boolean = false;

  @Output() onSuccess: EventEmitter<PaymentDetails> = new EventEmitter<PaymentDetails>();
  @Output() onSkip: EventEmitter<void> = new EventEmitter<void>();

  public readonly GOV_UK_LINK = 'https://www.gov.uk/lost-national-insurance-number';
  public readonly NATIONAL_INSURANCE_NUMBER_LENGTH_WITH_SPACES = 13;

  public loadError = false;
  public ready = false;
  public user: Worker;

  constructor(
    public errorService: ErrorService,
    public iab: InAppBrowser,
    public inAppBrowserConfigService: InAppBrowserConfigService,
    public loadingService: LoadingService,
    public paymentDetailsService: PaymentDetailsService,
    public toastService: ToastService
  ) {}

  public onFormSubmit(): void {
    if (this.nationalInsuranceNumberForm.form.valid) {
      if (!this.isOnboardingFlow) {
        this.loadingService.showLoader({ message: 'Updating details' });
      }

      this.paymentDetailsService
        .setPaymentDetails({
          nationalInsuranceNumber: this.cleanNationalInsuranceNumber(this.nationalInsuranceNumber)
        })
        .then(async response => {
          this.onSuccess.emit(response);

          if (!this.isOnboardingFlow) {
            this.loadingService.dismissLoader();
            await this.toastService.showToast({
              message: 'Your details have been changed.',
              theme: 'success'
            });
          }
        })
        .catch(error => {
          const message = this.errorService.getErrorMessage(error);
          if (!this.isOnboardingFlow) {
            this.loadingService.dismissLoader();
            this.toastService.showToast({ message, theme: 'danger' });
          }
        });
    }
  }

  public openBrowser(): void {
    this.inAppBrowserConfigService.configureBrowser(this.iab, this.GOV_UK_LINK);
  }

  private cleanNationalInsuranceNumber(nationalInsuranceNumber: string): string {
    return nationalInsuranceNumber
      .trim()
      .replace(/[^A-Za-z0-9]+/g, '')
      .toUpperCase();
  }
}
