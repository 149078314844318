import { DemoEndpoint } from '../../demo-service';
import { featureIsActiveEndpoint } from './infrastructure/feature-is-active';
import { featureIsActiveForUserEndpoint } from './infrastructure/feature-is-active-for-user';
import { settingValueEndpoint } from './infrastructure/setting-value';

const infrastructureEndpoint: DemoEndpoint = {
  path: 'infrastructure',
  endpoints: [featureIsActiveEndpoint, featureIsActiveForUserEndpoint, settingValueEndpoint]
};

export { infrastructureEndpoint };
