import { Directive } from '@angular/core';
import { FormControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
  selector: '[nationalInsuranceValidator]',
  providers: [{ provide: NG_VALIDATORS, useExisting: NationalInsuranceValidatorDirective, multi: true }]
})
export class NationalInsuranceValidatorDirective implements Validator {
  public readonly NATIONAL_INSURANCE_NUMBER_LENGTH = 9;

  /**
   * National insurance number is made up of 2 letters, 6 numbers, then a final letter
   * E.g. QQ123456A
   * The first two letters cannot be BG, GB, KN, NK, NT, TN, or ZZ
   * The first or second letter cannot be D, F, I, Q, U, or V
   * The second letter cannot be O either
   * The final letter is either A, B, C, or D
   */
  public readonly NATIONAL_INSURANCE_NUMBER_REGEX =
    '^(?!BG|GB|KN|NK|NT|TN|ZZ)[A-CEGHJ-PR-TW-Z][A-CEGHJ-NPR-TW-Z][0-9]{6}[A-D]$';

  public validate(control: FormControl): ValidationErrors | null {
    if (control.value) {
      let nationalInsuranceNumber: string = control.value.trim();

      /**
       * National Insurance number can be correctly submitted with spaces/hyphens between every
       * character pair. These characters are cleaned out first.
       */
      if (nationalInsuranceNumber.length > 0) {
        nationalInsuranceNumber = nationalInsuranceNumber.replace(/[^A-Za-z0-9]+/g, '');
        if (nationalInsuranceNumber.length === this.NATIONAL_INSURANCE_NUMBER_LENGTH) {
          nationalInsuranceNumber = nationalInsuranceNumber.toUpperCase();
          if (new RegExp(this.NATIONAL_INSURANCE_NUMBER_REGEX).test(nationalInsuranceNumber)) {
            return null;
          }
        }
      }
    }
    return { nationalInsuranceNumber: true };
  }
}
