import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

import { RangeCustomEvent, RangeValue } from '@ionic/core';

import { DateTime, Time, toTime } from '@housekeep/infra';

import { AvailabilityPeriod } from 'models/mixin.availability';

import { DEFAULT_DAY_END, DEFAULT_DAY_START } from 'var/availability';

// Ionic does not export different types for single-knob vs dual-knob ranges.
// We only use dual-knob ranges, so we can narrow the type of RangeValue.
type RangeValues = Exclude<RangeValue, number>;

interface ProcessedPeriod {
  period: AvailabilityPeriod;
  end: DateTime;
  start: DateTime;
  sliderValues: RangeValues;
  positioning: {
    left: number; // pixels
    width: number; // pixels
  };
}

// A working day must be at least 2 hours long
const MIN_WORKING_SECONDS = 7200;

@Component({
  selector: 'time-line',
  templateUrl: './time-line.component.html',
  styleUrls: ['./time-line.component.scss']
})
export class TimeLineComponent implements OnChanges {
  /** The day of the week. Required only if `editable` is true. */
  @Input() dayOfWeek: number;

  /** The time at which the day starts. */
  @Input() dayStart: Time = DEFAULT_DAY_START;

  /** The time at which the day ends. */
  @Input() dayEnd: Time = DEFAULT_DAY_END;

  /** The recommended start time. */
  @Input() recommendedStart: Time;

  /** The recommended end time. */
  @Input() recommendedEnd: Time;

  /** The label to show above the recommended start/end time range. */
  @Input() recommendedLabel: string;

  /** Set `true` to allow the user to change their start/end times. */
  @Input() editable: boolean = false;

  /** Set `false` to hide the moving start and end time markers. */
  @Input() showMarkers: boolean = true;

  /** Set `true` to show start/end time fields. Only applicable if `editable==true`. */
  @Input() showTimeFields: boolean = false;

  /**
   * The possible `hourValues` for the start/end time fields, if shown.
   * @todo we can infer this from DEFAULT_DAY_END and DEFAULT_DAY_START
   */
  @Input() timeFieldHourValues: number[] | string = [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22];

  /** The possible `minuteValues` for the start/end time fields, if shown. */
  @Input() timeFieldMinuteValues: number[] | string = [0, 30];

  /** The array of `Period` objects to render as timelines. */
  @Input() periods: AvailabilityPeriod[] = [];

  /** Emits the `Period[]` when they change. Only applicable if `editable==true`. */
  @Output() periodsChange: EventEmitter<AvailabilityPeriod[]> = new EventEmitter<AvailabilityPeriod[]>();

  public processedPeriods: ProcessedPeriod[] = [];

  public get recommendedLeft(): number {
    const rec = this.recommendedStart.unix();
    const min = this.dayStart.unix();
    const max = this.dayEnd.unix();
    return (100 * (rec - min)) / (max - min);
  }

  public get recommendedRight(): number {
    const rec = this.recommendedEnd.unix();
    const min = this.dayStart.unix();
    const max = this.dayEnd.unix();
    return 100 - (100 * (rec - min)) / (max - min);
  }

  ngOnChanges() {
    this.processedPeriods = this.periods.map(period => this.getProcessedPeriod(period));
  }

  public getDuration(period: ProcessedPeriod): number {
    return period.end.diff(period.start, 'minutes');
  }

  /**
   * Called upon changing the position of either knob on the ion-range component.
   * Changes are not emitted here as the user may make further interactions with the knob.
   * @param period
   * @param event
   */
  public onChangeRange(period: ProcessedPeriod, event: RangeCustomEvent): void {
    const { lower, upper } = event.detail.value as RangeValues;
    if (lower !== period.sliderValues.lower || upper !== period.sliderValues.upper) {
      this.updatePeriod(period, { lower, upper });
    }
  }

  /**
   * Called upon finishing the interaction with a knob on the ion-range component
   * (e.g. when releasing the mouse button after clicking and dragging the knob).
   * Emits changes.
   */
  public onFinishChangeRange(): void {
    this.emitChanges();
  }

  private emitChanges(): void {
    const periodFromProcessed = this.processedPeriods.map(processedPeriod => processedPeriod.period);
    this.periodsChange.emit(periodFromProcessed);
  }

  private updatePeriod(period: ProcessedPeriod, { lower, upper }: RangeValues) {
    // If the user tries to make their hours too short, stop them
    const isTooShort = upper - lower < MIN_WORKING_SECONDS;
    if (isTooShort) {
      if (upper < period.sliderValues.upper) {
        upper = lower + MIN_WORKING_SECONDS;
      } else if (lower > period.sliderValues.lower) {
        lower = upper - MIN_WORKING_SECONDS;
      }
    }

    // Convert the Unix timestamps back into moment instances
    const lowerTime = new Date(lower * 1000).toISOString().slice(11, 19);
    const upperTime = new Date(upper * 1000).toISOString().slice(11, 19);
    period.period.start = toTime(lowerTime);
    period.period.end = toTime(upperTime);

    // This updates the position of the time markers and updates the model.
    // The changes will only be emitted on blur.
    period.positioning = this.calculatePositioning(period.period);
    period.sliderValues.lower = lower;
    period.sliderValues.upper = upper;
    period.start = period.period.start.clone();
    period.end = period.period.end.clone();
  }

  private calculatePositioning(period: AvailabilityPeriod) {
    const minsFromDayStart = period.start.diff(this.dayStart, 'minutes');
    const durationMins = period.end.diff(period.start, 'minutes');

    const totalMinutes = this.dayEnd.diff(this.dayStart, 'minutes');

    return {
      left: (minsFromDayStart / totalMinutes) * 100,
      width: (durationMins / totalMinutes) * 100
    };
  }

  private getProcessedPeriod(period: AvailabilityPeriod): ProcessedPeriod {
    return {
      period,
      end: period.end.clone(),
      start: period.start.clone(),
      sliderValues: {
        lower: period.start.unix(),
        upper: period.end.unix()
      },
      positioning: this.calculatePositioning(period)
    };
  }
}
