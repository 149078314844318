import { Injectable } from '@angular/core';

import { groupBy, noop, round, sortBy } from 'lodash-es';

import { Observable, ReplaySubject, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { Date, dateToStr, Duration, max, RequestOpts, SECONDS_IN_HOUR, Time, Visit } from '@housekeep/infra';

import { AvailabilityPeriod } from 'models/mixin.availability';
import { Period, period, PeriodType } from 'models/period';
import { WorkerAvailabilityOverride } from 'models/worker-availability-override';
import { WorkingDay } from 'models/working-day';

import { workingDaySerializer } from 'serializers/working-day';

import { AvailabilityService } from 'services/availability-service';
import { InfrastructureService } from 'services/infrastructure-service';

import { SCHEDULE_CACHE_KEY } from 'var/cache';
import { SHOW_RECOMMENDED_START } from 'var/features';

import { AuthService } from './auth-service';
import { CacheService } from './cache-service';
import { RequestService } from './request-service';
import { TimeService } from './time-service';
import { UserService } from './user-service';
import { VisitRatingService } from './visit-rating-service';
import { VisitService } from './visit-service';

enum ScheduleUpdateTrigger {
  Day = 'day',
  Visit = 'visit',
  Server = 'server'
}

interface Schedule {
  days: WorkingDay[];
  currentDay: WorkingDay | null;
  currentVisit: Visit | null;
  trigger?: ScheduleUpdateTrigger;
}

interface Snapshot {
  start: Time;
  end: Time;
  durationHours: number;
}

interface ActivityInSnapshot {
  hoursInSnapshot: number;
  hoursFromStart: number;
  instance: Period;
  visitNumber?: number;
}

interface DaysByWeek {
  beginning: Date;
  days: WorkingDay[];
}

const WORKING_DAY_EXPANDABLES = [
  'activities__changes',
  'activities__visit__account__initial_customer',
  'activities__visit__property__key',
  'activities__visit__remote_key_transfers',
  'activities__visit__required_keys',
  'activities__visit__recommended_start_time_slot',
  'skipped_visits__account__initial_customer',
  'skipped_visits__property'
];

/**
 * Mock service to interact with schedule information.
 */
@Injectable({ providedIn: 'root' })
class ScheduleService {
  private days: WorkingDay[] = [];
  private currentDay: WorkingDay;
  private currentVisit: Visit;

  private cachingVisits: boolean = false;
  private scheduleRequested: boolean;
  private scheduleSubject: ReplaySubject<Schedule | Error>;
  private observable: Observable<Schedule | Error>;
  private firstVisitDateByCustomer: Map<string, Date>;

  constructor(
    private authService: AuthService,
    private availabilityService: AvailabilityService,
    private cacheService: CacheService,
    private infrastructureService: InfrastructureService,
    private requestService: RequestService,
    private timeService: TimeService,
    private userService: UserService,
    private visitRatingService: VisitRatingService,
    private visitService: VisitService
  ) {
    this.init();

    this.authService.subscribe(event => {
      if (event[0] === 'logout') {
        this.init();
      }
    });
  }

  /**
   * Initialise the observable. This can be called multiple times if needed. For example,
   * if the user logs out, we need to reinitialise to ensure the new user doesn't see
   * anything from the previous user's schedule.
   */
  init() {
    if (this.scheduleSubject) {
      this.scheduleSubject.complete();
    }

    this.scheduleSubject = new ReplaySubject(1);
    this.observable = this.scheduleSubject.asObservable();
  }

  /**
   * Subscribe to schedule changes.
   * Retrieve the initial data if required.
   * @param {Function} successFn - The method to call on each change
   * @returns {Subscription}
   */
  public subscribe(successFn: (schedule: Schedule) => any, errFn?: any): Subscription {
    const subscription = this.observable.subscribe(successFn, errFn);

    if (!this.scheduleRequested) {
      this.getSchedule();
    }

    return subscription;
  }

  /**
   * Returns an observable of the currently selected date.
   * Will filter out updates from server responses and errors.
   */
  public get currentDay$(): Observable<Date> {
    return this.observable.pipe(
      map(schedule => {
        if (!(schedule instanceof Error)) {
          return schedule;
        }
      }),
      filter(schedule => !!schedule),
      filter(schedule => schedule.trigger === 'day'),
      map(schedule => schedule.currentDay.day)
    );
  }

  /**
   * Force the schedule to be updated from the server.
   */
  public refreshSchedule(): Promise<Schedule> {
    this.visitRatingService.getVisitRatingIssues();
    return this.getSchedule(true);
  }

  public refreshDay(day: Date): Promise<WorkingDay | null> {
    return this.getDayFromServer(day);
  }

  /**
   * Return the working day for the given date.
   * Attempt to find the day from within the current schedule, but request
   * it from the server if required.
   */
  public async getDay(day: Date): Promise<WorkingDay | null> {
    if (!this.days) {
      await this.getSchedule();
    }

    const workingDay = this.days.find(wd => day.date() === wd.day.date());
    if (workingDay) {
      return Promise.resolve(workingDay);
    } else {
      return this.getDayFromServer(day);
    }
  }

  /**
   * Set the given day to be the current one. Notify all subscribers
   */
  public setCurrentDay(workingDay: WorkingDay, includeTrigger = true) {
    // If the newly set day is different to the previous one, request its latest data
    // from the server
    if (!this.currentDay || !this.currentDay.day.isSame(workingDay.day, 'day')) {
      this.refreshDay(workingDay.day).catch(noop);
    }

    this.currentDay = workingDay;
    this.scheduleSubject.next(this.getState(includeTrigger ? ScheduleUpdateTrigger.Day : undefined));
  }

  /**
   * Set the given visit to be the current one. Notify all subscribers
   * @param {any} visit
   */
  public setCurrentVisit(visit: Visit) {
    this.currentDay.visitNumber(visit); // checks if the visit is in this day
    this.currentVisit = visit;
    this.scheduleSubject.next(this.getState(ScheduleUpdateTrigger.Visit));
  }

  public groupByWeek(days: WorkingDay[]): DaysByWeek[] {
    const orderedDays = sortBy(days, day => {
      return day.day.unix();
    });

    const weeksObj = groupBy(orderedDays, day => {
      return day.day.isoWeek();
    });

    const orderedWeeks = sortBy(weeksObj, week => {
      return week[0].day.unix();
    });

    return orderedWeeks.map(week => {
      return {
        beginning: week[0].day.clone().startOf('isoWeek'),
        days: week
      };
    });
  }

  /**
   * Return the "snapshot" of the worker's day to show.
   * @param  {WorkerScheduleModels.workingDay} day
   * @param  {boolean} useRecommendedStart - Should alter behaviour to respect
   *  the recommended start timeslot.
   * @return {Object} - A start and end time, and the total seconds
   */
  public getSnapshot(day: WorkingDay, useRecommendedStart: boolean = false): Snapshot {
    const start = this.getDayStartTime(day) as Time;
    let end = this.getDayEndTime(day) as Time;

    if (useRecommendedStart) {
      let lastWorkPeriod: Period;
      for (let i = day.activities.length - 1; i >= 0; i--) {
        if (day.activities[i].type === PeriodType.Work) {
          lastWorkPeriod = day.activities[i];
          break;
        }
      }
      if (lastWorkPeriod?.visit?.recommendedStartTimeSlot) {
        const periodDuration = lastWorkPeriod.visit.requiredDuration.toHours();
        const newEnd = lastWorkPeriod.visit.recommendedStartTimeSlot.end.clone();
        newEnd.add(periodDuration, 'hours');
        end = max(newEnd, day.endTime);
      }
    }

    const durationHours = round(end.diff(start, 'seconds') / SECONDS_IN_HOUR, 2);

    return {
      start,
      end,
      durationHours
    };
  }

  public getDayStartTime(day: WorkingDay): Time | null {
    let startTime;

    day.activities.some(activity => {
      if (activity.type !== PeriodType.Break) {
        startTime = activity.startTime;
        return true;
      }
    });

    return startTime || null;
  }

  public getDayEndTime(day: WorkingDay): Time | null {
    let lastActivity;

    day.activities.forEach(activity => {
      if (activity.type !== PeriodType.Break) {
        lastActivity = activity;
      }
    });

    return lastActivity ? lastActivity.endTime : null;
  }

  /**
   * Return the hours of the snapshot covered by a span (start and end time)
   * @param  {Object} snapshot
   * @param  {Time} start
   * @param  {Time} end
   * @return Number - The hours of the snapshot covered
   */
  public hoursWithinSnapshot(snapshot: Snapshot, start: Time, end: Time): number {
    const seconds = end.diff(start, 'seconds');
    let hours = seconds / SECONDS_IN_HOUR;

    if (start.isBefore(snapshot.start)) {
      hours -= snapshot.start.diff(start, 'seconds') / SECONDS_IN_HOUR;
    }
    if (end.isAfter(snapshot.end)) {
      hours -= end.diff(snapshot.end, 'seconds') / SECONDS_IN_HOUR;
    }

    return round(hours, 2);
  }

  public async getActivitiesInSnapshot(
    snapshot: Snapshot,
    day: WorkingDay,
    useRecommendedStart: boolean = false
  ): Promise<ActivityInSnapshot[]> {
    const activitiesInSnapshot: ActivityInSnapshot[] = [];

    const processablePeriods = await this.getProcessablePeriods(day, useRecommendedStart);

    let visitNumber = 0;
    processablePeriods.forEach(period => {
      const activity = this.processPeriod(period, snapshot, useRecommendedStart, visitNumber);
      if (activity !== undefined) {
        activitiesInSnapshot.push(activity);
      }
      if (activity?.visitNumber !== undefined) {
        visitNumber++;
      }
    });

    return activitiesInSnapshot;
  }

  private processPeriod(
    period: Period,
    snapshot: Snapshot,
    useRecommendedStart: boolean,
    visitNumber: number
  ): ActivityInSnapshot | undefined {
    let start = period.startTime;
    let end = period.endTime;

    if (useRecommendedStart && period.visit?.recommendedStartTimeSlot) {
      // If the recommended start time is before the working day begins, i.e. the first visit buffer is in effect,
      // Instead consider it to start at the beginning of the working day to sidestep the first visit buffer rule.
      start = period.visit.recommendedStartTimeSlot.start.isBefore(snapshot.start)
        ? snapshot.start
        : period.visit.recommendedStartTimeSlot.start;
      end = period.visit.recommendedStartTimeSlot.end;
    }

    if (start.isBefore(snapshot.start) || end.isAfter(snapshot.end)) {
      return;
    }

    const hoursInSnapshot =
      useRecommendedStart && period.visit
        ? period.visit.requiredDuration.toHours()
        : this.hoursWithinSnapshot(snapshot, start, end);
    const seconds = start.diff(snapshot.start, 'seconds');
    const inHours = seconds / SECONDS_IN_HOUR;

    return this.buildNewActivity(hoursInSnapshot, inHours, period, visitNumber);
  }

  private buildNewActivity(
    hoursInSnapshot: number,
    inHours: number,
    period: Period,
    visitNumber: number
  ): ActivityInSnapshot {
    if (hoursInSnapshot > 0) {
      const activityInfo: ActivityInSnapshot = { hoursInSnapshot, hoursFromStart: inHours, instance: period };

      if (period.type === PeriodType.Work) {
        activityInfo.visitNumber = visitNumber;
      }

      return activityInfo;
    }
  }

  /**
   * A fire-and-forget function that will cache visits for a number of days in the future
   * @param force Force fresh fetch of data to update cache
   */
  public async cacheUpcomingVisits(force: boolean = false, { daysToCache = 2 } = {}) {
    // We don't want to make multiple requests - this would happen if the user
    // switches between days before the caching requests have finished.
    if (this.cachingVisits) {
      return;
    }

    this.cachingVisits = true;

    this.visitService.uncachePastVisits();

    const days = this.days.slice(0, daysToCache);
    const visits = days.map(day => day.visits).reduce((a, b) => a.concat(b), []);
    const cachePromises = visits.map(visit => this.visitService.cacheVisit(visit, force));

    try {
      await Promise.all(cachePromises);
    } catch {}

    this.cachingVisits = false;
  }

  public isVisitEarliestForCustomer(visit: Visit): boolean | undefined {
    if (this.firstVisitDateByCustomer) {
      return !!visit.actualDate.isSame(this.firstVisitDateByCustomer.get(visit.account.initialCustomer.id));
    }
    return undefined;
  }

  public async getShowRecommendedStart(forceRefresh: boolean = false): Promise<boolean> {
    return await this.infrastructureService.cachedIsFeatureActiveForUser(SHOW_RECOMMENDED_START, forceRefresh);
  }

  /**
   * @param day The working day to fetch the activities for/from
   * @param useRecommendedStart Whether recommended start times should be used in place of
   * latest_possible_start_times
   * @returns an unordered array of periods for that day
   */
  private async getProcessablePeriods(day: WorkingDay, useRecommendedStart: boolean): Promise<Period[]> {
    const excludeBreakPeriods = useRecommendedStart && day.day.isSame(this.timeService.today());
    // Exclude potentially incorrect break periods from the working day endpoint
    // if the schedule is for today and the recommended start feature is active
    const processablePeriods: Period[] = excludeBreakPeriods
      ? day.activities.filter(activity => activity.type === PeriodType.Work)
      : day.activities.filter(activity => activity.type === PeriodType.Work || activity.type === PeriodType.Break);

    if (excludeBreakPeriods) {
      processablePeriods.push(...(await this.getBreakPeriodsForToday()));
    }

    return processablePeriods;
  }

  /**
   * Generates a list of all break periods from the availability of the worker.
   * E.g: availability is [[8-11], [14-17], [18-20]],
   * this will return a list of Periods of type Break: [[11-14], [17-18]], when the worker
   * is not available
   */
  public async getBreakPeriodsForToday(): Promise<Period[]> {
    let availabilityOverride: WorkerAvailabilityOverride;

    try {
      availabilityOverride = await this.availabilityService.getDayAvailability(this.timeService.today());
    } catch {
      return [];
    }

    if (!availabilityOverride) {
      return [];
    }

    const breakPeriods: Period[] = [];

    availabilityOverride.availability.forEach((availabilityPeriod, currentIndex, availabilityArray) => {
      const nextAvailabilityPeriod: AvailabilityPeriod | undefined = availabilityArray[currentIndex + 1];
      if (!nextAvailabilityPeriod) {
        return;
      }
      // break period = end of current availability -> start of next availability
      const newBreakPeriod: Period = period.create({
        type: PeriodType.Break,
        start: availabilityPeriod.end,
        end: nextAvailabilityPeriod.start
      });
      breakPeriods.push(newBreakPeriod);
    });

    return breakPeriods;
  }

  /**
   * Retrieve the working days from the server, and update the schedule
   * @param  {boolean}  [forceRequest] - set to true to force a server request
   * @return {Promise<Schedule>}
   */
  private async getSchedule(forceRequest: boolean = false): Promise<Schedule> {
    let workingDays: WorkingDay[];
    this.scheduleRequested = true;

    try {
      if (forceRequest) {
        workingDays = await this.getServerSchedule().catch(() => this.getCachedSchedule());
      } else {
        workingDays = await this.getCachedSchedule().catch(() => this.getServerSchedule());
      }

      this.parseDays(workingDays);
      const schedule = this.getState(ScheduleUpdateTrigger.Server);
      this.scheduleSubject.next(schedule);
      this.scheduleRequested = false;
      this.cacheUpcomingVisits(forceRequest);

      return schedule;
    } catch (err) {
      // We don't use scheduleSubject.error here because that terminates the stream
      this.scheduleSubject.next(new Error(err));
      this.scheduleRequested = false;
    }
  }

  private async getServerSchedule(): Promise<WorkingDay[]> {
    const user = await this.userService.getUser();
    const endpoint = `workers/${user.id}/working-days/`;
    const serializer = workingDaySerializer;
    const opts: RequestOpts = {
      params: {
        expand: WORKING_DAY_EXPANDABLES
      },
      cache: {
        duration: new Duration({ days: 1 }),
        key: SCHEDULE_CACHE_KEY
      }
    };

    return await this.requestService.getList(endpoint, serializer, opts);
  }

  /**
   * Get the schedule from the cache. This will not return any days that are in the past.
   */
  private async getCachedSchedule(): Promise<WorkingDay[]> {
    const days = await this.cacheService.get(SCHEDULE_CACHE_KEY);
    const serializedDays = workingDaySerializer.deserialize(days, { many: true }) as WorkingDay[];
    const todayDate = this.timeService.today();

    if (serializedDays) {
      return serializedDays.filter(day => {
        return day.day.diff(todayDate, 'days') >= 0;
      });
    }

    return [];
  }

  /**
   * Retrieve the given working day from the server.
   */
  private getDayFromServer(day: Date): Promise<WorkingDay | null> {
    return this.userService
      .getUser()
      .then(user => {
        const endpoint = `workers/${user.id}/working-day/${dateToStr(day)}/`;
        const serializer = workingDaySerializer;
        const params = {
          expand: WORKING_DAY_EXPANDABLES
        };
        return this.requestService.getInstance(endpoint, serializer, {
          params,
          cache: {
            key: SCHEDULE_CACHE_KEY,
            merge: true,
            trackBy: 'day'
          }
        });
      })
      .then(workingDay => {
        // Attempt to update the schedule with the new day's information
        this.days.some(existingDay => {
          if (existingDay.day.isSame(workingDay.day)) {
            existingDay.update(workingDay);
            this.scheduleSubject.next(this.getState(ScheduleUpdateTrigger.Server));
            return true;
          }
        });

        return workingDay;
      })
      .catch(err => {
        this.scheduleSubject.next(new Error(err));
        return null;
      });
  }

  private parseDays(days: WorkingDay[]): void {
    let currentDay = days[0];

    if (this.currentDay) {
      days.some(workingDay => {
        if (workingDay.day.isSame(this.currentDay.day)) {
          currentDay = workingDay;
          return true;
        }
      });
    }

    this.days = days;
    this.setCurrentDay(currentDay, false);
    this.parseFirstVisitsByCustomer(days);
  }

  private parseFirstVisitsByCustomer(days: WorkingDay[]): void {
    this.firstVisitDateByCustomer = new Map<string, Date>();
    for (const day of days) {
      for (const visit of day.visits) {
        const customerId = visit.account.initialCustomer.id;
        const currentVal = this.firstVisitDateByCustomer.get(customerId);
        if (currentVal === undefined || visit.actualDate.isBefore(currentVal)) {
          this.firstVisitDateByCustomer.set(customerId, visit.actualDate);
        }
      }
    }
  }

  /**
   * The common state to push to subscribers whenever the schedule is updated.
   */
  private getState(trigger: ScheduleUpdateTrigger): Schedule {
    return {
      days: this.days,
      currentDay: this.currentDay,
      currentVisit: this.currentVisit,
      trigger
    };
  }
}

export { ActivityInSnapshot, DaysByWeek, Schedule, ScheduleService, ScheduleUpdateTrigger, Snapshot, WorkingDay };
