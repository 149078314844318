import {
  dateTimeField,
  Field,
  ModelSerializer,
  modelSerializer,
  serializerField,
  timeSlotSerializer,
  visitSerializer
} from '@housekeep/infra';

import { period, Period } from 'models/period';

export const periodSerializer = modelSerializer.extend({
  model: period,
  fields: {
    start: dateTimeField.create(),
    clash: serializerField.create({
      serializer: timeSlotSerializer,
      optional: true
    }),
    end: dateTimeField.create(),
    latest_constrained_start_dt: dateTimeField.create({ optional: true }),
    visit: serializerField.create({
      serializer: visitSerializer,
      optional: true
    }) as Field
  }
}) as ModelSerializer<Period>;
