import { DemoEndpoint } from '../../../demo-service';
import { getPagedPaymentPeriodData } from '../../util';

export const paymentsEndpoint: DemoEndpoint = {
  path: 'payments',
  handlers: [
    {
      method: 'get',
      handle(state) {
        // Generate a paged response for all payment periods.
        return getPagedPaymentPeriodData(state);
      }
    }
  ]
};
