import { DemoEndpoint } from '../../../demo-service';
import { getPagedPaymentPeriodData } from '../../util';

export const paymentEndpoint: DemoEndpoint = {
  path: 'payments/[a-z0-9]+',
  handlers: [
    {
      method: 'get',
      handle(state, path) {
        // Get the payment period ID from the path.
        const pathMatch = path.match(/.*\/payments\/([a-z0-9]+)\//);
        const id = pathMatch[1];

        // Get all payment periods.
        const pagedPaymentPeriodData = getPagedPaymentPeriodData(state);

        // Return the right one.
        return pagedPaymentPeriodData.results.find(period => period.id === id);
      }
    }
  ]
};
