import { DemoEndpoint } from '../../demo-service';
import { issuesEndpoint } from './ratings/worker/issues';
import { rateVisitEndpoint } from './ratings/worker/rate-visit';

const ratingsEndpoint: DemoEndpoint = {
  path: 'ratings',
  endpoints: [issuesEndpoint, rateVisitEndpoint]
};

export { ratingsEndpoint };
