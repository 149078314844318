import { Pipe, PipeTransform } from '@angular/core';

import { capitalize } from '@housekeep/infra';

@Pipe({ name: 'asHours' })
export class AsHours implements PipeTransform {
  transform(duration: number): string {
    return duration > 0 && duration < 0.5 ? duration.toFixed(2) : duration.toFixed(1);
  }
}

// TODO cut this - we have a Capitalise pipe!
@Pipe({ name: 'asPet' })
export class AsPet implements PipeTransform {
  transform(pet: string): string {
    return capitalize(pet);
  }
}

export const workPipes = [AsHours, AsPet];
