import { DemoEndpoint } from 'services/demo-service';

const visitChangesEndpoint: DemoEndpoint = {
  path: 'changes',
  handlers: [
    {
      method: 'get',
      handle(state, path) {
        if (path.indexOf('thechangesvisit') !== -1) {
          return {
            special_instructions: {
              thechangesvisit: {
                task_type: 'cleaning',
                instruction: 'Do not clean the bedrooms'
              }
            }
          };
        }

        return {};
      }
    }
  ]
};

export { visitChangesEndpoint };
