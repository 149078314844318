import { DemoEndpoint } from '../../../demo-service';

const userHasCommunicatedEndpoint: DemoEndpoint = {
  path: 'comms-by-user-for-visit',
  handlers: [
    {
      method: 'get',
      handle() {
        return { hasChatted: true, hasCalled: true };
      }
    }
  ]
};

export { userHasCommunicatedEndpoint };
