import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';

import { BehaviorSubject, Subscription } from 'rxjs';

import { WorkingDay } from 'models/working-day';

import { ChatInfoService } from 'services/chat-info-service';
import { Schedule, ScheduleService } from 'services/schedule-service';

@Component({
  selector: 'schedule-list-day',
  templateUrl: './schedule-list-day.component.html',
  styleUrls: ['./schedule-list-day.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScheduleListDayComponent implements OnInit, OnDestroy {
  @Input() day: WorkingDay;
  @Input() menuToggle = false;
  @Input() schedule: Schedule;

  public unreadChatMessagesCount$ = new BehaviorSubject<number>(0);
  private subscription: Subscription;

  constructor(
    private cdRef: ChangeDetectorRef,
    private chatInfoService: ChatInfoService,
    private scheduleService: ScheduleService
  ) {}

  ngOnInit() {
    const firstVisitsInSchedule = this.day.visits.filter(visit =>
      this.scheduleService.isVisitEarliestForCustomer(visit)
    );
    if (!firstVisitsInSchedule.length) {
      return;
    }
    this.chatInfoService.unreadMessagesObservableForVisits(firstVisitsInSchedule).then(observable => {
      this.subscription = observable.subscribe(unreadCount => {
        this.unreadChatMessagesCount$.next(unreadCount);
        this.cdRef.markForCheck();
      });
    });
  }

  ngOnDestroy() {
    // TODO - fires before subscription complete
    // this.subscription.unsubscribe();
  }

  changeDay(day: WorkingDay) {
    this.scheduleService.setCurrentDay(day);
  }

  isCurrentDay(day: WorkingDay): boolean {
    return day === this.schedule.currentDay;
  }
}
