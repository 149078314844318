import { Job, model, Model } from '@housekeep/infra';

import { AvailabilityPeriod } from './mixin.availability';

export interface AssignableJob extends Model {
  availabilityChanges?: AvailabilityPeriod[];
  isWithinExtraJobsSignal?: boolean;
  isWithinRegularWorkingArea?: boolean;
  isWithinRegularWorkingHours?: boolean;
  job: Job;
}

export const assignableJob = model.extend({
  modelName: 'assignableJob'
});
