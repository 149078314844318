import { Injectable } from '@angular/core';

/**
 * This simple service is responsible for providing a single interface to
 * the current network status of the device.
 */
@Injectable({ providedIn: 'root' })
class OnlineService {
  public deviceOnline = false;
  public suppressTimeoutErrors = false;

  public isOnline(): boolean {
    return this.deviceOnline;
  }

  public setOnlineState(isOnline: boolean): void {
    this.deviceOnline = isOnline;
  }

  public shouldSuppressTimeoutErrors(): boolean {
    return this.suppressTimeoutErrors;
  }

  public setSuppressTimeoutErrors(suppressErrors: boolean): void {
    this.suppressTimeoutErrors = suppressErrors;
  }
}

export { OnlineService };
