<div class="hk-round-progress-container">
  <div class="hk-round-progress-inner">
    <div class="hk-round-progress-inner__metric">
      <span>{{ metricValue }}</span>
      <ng-content select="[metric-value]"></ng-content>
    </div>
    <div class="hk-round-progress-inner__metric-label">
      <span *ngIf="metricLabel">{{ metricLabel }}</span>
      <ng-container *ngIf="!metricLabel">
        <ng-content select="[metric-label]"></ng-content>
      </ng-container>
    </div>
  </div>
  <round-progress
    [current]="current"
    [max]="max"
    [radius]="radius"
    [color]="color"
    [background]="background"
    [stroke]="stroke"
    [responsive]="true"
    [rounded]="rounded"
    [duration]="duration"
    [animationDelay]="animationDelay"
    [animation]="animation"
    (onRender)="doRender($event)"
    [rounded]="true"
  ></round-progress>
  <round-progress
    class="hk-round-progress-target-range"
    *ngIf="lowerTarget"
    [current]="max - lowerTarget"
    [max]="max"
    [radius]="radius"
    [color]="targetColor"
    background="rgba(0,0,0,0)"
    [stroke]="targetStroke"
    [responsive]="true"
    [rounded]="false"
    [clockwise]="false"
    [duration]="1"
  ></round-progress>
  <div
    class="hk-round-progress-target-indicator"
    [ngStyle]="getIndicatorStyle()"
    *ngIf="lowerTarget"
  >
    <div
      class="hk-round-progress-target-indicator-label"
      [ngStyle]="getIndicatorLabelStyle()"
      *ngIf="lowerTarget"
    >
      <span>{{ lowerTargetLabel }}</span>
      <ng-content select="[lower-target-label]"></ng-content>
    </div>
  </div>

  <div class="hk-round-progress-target-indicator" *ngIf="upperTarget">
    <div class="hk-round-progress-target-indicator-label">
      <span>{{ upperTargetLabel }}</span>
      <ng-content select="[upper-target-label]"></ng-content>
    </div>
  </div>
</div>
