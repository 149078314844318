import { subtractDaysFromDate, todayNaive } from '@housekeep/infra';

import { DemoEndpoint } from 'services/demo-service';

const YESTERDAY = subtractDaysFromDate(todayNaive(), 1).format('YYYY-MM-DD');
const WEEK_AGO = subtractDaysFromDate(todayNaive(), 7).format('YYYY-MM-DD');

const ticketLinkResponse = {
  url_next_page: null,
  url_previous_page: null,
  current_page: 1,
  num_pages: 1,
  num_results: 5,
  per_page: 50,
  results: [
    {
      created_at: `${YESTERDAY}T09:56:23Z`,
      last_updated_at: `${YESTERDAY}T09:56:23Z`,
      status: 'open',
      priority: 'normal',
      subject: 'Housekeeper App: Keys',
      category: null,
      call_requested: false,
      ticket_id: '1231231'
    },
    {
      created_at: `${WEEK_AGO}T09:56:23Z`,
      last_updated_at: `${WEEK_AGO}T09:56:23Z`,
      status: 'closed',
      priority: 'urgent',
      subject: 'Housekeeper App: Job later: Question',
      category: 'holiday',
      call_requested: false,
      ticket_id: '1231232'
    }
  ]
};

const zendeskTicketLinksEndpoint: DemoEndpoint = {
  path: 'zendesk/ticket-links',
  handlers: [
    {
      method: 'get',
      handle() {
        return ticketLinkResponse;
      }
    }
  ]
};

export { zendeskTicketLinksEndpoint };
