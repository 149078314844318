<div
  *ngIf="visit"
  tappable
  (click)="goToVisit(visit)"
  class="visit-block"
  [class.visit-block--scheduled]="visit.isScheduled"
  [class.visit-block--started]="visit.isStarted"
  [class.visit-block--finished]="visit.isFinished"
>
  <div *ngIf="visit.isStarted" class="visit-block__progress-icon">
    <hk-wc-icon
      class="animate animate--fade-in-out-pause"
      name="clock"
      size="42"
    ></hk-wc-icon>
  </div>
  <div *ngIf="visit.isFinished" class="visit-block__progress-icon">
    <hk-wc-icon name="tick-circle" size="42"></hk-wc-icon>
  </div>
  <div class="visit-block__container">
    <div class="visit-block__header visit-block__row">
      <span class="visit-block__detail">
        <span class="visit-block__job-number">Job {{ visitNumber }}</span>
        <ion-badge
          class="mar-l-5 icon-badge"
          *ngIf="hasChanges && !visit.isFinished"
          color="warning"
          id="changes-badge"
        >
          <hk-wc-icon size="16" name="recurring-arrow"></hk-wc-icon>
          Check updates
        </ion-badge>
      </span>
      <span class="visit-block__detail visit-block__location">
        <hk-wc-icon name="location-pin" size="20"></hk-wc-icon>
        {{ visit.property.postcode.full }}
      </span>
    </div>
    <div class="visit-block__details visit-block__row">
      <span class="visit-block__detail visit-block__customer-name">
        {{ getDisplayName(visit.account.initialCustomer.preferredName) | titlecase }}
        <div class="visit-block__unread-chats">
          <visit-unread-chats
            *ngIf="!visit.isRktTraveller"
            [showAlways]="false"
            [visit]="visit"
          ></visit-unread-chats>
        </div>
      </span>
      <span
        *ngFor="let transfer of visit.remoteKeyTransfers"
        class="visit-block__detail visit-block__worker-name"
      >
        <hk-wc-icon name="key-hand" size="20"></hk-wc-icon>
        {{ transfer.otherWorker.firstNames | titlecase }}
        <div class="visit-block__unread-chats">
          <rkt-unread-chats
            [showAlways]="false"
            iconName="chat"
            [transfer]="transfer"
            [isRktTraveller]="visit.isRktTraveller"
          ></rkt-unread-chats>
        </div>
      </span>
    </div>
    <div class="visit-block__details visit-block__row">
      <span class="visit-block__detail visit-block__start-time">
        {{ getStartTimeDisplayValue }}
      </span>
      <span class="visit-block__detail visit-block__duration">
        <hk-wc-icon
          *ngIf="visit.requiresProducts"
          name="spray-bottle"
          size="20"
        ></hk-wc-icon>

        <hk-wc-icon *ngIf="visit.isKeysOnlyVisit" name="key" size="20"></hk-wc-icon>

        <span *ngIf="this.visit.isDeepClean">
          <hk-wc-icon name="sponge" size="20"></hk-wc-icon>
          Deep clean
        </span>

        <span *ngIf="this.visit.isRemoteKeyTransfer">
          <ng-container *ngIf="this.visit.isRktTraveller">Key transfer</ng-container>
        </span>

        <span *ngIf="!visit.isDeepClean && !visit.isRktTraveller">
          {{ visit.requiredDuration.toHours() | asHours }} hours
        </span>
      </span>
    </div>
  </div>
</div>
