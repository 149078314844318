import { Injectable } from '@angular/core';

import { Platform } from '@ionic/angular';

import { noop, orderBy } from 'lodash-es';

import {
  dateField,
  dateToStr,
  Duration,
  RequestOpts,
  serializer,
  Visit,
  VisitRating,
  visitRatingSerializer,
  WorkerMetrics
} from '@housekeep/infra';

import { WorkerDailyStats } from 'models/worker-daily-stats';
import { WorkerVisitRating, WorkerVisitRatingIssue } from 'models/worker-visit-rating';

import { VisitRatingWeek } from 'pages/performance/rating-period';

import { workerDailyStatsSerializer } from 'serializers/worker-daily-stats';
import { workerVisitRatingIssueSerializer, workerVisitRatingSerializer } from 'serializers/worker-visit-rating';

import { OfflineEventActionTypes, OfflineEventObjectTypes } from 'var/offline-events';

import { CacheService } from './cache-service';
import { RequestService } from './request-service';
import { TimeService } from './time-service';
import { UserService } from './user-service';

const RATINGS_CACHE_KEY = 'RATINGS';
const SUMMARY_CACHE_KEY = 'RATINGS_STATS';
const VISIT_RATING_ISSUES_CACHE_KEY = 'VISIT_RATING_ISSUES';
const VISIT_RATING_ISSUES_CACHE_DURATION = new Duration({ days: 3 });
const WORKER_DAILY_STATS_CACHE_KEY = 'DAILY_STATS';
const WORKER_DAILY_STATS_CACHE_DURATION = new Duration({ days: 1 });

/**
 * Service to handle retrieving visit rating information from the public API
 */
@Injectable({ providedIn: 'root' })
class VisitRatingService {
  constructor(
    public platform: Platform,
    private cacheService: CacheService,
    private requestService: RequestService,
    private timeService: TimeService,
    private userService: UserService
  ) {
    this.platform
      .ready()
      .then(() => {
        this.userService
          .getUser()
          .then(() => this.getVisitRatingIssues())
          .catch(noop);
      })
      .catch(noop);
  }

  /**
   * Give paged ratings from visits over some past number of days.
   *
   * @param {number} [dayCount] The number of past days from which to draw
   * visits whose ratings will be returned; `Infinity` works for all history and
   * is the default
   * @param {string} [lastId] The ID of the last retrieved visit rating
   *
   * @return {Promise<Object>} A promise that gives an object with `moreExist`
   * and `visitRatings`
   */
  public getVisitRatings(dayCount: number = Infinity, lastId?: string, opts?: RequestOpts) {
    const defaultOpts = {
      cache: {
        duration: new Duration({ days: 7 }),
        key: RATINGS_CACHE_KEY
      },
      serializer: visitRatingSerializer,
      params: {
        expand: ['customer', 'visit', 'visit__property'],
        lastRating: lastId
      }
    };

    opts = Object.assign({}, defaultOpts, opts);

    return this.userService
      .getUser()
      .then(user => {
        const endpoint = `workers/${user.id}/ratings/`;
        return this.requestService.getPage(endpoint, opts);
      })
      .then(response => {
        let visitRatings;
        // @TODO - Request directly from the cache in this service
        // If the response has come from a server request, it will be paged, and in
        // response.results. If it's from the cache, then the data we want will be
        // the response itself.
        if (response.results) {
          visitRatings = response.results;
        } else {
          visitRatings = response;
        }

        if (dayCount !== Infinity) {
          visitRatings = visitRatings.filter(visitRating => {
            const cutoffDate = this.timeService.today().subtract(dayCount, 'days');
            return visitRating.visit.actualDate >= cutoffDate;
          });
        }

        return {
          moreExist: response.currentPage !== response.numPages,
          visitRatings: visitRatings
        };
      });
  }

  /**
   * Get visit ratings from the cache
   *
   * @param  {number}       dayCount Limit results to ratings no older than dayCount days
   * @return {Promise<any>}
   */
  public getCachedVisitRatings(dayCount: number): Promise<any> {
    return this.cacheService.get(RATINGS_CACHE_KEY).then(result => {
      let ratings = visitRatingSerializer.deserialize(result, { many: true });

      if (dayCount !== Infinity) {
        const cutoffDay = this.timeService.today().subtract(dayCount + 1, 'days');
        ratings = ratings.filter(rating => rating.visit.actualDate.isAfter(cutoffDay));
      }

      return ratings;
    });
  }

  /**
   * Get a specific visit rating from the cache.
   * @param visitRatingId The ID of the visit rating
   */
  public async getCachedVisitRatingById(visitRatingId: string): Promise<VisitRating> {
    const ratings = visitRatingSerializer.deserialize(await this.cacheService.get(RATINGS_CACHE_KEY), { many: true });
    return ratings.find(rating => rating.id === visitRatingId);
  }

  /**
   * Give an object that contains metadata about ratings over some past number
   * of days.
   *
   * @param {number} [dayCount] The number of past days from which to draw
   * visits whose metadata will be returned; `Infinity` works for all history
   * and is the default
   *
   * @return {Promise<Object>} A promise that gives an object with the metadata
   */
  public getVisitRatingSummary(dayCount: number = Infinity, opts: RequestOpts = {}): Promise<WorkerMetrics> {
    return this.userService.getUser().then(user => {
      const params: any = {};

      if (dayCount !== Infinity) {
        params.fromDate = this.timeService.today().subtract(dayCount, 'days');
      }

      const paramsSerializer = serializer.extend({
        fields: {
          from_date: dateField.create({ optional: true })
        }
      });

      const defaultOpts = {
        params,
        paramsSerializer,
        cache: {
          duration: new Duration({ days: 7 }),
          key: `${SUMMARY_CACHE_KEY}${dayCount}`
        }
      };

      opts = Object.assign({}, defaultOpts, opts);

      return this.requestService.get(`workers/${user.id}/stats/`, opts);
    });
  }

  /**
   * Get summaries from the cache
   * @param  {number}       dayCount The number of days that the summary should be for
   * @return {Promise<any>}
   */
  public getCachedVisitRatingSummary(dayCount: number): Promise<any> {
    return this.cacheService.get(`${SUMMARY_CACHE_KEY}${dayCount}`).then(result => serializer.deserialize(result));
  }

  /**
   * Group visit ratings by week.
   * @param {Array} visitRatings Visit ratings sorted by newest to oldest visit
   * @return {Array<VisitRatingWeek>} The visit ratings grouped by week
   */
  public groupByWeek(visitRatings: any[] = []) {
    // TODO: Let the user pass in existing weeks and only append the ratings to
    // those weeks.

    let week: VisitRatingWeek;

    // Reset the current weeks.
    const weeks = [];

    // Add the ratings to the list, adding new weeks when necessary.
    visitRatings.forEach(visitRating => {
      if (week && week.startDay.isSame(visitRating.visit.weekBeginning)) {
        week.visitRatings.push(visitRating);
      } else {
        week = {
          startDay: visitRating.visit.weekBeginning,
          visitRatings: [visitRating]
        };

        weeks.push(week);
      }
    });

    // Give the array of sorted weeks.
    return orderBy(weeks, 'startDay', 'desc');
  }

  /**
   * Get the list of possible issues a worker can submit with a visit rating.
   *
   * @return {Promise<WorkerVisitRatingIssue[]>}
   */
  public getVisitRatingIssues(): Promise<WorkerVisitRatingIssue[]> {
    const endpoint = 'ratings/worker/issues/';
    const opts = {
      cache: {
        duration: VISIT_RATING_ISSUES_CACHE_DURATION,
        key: VISIT_RATING_ISSUES_CACHE_KEY
      }
    };

    return this.cacheService
      .get(VISIT_RATING_ISSUES_CACHE_KEY)
      .then(issues => workerVisitRatingIssueSerializer.deserialize(issues, { many: true }))
      .catch(() =>
        this.userService
          .getUser()
          .then(user => this.requestService.getList(endpoint, workerVisitRatingIssueSerializer, opts))
      );
  }

  /**
   * Send a visit rating to the server.
   *
   * @param {Visit} visit The visit being rated by the worker
   * @param {WorkerVisitRating} workerVisitRating The rating data
   * @return {Promise<WorkerVisitRating>}
   */
  public rateVisit(visit: Visit, workerVisitRating: WorkerVisitRating): Promise<WorkerVisitRating> {
    return this.userService.getUser().then(user => {
      const endpoint = `ratings/worker/${user.id}/` + `rate-visit/${visit.jobId}/${dateToStr(visit.scheduledDate)}/`;

      return this.requestService.put(endpoint, workerVisitRating, {
        offline: {
          expiryTimestamp: this.timeService.today().add(1, 'days').startOf('day').unix(),
          enqueueIfOffline: true,
          objectId: visit.jobId,
          objectType: OfflineEventObjectTypes.JobRating,
          offlineResponsePayload: {},
          raiseOfflineError: false,
          requestFailedMessage: 'Failed to rate job',
          type: OfflineEventActionTypes.RateJob
        },
        serializer: workerVisitRatingSerializer
      });
    });
  }

  /**
   * Get a daily breakdown of the worker's performance over the last `days` days from the
   * cache.
   * @param {number} days
   * @return {Promise<WorkerDailyStats[]>}
   */
  public getCachedDailyStats(days: number): Promise<WorkerDailyStats[]> {
    return this.cacheService
      .get(`${WORKER_DAILY_STATS_CACHE_KEY}${days}`)
      .then(result => workerDailyStatsSerializer.deserialize(result, { many: true }));
  }

  /**
   * Get a daily breakdown of the worker's performance over the last `days` days.
   * @param {number} days
   * @return {Promise<WorkerDailyStats[]>}
   */
  public getDailyStats(days: number): Promise<WorkerDailyStats[]> {
    const params = {
      fromDate: this.timeService.today().subtract(days, 'days')
    };

    const paramsSerializer = serializer.extend({
      fields: {
        from_date: dateField.create({ optional: true })
      }
    });

    const opts = {
      params,
      paramsSerializer,
      cache: {
        duration: WORKER_DAILY_STATS_CACHE_DURATION,
        key: `${WORKER_DAILY_STATS_CACHE_KEY}${days}`
      }
    };

    return this.userService.getUser().then(user => {
      const endpoint = `workers/${user.id}/daily-stats/`;
      return this.requestService.getList(endpoint, workerDailyStatsSerializer, opts);
    });
  }
}

export {
  RATINGS_CACHE_KEY,
  SUMMARY_CACHE_KEY,
  VISIT_RATING_ISSUES_CACHE_KEY,
  VISIT_RATING_ISSUES_CACHE_DURATION,
  VisitRatingService
};
