import {
  dateField,
  ModelSerializer,
  modelSerializer,
  serializerField,
  timeField,
  visitSerializer,
  workerSerializer
} from '@housekeep/infra';

import { workingDay, WorkingDay } from 'models/working-day';

import { periodSerializer } from './period';

export const workingDaySerializer = modelSerializer.extend({
  model: workingDay,
  fields: {
    worker: serializerField.create({
      serializer: workerSerializer,
      optional: true
    }),
    day: dateField.create(),
    start_time: timeField.create({ optional: true }),
    end_time: timeField.create({ optional: true }),
    activities: serializerField.create({
      serializer: periodSerializer,
      serializerKwargs: { many: true }
    }),
    skipped_visits: serializerField.create({
      serializer: visitSerializer,
      serializerKwargs: { many: true }
    })
  }
}) as ModelSerializer<WorkingDay>;
