import { Component, Input } from '@angular/core';

@Component({
  selector: 'hk-compliment',
  templateUrl: './hk-compliment.component.html',
  styleUrls: ['./hk-compliment.component.scss']
})
export class HkCompliment {
  @Input() public compliment: string;
  @Input() public complimentDisplay: string;

  get complimentIcon() {
    return this.compliment.replace(/_/g, '-');
  }
}
