import { Component, Input, OnInit } from '@angular/core';

import { Property, PropertyAccess, Visit } from '@housekeep/infra';

import { VisitChanges } from 'models/visit-changes';

@Component({
  selector: 'hk-visit-equipment',
  templateUrl: './hk-visit-equipment.component.html',
  styleUrls: ['./hk-visit-equipment.component.scss']
})
export class HkVisitEquipment implements OnInit {
  @Input() public visit: Visit;
  @Input() public visitChanges: VisitChanges;

  public property: Property;
  public equipmentLocations: any;
  public PROPERTY_ACCESS_AT_HOME = PropertyAccess.AtHome;

  private equipmentFields: string[][] = [
    ['Cleaning products', 'cleaningProductsLocation'],
    ['Bin bags', 'binBagsLocation'],
    ['Vacuum', 'hooverLocation'],
    ['Mop', 'mopLocation'],
    ['Iron', 'ironLocation'],
    ['Ironing board', 'ironingBoardLocation']
  ];

  public ngOnInit(): void {
    this.property = this.visit.property;
    this.equipmentLocations = this.getEquipmentLocations();
  }

  /**
   * Return a list of {key, label, location} objects for cleaning equipment.
   */
  private getEquipmentLocations(): Record<string, any>[] {
    const equipment = this.property.cleaningEquipment;

    return this.equipmentFields
      .map(([label, key]) => ({ key, label, location: equipment[key] }))
      .filter(({ location }) => !!location);
  }
}
