<ion-list class="pad-0">
  <ion-item
    *ngFor="let customerKeyEntry of customerKeyEntries"
    tappable
    [disabled]="!canClick(customerKeyEntry)"
    (click)="onClick(customerKeyEntry)"
  >
    <div class="customer-details">
      <h5 class="mar-t-0 mar-b-5">
        {{ customerKeyEntry.account.initialCustomer.preferredName }}
      </h5>
      <p class="mar-t-0 mar-b-5">
        {{ customerKeyEntry.property.postcode.outward }}
        <span
          *ngIf="
            customerKeyEntry.activePropertiesSameAccount > 1 &&
            customerKeyEntry.property.shortDescription
          "
        >
          ({{ customerKeyEntry.property.shortDescription }})
        </span>
      </p>
      <p class="text--light mar-y-0">
        {{ customerKeyEntry.finishedVisitsCount }} completed cleans
      </p>
    </div>

    <ion-badge
      *ngIf="customerKeyEntry.key"
      class="icon-badge"
      [class.mar-r-0]="canClick(customerKeyEntry)"
      [color]="customerKeyEntry.key?.transferInProgress ? 'warning' : 'light'"
      slot="end"
    >
      <hk-wc-icon size="16" name="key"></hk-wc-icon>
      <span>
        {{ customerKeyEntry.key.code }}
      </span>
    </ion-badge>

    <ng-container *ngIf="canClick(customerKeyEntry)">
      <ion-button *ngIf="!customerKeyEntry.key" fill="outline" size="small" slot="end">
        <hk-wc-icon name="plus" size="18" slot="start"></hk-wc-icon>
        Add keys
      </ion-button>

      <ion-icon
        slot="end"
        name="chevron-forward"
        class="mar-r-0 mar-l-5"
        size="small"
      ></ion-icon>
    </ng-container>
  </ion-item>
</ion-list>
