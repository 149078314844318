<div class="alert-card alert-card--{{ type }} {{ extraClasses }}">
  <ion-grid class="ion-no-padding">
    <ion-row>
      <ion-col *ngIf="icon" size="auto">
        <hk-wc-icon [name]="icon" [size]="iconSize"></hk-wc-icon>
      </ion-col>
      <ion-col>
        <ng-content></ng-content>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>
