<div
  *ngIf="user && !isHousekeep"
  class="user-image"
  [ngStyle]="{
    backgroundImage: user.image ? 'url(' + user.image + ')' : 'url(assets/design-tokens/images/user-avatar.svg)'
  }">
</div>

<div
  *ngIf="isHousekeep"
  class="housekeep-symbol">
</div>
