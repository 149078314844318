import { Component, Input } from '@angular/core';

import { ModalController } from '@ionic/angular';

import { MoodSampleCampaign, WorkerInterventionResponse, WorkerMood } from '@housekeep/infra';

import { AlertService } from 'services/alert-service';
import { AnalyticsService } from 'services/analytics-service';
import { ErrorService } from 'services/error-service';
import { MoodSamplingService } from 'services/mood-sampling-service';
import { SentryService } from 'services/sentry-service';
import { ToastService } from 'services/toast-service';

import {
  CLASS_ALERT as CLASS_ALERT_BASE,
  CLASS_HIDE,
  CLASS_MODAL as CLASS_MODAL_BASE,
  ModalBaseRating
} from '../modal-base-rating/modal-base-rating';

const DEFAULT_ERROR_MESSAGE = 'Sorry, there was a problem. Please contact us if you are unhappy via chat or email.';

@Component({
  selector: 'mood-sampling-modal',
  templateUrl: './modal-mood-sampling.component.html',
  styleUrls: ['./modal-mood-sampling.component.scss']
})
export class ModalMoodSampling extends ModalBaseRating {
  public static CLASS_ALERT = `${CLASS_ALERT_BASE} mood-sampling-alert`;
  public static CLASS_HIDE = CLASS_HIDE;
  public static CLASS_MODAL = `${CLASS_MODAL_BASE} mood-sampling-modal`;

  @Input() negativeIcon?: string;
  @Input() negativeAnswer?: string;
  @Input() sampleId: string;
  @Input() positiveIcon?: string;
  @Input() positiveAnswer?: string;
  @Input() question: string;
  @Input() title?: string;
  @Input() isIntervention: boolean;

  public hasSubmitted = false;

  protected readonly MODAL_NAME = 'Mood Sampling Modal';

  constructor(
    private alertService: AlertService,
    protected analyticsService: AnalyticsService,
    private errorService: ErrorService,
    protected modalController: ModalController,
    private moodSamplingService: MoodSamplingService,
    private sentryService: SentryService,
    private toastService: ToastService
  ) {
    super();
  }

  public async modalDidLeave(): Promise<void> {
    // Will be called to dismiss the modal after a regular submit as well
    // as when the user dismisses the modal themselves.
    if (!this.hasSubmitted) {
      try {
        if (this.isIntervention) {
          await this.submitInterventionSample(WorkerInterventionResponse.Dismissed);
        } else {
          await this.submitMoodSample(WorkerMood.Dismissed);
        }
      } catch (error) {
        this.sentryService.log({
          message: 'Mood sample dismiss failed',
          level: 'error',
          extras: { error }
        });
      }
    }
  }

  /**
   * Submit the rating and associated information.
   */
  public async submitRating(): Promise<void> {
    // Mark the mood as submitted so no further submissions can be made
    this.hasSubmitted = true;

    try {
      if (this.isIntervention) {
        await this.submitInterventionSample();
      } else {
        await this.submitMoodSample();
      }
      this.dismiss();
      const alert = await this.alertService.create({
        trackingName: `${this.isIntervention ? 'Intervention' : 'Mood'} Sample Modal: Submitted`,
        header: 'Submitted ✅',
        subHeader: 'Thank you',
        buttons: ['OK']
      });
      await alert.present();
    } catch (error) {
      this.dismiss();
      const message = this.errorService.getErrorMessage(error, DEFAULT_ERROR_MESSAGE);
      if (message) {
        this.toastService.showToast({ message, theme: 'danger' });
      }
    }
  }

  private submitMoodSample(mood?: WorkerMood): Promise<WorkerMood> {
    return this.moodSamplingService.submitMoodSample(this.sampleId, mood ? mood : this.ratingToMood());
  }

  private submitInterventionSample(response?: WorkerInterventionResponse): Promise<WorkerInterventionResponse> {
    return this.moodSamplingService.submitInterventionSample(
      this.sampleId,
      response ? response : this.ratingToResponse()
    );
  }

  private ratingToMood(): WorkerMood {
    return this.isRatingGood() ? WorkerMood.Good : WorkerMood.Bad;
  }

  private ratingToResponse(): WorkerInterventionResponse {
    return this.isRatingGood() ? WorkerInterventionResponse.Yes : WorkerInterventionResponse.No;
  }
}

export interface ModalMoodSamplingInputs extends MoodSampleCampaign {
  sampleId: string;
  isIntervention: boolean;
}
