import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { AppContextService } from 'services/app-context-service';
import { AuthService } from 'services/auth-service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  private isFirstNavigation = true;

  constructor(private appContextService: AppContextService, private authService: AuthService, private router: Router) {}

  public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    let canActivate = true;
    await this.authService.authStateReady();
    if (!this.authService.isAuthenticated) {
      // Redirect to the login page, unless the app was launched using a deeplink or push and this is the first navigation
      if (this.appContextService.isNormalContext() || !this.isFirstNavigation) {
        this.router.navigate(['/app-housekeepers/login'], { queryParams: { redirectTo: state.url } }).then();
      }
      canActivate = false;
    }
    this.isFirstNavigation = false;
    return canActivate;
  }
}
