<div
  *ngIf="job"
  class="visit-block visit-block--extra-jobs visit-block--regular-job"
  [class.visit-block--outside-area]="outsideArea"
  [class.locked]="locked"
>
  <ion-grid class="ion-no-padding">
    <ion-row>
      <ion-col size="auto">
        <p class="strong">{{ job.nextVisitDate | asDay }}</p>
        <p>{{ job.timeSlot.startTimeDisplay() }}</p>
        <p>
          <hk-wc-icon
            *ngIf="job.requiresProducts"
            name="spray-bottle"
            size="20"
          ></hk-wc-icon>
          + {{ job.requiredDuration.toHours() | asHours }} hours
        </p>
      </ion-col>

      <ion-col class="ion-text-right">
        <p>
          <hk-wc-icon name="location-pin" size="20"></hk-wc-icon>
          <strong>{{ job.property.postcode.full }}</strong>
        </p>
        <p>{{ areaDisplayName }}</p>
        <p class="text--capitalize">
          {{ job.frequencyDisplay }}
        </p>
      </ion-col>
    </ion-row>

    <ion-row *ngIf="extraJobAppSignalEnabled && appSignalDaysLookaheadHours">
      <ion-col class="ion-text-center" *ngIf="assignableJob.isWithinExtraJobsSignal">
        <ion-badge class="icon-badge" color="warning">
          <hk-wc-icon name="timer" size="16"></hk-wc-icon>
          <span>{{ appSignalDaysLookaheadHours }} hr</span>
        </ion-badge>
      </ion-col>
    </ion-row>

    <ion-row class="ion-margin-top" *ngIf="showActions">
      <ion-col>
        <ion-button
          size="small"
          [color]="locked ? 'medium' : 'primary'"
          (click)="openExtraJobDetail()"
        >
          See Details
          <ion-icon name="arrow-forward" slot="end"></ion-icon>
        </ion-button>
      </ion-col>
      <ion-col class="ion-text-right">
        <ion-button
          size="small"
          [color]="locked ? 'medium' : 'primary'"
          (click)="doAdd()"
        >
          Add
          <ion-icon name="add" slot="end"></ion-icon>
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>
