import { Date, model, Model } from '@housekeep/infra';

export interface WorkerDailyStats extends Model {
  date: Date;
  visitCount: number;
  visitsAffectedLate: number;
}

export const workerDailyStats: WorkerDailyStats = model.extend({
  modelName: 'workerDailyStats'
});
