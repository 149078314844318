import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Visit } from '@housekeep/infra';

@Component({
  selector: 'hk-extra-jobs-visit',
  templateUrl: './hk-extra-jobs-visit.component.html',
  styleUrls: ['./hk-extra-jobs-visit.component.scss']
})
export class HkExtraJobsVisit {
  @Input() public visit: Visit;
  @Input() public showActions: boolean = true;
  @Input() public appSignalDaysLookaheadHours: number;
  @Input() public extraJobAppSignalEnabled: boolean;
  @Input() public outsideArea: boolean = false;
  @Output() public onAdd: EventEmitter<null> = new EventEmitter();
  @Output() public moreDetails: EventEmitter<null> = new EventEmitter();

  public areaDisplayName: string;

  public ngOnInit(): void {
    this.areaDisplayName = this.visit.property.postcode.area.extraJobAreaDisplayName;
  }

  public doAdd(): void {
    this.onAdd.emit(null);
  }

  public openExtraJobDetail(): void {
    this.moreDetails.emit(null);
  }
}
