import { DemoEndpoint } from '../../../demo-service';

const cancelCallRequestsEndpoint: DemoEndpoint = {
  path: 'cancel-call-requests',
  handlers: [
    {
      method: 'post',
      handle() {
        return {
          success: true
        };
      }
    }
  ]
};

export { cancelCallRequestsEndpoint };
