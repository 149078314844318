import { HttpErrorResponse } from '@angular/common/http';

import { DemoEndpoint } from '../../../demo-service';

const requestCustomerServiceChatTokenEndpoint: DemoEndpoint = {
  path: 'request-customer-service-chat-token',
  handlers: [
    {
      method: 'get',
      handle() {
        throw new HttpErrorResponse({
          status: 403,
          statusText: 'Forbidden'
        });
      }
    }
  ]
};

export { requestCustomerServiceChatTokenEndpoint };
