import { extend } from 'lodash-es';

import {
  dateField,
  ModelSerializer,
  modelSerializer,
  Serializer,
  serializer,
  serializerField,
  timeField,
  workerSerializer
} from '@housekeep/infra';

import { AvailabilityPeriod } from 'models/mixin.availability';
import { workerAvailability, WorkerAvailability } from 'models/worker-availability';
import { workerAvailabilityOverride, WorkerAvailabilityOverride } from 'models/worker-availability-override';
import { workerBreak, WorkerBreak } from 'models/worker-break';

import { noticeCategoryField } from './worker-break-effects';

/**
 * Serialize an availability period (given by start and end times)
 */
export const availabilitySerializer = serializer.extend({
  fields: {
    start: timeField.create(),
    end: timeField.create()
  }
}) as Serializer<AvailabilityPeriod>;

export const availabilityBaseFields = {
  availability: serializerField.create({
    serializer: availabilitySerializer,
    serializerKwargs: { many: true }
  }),
  worker: serializerField.create({
    optional: true,
    serializer: workerSerializer
  })
};

/**
 * Serialize a worker's regular availability.
 */
export const workerAvailabilitySerializer = modelSerializer.extend({
  model: workerAvailability,
  fields: availabilityBaseFields
}) as ModelSerializer<WorkerAvailability>;

/**
 * Serialize a worker's regular availability.
 */
export const workerAvailabilityOverrideSerializer = modelSerializer.extend({
  model: workerAvailabilityOverride,
  fields: extend(
    {
      day: dateField.create()
    },
    availabilityBaseFields
  )
}) as ModelSerializer<WorkerAvailabilityOverride>;

/**
 * Serializer a worker's availability on a specific day.
 */
export const workerBreakSerializer = modelSerializer.extend({
  model: workerBreak,
  fields: extend(
    {
      end_date: dateField.create(),
      start_date: dateField.create(),
      notice_category: noticeCategoryField.create()
    },
    workerAvailabilitySerializer.fields
  )
}) as ModelSerializer<WorkerBreak>;
