import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';

import { Subscription } from 'rxjs';

import { TwilioChatService } from '@housekeep/hk-chat-client';
import { Visit } from '@housekeep/infra';

import { ChatInfoService } from 'services/chat-info-service';
import { ScheduleService } from 'services/schedule-service';

@Component({
  selector: 'visit-unread-chats',
  templateUrl: './visit-unread-chats.component.html',
  styleUrls: ['./visit-unread-chats.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VisitUnreadChatsComponent implements OnInit, OnDestroy {
  @Input() displayPrimary = true;
  @Input() iconSize = 28;
  @Input() showAlways = true;
  @Input() visit: Visit;

  public isFirstVisitForCustomer: boolean;
  public unread: number;

  private subscription: Subscription;

  constructor(
    private chatService: TwilioChatService,
    private chatInfoService: ChatInfoService,
    private cdRef: ChangeDetectorRef,
    private scheduleService: ScheduleService
  ) {}

  public ngOnInit(): void {
    this.chatService
      .connect()
      .then(() => {
        this.chatInfoService.getChatUniqueName(this.visit).then((name: string) => {
          this.subscription = this.chatService.getUnreadMessagesObservable(name).subscribe(unread => {
            this.unread = unread;
            this.isFirstVisitForCustomer = this.scheduleService.isVisitEarliestForCustomer(this.visit);
            this.cdRef.markForCheck();
          });
        });
      })
      .catch(() => undefined);
  }

  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
