import { range } from 'lodash-es';

import { DemoEndpoint } from '../../../demo-service';
import { getWorkingDayData } from '../../util';

const workingDaysEndpoint: DemoEndpoint = {
  path: 'working-days',
  handlers: [
    {
      method: 'get',
      handle(state) {
        const today = state.today();

        return range(28).map(dayCount => {
          const date = today.clone().add(dayCount, 'days');
          return getWorkingDayData(state, date);
        });
      }
    }
  ]
};

export { workingDaysEndpoint };
