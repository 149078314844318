import { Component, EventEmitter, Input, Output } from '@angular/core';

import { CustomersAndKeys } from 'models/customers-and-keys';

@Component({
  selector: 'customer-key-table',
  templateUrl: './customer-key-table.component.html',
  styleUrls: ['./customer-key-table.component.scss']
})
export class CustomerKeyTable {
  @Input() public customerKeyEntries: CustomersAndKeys[];
  @Input() public clickable: boolean;
  @Output() public clickCustomerKey: EventEmitter<CustomersAndKeys> = new EventEmitter();

  public canClick(customerKeyEntry: CustomersAndKeys): boolean {
    return this.clickable && (customerKeyEntry.finishedVisitsCount > 0 || this.isRemoteTransfer(customerKeyEntry));
  }

  public onClick(customerKeyEntry: CustomersAndKeys): void {
    this.clickCustomerKey.emit(customerKeyEntry);
  }

  private isRemoteTransfer(customerKeyEntry: CustomersAndKeys): boolean {
    return customerKeyEntry.key && customerKeyEntry.key.remoteKeyTransferInProgress;
  }
}
