import { Injectable } from '@angular/core';

import { Platform } from '@ionic/angular';

import { Device } from '@awesome-cordova-plugins/device/ngx';
import {
  InAppBrowser,
  InAppBrowserEvent,
  InAppBrowserObject,
  InAppBrowserOptions
} from '@awesome-cordova-plugins/in-app-browser/ngx';

import { noop } from 'lodash-es';

import { take } from 'rxjs/operators';

import { ColorPrimary, ColorWhite } from '@housekeep/design-tokens';

import { AnalyticsService } from './analytics-service';
import { DeviceService } from './device-service';

const HELP_CENTRE_HOSTNAME = 'housekeep.zendesk.com';
const HELP_CENTRE_URL = `https://${HELP_CENTRE_HOSTNAME}/hc/en-gb/categories/115000169471-For-Housekeepers`;
// Paths that should be redirected back to HELP_CENTRE_URL
const HELP_CENTRE_REDIRECTS = [
  /\/profiles\//, // staff profiles
  /^\/hc\/en-gb$/, // Zendesk homepage
  /^\/hc\/en-gb\/categories\/115000168752-For-Customers/ // Customer homepage
];
// "Submit a request" links in Zendesk all point to the Members' Area help page
const MEMBERS_AREA_HELP_URL = 'https://housekeep.com/members/help/';

const HOUSEKEEP_HOSTNAME = 'housekeep.com';
const APP_PATH_PREFIX = '/app-housekeepers/';

@Injectable({ providedIn: 'root' })
export class InAppBrowserConfigService {
  constructor(
    private analyticsService: AnalyticsService,
    private device: Device,
    private deviceService: DeviceService,
    private platform: Platform
  ) {}

  /**
   * Configure the in-app browser, with special config if it's
   * navigating to the help centre.
   *
   * @param iab an injected instance of InAppBrowser
   * @param targetUrl URL to navigate to
   * @param target '_blank' to open an IAB; or '_system' to open the system browser
   * @param exitCallback run callback function on browser exit
   */
  public configureBrowser(
    iab: InAppBrowser,
    targetUrl: string,
    target?: '_blank' | '_system',
    exitCallback?: () => void | Promise<void>
  ): InAppBrowserObject {
    if (!target) {
      target = this.deviceService.isBrowser() ? '_system' : '_blank';
    }

    if (targetUrl === HELP_CENTRE_URL && target !== '_system') {
      return this.configureBrowserForHelpCentreLink(iab, noop, noop);
    } else {
      return this.coreConfig(iab, targetUrl, target, exitCallback);
    }
  }

  /**
   * Apply standard config plus customisations for the Housekeep marketing site and blog.
   */
  public configureBrowserForWebsiteLink(iab: InAppBrowser, url: string): InAppBrowserObject {
    const browser = this.coreConfig(iab, url, '_blank');

    this.onEvent(browser, 'loadstop', () => {
      browser.insertCSS({ code: `body { padding-top: 0 !important; }` });
      browser.insertCSS({
        code: '.#blog .entry-header a { font-size: 1.4rem !important; }'
      });
      browser.insertCSS({
        code: '.banner--default, .page-banner--default { margin-top: 0px !important; }'
      });
      browser.insertCSS({ code: '.breadcrumb { display: none !important; }' });
      browser.insertCSS({ code: '#housekeep-navbar { display: none !important; }' });
      browser.insertCSS({ code: '.page-banner--footer { display: none !important; }' });
      browser.insertCSS({ code: '#sidebar { display: none !important; }' });
      browser.insertCSS({ code: 'section.get-started { display: none !important; }' });
      browser.insertCSS({ code: 'footer { display: none !important; }' });
      browser.insertCSS({
        code: 'iframe:not([src*="youtube.com"]) { display: none !important; }'
      });
    });
    this.onEvent(browser, 'loadstart', event => {
      if (new URL(event.url).hostname !== HOUSEKEEP_HOSTNAME) {
        browser.close();
      }
    });

    return browser;
  }

  /**
   * Apply standard config plus customisations for the help centre.
   *
   * @param iab an injected instance of InAppBrowser
   * @param handleDeeplink called if an app deeplink is opened
   * @param submitHelpRequestCallback called if the user submits a help request
   * @param url optional Help Centre URL to open (defaults to Help Centre homepage)
   */
  public configureBrowserForHelpCentreLink(
    iab: InAppBrowser,
    handleDeeplink: (path: string) => void,
    submitHelpRequestCallback: () => void,
    url?: string
  ): InAppBrowserObject {
    const browser = this.coreConfig(iab, url || HELP_CENTRE_URL, '_blank');

    this.onEvent(browser, 'loadstop', () => {
      browser.insertCSS({
        code: `
          main {
            padding-bottom: 30px !important;
          }

          .header, .footer, ol.breadcrumbs > li:first-child, .article-more-questions,
          .section-subscribe, iframe {
            display: none !important;
          }`
      });
    });

    this.onEvent(browser, 'loadstart', event => {
      const url = new URL(event.url);

      if (url.toString() === MEMBERS_AREA_HELP_URL) {
        // Intercept "Submit a request" links
        browser.close();
        submitHelpRequestCallback();
      } else if (url.hostname === HOUSEKEEP_HOSTNAME && url.pathname.startsWith(APP_PATH_PREFIX)) {
        // Intercept app deeplinks (stripping any trailing slash from the URL)
        browser.close();
        handleDeeplink(url.pathname.replace(/\/$/, '') + url.search);
      } else if (url.hostname !== HELP_CENTRE_HOSTNAME) {
        // If any other non-Zendesk URL is requested, open the link in the system browser
        window.open(event.url, '_system');
        // Then close the IAB (as we can't easily stop it also loading the URL)
        browser.close();
      } else {
        // Redirect certain Zendesk URLs back to the Housekeeper homepage
        for (const pattern of HELP_CENTRE_REDIRECTS) {
          if (url.pathname.match(pattern)) {
            browser.executeScript({
              code: `window.location = "${HELP_CENTRE_URL}";`
            });
            break;
          }
        }
      }
    });

    return browser;
  }

  /**
   * Standard config for the in-app browser.
   *
   * @param iab an injected instance of InAppBrowser
   * @param targetUrl URL to navigate to
   * @param target '_blank' to open an IAB; or '_system' to open the system browser
   * @param exitCallback run callback function on browser exit
   */
  private coreConfig(
    iab: InAppBrowser,
    targetUrl: string,
    target: '_blank' | '_system',
    exitCallback?: () => void | Promise<void>
  ): InAppBrowserObject {
    const options: InAppBrowserOptions = {
      closebuttoncaption: '< Back',
      closebuttoncolor: ColorWhite,
      location: 'no'
    };
    if (this.platform.is('ios')) {
      options.toolbarcolor = ColorPrimary;
      options.toolbartranslucent = 'no';
    } else {
      options.footer = 'yes';
      options.footercolor = ColorPrimary;
      options.zoom = 'no';
    }

    const browser = iab.create(targetUrl, target, options);
    this.onEvent(browser, 'loadstart', async (event: InAppBrowserEvent) => {
      await this.analyticsService.trackExternalPage(event.url);
    });
    this.onEvent(browser, 'exit', async () => {
      await exitCallback?.();
    });
    return browser;
  }

  /**
   * Convenience method to listen for the specified type of browser event and unsubscribe when the browser closes.
   */
  private onEvent(
    browser: InAppBrowserObject,
    event: 'loadstart' | 'loadstop' | 'exit',
    next: (event: InAppBrowserEvent) => void | Promise<void>
  ): void {
    const event$ = browser.on(event);
    // Check event$ is an observable (it won't be when the app is run in a browser in development)
    if (event$ && event$.subscribe) {
      const subscription = event$.subscribe(next);
      browser
        .on('exit')
        .pipe(take(1))
        .subscribe(() => subscription.unsubscribe());
    }
  }
}
