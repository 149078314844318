import { Component, Input } from '@angular/core';

@Component({
  selector: 'hk-accordion-block',
  templateUrl: 'hk-accordion-block.component.html',
  styleUrls: ['./hk-accordion-block.component.scss']
})
export class HkAccordionBlock {
  @Input() title: string;
  @Input() isOpen: boolean = false;

  public toggle(): void {
    this.isOpen = !this.isOpen;
  }
}
