import { FinishedEarlyReasons, toMoment, Visit, visitSerializer } from '@housekeep/infra';

import { DemoEndpoint } from 'services/demo-service';

import { getVisitData, getWorkingDayData } from '../../../util';

const visitFinishEndpoint: DemoEndpoint = {
  path: 'finish',
  handlers: [
    {
      method: 'patch',
      handle(state, path, parameters, headers, body) {
        // Get the job ID and visit date from the path.
        const pathMatch = path.match(/.*\/jobs\/([a-z0-9]+)\/visits\/(\d\d\d\d\-\d\d\-\d\d)/);
        const [, jobId, visitDateString] = pathMatch;
        // Get the visit.
        const visitData = getVisitData(state, visitDateString, jobId);

        // Deserialize the visit to get some extra functionality.
        const visit: Visit = visitSerializer.deserialize(visitData);

        // Finish the job, and calculate the initial and payable hours.
        const intendedHours = visit.requiredDuration.toHours();
        visit.finish(state.now());
        const payableHours = state.visitDurationService.currentPayableHours(visit);

        // Handle a worker not working all the required hours.
        if (intendedHours !== payableHours) {
          /* Reduce the task durations. */

          // Calculate the hours by which to reduce the tasks.
          let hoursToReduce = intendedHours - payableHours;

          // Change the length of each task to match the payable hours.
          visit.tasks.some(task => {
            const reductionHours = Math.min(task.hours, hoursToReduce);
            task.hours = task.hours - reductionHours;
            hoursToReduce -= reductionHours;
            return hoursToReduce === 0;
          });

          // Delete any tasks that now have 0 hours.
          visit.tasks = visit.tasks.filter(task => task.hours !== 0);

          /* Adjust the working-day activities. */

          // Get the working day.
          const workingDay = getWorkingDayData(state, visitDateString);

          // Get the activity for the visit.
          let visitActivityIndex;
          const visitActivity = workingDay.activities.find((activity, index) => {
            if (activity.visit__job_id === jobId) {
              visitActivityIndex = index;
              return true;
            }
          });

          // Update the activity end time.
          const originalEnd = visitActivity.end;

          visitActivity.end = toMoment(visitActivity.start).add(payableHours, 'hours').format();

          // Update the activity duration.
          const originalSeconds = visitActivity.seconds;
          visitActivity.seconds = payableHours * 3600;

          // Make a new idle period for the remaining time.
          const newIdlePeriod = {
            seconds: originalSeconds - visitActivity.seconds,
            start: visitActivity.end,
            end: originalEnd,
            type: 'IdlePeriod'
          };

          // Add it to the day's activities
          workingDay.activities.splice(visitActivityIndex + 1, 0, newIdlePeriod);

          // Update the state with the new working day.
          state.workingDays[visitDateString] = workingDay;
        }

        // Serialise the visit again, and update the state with the visit.
        const serializedVisit = visitSerializer.serialize(visit);
        state.visits[`${jobId}:${visitDateString}`] = serializedVisit;

        return serializedVisit;
      }
    }
  ]
};

export { visitFinishEndpoint };
