<div
  *ngIf="equipmentLocations.length === 0"
  [ngSwitch]="visit.access"
  class="text--muted"
>
  <span *ngSwitchCase="PROPERTY_ACCESS_AT_HOME">No details provided, ask customer.</span>
  <span *ngSwitchDefault>No details provided.</span>
</div>
<ul *ngIf="equipmentLocations.length > 0" class="equipment">
  <li *ngFor="let equipment of equipmentLocations" class="equipment-item">
    <span class="equipment-item-label">
      {{ equipment.label }}
    </span>
    <span
      class="equipment-item-value"
      [class.changes]="visitChanges && visitChanges.hasPropertyChanges(equipment.key)"
    >
      {{ equipment.location | capitaliseSentence }}
    </span>
  </li>
</ul>
