import { DemoEndpoint } from 'services/demo-service';

import { WORKER_RATING_ISSUES } from '../../../data/worker-rating-issues';

const issuesEndpoint: DemoEndpoint = {
  path: 'worker/issues',
  handlers: [
    {
      method: 'get',
      handle(state) {
        return WORKER_RATING_ISSUES;
      }
    }
  ]
};

export { issuesEndpoint };
