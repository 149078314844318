import { addDaysToDate, toDate } from '@housekeep/infra';

import { DemoEndpoint } from '../../../demo-service';
import { getWorkingDayData } from '../../util';

function getRequiredDurationFromVisit(visit) {
  return visit.tasks.length === 1 && ['collect', 'return-keys'].includes(visit.tasks[0].type)
    ? 1
    : visit.tasks.map(task => task.hours || 0).reduce((a, b) => a + b, 0);
}

function getWorkerBreakEffects(parameters, state) {
  const startDate = toDate(parameters.get('start_day'));
  const endDate = toDate(parameters.get('end_day'));

  const today = state.today();
  const tomorrow = addDaysToDate(today, 1);

  let noticeCategory;
  let affectsReliability = false;

  if (startDate.isSame(today)) {
    noticeCategory = 'late';
  } else if (startDate.isSame(tomorrow)) {
    noticeCategory = 'short';
  } else {
    noticeCategory = 'good';
  }

  const keyReturnThreshold = 14;
  const keyReturnRequired = endDate.diff(startDate, 'd') > keyReturnThreshold;

  let totalAffectedHours = 0;

  // Build up the array of affected visits across all booked dates
  const affectedVisits = [];
  const day = startDate.clone();
  while (day.isSameOrBefore(endDate)) {
    const visitsOnDay = getWorkingDayData(state, day)
      .activities.filter(activity => activity.type === 'WorkPeriod')
      .map(activity => activity.visit);

    for (const visit of visitsOnDay) {
      const visitDate = toDate(visit.actual_date);

      const requiredDuration = getRequiredDurationFromVisit(visit);
      totalAffectedHours += requiredDuration;

      let fine, visitNoticeCategory;
      if (visitDate.isSame(today)) {
        visitNoticeCategory = 'late';
        fine = 10.0;
        affectsReliability = true;
      } else if (visitDate.isSame(tomorrow)) {
        visitNoticeCategory = 'short';
        fine = 5.0;
      } else {
        visitNoticeCategory = 'good';
        fine = 0.0;
      }

      affectedVisits.push({
        fine,
        notice_category: visitNoticeCategory,
        required_duration: requiredDuration,
        customer_preferred_name: visit.account.initial_customer.preferred_name,
        postcode: visit.property.postcode,
        visit_date: visit.actual_date
      });
    }

    day.add(1, 'day');
  }

  return {
    fines_are_waived: false,
    key_return_threshold: keyReturnThreshold,
    key_return_required: keyReturnRequired,
    notice_category: noticeCategory,
    key_return_pay: 8.5,
    reliability: affectsReliability ? 0.8 : 0.98,
    requires_ops: false,
    affected_visits: affectedVisits,
    // NB this is not a field in the real API response but is included here for ease of use below
    total_affected_hours: totalAffectedHours
  };
}

const workerBreaksEndpoint: DemoEndpoint = {
  path: 'breaks',
  handlers: [
    {
      method: 'get',
      handle(state, path, parameters) {
        return getWorkerBreakEffects(parameters, state);
      }
    },
    {
      method: 'post',
      handle(state, path, parameters) {
        let availability = parameters.get('availability');
        if (availability) {
          availability = JSON.parse(availability).map(avail => {
            return {
              start: `${avail[0]}:00`,
              end: `${avail[1]}:00`
            };
          });
        }

        const effects = getWorkerBreakEffects(parameters, state);

        if (!effects.requires_ops) {
          state.workerAvailabilityData.push({
            start_date: parameters.get('start_day'),
            end_date: parameters.get('end_day'),
            notice_category: effects.notice_category,
            reason: parameters.get('reason'),
            availability: availability || [],
            requires_ops: effects.requires_ops,
            total_affected_cleans: effects.affected_visits.length,
            total_affected_hours: effects.total_affected_hours
          });
        }

        return effects;
      }
    }
  ]
};

export { workerBreaksEndpoint };
