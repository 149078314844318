<ion-card
  class="ion-margin-vertical"
  [class.danger]="tappable && metrics?.fulfilmentState == FULFILMENT_STATES.Low"
  [class.tappable]="tappable"
  (click)="tap.emit()"
>
  <ion-card-content>
    <ion-grid class="grid--negative-margin">
      <ion-row class="ion-align-items-center">
        <ion-col size="4">
          <div class="progress">
            <hk-round-progress
              [current]="fulfilmentValues.rate"
              [max]="1"
              [color]="fulfilmentValues.color"
              [stroke]="25"
              [responsive]="true"
              [upperTarget]="false"
              [background]="
                tappable && metrics?.fulfilmentState === FULFILMENT_STATES.Low
                  ? COLOR_WHITE
                  : COLOR_GREY
              "
            >
              <span metric-value class="metric-small">
                {{
                  metrics?.fulfilmentState
                    ? metrics?.fulfilmentState === FULFILMENT_STATES.None
                      ? FULFILMENT_STATES.Good
                      : metrics?.fulfilmentState
                    : '--'
                }}
              </span>
            </hk-round-progress>
          </div>
        </ion-col>
        <ion-col class="mar-y-0 mar-l-10">
          <ng-container *ngIf="tappable">
            <ion-card-title class="ion-no-padding">
              {{ fulfilmentValues.title }}
            </ion-card-title>
            <p>
              {{ fulfilmentValues.content }}
            </p>
          </ng-container>
          <ng-container *ngIf="!tappable">
            <ion-card-title class="ion-no-padding">In the last 60 days</ion-card-title>
            <p>{{ metrics.visitCount }} attended jobs</p>
            <p>{{ metrics.totalVisits - metrics.visitCount }} unattended jobs</p>
            <p>{{ metrics.timeOffCount }} time off bookings</p>
          </ng-container>
        </ion-col>
        <ion-col *ngIf="tappable" size="auto" class="tap-icon">
          <ion-icon name="chevron-forward"></ion-icon>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-card-content>
</ion-card>
