import { Area, Date, Job, Visit } from '@housekeep/infra';

// Error code raised when an outdated request resolves
const ERR_OLD_REQUEST = 'old_request';

// Configuration for retrieving jobs
const CONFIG = {
  BATCH_LIMIT: 5,
  FIRST_DAY_FROM_TODAY: 0,
  LAST_DAY_FROM_TODAY: 14
};

// Surrounding areas "contexts"
// Passed in the request to allow the server to control the distance.
const SURROUNDING_CONTEXT = {
  ONE_OFF: 'one-off-visits',
  REGULAR: 'regular-jobs'
};

/**
 * Match the outward postcode of the visit's property to an area.
 */
function findArea(visitOrJob: Visit | Job, areas: Area[]): Area {
  const outwardPostcode = visitOrJob.property.postcode.outward;
  return areas.find(area => outwardPostcode.startsWith(area.code));
}

/**
 * Return the first and last valid dates for extra one-off jobs.
 */
function validOneOffDateRange(today: Date): [Date, Date] {
  return [
    today.clone().add(CONFIG.FIRST_DAY_FROM_TODAY, 'days'),
    today.clone().add(CONFIG.LAST_DAY_FROM_TODAY, 'days')
  ];
}

/**
 * Return a new Error to signify that the request is out of date.
 *
 * These errors are sinkholed by the requester, and allow additional requests
 * to be terminated given a change to the extra-jobs frequency / one-off week etc.
 */
function createOldRequestError(): Error {
  return new Error(ERR_OLD_REQUEST);
}

function isOldRequestError(err: any): boolean {
  return !!(err && err.message === ERR_OLD_REQUEST);
}

export { createOldRequestError, isOldRequestError, findArea, validOneOffDateRange, CONFIG, SURROUNDING_CONTEXT };
