import { Component } from '@angular/core';

import { AnalyticsService } from 'services/analytics-service';

/**
 * Abstract class to handle core functionality for all app page components.
 * FIXME public/protected access modifiers
 *
 * Requires the child class to provide:
 * - AnalyticsService as analyticsService
 */
@Component({
  template: ''
})
export abstract class PageBase {
  public isActive: boolean; // whether view entered and did not leave
  protected analyticsService: AnalyticsService;
  protected abstract PAGE_NAME: string;

  ngOnInit() {
    this.pageDidLoad();
  }

  pageDidLoad() {
    // will be overridden by child views
  }

  ionViewWillEnter() {
    this.pageWillEnter();
  }

  pageWillEnter() {
    // will be overridden by child views
  }

  ionViewDidEnter() {
    this.isActive = true;
    this.analyticsService.trackPage(this.PAGE_NAME);
    this.pageDidEnter();
  }

  pageDidEnter() {
    // will be overridden by child views
  }

  ionViewWillLeave() {
    this.pageWillLeave();
  }

  pageWillLeave() {
    // will be overridden by child views
  }

  ionViewDidLeave() {
    this.isActive = false;
    this.pageDidLeave();
  }

  pageDidLeave() {
    // will be overridden by child views
  }
}
