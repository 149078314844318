import { DateTime, defineModelProperties, Duration, Model, model, Time, toTime, Visit } from '@housekeep/infra';

interface Clash {
  start: Time;
  end: Time;
}

interface PeriodMixin {
  duration: Duration;
  startTime: Time;
  endTime: Time;
  isEndOfDay: () => boolean;
  expectedStartTime: Time;
}

export interface Period extends Model, PeriodMixin {
  type: PeriodType;
  changes?: boolean;
  clash?: Clash;
  end: DateTime;
  start: DateTime;
  latestConstrainedStartDt?: DateTime;
  visit?: Visit;
  seconds: number;
}

export enum PeriodType {
  Break = 'BreakPeriod',
  Idle = 'IdlePeriod',
  Travel = 'TravelPeriod',
  Work = 'WorkPeriod'
}

export const period: Period = model.extend({
  modelName: 'period',

  construct(data) {
    Object.assign(this, data);

    this.startTime = toTime(this.start);
    this.endTime = toTime(this.end);

    // If there's a clash then this period should actually start earlier
    this.expectedStartTime = this.clash ? toTime(this.clash.start) : this.startTime;
  },

  isEndOfDay() {
    return this.endTime.format('HH:mm:ss') === '23:59:59';
  }
});

defineModelProperties<Period>(period, {
  duration: {
    get() {
      return new Duration({ milliseconds: this.endTime.diff(this.startTime) });
    }
  }
});
