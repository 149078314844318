import { WorkerRatingIssue, WorkerVisitRatingChoice } from 'models/worker-visit-rating';

const ratings = [WorkerVisitRatingChoice.Bad, WorkerVisitRatingChoice.Okay];

export const WORKER_RATING_ISSUES = [
  {
    ratings,
    issue: WorkerRatingIssue.CleaningEquipment,
    issueDisplay: 'Cleaning equipment (e.g. mop, vacuum)'
  },
  {
    ratings,
    issue: WorkerRatingIssue.CleaningProducts,
    issueDisplay: 'Cleaning products (e.g. sprays, cloths)'
  },
  {
    ratings,
    issue: WorkerRatingIssue.NotEnoughTime,
    issueDisplay: 'Not enough time'
  },
  {
    ratings,
    issue: WorkerRatingIssue.NeedsEotClean,
    issueDisplay: 'Needs move in/out clean'
  },
  {
    ratings,
    issue: WorkerRatingIssue.NeedsDeepClean,
    issueDisplay: 'Needs specialist deep clean'
  },
  {
    ratings,
    issue: WorkerRatingIssue.CustomerAttitude,
    issueDisplay: 'Customer attitude'
  },
  {
    ratings,
    issue: WorkerRatingIssue.Access,
    issueDisplay: 'Access'
  },
  {
    ratings,
    issue: WorkerRatingIssue.JobLocation,
    issueDisplay: 'Job location'
  }
];
