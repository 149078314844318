<div class="time-line" [class.time-line--editable]="editable">
  <ng-container *ngIf="editable">
    <div *ngFor="let period of processedPeriods">
      <ion-grid
        *ngIf="showTimeFields"
        class="time-line__time-fields ion-no-padding ion-margin-bottom"
      >
        <ion-row class="ion-align-items-center">
          <ion-col size="5" class="ion-text-right">
            <p>
              <ion-text color="muted"><sub>From</sub></ion-text>
            </p>
            <p>
              <ion-text>
                <strong>{{ period.period.start | asTime }}</strong>
              </ion-text>
            </p>
          </ion-col>
          <ion-col size="2" class="ion-text-center">
            <ion-icon
              name="arrow-forward"
              class="time-line__time-fields__arrow"
            ></ion-icon>
          </ion-col>
          <ion-col size="5">
            <p>
              <ion-label color="muted"><sub>To</sub></ion-label>
            </p>
            <p>
              <ion-text>
                <strong>{{ period.period.end | asTime }}</strong>
              </ion-text>
            </p>
          </ion-col>
        </ion-row>
      </ion-grid>

      <ion-range
        dualKnobs="true"
        snaps="true"
        ticks="false"
        step="1800"
        [min]="dayStart.unix()"
        [max]="dayEnd.unix()"
        [recommended]="
          recommendedStart && recommendedEnd
            ? { lower: recommendedStart.unix(), upper: recommendedEnd.unix() }
            : null
        "
        [value]="period.sliderValues"
        (ionChange)="onChangeRange(period, $event)"
        (ionKnobMoveEnd)="onFinishChangeRange()"
      >
        <div
          *ngIf="recommendedStart && recommendedEnd"
          class="range-recommended"
          slot="start"
        >
          <div
            class="range-label-recommended"
            [style.left]="recommendedLeft + '%'"
            [style.right]="recommendedRight + '%'"
          >
            <div class="range-label-recommended-text">
              {{ recommendedLabel }}
            </div>
          </div>
        </div>
      </ion-range>

      <ion-grid class="time-line__markers-grid">
        <ion-row>
          <ion-col class="ion-no-padding">
            {{ dayStart | asTime }}
          </ion-col>
          <ion-col class="ion-no-padding ion-text-right">
            {{ dayEnd | asTime }}
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </ng-container>

  <ng-container *ngIf="!editable">
    <div class="time-line__periods">
      <div
        *ngFor="let period of processedPeriods"
        class="time-line__period"
        [ngStyle]="{
          left: period.positioning.left + '%',
          width: period.positioning.width + '%'
        }"
      >
        <span
          *ngIf="showMarkers"
          class="time-line__period__marker"
          [class.time-line__period__marker--shift-right]="period.positioning.left === 0"
          [class.time-line__period__marker--shift-left]="
            period.positioning.width + period.positioning.left === 100 &&
            getDuration(period) < 180
          "
        >
          {{ period.start | asTime }}
        </span>
        <span
          *ngIf="showMarkers"
          class="time-line__period__marker time-line__period__marker--end"
          [class.time-line__period__marker--shift-right]="
            period.positioning.left === 0 && getDuration(period) < 180
          "
          [class.time-line__period__marker--shift-left]="
            period.positioning.width + period.positioning.left === 100
          "
        >
          {{ period.end | asTime }}
        </span>
      </div>
      <!--/.time-line__period-->
    </div>
    <!--/.time-line__periods-->

    <ion-grid class="time-line__markers-grid">
      <ion-row>
        <ion-col class="ion-no-padding">
          {{ dayStart | asTime }}
        </ion-col>
        <ion-col class="ion-no-padding ion-text-right">
          {{ dayEnd | asTime }}
        </ion-col>
      </ion-row>
    </ion-grid>
  </ng-container>
</div>
