import { DemoEndpoint } from '../../../demo-service';
import { getPagedRatingData } from '../../util';

export const ratingsEndpoint: DemoEndpoint = {
  path: 'ratings',
  handlers: [
    {
      method: 'get',
      handle(state) {
        // Generate a paged response for all payment periods.
        return getPagedRatingData(state);
      }
    }
  ]
};
