<ion-card
  [class.visit-changed]="hasInstructionChanges"
  *ngIf="!(visit?.isKeysOnlyVisit || visit?.isRktTraveller)"
>
  <ion-card-header>
    <ion-card-title>
      Customer instructions
      <ion-badge *ngIf="hasInstructionChanges" color="warning" class="changed-badge">
        <hk-wc-icon name="recurring-arrow" size="16"></hk-wc-icon>
        Updated
      </ion-badge>
    </ion-card-title>
  </ion-card-header>

  <ion-card-content>
    <div *ngIf="instructions.length === 0" class="text--muted">No details provided.</div>
    <ul *ngIf="instructions.length > 0" class="instructions">
      <li
        *ngFor="let instruction of instructions"
        [class.changes]="visitChanges.hasInstructionChanges(instruction)"
      >
        {{ instruction.instruction }}
      </li>
    </ul>
  </ion-card-content>
</ion-card>
