import { Component, Input } from '@angular/core';

import { combine, DateTime, durationBetweenToReadableString, Visit } from '@housekeep/infra';

import { TimeService } from 'services/time-service';

@Component({
  selector: 'hk-arrival-time',
  templateUrl: './hk-arrival-time.component.html',
  styleUrls: ['./hk-arrival-time.component.scss']
})
export class HkArrivalTime {
  @Input() public visit: Visit;

  public constructor(protected timeService: TimeService) {}

  public isLate(): boolean {
    return this.timeService.now().isAfter(this.latestStartDateTime);
  }

  public humanReadableLateness(): string {
    return durationBetweenToReadableString(this.latestStartDateTime, this.timeService.now());
  }

  /**
   * The timezone-aware latest start datetime of the visit.
   */
  private get latestStartDateTime(): DateTime {
    return combine(this.visit.actualDate, this.visit.timeSlot.end, this.timeService.now().utcOffset());
  }
}
