import { Injectable } from '@angular/core';

import { Area, AreasByStrength, areaSerializer, Duration, RequestOpts } from '@housekeep/infra';

import { CacheService } from './cache-service';
import { ErrorService } from './error-service';
import { RequestService } from './request-service';
import { UserService } from './user-service';

const AREAS_CACHE_KEY = 'AREAS';
const SURROUNDING_AREAS_CACHE_KEY = 'SURROUNDING-AREAS';
const CACHE_DURATION = new Duration({ days: 7 });

export interface WorkingArea {
  [key: string]: number;
}

@Injectable({ providedIn: 'root' })
class AreasService {
  constructor(
    private cacheService: CacheService,
    private errorService: ErrorService,
    private requestService: RequestService,
    private userService: UserService
  ) {}

  public getAreasByStrength(): Promise<AreasByStrength> {
    return this.userService.getUser().then(worker => worker.assignedAreas);
  }

  /**
   * Request the worker's home area.
   */
  public getHomeArea(): Promise<Area | null> {
    return this.userService.getUser().then(worker => {
      if (worker.postcode) {
        return worker.postcode.area || null;
      } else {
        return null;
      }
    });
  }

  /**
   * Request the worker's assigned areas.
   * Attempts to use the cached areas unless the `refresh` parameter is provided.
   */
  public getAreas(refresh: boolean = false): Promise<Area[]> {
    return this._areasFromCacheOrServer(
      () => this._getAreasFromCache(),
      () => this._getAreasFromServer(),
      refresh
    );
  }

  /**
   * Request the areas surrounding the ones given, ordered by closeness.
   * Attempts to use the cached areas unless the `refresh` parameter is provided.
   */
  public getSurroundingAreas(fromAreas: Area[], context: string, refresh: boolean = false): Promise<Area[]> {
    return this._areasFromCacheOrServer(
      () => this._getSurroundingAreasFromCache(fromAreas, context),
      () => this._getSurroundingAreasFromServer(fromAreas, context),
      refresh
    );
  }

  /**
   * Private method to retrieve a list of areas, either from the cache or
   * the server.
   */
  private _areasFromCacheOrServer(fromCacheFn: Function, fromServerFn: Function, refresh: boolean): Promise<Area[]> {
    if (refresh) {
      return fromServerFn();
    } else {
      return fromCacheFn().catch(err => {
        if (this.errorService.isCacheError(err)) {
          return fromServerFn();
        } else {
          throw err;
        }
      });
    }
  }

  private _getAreasFromCache(): Promise<Area[]> {
    return this.cacheService
      .get(AREAS_CACHE_KEY)
      .then(serializedAreas => areaSerializer.deserialize(serializedAreas, { many: true }));
  }

  private _getAreasFromServer(): Promise<Area[]> {
    return this.userService.getUser().then(worker => {
      const endpoint = `workers/${worker.id}/areas/`;
      return this.requestService.getList(endpoint, areaSerializer, {
        cache: {
          key: AREAS_CACHE_KEY,
          duration: CACHE_DURATION
        }
      });
    });
  }

  public getWorkingAreas(): Promise<WorkingArea> {
    return this.userService.getUser().then(worker => {
      const endpoint = `workers/${worker.id}/working-areas/`;
      return this.requestService.get(endpoint);
    });
  }

  private _getSurroundingAreasCacheKey(fromAreas: Area[], context: string) {
    const orderedAreaCodes = fromAreas
      .map(a => a.code)
      .sort()
      .join('-');
    return `${SURROUNDING_AREAS_CACHE_KEY}__${orderedAreaCodes}__${context}`;
  }

  private _getSurroundingAreasFromCache(fromAreas: Area[], context: string): Promise<Area[]> {
    const cacheKey = this._getSurroundingAreasCacheKey(fromAreas, context);
    return this.cacheService
      .get(cacheKey)
      .then(serializedAreas => areaSerializer.deserialize(serializedAreas, { many: true }));
  }

  private _getSurroundingAreasFromServer(fromAreas: Area[], context: string): Promise<Area[]> {
    const requestOpts: RequestOpts = {
      cache: {
        duration: CACHE_DURATION,
        key: this._getSurroundingAreasCacheKey(fromAreas, context)
      },
      params: {
        areas: fromAreas.map(area => area.code).sort(),
        context
      }
    };

    return this.requestService.getList('mapping/surrounding-areas/', areaSerializer, requestOpts);
  }
}

export { AreasService, AREAS_CACHE_KEY, CACHE_DURATION, SURROUNDING_AREAS_CACHE_KEY };
