import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';

import { Subscription } from 'rxjs';

import { TwilioChatService } from '@housekeep/hk-chat-client';
import { RemoteKeyTransfer } from '@housekeep/infra';

import { ChatInfoService } from 'services/chat-info-service';

@Component({
  selector: 'rkt-unread-chats',
  templateUrl: './rkt-unread-chats.component.html',
  styleUrls: ['./rkt-unread-chats.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RktUnreadChats implements OnInit, OnDestroy {
  @Input() transfer: RemoteKeyTransfer;
  @Input() isRktTraveller: boolean;
  @Input() showAlways: boolean = true;
  @Input() iconSize = 28;
  @Input() iconName: 'key-hand' | 'chat' = 'key-hand';

  public unread: number;

  private subscription: Subscription;

  constructor(
    private chatService: TwilioChatService,
    private chatInfoService: ChatInfoService,
    private cdRef: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this.chatService
      .connect()
      .then(() => {
        this.chatInfoService.getRktChatUniqueName(this.transfer, this.isRktTraveller).then((name: string) => {
          this.subscription = this.chatService.getUnreadMessagesObservable(name).subscribe(unread => {
            this.unread = unread;
            this.cdRef.markForCheck();
          });
        });
      })
      .catch(() => undefined);
  }

  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
