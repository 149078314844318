export const SURROUNDING_AREAS = [
  { code: 'N2', distance_km: 0.62213796673, id: '78zp451j5zk6' },
  { code: 'N22', distance_km: 0.91756306658, id: 'dmz2gjcmmok5' },
  { code: 'N8', distance_km: 0.98949866164, id: 'dnb5rlc28o47' },
  { code: 'S1', distance_km: 1.09801957858, id: '59oy7asndoxr' },
  { code: 'S10', distance_km: 1.29596891482, id: '7yzdxqcd8ol2' },
  { code: 'S11', distance_km: 1.43026457169, id: '6jovk3cxrzpe' },
  { code: 'S12', distance_km: 1.72593202461, id: 'ejo8ngceaok3' },
  { code: 'S13', distance_km: 1.72992534095, id: 'gromldc2rbym' },
  { code: 'S14', distance_km: 1.73077737343, id: '6wbky9c6xz7q' },
  { code: 'S15', distance_km: 2.11577408065, id: 'ejo8ngcgaok3' },
  { code: 'S16', distance_km: 2.19771401495, id: 'dnb5rlcl8o47' },
  { code: 'S17', distance_km: 2.27548217203, id: '7yzdxqc35ol2' },
  { code: 'S18', distance_km: 2.31468014887, id: '78zp451dpzk6' },
  { code: 'S19', distance_km: 2.49056203506, id: 'e2z4xjcmqo4v' },
  { code: 'S2', distance_km: 2.50837046036, id: '7yzdxqc55ol2' },
  { code: 'S20', distance_km: 2.61526679584, id: '9vzqmgc7jo6p' },
  { code: 'S21', distance_km: 2.67629896023, id: 'dgzg6qcm5o9w' },
  { code: 'S3', distance_km: 2.77416154683, id: 'lnb7jgc64bem' },
  { code: 'S4', distance_km: 2.95855023109, id: 'dmz2gjcwmok5' },
  { code: 'S5', distance_km: 3.12538373071, id: 'gromldcddbym' },
  { code: 'S6', distance_km: 3.14325570329, id: 'dnb5rlca8o47' },
  { code: 'S7', distance_km: 3.29207703791, id: 'e2z4xjcwqo4v' },
  { code: 'S77', distance_km: 3.34129655174, id: '7yzdxqc65ol2' },
  { code: 'S8', distance_km: 3.34286958477, id: 'nqzxkvcn2oky' },
  { code: 'S81', distance_km: 3.35981353987, id: 'nqzxkvc52oky' },
  { code: 'S9', distance_km: 3.51287381209, id: 'gromldcpqbym' },
  { code: 'S98', distance_km: 3.72535409285, id: 'v3bap4cembem' },
  { code: 'SE1', distance_km: 3.78287603346, id: '7dznj8c8jo5r' },
  { code: 'SE10', distance_km: 3.78946999337, id: '59oy7asxeoxr' },
  { code: 'SE11', distance_km: 3.93284975706, id: 'nqolnyc9db2a' },
  { code: 'SE13', distance_km: 4.0808499986, id: 'gromldc2qbym' },
  { code: 'SE14', distance_km: 4.10506933645, id: '4mxb6plckopy' },
  { code: 'SE15', distance_km: 4.12195906334, id: 'ejo8ngcaaok3' },
  { code: 'SE16', distance_km: 4.2607022083, id: 'vxowkgc8eo2a' },
  { code: 'SE17', distance_km: 4.3380326836, id: '59oy7asleoxr' },
  { code: 'SE21', distance_km: 4.46513141576, id: 'mxb6plcwjopy' },
  { code: 'SE22', distance_km: 4.48329084114, id: 'mxb6plcyjopy' },
  { code: 'SE23', distance_km: 4.48606265836, id: 'e2z4xjc84o4v' },
  { code: 'SE24', distance_km: 4.50671924324, id: 'gkbj4k174b43' },
  { code: 'SE4', distance_km: 4.60952638729, id: '2nqzxkvc9oky' },
  { code: 'SE5', distance_km: 4.98314833927, id: '7dznj8cpjo5r' },
  { code: 'SE8', distance_km: 5.072505469, id: '6wbky9cwxz7q' },
  { code: 'SW1', distance_km: 5.17656459867, id: '9vzqmgcv7o6p' },
  { code: 'SW10', distance_km: 5.18676442011, id: '7dznj8cwlo5r' },
  { code: 'SW11', distance_km: 5.24709704522, id: 'xvz9mgc3lbq7' },
  { code: 'SW12', distance_km: 5.25360244187, id: 'e78zp451kzk6' },
  { code: 'SW13', distance_km: 5.27575766434, id: 'mxb6plc6jopy' },
  { code: 'SW14', distance_km: 5.40792435463, id: 'ejo8ngcdaok3' },
  { code: 'SW15', distance_km: 5.52163199185, id: 'jdnb5rlcmo47' },
  { code: 'SW16', distance_km: 5.54175420717, id: 'dmz2gjclmok5' },
  { code: 'SW1W', distance_km: 5.56652262099, id: 'dgzg6qcg5o9w' },
  { code: 'SW26', distance_km: 5.59335172287, id: 'ejo8ngc4aok3' },
  { code: 'SW3', distance_km: 5.60990686132, id: 'dmz2gjc8mok5' },
  { code: 'SW4', distance_km: 5.75409028706, id: 'rnordvcg3z26' },
  { code: 'SW5', distance_km: 5.90953271173, id: 'dmz2gjcgmok5' },
  { code: 'SW6', distance_km: 5.97407284512, id: 'gromldcqqbym' },
  { code: 'SW7', distance_km: 6.06508261862, id: '7yzdxqcr5ol2' },
  { code: 'SW8', distance_km: 6.1468077731, id: '59oy7asrvoxr' },
  { code: 'SW9', distance_km: 6.17134213216, id: 'v7yzdxqckol2' },
  { code: 'W1', distance_km: 6.31607921655, id: 'nqolnycqdb2a' },
  { code: 'W10', distance_km: 6.41948216589, id: '9v3bap4clbem' },
  { code: 'W11', distance_km: 6.77770544228, id: 'mejo8ngcpok3' },
  { code: 'W12', distance_km: 6.77918127707, id: '2kz3njc2korj' },
  { code: 'W14', distance_km: 6.82403865076, id: 'pe2z4xjc2o4v' },
  { code: 'W2', distance_km: 6.86342972088, id: '7yzdxqc88ol2' },
  { code: 'W6', distance_km: 6.90293358815, id: 'rnordvcw3z26' },
  { code: 'W8', distance_km: 6.9446313995, id: '2dmz2gjc5ok5' },
  { code: 'W9', distance_km: 6.95855288476, id: '9vzqmgcg7o6p' }
];
