<main>
  <ion-card
    class="visit__section visit__section--rkt"
    [class.changes]="hasKeyCollectionChanges"
    *ngIf="this.visit.isRemoteKeyTransfer"
  >
    <ion-card-header class="visit__section__title">
      <ion-card-title>
        <ng-container *ngIf="this.visit.isRktHolder">
          Give keys to a Housekeeper
        </ng-container>
        <ng-container *ngIf="!this.visit.isRktHolder">
          Pick up keys from a Housekeeper
        </ng-container>
        <ion-badge *ngIf="hasKeyCollectionChanges" color="warning" class="changed-badge">
          <hk-wc-icon name="recurring-arrow" size="16"></hk-wc-icon>
          Updated
        </ion-badge>
      </ion-card-title>
    </ion-card-header>
    <ion-card-content>
      <div>
        <p>
          <ng-container *ngIf="!this.visit.isHomeAnchorRemoteKeyTransfer">
            You'll meet
            <span *ngIf="this.visit.remoteKeyTransfers.length === 1">
              another Housekeeper
            </span>
            <span *ngIf="this.visit.remoteKeyTransfers.length > 1">
              other Housekeepers
            </span>
            at
            <span *ngIf="this.visit.isRktTraveller">their</span>
            <span *ngIf="!this.visit.isRktTraveller">this</span>
            job.
          </ng-container>
          <ng-container *ngIf="this.visit.isHomeAnchorRemoteKeyTransfer">
            Another Housekeeper will meet you here for the transfer.
          </ng-container>
        </p>
      </div>
      <ul *ngIf="this.visit.remoteKeyTransferKeys.length > 1">
        <ng-container *ngFor="let rktWorker of this.visit.remoteKeyTransferWorkers">
          <!-- prettier-ignore -->
          <li *ngIf="rktWorker.keysToGive.length > 0">
            You need to give keys
            <strong *ngFor="let key of rktWorker.keysToGive; let last = last">{{ key.code }}{{ last ? '' : ', ' }}</strong>
            to <strong>{{ rktWorker.firstNames }}</strong>.
          </li>
          <!-- prettier-ignore -->
          <li *ngIf="rktWorker.keysToCollect.length > 0">
            You need to pick up keys
            <strong *ngFor="let key of rktWorker.keysToCollect; let last = last">
              {{ key.code }}{{ last ? '' : ', ' }}
            </strong>
            from <strong>{{ rktWorker.firstNames }}</strong>.
          </li>
        </ng-container>
      </ul>
      <div *ngIf="this.visit.remoteKeyTransferKeys.length === 1">
        <ng-container *ngFor="let rktWorker of this.visit.remoteKeyTransferWorkers">
          <!-- prettier-ignore -->
          <p *ngIf="rktWorker.keysToGive.length > 0">
            You need to give keys
            <strong *ngFor="let key of rktWorker.keysToGive; let last = last">
              {{ key.code }}{{ last ? '' : ', ' }}
            </strong>
            to <strong>{{ rktWorker.firstNames }}</strong>.
          </p>
          <!-- prettier-ignore -->
          <p *ngIf="rktWorker.keysToCollect.length > 0">
            You need to pick up keys
            <strong *ngFor="let key of rktWorker.keysToCollect; let last = last">
              {{ key.code }}{{ last ? '' : ', ' }}
            </strong>
            from <strong>{{ rktWorker.firstNames }}</strong>.
          </p>
        </ng-container>
      </div>
      <div>
        <p>
          To contact the other Housekeeper
          <span *ngIf="this.visit.remoteKeyTransferWorkers.length > 1">s</span>
          tap "Help".
        </p>
      </div>
      <div class="photos">
        <ng-container *ngFor="let rktWorker of this.visit.remoteKeyTransferWorkers">
          <img
            *ngIf="rktWorker.image"
            class="photo"
            [src]="rktWorker.image"
            [alt]="rktWorker.firstNames + rktWorker.lastName"
          />
        </ng-container>
      </div>
    </ion-card-content>
  </ion-card>
</main>
