<ion-app
  [class.app--dev-environment]="isDevEnv"
  [class.app--impersonate-mode]="isImpersonateMode"
>
  <ion-split-pane contentId="main-content">
    <ion-menu
      *ngIf="showMenu"
      menuId="menu-primary"
      contentId="main-content"
      type="overlay"
      [swipeGesture]="false"
    >
      <ion-content class="menu-content">
        <ion-menu-toggle menu="menu-primary" auto-hide="false">
          <profile-summary *ngIf="profileReady"></profile-summary>
        </ion-menu-toggle>
        <ion-list class="menu-primary__list">
          <ion-menu-toggle
            *ngIf="isImpersonateMode"
            menu="menu-primary"
            auto-hide="false"
          >
            <ion-item
              tappable
              detail="false"
              lines="none"
              class="menu-primary__item menu-primary__item--end-impersonation"
              (click)="endImpersonation()"
            >
              <hk-wc-icon name="logout" slot="start"></hk-wc-icon>
              End impersonation
            </ion-item>
          </ion-menu-toggle>
          <ion-menu-toggle
            *ngFor="let item of navItems"
            menu="menu-primary"
            auto-hide="false"
          >
            <ng-container *ngIf="!item.hidden || !item.hidden()">
              <ion-item
                tappable
                lines="none"
                class="menu-primary__item"
                [ngClass]="item.css || {}"
                [routerLink]="item.url"
                [routerLinkActive]="item.url ? 'menu-primary__item--active' : ''"
                [routerLinkActiveOptions]="{ exact: false }"
                [queryParams]="item.params"
                [button]="!!item.action"
                (click)="item.action ? item.action() : noop()"
              >
                <hk-wc-icon [name]="item.icon" slot="start"></hk-wc-icon>
                {{ item.title }}
                <ng-container
                  *ngIf="
                    this.extraJobsService.availabilitySignal?.appSignalEnabled &&
                    this.extraJobsService.availabilitySignal?.workAvailable
                  "
                >
                  <ion-badge
                    *ngIf="item.id === 'extra-jobs'"
                    class="mar-l-10 icon-badge"
                    color="warning"
                  >
                    <hk-wc-icon name="timer" size="16"></hk-wc-icon>
                    <span
                      *ngIf="
                        this.extraJobsService.availabilitySignal
                          ?.appSignalDaysLookaheadHours
                      "
                    >
                      {{
                        this.extraJobsService.availabilitySignal
                          .appSignalDaysLookaheadHours
                      }}
                      hr
                    </span>
                  </ion-badge>
                </ng-container>
                <ng-container *ngIf="ticketCount$ | async as ticketCount">
                  <ion-badge
                    *ngIf="item.id === 'help'"
                    class="mar-l-10 icon-badge"
                    color="warning"
                  >
                    <hk-wc-icon name="envelope" size="16"></hk-wc-icon>
                    {{ ticketCount }}
                  </ion-badge>
                </ng-container>
                <ng-container
                  *ngIf="unreadChatMessagesCount$ | async as unreadChatsCount"
                >
                  <ion-badge
                    class="mar-l-10 icon-badge"
                    *ngIf="item.id === 'schedule-list'"
                    color="warning"
                  >
                    <hk-wc-icon name="chat" size="16"></hk-wc-icon>
                    Chat
                  </ion-badge>
                </ng-container>
                <ion-badge
                  class="mar-l-10 icon-badge"
                  *ngIf="
                    item.id === 'profile' &&
                    (!paymentDetails?.nameOnAccount ||
                      !paymentDetails?.accountNumber ||
                      !paymentDetails?.sortCode)
                  "
                  color="warning"
                >
                  <hk-wc-icon name="exclamation-mark-circle" size="16"></hk-wc-icon>
                  Attention
                </ion-badge>
              </ion-item>
            </ng-container>
          </ion-menu-toggle>
        </ion-list>
      </ion-content>
    </ion-menu>
    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>
  <ion-menu contentId="main-content" menuId="menu-schedule" side="end">
    <ion-content>
      <schedule-list [menuToggle]="true"></schedule-list>
    </ion-content>
  </ion-menu>
</ion-app>
