import { DemoEndpoint } from '../../../demo-service';

const createKeyEndpoint: DemoEndpoint = {
  path: 'create-key',
  handlers: [
    {
      method: 'post',
      handle() {
        return {
          transfer: {
            confirmed_at: null,
            confirmed_by: null,
            from_location: 'With customer',
            from_holder: null,
            to_location: 'With Housekeeper',
            cancelled: false,
            key_id: 'dmz2gef4vxok',
            id: 'jovkrum9gvzp'
          },
          key: {
            code: '13003',
            location: 'customer',
            property_id: 'xb6y5cwenmop',
            holder_id: null,
            transfer_in_progress: true,
            id: 'dmz2gef4vxok'
          },
          success: true
        };
      }
    }
  ]
};

export { createKeyEndpoint };
