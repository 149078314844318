import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

import { MenuController, ModalController } from '@ionic/angular';

import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';

import { AnalyticsParam, AnalyticsService, TapElementName } from 'services/analytics-service';
import { DemoService } from 'services/demo-service';
import { InAppBrowserConfigService } from 'services/in-app-browser-config-service';
import { ModalContentCtaAction } from 'services/in-app-messaging-service';
import { InfrastructureService } from 'services/infrastructure-service';
import { ScheduleService } from 'services/schedule-service';
import { SentryService } from 'services/sentry-service';

import { CONTACT_CONSENT_TO_CALL_RECORDING } from 'var/features';

import { ModalBase } from '../modal-base/modal-base';

@Component({
  selector: 'content-modal',
  templateUrl: './modal-content.component.html',
  styleUrls: ['./modal-content.component.scss']
})
export class ModalContent extends ModalBase {
  public static CLASS_ALERT = 'content-modal-alert';

  public readonly APPROVED_CTA_ACTIONS = [
    ModalContentCtaAction.ExitDemoMode,
    ModalContentCtaAction.RefreshSchedule,
    ModalContentCtaAction.CallRecordingConsent
  ];

  @Input() body: string;
  @Input() ctaColor = 'primary';
  @Input() ctaActions?: ModalContentCtaAction[] = [];
  @Input() ctaFill?: 'outline' | 'solid' = 'solid';
  @Input() ctaTarget?: 'iab' | 'system' = 'iab';
  @Input() ctaText?: string;
  @Input() ctaUrl?: string;
  @Input() dismissText?: string;
  @Input() heading: string;
  @Input() icon?: string;
  @Input() image?: string;
  @Input() layout?: 'card' | 'modal' = 'modal';
  @Input() showTipHelpfulSection?: boolean = false;

  protected readonly MODAL_NAME = 'Content Modal';

  constructor(
    protected analyticsService: AnalyticsService,
    private demoService: DemoService,
    private iab: InAppBrowser,
    private iabConfigService: InAppBrowserConfigService,
    private menuController: MenuController,
    protected modalController: ModalController,
    private router: Router,
    private scheduleService: ScheduleService,
    private sentryService: SentryService,
    protected infrastructureService: InfrastructureService
  ) {
    super();
  }

  public async onClickCta(): Promise<void> {
    // Track a "tap" event
    this.analyticsService
      .trackTap(TapElementName.ModalContentCta, {
        [AnalyticsParam.Data]: this.ctaUrl,
        [AnalyticsParam.Message]: `${this.heading || ''} ${this.body || ''}`.trim()
      })
      .then();

    // If "Exit Demo Mode" is included as a CTA action, this should be done first
    if (this.ctaActions.includes(ModalContentCtaAction.ExitDemoMode)) {
      await this.demoService.endDemoMode();
    }

    // Mark call recording consent as given, if applicable
    if (this.ctaActions.includes(ModalContentCtaAction.CallRecordingConsent)) {
      try {
        await this.infrastructureService.setFeatureActiveForUser(CONTACT_CONSENT_TO_CALL_RECORDING);
      } catch (error) {}
    }

    // Refresh the schedule, if applicable
    if (this.ctaActions.includes(ModalContentCtaAction.RefreshSchedule)) {
      await this.scheduleService.refreshSchedule();
    }

    // Log any unrecognised CTA actions
    for (const action of this.ctaActions.filter(action => !this.APPROVED_CTA_ACTIONS.includes(action))) {
      this.sentryService.log({
        message: `Unrecognised in-app message CTA action "${action}"`,
        level: 'warning'
      });
    }

    // Open the CTA URL, if any
    if (this.ctaUrl) {
      if (this.ctaUrl.startsWith('/')) {
        await this.menuController.close();
        await this.router.navigateByUrl(this.ctaUrl);
      } else {
        this.iabConfigService.configureBrowser(
          this.iab,
          this.ctaUrl,
          this.ctaTarget === 'system' ? '_system' : '_blank'
        );
      }
    }
    await this.dismiss();
  }

  /**
   * Track if the displayed modal tip was helpful or not in Firebase.
   */
  public async submitTipIsHelpful(isHelpful: boolean): Promise<void> {
    this.analyticsService
      .trackTap(isHelpful ? TapElementName.ModalContentTipHelpful : TapElementName.ModalContentTipNotHelpful, {
        [AnalyticsParam.Message]: `${this.heading || ''} ${this.body || ''}`.trim()
      })
      .then();
    await this.dismiss();
  }
}

type RequiredModalContentInputs = Pick<ModalContent, 'body' | 'heading'>;
type OptionalModalContentInputs = Partial<
  Pick<
    ModalContent,
    | 'ctaActions'
    | 'ctaColor'
    | 'ctaFill'
    | 'ctaText'
    | 'ctaUrl'
    | 'ctaTarget'
    | 'dismissText'
    | 'icon'
    | 'image'
    | 'layout'
    | 'showTipHelpfulSection'
  >
>;

export type ModalContentInputs = RequiredModalContentInputs & OptionalModalContentInputs;
