import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';

import { RemoteKeyTransfer, Visit } from '@housekeep/infra';

import { ChatInfoService } from 'services/chat-info-service';
import { ContactService } from 'services/contact-service';
import { ErrorService } from 'services/error-service';
import { HelpService } from 'services/help-service';
import { LoadingService } from 'services/loading-service';
import { LocationService } from 'services/location-service';
import { ToastService } from 'services/toast-service';

@Component({
  selector: 'contact-worker',
  templateUrl: './contact-worker.component.html',
  styleUrls: ['./contact-worker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactWorker implements OnInit {
  @Input() visit: Visit;
  @Input() remoteKeyTransfer: RemoteKeyTransfer;
  @Input() helpCategory: string;

  @Output() chatting = new EventEmitter<string>();

  public allowed: boolean;
  public message: string;

  constructor(
    private chatInfoService: ChatInfoService,
    private cdRef: ChangeDetectorRef,
    private contactService: ContactService,
    public errorService: ErrorService,
    public helpService: HelpService,
    public loadingService: LoadingService,
    public locationService: LocationService,
    public toastService: ToastService
  ) {}

  async ngOnInit(): Promise<void> {
    try {
      this.allowed = await this.chatInfoService.workerChatIsAllowed(this.remoteKeyTransfer);
    } catch (err) {
      this.allowed = false;
    }
    this.message = `Hello ${this.remoteKeyTransfer.otherWorker.firstNames}, I need help with our key transfer`;
    this.cdRef.markForCheck();
  }

  /**
   * Open the chat page with the customer and send a pre-defined message from the parent page
   */
  public goToChatWithMessage(): void {
    this.goToChat(this.message);
  }

  /**
   * Open the chat page with the customer without sending a pre-defined message from the parent page
   */
  public goToChatWithoutMessage(): void {
    this.goToChat('');
  }

  protected goToChat(message: string): void {
    this.chatting.emit(message);
  }

  /**
   * Trigger a request to call the worker.
   */
  public async requestWorkerCall(): Promise<void> {
    await this.contactService.requestWorkerCall(this.visit, this.remoteKeyTransfer.id, this.helpCategory);
  }
}
