import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'message-input',
  templateUrl: './message-input.component.html'
})
export class MessageInput {
  @Input() disabledSend: boolean;
  @Output() messageSent = new EventEmitter<string>();

  public message: string = '';
  protected title: string;

  public isSendDisabled(): boolean {
    return this.disabledSend || !this.message;
  }

  public clear(): void {
    this.message = '';
  }

  protected onClickSend(): void {
    if (this.message) {
      this.messageSent.emit(this.message);
    }
  }
}
