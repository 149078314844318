import { Injectable } from '@angular/core';

import { Platform } from '@ionic/angular';
import { Platforms } from '@ionic/core';

import { Device } from '@awesome-cordova-plugins/device/ngx';

import { isEqual } from 'lodash-es';

import {
  dateToStr,
  HousekeepDevice,
  housekeepDevice,
  housekeepDeviceSerializer,
  toDate,
  todayNaive
} from '@housekeep/infra';

import { RequestService } from './request-service';
import { SentryService } from './sentry-service';
import { DEVICE_DETAILS, DEVICE_EXTERNAL_ID, DEVICE_LAST_SYNC_DATE, StorageService } from './storage-service';
import { VersionService } from './version-service';

const BROWSER_PLATFORMS: Platforms[] = ['desktop', 'mobileweb', 'pwa'];
const MIN_PHONE_HEIGHT = 700;

@Injectable({ providedIn: 'root' })
export class DeviceService {
  public zoomedIn: boolean = false;

  constructor(
    private device: Device,
    private platform: Platform,
    private sentryService: SentryService,
    private requestService: RequestService,
    private storageService: StorageService,
    private versionService: VersionService
  ) {}

  public isBrowser(): boolean {
    return BROWSER_PLATFORMS.some(platform => this.platform.is(platform));
  }

  /**
   * Device Service related actions we wish to execute on app init
   */
  public async onInitDeviceActions(): Promise<void> {
    // Update the backend daily of any device changes
    const hasSynced = await this.hasDeviceSyncedToday();
    if (!hasSynced) {
      await this.performDeviceActions();
      this.setDeviceSyncedToday().catch();
    }
  }

  /*
   * Registers device if not registered
   * Updates device if details changed since last checked
   * Saves current device to storage if differing from stored
   */
  public async performDeviceActions(): Promise<string | null> {
    try {
      const externalId = await this.getDeviceExternalId();

      if (!externalId) {
        const newExternalId = await this.createRemoteDevice();

        if (newExternalId) {
          await this.setDeviceExternalId(newExternalId);
          this.storeDeviceDetails().catch();
          return newExternalId;
        }
      } else if (await this.hasDeviceChanged()) {
        this.updateRemoteDevice(externalId).catch();
        this.storeDeviceDetails().catch();
        return externalId;
      }
    } catch (e) {
      this.sentryService.log({
        message: 'Perform Device actions failed',
        level: 'debug',
        extras: { error: e }
      });
      return null;
    }
  }

  public async ready(): Promise<void> {
    await Promise.all([this.storageService.ready(), this.versionService.ready()]);
  }

  public isZoomedIn(): boolean {
    return this.zoomedIn;
  }

  public setZoomedIn(isZoomed: boolean): void {
    this.zoomedIn = isZoomed;
  }

  /**
   * Return true if the device either has larger text or is short
   */
  public shouldCenterPopover(): boolean {
    return this.zoomedIn || this.platform.height() < MIN_PHONE_HEIGHT;
  }

  /**
   * Registers local device as new device on remote
   */
  private async createRemoteDevice(): Promise<string> {
    const device = this.getDeviceAttrs();
    // If the device has platform information, register it with the backend
    if (device.platform) {
      try {
        const response = await this.requestService.post('device/create/', device, {
          serializer: housekeepDeviceSerializer
        });
        return response.id;
      } catch (e) {
        this.sentryService.log({
          message: 'Create Remote Device failed',
          level: 'debug',
          extras: { response: e }
        });
        return null;
      }
    } else {
      return null;
    }
  }

  /**
   * Return dictionary of all current device attributes where known
   */
  private getDeviceAttrs(): HousekeepDevice {
    let platform = this.device.platform;

    if (platform) {
      platform = platform.toLowerCase();
    }

    return housekeepDevice.create({
      appVersion: this.versionService.getAppVersion(),
      manufacturer: this.device.manufacturer,
      model: this.device.model,
      platform: platform,
      serial: this.device.serial,
      uuid: this.device.uuid,
      osVersion: this.device.version
    });
  }

  /**
   * Retrieve external ID if known from persistent storage
   */
  private getDeviceExternalId(): Promise<string> {
    return this.storageService.get(DEVICE_EXTERNAL_ID);
  }

  /**
   * Retrieves historic device details if known from persistent storage
   */
  private getStoredDeviceDetails(): Promise<string> {
    return this.storageService.get(DEVICE_DETAILS);
  }

  /**
   * Have known device attributes changed?
   * OS Version upgrades are of interest for tracking
   */
  private async hasDeviceChanged(): Promise<boolean> {
    const currentDetails = this.getDeviceAttrs();
    const storedDetails = await this.getStoredDeviceDetails();
    return !isEqual(currentDetails, storedDetails);
  }

  /**
   * Returns if the device has synced its status with the backend today
   */
  private async hasDeviceSyncedToday(): Promise<boolean> {
    const lastSyncedDatetime = await this.storageService.get(DEVICE_LAST_SYNC_DATE);
    if (lastSyncedDatetime) {
      return toDate(lastSyncedDatetime).isSame(todayNaive(), 'day');
    }
    return false;
  }

  /**
   * Save external ID to persistent storage for future identification
   */
  private async setDeviceExternalId(externalId: string): Promise<void> {
    await this.storageService.set(DEVICE_EXTERNAL_ID, externalId);
  }

  /**
   * Save the last sync date to persistent storage.
   */
  private async setDeviceSyncedToday(): Promise<void> {
    await this.storageService.set(DEVICE_LAST_SYNC_DATE, dateToStr(todayNaive()));
  }

  /**
   * Save current device details to persistent storage
   */
  private async storeDeviceDetails(): Promise<void> {
    await this.storageService.set(DEVICE_DETAILS, this.getDeviceAttrs());
  }

  /**
   * Updates the remote with the newest device details
   */
  private async updateRemoteDevice(deviceId: string): Promise<void> {
    try {
      const { appVersion, osVersion, uuid }: HousekeepDevice = this.getDeviceAttrs();
      await this.requestService.patch(`device/${deviceId}/update/`, {
        appVersion,
        osVersion,
        uuid
      });
    } catch (e) {
      this.sentryService.log({
        message: 'Update Remote Device failed',
        level: 'debug',
        extras: { response: e }
      });
    }
  }
}
