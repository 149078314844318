import { NgModule } from '@angular/core';

import { momentPipes } from './moment-pipes';

import { sanitizeHtmlPipes } from './sanitize-html-pipes';

import { moneyPipes } from './money-pipes';
import { numberPipes } from './number-pipes';
import { ratingPipes } from './rating-pipes';
import { stringPipes } from './string-pipes';
import { workPipes } from './work-pipes';

const pipes = [
  ...momentPipes,
  ...moneyPipes,
  ...numberPipes,
  ...ratingPipes,
  ...sanitizeHtmlPipes,
  ...stringPipes,
  ...workPipes
];

@NgModule({
  declarations: pipes,
  exports: pipes
})
export class HousekeeperAppPipesModule {}
