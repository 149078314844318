import { Component, Input, OnInit } from '@angular/core';

import { Task } from '@housekeep/infra';

import { VisitChanges } from 'models/visit-changes';

@Component({
  selector: 'hk-visit-tasks',
  templateUrl: './hk-visit-tasks.component.html',
  styleUrls: ['./hk-visit-tasks.component.scss']
})
export class HkVisitTasks implements OnInit {
  @Input() public isKeysOnlyVisit: boolean;
  @Input() public workerTasks: Task[];
  @Input() public visitChanges: VisitChanges;

  public hasTaskChanges: boolean;
  public visitHasSingleTask: boolean;

  public ngOnInit() {
    // Any change to a task is relevant for this tab
    this.hasTaskChanges = this.visitChanges.hasTaskChanges();
    this.visitHasSingleTask = this.workerTasks.length === 1;
  }
}
