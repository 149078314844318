import { HttpContext, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { cloneDeep } from 'lodash-es';

import { Observable, of, Subject, Subscription, throwError } from 'rxjs';
import { delay, dematerialize, materialize } from 'rxjs/operators';

import { isDevEnv } from 'environment/environment';

import { HttpStatus } from 'util/error';

import { AlertService } from './alert-service';
import { analyticsEndpoint } from './demo/endpoints/analytics';
import { authEndpoint } from './demo/endpoints/auth';
import { contactEndpoint } from './demo/endpoints/contact';
import { customerEndpoint } from './demo/endpoints/customer';
import { infrastructureEndpoint } from './demo/endpoints/infrastructure';
import { integrationsEndpoint } from './demo/endpoints/integrations';
import { mappingEndpoint } from './demo/endpoints/mapping';
import { ratingsEndpoint } from './demo/endpoints/ratings';
import { workEndpoint } from './demo/endpoints/work';
import { workersEndpoint } from './demo/endpoints/workers';
import { SentryService } from './sentry-service';
import { TimeService } from './time-service';
import { ToastService } from './toast-service';
import { VisitDurationService } from './visit-duration-service';

const DEMO_MODE_ALERT_OPTIONS = {
  header: 'Welcome to Demo Mode',
  message: `<p>You can explore the app and learn how it works here.</p>
            <p>Demo Mode will automatically end after 60 minutes or you can exit by
               tapping 'Exit' in the red bar at the bottom of the screen.</p>`,
  buttons: ['OK']
};

const DEMO_MODE_ERROR_MESSAGE = 'This feature is not available in Demo Mode.';
const DEMO_MODE_LOAD_TIME = 250;

const DEMO_MODE_TIMEOUT_MILLISECONDS = 60 * 60 * 1000;

type HttpMethod = 'delete' | 'get' | 'head' | 'options' | 'patch' | 'post' | 'put';

type HttpOptions = {
  headers?:
    | HttpHeaders
    | {
        [header: string]: string | string[];
      };
  context?: HttpContext;
  observe?: 'body';
  params?:
    | HttpParams
    | {
        [header: string]: string | string[];
      };
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
};

interface DemoEndpoint {
  endpoints?: DemoEndpoint[];
  handlers?: DemoRequestHandler[];
  path: string; // used to make a case-insensitive Regular Expression
}

interface DemoRequestHandler {
  handle: Function; // (state, headers, parameters, body) => {object|string}
  method: HttpMethod;
}

interface StartDemoModeOpts {
  onEnd?: () => void;
  timeout?: boolean;
}

@Injectable({ providedIn: 'root' })
class DemoService {
  public isOn: boolean = false;
  public state: any = {};

  private _defaultState = {
    pagedPaymentPeriods: null,
    pagedRatings: null,
    workerAvailabilityData: null,
    visits: {
      // 'id:YYYY-MM-DD': {...},
    },
    worker: {
      account_number: '12345678',
      name_on_account: 'DANIELLE DEMO',
      sort_code: '123456'
    },
    workingDays: {
      // 'YYYY-MM-DD': {...},
    }
  };

  private _currentTimeout: any = null;

  private _demoEventsSubject: Subject<any> = new Subject();

  // Our fake API root endpoints
  private _rootEndpoints: DemoEndpoint[] = [
    {
      path: 'api/v1',
      endpoints: [
        analyticsEndpoint,
        authEndpoint,
        contactEndpoint,
        customerEndpoint,
        infrastructureEndpoint,
        integrationsEndpoint,
        mappingEndpoint,
        ratingsEndpoint,
        workersEndpoint,
        workEndpoint
      ]
    }
  ];

  constructor(
    private alertService: AlertService,
    private sentryService: SentryService,
    private timeService: TimeService,
    private toastService: ToastService,
    private visitDurationService: VisitDurationService
  ) {}

  /**
   * Turn Demo Mode on.
   */
  startDemoMode(opts: StartDemoModeOpts = {}): void {
    this._resetState();
    this.isOn = true;
    this._demoEventsSubject.next(['on']);

    if (opts.timeout) {
      this.setTimeoutDemoMode();
    }

    this.toastService.showDemoToast().then(demoToast => {
      demoToast.onDidDismiss().then(() => this._endDemoModeFromToast(opts));
    });

    this.toastService.dismissToast();
    this.sentryService.captureBreadcrumb({ message: 'Demo Mode enabled' });
  }

  /**
   * Turn Demo Mode off.
   */
  async endDemoMode(): Promise<void> {
    await this._endDemoMode(false);
  }

  /*
   * Set a timeout for Demo Mode to automatically eject the user
   */
  setTimeoutDemoMode(): void {
    this._currentTimeout = setTimeout(() => {
      if (this.isOn) {
        this._endDemoMode(false, true);
      }
    }, DEMO_MODE_TIMEOUT_MILLISECONDS);
  }

  /**
   * Show a message to say that something is not possible in Demo Mode.
   */
  showErrorMessage(): void {
    this.toastService.showToast({ message: DEMO_MODE_ERROR_MESSAGE, theme: 'warning' });
  }

  /**
   * Subscribe to demo service update events.
   * @param  {Function} fn
   * @return {Subscription}
   */
  public subscribe(fn: (event) => void): Subscription {
    return this._demoEventsSubject.subscribe(fn);
  }

  /* Angular `HttpClient` Methods */

  get(url: string, options: HttpOptions): Observable<any> {
    return this._respond('get', url, options);
  }

  post(url: string, body: any, options: HttpOptions): Observable<any> {
    return this._respond('post', url, options, body);
  }

  put(url: string, body: any, options: HttpOptions): Observable<any> {
    return this._respond('put', url, options, body);
  }

  delete(url: string, options: HttpOptions): Observable<any> {
    return this._respond('delete', url, options);
  }

  patch(url: string, body: any, options: HttpOptions): Observable<any> {
    return this._respond('patch', url, options, body);
  }

  head(url: string, options: HttpOptions): Observable<any> {
    return this._respond('head', url, options);
  }

  options(url: string, options: HttpOptions): Observable<any> {
    return this._respond('options', url, options);
  }

  /* Private Methods */

  private async _endDemoMode(fromToast: boolean, fromTimeout = false) {
    if (!fromTimeout) {
      clearTimeout(this._currentTimeout);
    }
    if (!this.isOn) {
      return;
    }

    this.isOn = false;
    this._demoEventsSubject.next(['off']);

    if (!fromToast) {
      await this.toastService.dismissDemoToast();
    }

    await this.toastService.showToast({
      message: 'Demo Mode is now off.',
      theme: 'warning',
      duration: 3000
    });
    this.sentryService.captureBreadcrumb({ message: 'Demo Mode disabled' });

    if (fromTimeout) {
      const alert = await this.alertService.create({
        header: 'Demo Mode ended',
        message: 'You will now see your real jobs and customer information.',
        buttons: ['OK']
      });
      await alert.present();
    }
  }

  /**
   * End the Demo Mode after a user clicks the dismiss button on the toast.
   */
  private async _endDemoModeFromToast(opts: StartDemoModeOpts): Promise<void> {
    if (!this.isOn) {
      return;
    }

    await this._endDemoMode(true);

    if (opts.onEnd) {
      opts.onEnd();
    }
  }

  /**
   * Get Demo Mode request handlers from a list of endpoints for a given URL path.
   * @param {string} path A URL path with no leading/trailing slashes/white-space
   * @param {Array<DemoEndpoint>}
   * @return {Array<DemoRequestHandler>|undefined} The endpoint matching the path, or
   *   `undefined` if there was no match
   */
  private _getRequestHandlers(path: string, endpoints: DemoEndpoint[]): DemoRequestHandler[] | undefined {
    for (const endpoint of endpoints) {
      const pathPattern = new RegExp('^' + endpoint.path + '/?', 'i');
      path = path.replace('dev.keep/', '');
      const pathMatch = path.match(pathPattern);

      if (pathMatch) {
        const remainingPath = path.replace(pathMatch[0], '');

        return endpoint.handlers || this._getRequestHandlers(remainingPath, endpoint.endpoints);
      }
    }
  }

  /**
   * Get a Demo Mode response for a request URL, HTTP method, option object, and body.
   */
  private _respond(method: HttpMethod, url: string, options?: any, requestBody?: any): Observable<any> {
    // Get the path from the URL.
    const urlPathPattern = /[a-z]+\:\/\/.*?\/(.*)/i;
    const pathMatch = url.match(urlPathPattern);
    const path = pathMatch ? pathMatch[1] : null;

    if (path) {
      // Get the headers and parameters from the options.
      const headers = options && options.headers ? options.headers : new HttpHeaders();
      const parameters = options && options.params ? options.params : new HttpParams();

      // Match the path to some Demo Mode response handlers.
      const handlers = this._getRequestHandlers(path, this._rootEndpoints);

      if (handlers) {
        // Get the request handler for the given method.
        const handler = handlers.find(r => r.method === method);

        if (handler) {
          if (isDevEnv) {
            console.debug(`${method.toUpperCase()} /${path}`);
          }

          // Get the response body string from the request handler.
          let responseBody;
          try {
            responseBody = handler.handle(this.state, path, parameters, headers, requestBody);
          } catch (e) {
            return throwError(e);
          }
          if (typeof responseBody === 'string') {
            responseBody = JSON.parse(responseBody);
          }

          // Give the response in the expected format.
          return of(responseBody).pipe(delay(DEMO_MODE_LOAD_TIME));
        }
      }

      if (isDevEnv) {
        console.error(`NOT IMPLEMENTED: ${method.toUpperCase()} /${path}`);
      }
    }

    // If we didn't return anything yet, then an appropriate handler couldn't
    // be found, so give a Demo Mode error.
    return this._getDefaultObservableResponse();
  }

  /**
   * Get a fake API response that gives an error for an unimplemented Demo Mode feature.
   */
  private _getDefaultObservableResponse(): Observable<any> {
    const responseBody = { error_message: DEMO_MODE_ERROR_MESSAGE };
    const responseOptions = {
      body: responseBody,
      status: HttpStatus.DemoModeFeatureMissing
    };
    const response = new HttpErrorResponse(responseOptions);
    return throwError(response).pipe(materialize(), delay(DEMO_MODE_LOAD_TIME), dematerialize());
  }

  /**
   * Set the Demo Mode state to what it should be at the start.
   */
  private _resetState() {
    this.state = cloneDeep(this._defaultState);
    this.state.today = () => this.timeService.today();
    this.state.now = () => this.timeService.now();
    this.state.visitDurationService = this.visitDurationService;
  }
}

export {
  DEMO_MODE_ALERT_OPTIONS,
  DEMO_MODE_ERROR_MESSAGE,
  DEMO_MODE_LOAD_TIME,
  DemoEndpoint,
  DemoRequestHandler,
  DemoService
};
