import { Component, Input } from '@angular/core';

import { HkRoundProgress } from 'components/hk-round-progress/hk-round-progress.component';

import { COLOR_GOOD, TARGET_DAYS_RELIABLE } from 'pages/performance/performance.page';

export const RELIABILITY_THRESHOLD_FOR_FULL_STREAK = 1.0;

@Component({
  selector: 'hk-reliability-round-progress',
  templateUrl: './hk-reliability-round-progress.component.html'
})
export class HkReliabilityRoundProgress extends HkRoundProgress {
  @Input() public reliability: number;
  @Input() public daysReliable: number;

  public TARGET_DAYS_RELIABLE = TARGET_DAYS_RELIABLE;
  public COLOR_GOOD = COLOR_GOOD;

  public RELIABILITY_THRESHOLD_FOR_FULL_STREAK = RELIABILITY_THRESHOLD_FOR_FULL_STREAK;
}
