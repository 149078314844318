// Shims
import includes from 'array-includes';

import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { Platform } from '@ionic/angular';

import { Badge } from '@awesome-cordova-plugins/badge/ngx';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';

import { App, URLOpenListenerEvent } from '@capacitor/app';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar } from '@capacitor/status-bar';
import { TextZoom } from '@capacitor/text-zoom';

import { noop } from 'lodash-es';

import { Observable, Subscription } from 'rxjs';

import { ColorPrimary } from '@housekeep/design-tokens';
import { stringIncludes, Worker } from '@housekeep/infra';

// Environment
import { environment, isDevEnv } from 'environment/environment';

import { PaymentDetails } from 'models/payment-details';

// Providers
import { AnalyticsService, MENU_NAME_MAIN_MENU, MENU_NAME_SCHEDULE, TapElementName } from 'services/analytics-service';
import { AppContextService, LaunchContext } from 'services/app-context-service';
import { AppZendeskChatService } from 'services/app-zendesk-chat-service';
import { AuthEventArgs, AuthService } from 'services/auth-service';
import { ChatInfoService } from 'services/chat-info-service';
import { DemoService } from 'services/demo-service';
import { DeviceService } from 'services/device-service';
import { ExtraJobsService } from 'services/extra-jobs-service';
import { InAppBrowserConfigService } from 'services/in-app-browser-config-service';
import { InfrastructureService } from 'services/infrastructure-service';
import { NavigationService } from 'services/navigation-service';
import { NotificationService } from 'services/notification-service';
import { OnlineService } from 'services/online-service';
import { PaymentDetailsService } from 'services/payment-details-service';
import { PlatformService } from 'services/platform-service';
import { DEFAULT_UPDATE_TIMEOUT } from 'services/request-service';
import { SentryService } from 'services/sentry-service';
import { TicketService } from 'services/ticket.service';
import { ToastService } from 'services/toast-service';
import { UserService, UserSubscriptionEvents } from 'services/user-service';
import { VersionService } from 'services/version-service';

// Vars
import { FEATURE_CORONAVIRUS_LINK_IN_MENU, FEATURE_HOUSEKEEP_ACADEMY } from 'var/features';
import { SETTING_HOUSEKEEP_ACADEMY_URL } from 'var/settings';
import { COVID_19_HELP_CENTRE_URL } from 'var/urls';

// Apply shims
includes.shim();
stringIncludes();

const CSRF_TOKEN_QUERY_PARAM = 'csrftoken';
const ENVIRONMENT_QUERY_PARAM = 'env';
const IMPERSONATE_QUERY_PARAM = 'impersonate';
const API_ROOT_QUERY_PARAM = 'api_root';

/*
 * One of the first API requests made by the app is to get the user from the backend.
 * If this times out, the cached user data (if any) is retrieved from storage.
 * We set the timeout for this request lower than the default, so that network issues
 * do not prevent the initial page being opened.
 */
const INITIAL_REQUEST_TIMEOUT = 6000;

interface NavItem {
  id: string;
  title: string;
  icon: string;
  css?: string[] | { [cssClass: string]: boolean };
  url?: string;
  params?: Record<string, string | number | boolean>;
  action?: () => void;
  hidden?: () => boolean;
}

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  public navItems: NavItem[] = [
    {
      id: 'profile',
      title: 'Profile',
      icon: 'person',
      url: '/app-housekeepers/profile',
      params: {}
    },
    {
      id: 'schedule-list',
      title: 'Schedule',
      icon: 'calendar-blank',
      url: '/app-housekeepers/schedule-list',
      params: {}
    },
    {
      id: 'extra-jobs',
      title: 'Extra Jobs',
      icon: 'house-stack',
      params: {},
      url: '/app-housekeepers/extra-jobs',
      action: () => this.openExtraJobs()
    },
    {
      id: 'history',
      title: 'Payments',
      icon: 'money-notes',
      url: '/app-housekeepers/payments',
      params: {}
    },
    {
      id: 'ratings',
      title: 'Ratings & Pay',
      icon: 'star',
      url: '/app-housekeepers/performance',
      params: {}
    },
    {
      id: 'achievements',
      title: 'Achievements',
      icon: 'rosette',
      url: '/app-housekeepers/achievements',
      params: {}
    },
    {
      id: 'academy',
      title: 'Housekeep Academy',
      icon: 'mortarboard',
      params: {},
      action: () => this.openHousekeepAcademy(),
      hidden: () => !this.housekeepAcademyUrl
    },
    {
      id: 'news',
      title: 'Guides & News',
      icon: 'light-bulb',
      url: '/app-housekeepers/news',
      params: {}
    },
    {
      id: 'referral',
      title: 'Refer Cleaners',
      icon: 'person-arrow',
      url: '/app-housekeepers/refer',
      params: {}
    },
    {
      id: 'help',
      title: 'Help',
      icon: 'question-mark-circle',
      url: '/app-housekeepers/help',
      params: {}
    }
  ];

  public readonly noop = noop;

  public user: Worker;
  public isChatToHousekeep = false;
  public isDevEnv = isDevEnv;
  public isOnboarding = false;
  public isWhatsNew = false;
  public paymentDetails: PaymentDetails;
  public profileReady = false;
  public showingPersistentToast = false;
  public unreadChatMessagesCount$: Observable<number>;
  public ticketCount$: Observable<number>;
  public unreadTicketCount$: Observable<number>;
  public chatToHousekeepToastSubscription: Subscription;
  public userSubscription: Subscription;

  private housekeepAcademyUrl: string = null;
  private ready = false;
  private resumeTimeout: ReturnType<typeof setTimeout>;

  constructor(
    private analyticsService: AnalyticsService,
    private appContextService: AppContextService,
    private appZendeskChatService: AppZendeskChatService,
    public authService: AuthService,
    private badge: Badge,
    private chatInfoService: ChatInfoService,
    private demoService: DemoService,
    private deviceService: DeviceService,
    public extraJobsService: ExtraJobsService,
    private iab: InAppBrowser,
    private inAppBrowserConfigService: InAppBrowserConfigService,
    private infrastructureService: InfrastructureService,
    private navigationService: NavigationService,
    private notificationService: NotificationService,
    private onlineService: OnlineService,
    private paymentDetailsService: PaymentDetailsService,
    private platform: Platform,
    private platformService: PlatformService,
    private router: Router,
    private sentryService: SentryService,
    private ticketService: TicketService,
    private toastService: ToastService,
    private userService: UserService,
    private versionService: VersionService,
    private zone: NgZone
  ) {}

  public get isImpersonateMode(): boolean {
    return this.authService.isImpersonateMode;
  }

  /**
   * Show the menu only if the user is logged in, is not onboarding, and the what's new
   * screens are not being shown on app launch.
   */
  public get showMenu(): boolean {
    return this.authService.isAuthenticated && !this.isOnboarding && !this.isWhatsNew;
  }

  /**
   * Initialize the app component by subscribing to events, initializing services, hiding the splash screen,
   * checking if the user is logged in, and redirecting to the initial page.
   *
   * This method should not make API calls that might fail due to a 401 Unauthorized error, as these errors trigger a
   * redirect to the login page. This may prevent deeplinks from being handled correctly when the app is cold-launched.
   */
  public async ngOnInit(): Promise<void> {
    await this.platform.ready();

    // Check if the environment has changed (involves a page refresh) and bail out if so
    if (this.checkEnvironmentAndReload()) {
      return;
    }

    this.authService.subscribe(event => this.onAuthEvent(event));
    this.demoService.subscribe(event => this.onDemoModeEvent(event));

    // Subscribe to deeplink events
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        const url = new URL(event.url);
        const path = url.pathname.replace(/\/$/, '');

        // Check if the app was launched using a deeplink
        if (!this.ready) {
          this.appContextService.setLaunchContext(LaunchContext.Deeplink, {
            path,
            url: event.url
          });
        }

        this.router.navigateByUrl(path + url.search);
      });
    });

    App.addListener('pause', () => {
      this.onlineService.setSuppressTimeoutErrors(true);
      clearTimeout(this.resumeTimeout);
    });

    App.addListener('resume', () => {
      this.resumeTimeout = setTimeout(() => {
        this.onlineService.setSuppressTimeoutErrors(false);
      }, DEFAULT_UPDATE_TIMEOUT);
    });

    // Subscribe to Router NavigationEnd events
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.isChatToHousekeep = event.url.startsWith('/app-housekeepers/help/chat-to-housekeep');
        this.isOnboarding =
          event.url.startsWith('/app-housekeepers/get-started') || event.url.startsWith('/app-housekeepers/onboarding');
        this.isWhatsNew = event.url === '/app-housekeepers/whats-new?context=launch';
      }
    });

    // Subscribe to back button taps
    this.platform.backButton.subscribeWithPriority(10, processNextHandler => {
      // Prevent back navigation on the onboarding welcome page
      if (!this.router.url.startsWith('/app-housekeepers/onboarding/welcome')) {
        processNextHandler();
      }
    });

    // Subscribe to toast events so we can open the "Chat to Housekeep" page when the chat toast button is tapped
    this.chatToHousekeepToastSubscription = this.toastService.subscribe(async toastEvent => {
      if (toastEvent === 'open-housekeep-chat') {
        await this.router.navigate(['/app-housekeepers/help/chat-to-housekeep']);
      }
    });

    // Subscribe to user events so we can clear the attention badge on the sidebar
    this.userSubscription = this.userService.subscribe(async userEvent => {
      if (userEvent === UserSubscriptionEvents.UpdatePaymentDetails) {
        this.paymentDetails = await this.paymentDetailsService.getPaymentDetails();
      }
    });

    // Wait for services to initialize
    await Promise.all([this.versionService.ready(), this.authService.ready(), this.deviceService.ready()]);

    // Do some initial setup
    await Promise.all([this.checkImpersonateMode(), this.setTextZoom()]);

    await this.checkUserAuthState();

    await this.hideSplashScreenAndChangeStatusBar();

    // We're ready to show the ProfileSummaryComponent
    this.profileReady = true;

    // More setup (this should happen after checking the user auth state)
    await Promise.all([this.analyticsService.initialize(), this.appZendeskChatService.initialize()]);

    // Asynchronously check if Coronavirus link is active
    this.checkCoronavirusFeature().then(noop);

    // Check if the user should be redirected to a different page
    // We only do this if the app was not launched with a deeplink
    if (!this.appContextService.isDeeplinkContext()) {
      await this.redirectInitialPage();
    }

    this.ready = true;
  }

  public ngOnDestroy(): void {
    this.chatInfoService.destroyUnreadMessagesCount();
    this.ticketService.killTicketCountSubscription();
    this.chatToHousekeepToastSubscription.unsubscribe();
    this.userSubscription.unsubscribe();
  }

  public endImpersonation(): Promise<void> {
    return this.authService.endImpersonation();
  }

  public async menuOpened(): Promise<void> {
    await this.analyticsService.trackMenuView(MENU_NAME_MAIN_MENU);
  }

  public async scheduleOpened(): Promise<void> {
    await this.analyticsService.trackMenuView(MENU_NAME_SCHEDULE);
  }

  public async openExtraJobs(): Promise<void> {
    await Promise.all([
      this.extraJobsService.trackSignalAnalyticsMenuPress(),
      this.extraJobsService.getUserExtraJobSignal()
    ]);
  }

  public openHelpCentre(url: string): void {
    this.inAppBrowserConfigService.configureBrowserForHelpCentreLink(
      this.iab,
      (path: string) => this.zone.run(() => this.router.navigateByUrl(path)),
      () => this.router.navigateByUrl('/app-housekeepers/help'),
      url
    );
  }

  private openHousekeepAcademy(): void {
    this.analyticsService.trackTap(TapElementName.HousekeepAcademy).then(noop);
    this.inAppBrowserConfigService.configureBrowser(this.iab, this.housekeepAcademyUrl);
  }

  private async checkCoronavirusFeature(): Promise<void> {
    if (!this.onlineService.isOnline() || this.platformService.isPreReleaseIos()) {
      return;
    }
    try {
      if (await this.infrastructureService.isFeatureActive(FEATURE_CORONAVIRUS_LINK_IN_MENU)) {
        this.navItems.push({
          id: 'coronavirus',
          title: 'Coronavirus',
          icon: 'medical-cross-square',
          action: (): void => this.openHelpCentre(COVID_19_HELP_CENTRE_URL)
        });
      }
    } catch (err) {
      // ignore
    }
  }

  /**
   * Check if the Housekeep Academy link is active for this user, then get the URL.
   */
  private async checkHousekeepAcademyFeatureAndSetUrl(): Promise<void> {
    try {
      if (await this.infrastructureService.isFeatureActiveForUser(FEATURE_HOUSEKEEP_ACADEMY)) {
        this.housekeepAcademyUrl = await this.infrastructureService.getSettingValue(SETTING_HOUSEKEEP_ACADEMY_URL);
      } else {
        this.housekeepAcademyUrl = null;
      }
    } catch (err) {
      // ignore
    }
  }

  /**
   * Checks if the environment and API root have been specified in query params,
   * and persists them to storage if so. The page will reload if either has changed.
   * @returns true if the page is reloading, false otherwise
   */
  private checkEnvironmentAndReload(): boolean {
    // Record current values of local storage items
    const envPriorValue = environment.ID;
    const apiRootPriorValue = localStorage.getItem('DEV_API_ROOT');

    // Switch environment using query string parameter
    const env = this.platform.getQueryParam(ENVIRONMENT_QUERY_PARAM);
    if (env && env !== environment.ID) {
      localStorage.setItem('environment', env);
    }
    const apiRoot = this.platform.getQueryParam(API_ROOT_QUERY_PARAM);
    if (apiRoot) {
      localStorage.setItem('DEV_API_ROOT', apiRoot);
    }

    // Reload if at least one of the values has changed
    if ((env && env !== envPriorValue) || (apiRoot && apiRoot !== apiRootPriorValue)) {
      location.reload();
      return true;
    }

    return false;
  }

  private async checkImpersonateMode(): Promise<void> {
    try {
      // Enable impersonate mode using query string parameter
      if (this.platform.getQueryParam(IMPERSONATE_QUERY_PARAM)) {
        await Promise.all([this.authService.setImpersonateState(true), this.authService.setSessionState(true)]);
      }

      const csrfToken = this.platform.getQueryParam(CSRF_TOKEN_QUERY_PARAM);
      if (csrfToken) {
        this.authService.setCsrfToken(csrfToken);
      }
    } catch (err) {
      // Ignore
    }

    this.authService.markAuthStateReady();
  }

  /**
   * Check if the user is authenticated.
   *
   * This first checks for the existence of an auth token in local storage.
   * If a token is found, then a request is made to the backend to confirm the user is
   * still logged in. If this request fails, the app checks for cached user data.
   *
   * Network issues may cause the request to time out, which would slow down the
   * app launch, so this request has a lower timeout than the default.
   */
  private async checkUserAuthState(): Promise<void> {
    try {
      this.user = await this.userService.getUser(false, INITIAL_REQUEST_TIMEOUT);

      // Get the extra job signal and sync the device in the background
      this.extraJobsService.getUserExtraJobSignal().catch(noop);
      this.deviceService.onInitDeviceActions().catch(noop);

      await this.onAuthorized();
    } catch (err) {
      // Ignore
    }
  }

  /**
   * Redirect the user to the Onboarding flow or What's New page if applicable.
   */
  private async redirectInitialPage(): Promise<void> {
    const urlsToRedirect = ['/app-housekeepers/onboarding/welcome', '/app-housekeepers/whats-new'];
    const url = await this.navigationService.getOpeningPage(!this.onlineService.isOnline());
    if (urlsToRedirect.includes(url)) {
      await this.router.navigateByUrl(url);
    }
  }

  /**
   * Limit the amount of text zoom the device can apply.
   */
  private async setTextZoom(): Promise<void> {
    if (!this.deviceService.isBrowser()) {
      try {
        // Cap the text zoom to 130%.
        const preferredZoom = (await TextZoom.getPreferred()).value;
        await TextZoom.set({
          value: Math.min(preferredZoom, 1.3)
        });
        this.deviceService.setZoomedIn(preferredZoom > 1);
      } catch (err) {
        // Ignore
      }
    }
  }

  private async hideSplashScreenAndChangeStatusBar(): Promise<void> {
    if (!this.deviceService.isBrowser()) {
      await SplashScreen.hide();

      if (this.platform.is('android')) {
        await StatusBar.setBackgroundColor({
          color: ColorPrimary
        });
      }
    }
  }

  /**
   * Respond to logout events by:
   *  - unregistering the current FCM token with Firebase
   *  - redirecting to the login page.
   * If the logout was prompted by an authorized exception, show a toast
   * explaining the reason to the user
   * @param {AuthEventArgs} event
   */
  private async onAuthEvent(event: AuthEventArgs): Promise<void> {
    const [eventName, eventArgs = {}] = event;

    if (eventName === 'login') {
      await this.onAuthorized();
    } else if (eventName === 'logout') {
      this.sentryService.setUser({});
      await Promise.all([
        this.notificationService.cleanFirebaseToken(),
        this.router.navigate(['/app-housekeepers/login'], {
          queryParams: {
            logoutReason: eventArgs.reason
          }
        })
      ]);
    }
  }

  /**
   * When the user is marked as being authorized, ensure that they have
   * notifications enabled, and capture their credentials in Sentry.
   * Also initiates poll for the open ticket count.
   */
  private async onAuthorized(): Promise<void> {
    this.notificationService.initialize().then();

    // Initialize the unread chat messages poll
    this.chatInfoService.initUnreadMessagesCount();
    this.unreadChatMessagesCount$ = this.chatInfoService.getUnreadMessagesCount();

    // Initialize the ticket count poll
    this.ticketService.initTicketCountPoll();
    this.ticketCount$ = this.ticketService.getInProgressTicketCount();
    this.unreadTicketCount$ = this.ticketService.getUnreadInProgressTicketCount();

    this.user = await this.userService.getUser();

    // Get payment details to check if empty
    this.paymentDetails = await this.paymentDetailsService.getPaymentDetails();

    try {
      this.sentryService.setUser({
        id: this.user.id,
        email: this.user.email
      });
    } catch (sentryFailure) {
      // Ignore
    }

    // Don't request push permission from onboarding users, as they will be asked to
    // accept push permission during the "What's Next" slides
    if (!this.user.isOnboarding()) {
      try {
        await this.notificationService.requestPermission(true);
      } catch (e) {
        try {
          this.sentryService.log({
            message: 'Failed to request notification permission.',
            level: 'error',
            extras: { e }
          });
        } catch (sentryFailure) {
          // Ignore
        }
      }
    }

    // Set the app badge count to be based on the count of unread chat messages
    this.unreadChatMessagesCount$.subscribe(unreadChatsCount => {
      unreadChatsCount ? this.badge.set(unreadChatsCount) : this.badge.clear();
    });

    // Asynchronously check for the Housekeep Academy link
    this.checkHousekeepAcademyFeatureAndSetUrl().then(noop);
  }

  private onDemoModeEvent(event: string[]): void {
    const [eventName] = event;
    if (eventName === 'on') {
      // Add a 'What's Next?' nav item before 'Help'
      this.navItems.splice(
        this.navItems.findIndex(({ id }) => id === 'help'),
        0,
        {
          id: 'whats-next',
          title: "What's Next?",
          icon: 'face-happy',
          url: '/app-housekeepers/whats-next',
          params: {}
        }
      );
    } else if (eventName === 'off') {
      this.navItems = this.navItems.filter(({ id }) => id !== 'whats-next');
    }
  }
}
