<ion-card [class.changes]="hasPetChanges">
  <ion-card-header>
    <ion-card-title>
      Pets
      <ion-badge *ngIf="hasPetChanges" color="warning" class="changed-badge">
        <hk-wc-icon name="recurring-arrow" size="16"></hk-wc-icon>
        Updated
      </ion-badge>
    </ion-card-title>
  </ion-card-header>

  <ion-card-content *ngIf="hasPets()">
    <div class="pets-item pets-item-pet-type">
      <p class="pets-item-label">Types</p>
      <div *ngFor="let petType of getPetsTypesNotIncludingOtherPets()">
        <div
          class="pets-item-value pets-item-value--{{ petType }}"
          [class.changes]="visitChanges.hasPropertyChanges(petType)"
        >
          <hk-wc-icon *ngIf="petType === 'dogs'" name="dog" size="20"></hk-wc-icon>
          <hk-wc-icon *ngIf="petType === 'cats'" name="cat" size="20"></hk-wc-icon>
          {{ petType | asPet }}
        </div>
      </div>
      <div
        *ngIf="property.pets.otherPets"
        class="pets-item-value pets-item-value--other-pets"
        [class.changes]="
          visitChanges.hasPropertyChanges('otherPets') ||
          visitChanges.hasPropertyChanges('otherPetsType')
        "
      >
        <hk-wc-icon name="paw" size="20"></hk-wc-icon>
        <span
          *ngIf="property.pets.otherPetsType"
          class="pets-item-value--other-pets-type"
        >
          {{ property.pets.otherPetsType | asPet }}
        </span>
        <span
          *ngIf="!property.pets.otherPetsType"
          class="pets-item-value--other-pets-type"
        >
          Other
        </span>
      </div>
    </div>

    <div *ngIf="property.pets.petNames" class="pets-item pets-item-pet-names">
      <p class="pets-item-label">Pet names</p>
      <p
        class="pets-item-value"
        [class.changes]="visitChanges.hasPropertyChanges('petNames')"
      >
        {{ property.pets.petNames | capitaliseSentence }}
      </p>
    </div>

    <div
      *ngIf="property.pets.petInstructions"
      class="pets-item pets-item-pet-instructions"
    >
      <p class="pets-item-label">Pet instructions</p>

      <p
        class="pets-item-value"
        [class.changes]="visitChanges.hasPropertyChanges('petInstructions')"
      >
        {{ property.pets.petInstructions | capitaliseSentence }}
      </p>
    </div>
  </ion-card-content>

  <ion-card-content *ngIf="!hasPets()">
    <div class="pets-item pets-item-pet-names">
      <div class="pets-item-label">No pets</div>
    </div>
  </ion-card-content>
</ion-card>
