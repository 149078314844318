<div
  class="inner-content"
  [class.ion-padding]="layout === 'modal'"
  [class.pad-x-0]="layout === 'card'"
  [class.pad-y-0]="layout === 'card'"
>
  <img *ngIf="image && layout === 'card'" [src]="image" class="card-image" />
  <div [class.ion-padding]="layout === 'card'">
    <p *ngIf="icon" class="mar-y-8">
      <hk-wc-icon [name]="icon" size="48" slot="start"></hk-wc-icon>
    </p>
    <h1 class="heading mar-y-8">{{ heading }}</h1>
    <img *ngIf="image && layout === 'modal'" [src]="image" />
    <p class="body mar-y-15">{{ body }}</p>
    <ion-button
      *ngIf="ctaText"
      [color]="ctaColor"
      [fill]="ctaFill"
      expand="block"
      (click)="onClickCta()"
    >
      {{ ctaText }}
    </ion-button>
    <p *ngIf="dismissText" class="ion-no-margin mar-y-8">
      <ion-text class="dismiss-text">
        <a (click)="dismiss()" class="text--muted">{{ dismissText }}</a>
      </ion-text>
    </p>
    <ion-grid class="ion-no-padding border-top" *ngIf="showTipHelpfulSection">
      <ion-row>
        <ion-col size="12" class="ion-text-center">
          <p class="text--muted">Was this helpful?</p>
        </ion-col>
        <ion-col size="6" class="pad-r-5">
          <ion-button
            color="primary"
            fill="outline"
            expand="block"
            (click)="submitTipIsHelpful(true)"
          >
            <span slot="start" class="text-large mar-r-5">👍</span>
            Yes
          </ion-button>
        </ion-col>
        <ion-col size="6" class="pad-l-5">
          <ion-button
            color="primary"
            fill="outline"
            expand="block"
            (click)="submitTipIsHelpful(false)"
          >
            <span slot="start" class="text-large mar-r-5">👎</span>
            No
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</div>
