import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Job } from '@housekeep/infra';

import { AssignableJob } from 'models/assignable-job';

@Component({
  selector: 'hk-extra-jobs-regular-job',
  templateUrl: './hk-extra-jobs-regular-job.component.html',
  styleUrls: ['./hk-extra-jobs-regular-job.component.scss']
})
export class HkExtraJobsRegularJob implements OnInit {
  @Input() public showActions: boolean = true;
  @Input() public appSignalDaysLookaheadHours: number;
  @Input() public assignableJob: AssignableJob;
  @Input() public extraJobAppSignalEnabled: boolean;
  @Input() public outsideArea: boolean = false;
  @Input() public locked: boolean = false;
  @Output() public onAdd: EventEmitter<null> = new EventEmitter();
  @Output() public moreDetails: EventEmitter<null> = new EventEmitter();

  public job: Job;
  public areaDisplayName: string;

  public ngOnInit(): void {
    this.job = this.assignableJob.job;
    this.areaDisplayName = this.job.property.postcode.area.extraJobAreaDisplayName;
  }

  public doAdd(): void {
    this.onAdd.emit(null);
  }

  public openExtraJobDetail(): void {
    this.moreDetails.emit(null);
  }
}
