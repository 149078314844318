import { DemoEndpoint } from 'services/demo-service';

import { getVisitDataFromPath } from '../../../util';

const visitEndpoint: DemoEndpoint = {
  path: '',
  handlers: [
    {
      method: 'get',
      handle(state, path) {
        return getVisitDataFromPath(path, state);
      }
    },
    {
      method: 'patch',

      /**
       * Just return the visit.
       * This is called when location data is sent to the server after a job
       * is started / finished.
       */
      handle(state, path) {
        return getVisitDataFromPath(path, state);
      }
    }
  ]
};

export { visitEndpoint };
