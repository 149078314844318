<ion-item class="item--no-padding item--input-border">
  <ion-label position="stacked">Send new message</ion-label>
  <ion-textarea
    rows="3"
    [(ngModel)]="message"
    placeholder="Enter new message">
  </ion-textarea>
</ion-item>
<ion-button
  expand="block"
  [disabled]="isSendDisabled()"
  (click)="onClickSend()"
>
  Send
</ion-button>
