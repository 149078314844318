import { defineModelProperties, getDayOfWeekName } from '@housekeep/infra';

import { availabilityPeriods, AvailabilityPeriods } from './mixin.availability';

export interface WorkerAvailability extends AvailabilityPeriods {
  dayOfWeek: number;
  dayOfWeekName: string;
  promoteDayToWorkers: boolean;
}

export const workerAvailability: WorkerAvailability = availabilityPeriods.extend({
  modelName: 'workerAvailability'
});

defineModelProperties<WorkerAvailability>(workerAvailability, {
  dayOfWeekName: {
    get() {
      return getDayOfWeekName(this.dayOfWeek);
    }
  },
  promoteDayToWorkers: {
    get(): boolean {
      return this.dayOfWeek === 4; // Friday
    }
  }
});
