import { DemoEndpoint } from '../../../demo-service';

const extraJobEligibilityEndpoint: DemoEndpoint = {
  path: 'extra-job-eligibility',
  handlers: [
    {
      method: 'get',
      handle() {
        return { is_eligible: true, minRating: 4.2, reason: '' };
      }
    }
  ]
};

export { extraJobEligibilityEndpoint };
