import { DemoEndpoint } from '../../demo-service';
import { jobsEndpoint } from './work/jobs';
import { reassignableJobsEndpoints, reassignableVisitsEndpoint } from './work/reassignable-visits';
import { visitsEndpoint } from './work/visits';

const workEndpoint: DemoEndpoint = {
  path: 'work',
  endpoints: [reassignableJobsEndpoints, reassignableVisitsEndpoint, visitsEndpoint, jobsEndpoint]
};

export { workEndpoint };
