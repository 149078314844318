import { DemoEndpoint } from 'services/demo-service';

const rateVisitEndpoint: DemoEndpoint = {
  path: 'worker/[a-z0-9]+/rate-visit/[a-z0-9]+/\\d{4}-\\d{2}-\\d{2}',
  handlers: [
    {
      method: 'put',
      handle(state, path, parameters, headers, body) {
        return body;
      }
    }
  ]
};

export { rateVisitEndpoint };
