import { DateTime, defineModelProperties, model, Model } from '@housekeep/infra';

export const DISPLAY_STATUS_IN_PROGRESS = 'In progress';
export const DISPLAY_STATUS_CLOSED = 'Closed';

export enum Status {
  New = 'new',
  Open = 'open',
  Pending = 'pending',
  Hold = 'hold',
  Solved = 'solved',
  Closed = 'closed'
}

export const OPEN_STATUSES = [Status.New, Status.Open, Status.Pending, Status.Hold];
export const CLOSED_STATUSES = [Status.Solved, Status.Closed];

export enum Priority {
  Low = 'low',
  Normal = 'normal',
  High = 'high',
  Urgent = 'urgent'
}

interface TicketMixin {
  isClosedToUser: boolean;
  statusDisplay: string;
}

export interface Ticket extends Model, TicketMixin {
  status: Status;
  priority?: Priority;
  subject: string;
  ticketId: string;
  createdAt: DateTime;
  lastUpdatedAt: DateTime;
  hasUnreadComments: boolean;
}

export const ticket: Ticket = model.extend({
  modelName: 'ticket'
});

defineModelProperties<Ticket>(ticket, {
  isClosedToUser: {
    get(): boolean {
      return CLOSED_STATUSES.includes(this.status);
    }
  },
  statusDisplay: {
    get(): string {
      if (this.isClosedToUser) {
        return DISPLAY_STATUS_CLOSED;
      }
      return DISPLAY_STATUS_IN_PROGRESS;
    }
  }
});
