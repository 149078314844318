import { Component, OnDestroy, OnInit } from '@angular/core';

import { ModalController } from '@ionic/angular';

import { AnalyticsService } from 'services/analytics-service';

/**
 * Abstract class to handle core functionality for all modal components.
 *
 * Requires the child class to provide:
 * - AnalyticsService as analyticsService
 * - ModalController as modalController
 */
@Component({
  template: ''
})
export abstract class ModalBase implements OnInit, OnDestroy {
  protected abstract MODAL_NAME: string;

  protected active: boolean;
  protected analyticsService: AnalyticsService;
  protected modalController: ModalController;

  public dismiss(dismissParams: object = {}): Promise<any> {
    this.analyticsService.trackModalDismissal(this.MODAL_NAME);
    return this.modalController.dismiss(dismissParams);
  }

  public ngOnInit() {
    this.modalDidLoad();
  }

  public ngOnDestroy(): void {
    this.modalWillUnload();
  }

  public ionViewWillEnter() {
    this.modalWillEnter();
  }

  public ionViewDidEnter() {
    this.active = true;
    this.analyticsService.trackModal(this.MODAL_NAME);
    this.modalDidEnter();
  }

  public ionViewWillLeave() {
    this.modalWillLeave();
  }

  public ionViewDidLeave() {
    this.active = false;
    this.modalDidLeave();
  }

  public isActive() {
    return this.active;
  }

  protected modalDidLoad() {
    // will be overridden by child views
  }

  protected modalWillEnter() {
    // will be overridden by child views
  }

  protected modalDidEnter() {
    // will be overridden by child views
  }

  protected modalWillLeave() {
    // will be overridden by child views
  }

  protected modalDidLeave() {
    // will be overridden by child views
  }

  protected modalWillUnload() {
    // will be overridden by child views
  }
}
