import { toDate } from '@housekeep/infra';

import { DemoEndpoint } from '../../../demo-service';
import { getPagedRatingData } from '../../util';

export const statsEndpoint: DemoEndpoint = {
  path: 'stats',
  handlers: [
    {
      method: 'get',
      handle(state, path, parameters) {
        // Get all ratings.
        const ratingData = getPagedRatingData(state);
        let ratings = ratingData.results;

        // Filter out ratings older than desired.
        const minDateString = parameters.get('from_date');
        if (minDateString) {
          const minDate = toDate(minDateString);
          ratings = ratings.filter(rating => toDate(rating.visit.actual_date) >= minDate);
        }

        // Return calculated stats from the ratings.
        return {
          fulfilment_percentage: '0.9',
          fulfilment_state: 'Good',
          fulfilment_is_above_minimum: true,
          fulfilment_is_above_target: true,
          rating_average: '4.9',
          rating_count: ratings.length,
          rating_five_star_count: ratings.filter(rating => rating.rating === 5).length,
          rating_four_star_count: ratings.filter(rating => rating.rating === 4).length,
          rating_is_above_average: true,
          rating_is_above_target_average: true,
          rating_is_above_trialling_average: true,
          rating_is_above_trialling_target_average: true,
          rating_low_count: ratings.filter(rating => rating.rating < 4).length,
          tip_total_pounds: ratings
            .map(rating => rating.visit.tip_amount_pounds || 0)
            .reduce((amount1, amount2) => Number(amount1) + Number(amount2)),
          time_off_count: 2,
          total_visits: 20,
          visit_count: 18,
          reliability: '0.96',
          days_reliable: 20,
          fines_pounds: -20.0,
          visits_affected_late: 0,
          target_average_rating: 4.8,
          trialling_target_average_rating: 4.5,
          trialling_target_visit_count: 25
        };
      }
    }
  ]
};
