import { dateField, modelSerializer, ModelSerializer } from '@housekeep/infra';

import { workerDailyStats, WorkerDailyStats } from 'models/worker-daily-stats';

export const workerDailyStatsSerializer = modelSerializer.extend({
  model: workerDailyStats,
  fields: {
    date: dateField.create()
  }
}) as ModelSerializer<WorkerDailyStats>;
