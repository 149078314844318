import {
  Date,
  DATE_FORMAT,
  dateToStr,
  dayOfWeek,
  toDate,
  transformObj,
  Visit,
  visitSerializer
} from '@housekeep/infra';

import { WORKER_AVAILABILITY, WORKER_AVAILABILITY_CHANGES } from './data/availability';
import { EXTRA_JOBS_ONE_OFF, EXTRA_JOBS_REGULAR } from './data/extra-jobs';
import { PAGED_PAYMENT_PERIOD_TEMPLATES } from './data/payment-periods';
import { getPagedRatings } from './data/ratings';
import { VISIT_TEMPLATES_BY_JOB_ID } from './data/visits';
import { WORKER_DAILY_STATS } from './data/worker-daily-stats';
import { workingDayTemplates } from './data/working-days';

interface Replacement {
  regexString: string;
  replacer: Function | string;
}

function getTemplateKeyRegex(key: string) {
  return new RegExp(`{{ *(${key}) *}}`, 'g');
}

/**
 * Replace the template strings within the given object.
 *
 * If a value in the replacement object is a function, it is run on the
 * template string, and the template string is replaced with the result;
 * otherwise, the template string is simply replaced with the value.
 *
 * @example
 * >>> parseTemplateStrings(
 * ...   { key: 'x marks the {{ y }}' },
 * ...   [{ regexString: 'y', replacer: 'spot' }]
 * ... );
 * { key: 'x marks the spot' };
 */
function parseTemplateStrings(obj: any, replacements: Array<Replacement>) {
  return transformObj(obj, null, (key, val) => {
    if (typeof val === 'string') {
      replacements.forEach(replacement => {
        const keyRegex = getTemplateKeyRegex(replacement.regexString);
        const regexMatch = keyRegex.exec(val);

        if (regexMatch) {
          val = val.replace(keyRegex, replacement.replacer);
        }
      });
    }

    return { key, val };
  });
}

/**
 * Get a copy of an object with its date placeholders in its string
 * properties replaced as appropriate.
 * @param {Object} obj The object to clone whose string properties will be
 *   replaced
 * @param {Date|string} date The date with which to replace the date
 *   placeholders; either a Moment object or a `YYYY-MM-DD` string
 * @return {Object} The cloned object with replaced string properties
 */
function cloneAndReplaceDates(obj: any, date: Date | string) {
  const dateMoment = toDate(date);
  const weekBeginning = dateMoment.clone().startOf('isoWeek');

  const dateReplacer = _getDateStringReplacer(dateMoment);

  return parseTemplateStrings(obj, [
    { regexString: 'date(?: (-|#|\\+) (\\d+))?', replacer: dateReplacer },
    { regexString: 'weekBeginning', replacer: dateToStr(weekBeginning) }
  ]);
}

/**
 * Get paged response data for payment periods.
 */
function getPagedPaymentPeriodData(demoState) {
  // Try to get it from the state directly.
  let data = demoState.pagedPaymentPeriods;

  // If that doesn't work, generate and store it.
  if (!data) {
    const nextFriday = goBackWeeksAndFindWeekday(demoState.today(), '12');
    data = cloneAndReplaceDates(PAGED_PAYMENT_PERIOD_TEMPLATES, nextFriday);
  }

  return data;
}

/**
 * Get worker availability.
 */
function getWorkerAvailabilityData(demoState) {
  return WORKER_AVAILABILITY;
}

/**
 * Get worker availability changes (Time Off bookings).
 */
function getWorkerAvailabilityChangesData(demoState) {
  let data = demoState.workerAvailabilityData;

  if (!data) {
    data = cloneAndReplaceDates(WORKER_AVAILABILITY_CHANGES, demoState.today());
    demoState.workerAvailabilityData = data;
  }

  return data;
}

function getWorkerDailyStatsData(demoState) {
  return cloneAndReplaceDates(WORKER_DAILY_STATS, demoState.today());
}

/**
 * Get paged response data for ratings.
 */
function getPagedRatingData(demoState) {
  // Try to get it from the state directly.
  let data = demoState.pagedRatings;

  // If that doesn't work, generate and store it.
  // TODO: Replace the dates to make sense.
  if (!data) {
    data = getPagedRatings(demoState);
  }

  return data;
}

/**
 * Get a visit's response data for a date and job ID.
 */
function getVisitData(demoState, date: Date | string, jobId: string) {
  const dateMoment = toDate(date);

  // Try to get it from the state directly.
  const key = `${jobId}:${dateToStr(dateMoment)}`;
  let visit = demoState.visits[key];

  // If that doesn't work, clone it from the default work data, and store it.
  if (!visit) {
    visit = cloneAndReplaceDates(VISIT_TEMPLATES_BY_JOB_ID[jobId], date);
    demoState.visits[key] = visit;
  }

  return visit;
}

/**
 * Return a serialized visit matching the given path
 */
function getVisitDataFromPath(path: string, state: any) {
  const pathMatch = path.match(/.*\/jobs\/([a-z0-9]+)\/visits\/(\d{4}-\d{2}-\d{2})/);
  const [, jobId, visitDateString] = pathMatch;
  return getVisitData(state, visitDateString, jobId);
}

function getVisitStateKey(visit: Visit): string {
  return `${visit.jobId}:${dateToStr(visit.scheduledDate)}`;
}

function updateVisitState(visit: Visit, state: any) {
  state.visits[getVisitStateKey(visit)] = visitSerializer.deserialize(visit);
}

/**
 * Get paged response data for extra jobs one off.
 */
function getExtraJobsOneOffData(demoState) {
  // Try to get it from the state directly.
  let data = demoState.extraJobsOneOffData;

  // If that doesn't work, generate and store it.
  if (!data) {
    data = cloneAndReplaceDates(EXTRA_JOBS_ONE_OFF, demoState.today());
  }

  return data;
}

/**
 * Get paged response data for extra jobs regular.
 */
function getExtraJobsRegularData(demoState) {
  // Try to get it from the state directly.
  let data = demoState.extraJobsRegularData;

  // If that doesn't work, generate and store it.
  if (!data) {
    data = cloneAndReplaceDates(EXTRA_JOBS_REGULAR, demoState.today());
  }

  return data;
}

/**
 * Get a working-day's response data including the day's visits.
 */
function getWorkingDayData(demoState, date: Date | string) {
  // Convert the date to a Moment, cloning it if it already is one.
  const dateMoment = toDate(date);

  // Try to get it from the state directly.
  const key = dateMoment.format(DATE_FORMAT);
  let workingDay = demoState.workingDays[key];

  // If that doesn't work, generate it from a template and store it.
  if (!workingDay) {
    const templateIndex = getWorkingDayIndex(demoState.today(), dateMoment);
    workingDay = cloneAndReplaceDates(workingDayTemplates[templateIndex], date);
  }

  // Add the appropriate visits to the working days.
  workingDay.activities.forEach(activity => {
    const jobId = activity.visit__job_id;

    if (jobId) {
      activity.visit = getVisitData(demoState, date, jobId);
    }
  });

  // Store the working day.
  demoState.workingDays[key] = workingDay;

  return workingDay;
}

/**
 * Return a number representing the index of the working day to retrieve.
 *
 * The index value returned is always between 0 and 6, and relative to today,
 * such that requesting the working day for both today and a week from today
 * returns 0.
 */
function getWorkingDayIndex(today: Date, workingDayDate: Date): number {
  const todayDayOfWeek = dayOfWeek(today);
  const workingDayOfWeek = dayOfWeek(workingDayDate);
  return (7 + workingDayOfWeek - todayDayOfWeek) % 7;
}

/**
 * Return a function to replace placeholders relative to the given date.
 *
 * The arguments of this returned function conform to those expected by the
 * String.prototype.replace method (https://mzl.la/2GbmLeL).
 *
 * This function is used to allow date-relative template strings such as
 * {{ date - 1 }} and {{ date + 1 }} to be replaced by the given date adjusted
 * by the given number of days.
 */
function _getDateStringReplacer(date: Date | string) {
  return (match: string, templateExpression: string, operator: string, dayOffset: string) => {
    // Convert the date to a Moment, cloning it if it already is one.
    let dateMoment = toDate(date);

    if (operator === '+') {
      dateMoment = dateMoment.add(dayOffset, 'days');
    } else if (operator === '-') {
      dateMoment = dateMoment.subtract(dayOffset, 'days');
    } else if (operator === '#') {
      dateMoment = goBackWeeksAndFindWeekday(dateMoment, dayOffset);
    }

    return dateToStr(dateMoment);
  };
}

/*
 * Day weekday modulo offset
 *
 * If dayOffset < 7 then simple nearest weekday find.
 * eg. 1 = nearest Monday, 6 = nearest Saturday
 *
 * If dayOffset >= 7 then week division then modulo.
 * eg 8: floor(8/7) = 1 week in past. 8%7 = 1 (Monday). Net: Last week Monday.
 * eg 19: floor(19/7) = 2 week in past. 19%7 = 5 (Friday). Net: 2 weeks ago Fri.
 */
function goBackWeeksAndFindWeekday(dateMoment: Date, dayOffset: string) {
  const offset = Number(dayOffset);

  if (offset > 7) {
    const weekOffset = Math.floor(offset / 7);
    dateMoment = dateMoment.subtract(weekOffset, 'weeks');
  }

  const targetWeekday = offset % 7;
  const dateWeekday = dateMoment.isoWeekday();
  if (dateWeekday <= targetWeekday) {
    return dateMoment.isoWeekday(targetWeekday);
  } else {
    return dateMoment.add(1, 'weeks').isoWeekday(targetWeekday);
  }
}

export {
  cloneAndReplaceDates,
  getExtraJobsOneOffData,
  getExtraJobsRegularData,
  getPagedPaymentPeriodData,
  getPagedRatingData,
  getWorkerAvailabilityData,
  getWorkerAvailabilityChangesData,
  getWorkerDailyStatsData,
  getVisitData,
  getVisitDataFromPath,
  updateVisitState,
  getWorkingDayData,
  getWorkingDayIndex,
  parseTemplateStrings,
  goBackWeeksAndFindWeekday
};
