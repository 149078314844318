import { Date, VisitRating, WorkerMetrics } from '@housekeep/infra';

export interface VisitRatingWeek {
  startDay: Date;
  visitRatings: VisitRating[];
}

export class RatingPeriod {
  public dayCount: number; // should be positive integer or `Infinity`
  public hasMore: boolean = true;
  public summary: WorkerMetrics;
  public visitRatings: any[] = [];
  public weeks: VisitRatingWeek[] = [];

  constructor(dayCount: number = Infinity) {
    this.dayCount = dayCount;
  }

  get isAllTime(): boolean {
    return !isFinite(this.dayCount);
  }
}
