import { Injectable } from '@angular/core';

import { PaymentDetails } from 'models/payment-details';

import { paymentDetailsSerializer } from 'serializers/payment-details';

import { RequestService } from './request-service';
import { UserService } from './user-service';

export interface PaymentDetailsFormData {
  nameOnAccount?: string;
  accountNumber?: string;
  sortCode?: string;
  nationalInsuranceNumber?: string;
}

@Injectable({ providedIn: 'root' })
export class PaymentDetailsService {
  constructor(public userService: UserService, public requestService: RequestService) {}

  public getPaymentDetails(): Promise<PaymentDetails> {
    return this.userService.getUser().then(user => {
      const endpoint = `workers/${user.id}/payment-details/`;
      return this.requestService.getInstance(endpoint, paymentDetailsSerializer);
    });
  }

  public setPaymentDetails(updatedDetails: PaymentDetailsFormData): Promise<PaymentDetails> {
    return this.userService.getUser().then(user => {
      const endpoint = `workers/${user.id}/payment-details/`;
      return this.requestService.patch(endpoint, updatedDetails);
    });
  }
}
