import { subtractDaysFromDate, todayNaive } from '@housekeep/infra';

import { DemoEndpoint } from '../../demo-service';

const userImageHost = 'https://s3-eu-west-1.amazonaws.com/';
const userImagePath = 'housekeep-static/housekeepers-app/demo/demo-user.jpg';

const SEVENTEEN_DAYS_WED_ISO = subtractDaysFromDate(todayNaive(), 17).isoWeekday(3).toISOString();

const authEndpoint: DemoEndpoint = {
  path: 'auth',
  handlers: [
    {
      method: 'get',
      handle(state) {
        return {
          created: SEVENTEEN_DAYS_WED_ISO,
          started_work: SEVENTEEN_DAYS_WED_ISO,
          last_activation_dt: SEVENTEEN_DAYS_WED_ISO,
          last_confirmation_dt: SEVENTEEN_DAYS_WED_ISO,
          last_app_usage: SEVENTEEN_DAYS_WED_ISO,
          first_names: 'Danielle',
          last_name: 'Demo',
          email: 'demo.mode@housekeep.com',
          gender: 'f',
          postcode: {
            outward: 'SW15',
            inward: '2BU',
            location: {
              latitude: '51.523',
              longitude: '-0.108'
            }
          },
          image: userImageHost + userImagePath,
          current_rating: 4.9,
          num_ratings: 12,
          trust_level: 'trialling',
          status: 'active',
          reliability: '0.98',
          primary_telephone_number: '07700 900045',
          has_secondary_numbers: true,
          assigned_areas: {
            regular: ['SW1', 'WC1'],
            one_off: ['SW', 'WC']
          },
          admin_fee: '5.00',
          base_pay: '11.50',
          hourly_bonus: 0.5,
          is_eligible_for_bonus: false,
          pay_increase_date: null,
          applicant_id: null,
          id: 'abcdef123456',
          background_check: true,
          works_with_cats: true,
          works_with_dogs: true,
          works_with_other_pets: true
        };
      }
    }
  ]
};

export { authEndpoint };
