import { Injectable } from '@angular/core';

import { DateTime } from '@housekeep/infra';

import { TimeService } from './time-service';

export enum LaunchContext {
  NormalAppOpen = 'normal',
  Deeplink = 'deeplink',
  PushNotification = 'push'
}

interface DeeplinkContextBlob {
  path?: string;
  url?: string;
}

interface PushContextBlob {
  deliveryId: string;
}

/**
 * The app context service records information about how the app was launched, for
 * analytics purposes.
 *
 * This includes the launch method (e.g. via deeplink or push notification)
 * as well as any relevant context data (e.g. the delivery ID of the push notification,
 * to allow us to track subsequent events back to a specific push).
 */
@Injectable({ providedIn: 'root' })
export class AppContextService {
  constructor(private timeService: TimeService) {}

  public launchContext: LaunchContext = LaunchContext.NormalAppOpen;
  public launchContextBlob: DeeplinkContextBlob | PushContextBlob;
  public launchedAt: DateTime = this.timeService.now();

  public setLaunchContext(launchContext: LaunchContext, contextBlob: DeeplinkContextBlob | PushContextBlob) {
    this.launchContext = launchContext;
    this.launchContextBlob = contextBlob;
    this.launchedAt = this.timeService.now();
  }

  public isDeeplinkContext(): boolean {
    return this.launchContext === LaunchContext.Deeplink;
  }

  public isPushContext(): boolean {
    return this.launchContext === LaunchContext.PushNotification;
  }

  public isNormalContext(): boolean {
    return this.launchContext === LaunchContext.NormalAppOpen;
  }
}
