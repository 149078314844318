import { Component, Input } from '@angular/core';

@Component({
  selector: 'tip-badge',
  templateUrl: './tip-badge.component.html',
  styleUrls: ['./tip-badge.component.scss']
})
export class TipBadgeComponent {
  @Input() amount: number;
}
