import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { noop } from 'lodash-es';

import { Subscription } from 'rxjs';

import { AuthEventArgs, AuthService } from 'services/auth-service';
import { DaysByWeek, Schedule, ScheduleService } from 'services/schedule-service';
import { UserService } from 'services/user-service';

/**
 * A component to display the list of days in the worker's upcoming schedule.
 * This component is displayed within an off-canvas menu pulled in from the
 * right.
 */
@Component({
  selector: 'schedule-list',
  templateUrl: './schedule-list.component.html',
  styleUrls: ['./schedule-list.component.scss']
})
export class ScheduleListComponent implements OnDestroy, OnInit {
  @Input() menuToggle = false;

  public loadError: boolean = false;

  public schedule: Schedule;
  public scheduleByWeek: DaysByWeek[] = [];
  private scheduleObserver: Subscription;

  constructor(
    public authService: AuthService,
    public scheduleService: ScheduleService,
    public userService: UserService
  ) {}

  initSchedule() {
    this.scheduleObserver = this.scheduleService.subscribe(schedule => {
      if (schedule instanceof Error) {
        this.loadError = true;
      } else {
        this.schedule = schedule;
        this.scheduleByWeek = this.scheduleService.groupByWeek(schedule.days);
        this.loadError = false;
      }
    }, false);
  }

  teardownSchedule() {
    if (this.scheduleObserver) {
      this.scheduleObserver.unsubscribe();
    }
  }

  ngOnInit() {
    // We only want to initialise the schedule if there is a logged in user
    this.userService
      .getUser()
      .then(() => this.initSchedule())
      .catch(noop);

    this.authService.subscribe(event => this._onAuthEvent(event));
  }

  ngOnDestroy() {
    this.teardownSchedule();
  }

  private _onAuthEvent(event: AuthEventArgs) {
    const [eventName] = event;

    if (eventName === 'login') {
      this.initSchedule();
    } else if (eventName === 'logout') {
      this.teardownSchedule();
    }
  }
}
