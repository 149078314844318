import { defineModelProperties, model, Model } from '@housekeep/infra';

export interface WorkerExtraJobSignalAvailability extends Model {
  appSignalDaysLookahead: number;
  appSignalDaysLookaheadHours: number;
  appSignalEnabled: boolean;
  appSignalRefreshIntervalSeconds: number;
  oneOffVisitsAvailable: number;
  oneOffVisitsOutOfArea: number;
  oneOffVisitsOutOfAvailability: number;
  regularJobsAvailable: number;
  regularJobsOutOfArea: number;
  regularJobsOutOfAvailability: number;
  workAvailable: boolean;
}

export const workerExtraJobSignalAvailability: WorkerExtraJobSignalAvailability = model.extend({
  modelName: 'workerExtraJobSignalAvailability'
});

defineModelProperties<WorkerExtraJobSignalAvailability>(workerExtraJobSignalAvailability, {
  appSignalDaysLookaheadHours: {
    get(): number {
      return (this.appSignalDaysLookahead || 0) * 24;
    }
  }
});
