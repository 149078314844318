// Finished visit counts
const visitCounts = [
  2,
  3,
  2,
  1,
  4,
  0,
  0, // week 1
  1,
  2,
  3,
  2,
  2,
  0,
  0, // week 2
  0,
  0,
  0,
  0,
  0,
  0,
  0, // week 3
  0,
  0,
  0,
  2,
  2,
  2,
  0, // week 4
  2,
  3,
  2,
  3,
  4,
  0,
  0, // week 5
  1,
  2,
  3,
  2,
  2,
  0,
  0, // week 6
  2,
  3,
  2,
  3,
  4,
  0,
  0, // week 7
  1,
  2,
  3,
  2,
  2,
  1,
  0, // week 8
  2,
  3,
  2,
  3,
  1 // week 9 ending today
];

// Late cancellations
const cancellations = [0, 0, 0, 0];

export const WORKER_DAILY_STATS = visitCounts.map((visitCount, dayCount) => {
  return {
    visitCount,
    visitsAffectedLate: dayCount in cancellations ? cancellations[dayCount] : 0,
    date: '{{ date - ' + (60 - dayCount) + '}}'
  };
});
