import { Date, Duration, model, Model, Postcode, Visit } from '@housekeep/infra';

import { WorkerBreakNotice } from './worker-break';

export interface AffectedVisit extends Visit {
  noticeCategory?: WorkerBreakNotice;
  fine: number;
  requiredDuration: Duration;
  customerPreferredName: string;
  postcode: Postcode;
  visitDate: Date;
}

export const affectedVisit: AffectedVisit = model.extend({
  modelName: 'affectedVisit'
});

export interface WorkerBreakEffects extends Model {
  noticeCategory?: WorkerBreakNotice; // should be null if worker extended their hours
  affectedVisits: AffectedVisit[];
  reliability: number;
  finesAreWaived: boolean;
  previouslyHadFinesWaived: boolean;
  keyReturnRequired: boolean;
  keyReturnThreshold: number;
  keyReturnPay: number;
  hasFines: () => boolean;
  hasFineableCancellations: () => boolean;
  hasReliabilityAffectingCancellations: () => boolean;
  totalFineAmount: () => number;
  totalHoursRemoved: () => number;
  totalFineableCancellations: () => number;
}

export const workerBreakEffects: WorkerBreakEffects = model.extend({
  modelName: 'workerBreakEffects',

  hasFines(): boolean {
    return !this.finesAreWaived && this.affectedVisits.some(visit => visit.fine);
  },

  hasFineableCancellations(): boolean {
    return this.affectedVisits.some(visit => visit.noticeCategory !== WorkerBreakNotice.Good);
  },

  hasReliabilityAffectingCancellations(): boolean {
    return this.affectedVisits.some(visit => visit.noticeCategory === WorkerBreakNotice.Late);
  },

  totalFineAmount(): number {
    return this.affectedVisits.map(visit => visit.fine).reduce((acc, cur) => acc + cur, 0);
  },

  totalHoursRemoved(): number {
    return this.affectedVisits.map(visit => visit.requiredDuration.toHours()).reduce((acc, cur) => acc + cur, 0);
  },

  totalFineableCancellations(): number {
    return this.affectedVisits.filter(visit => visit.noticeCategory !== WorkerBreakNotice.Good).length;
  }
});
