import { Component } from '@angular/core';

import { environment } from 'environment/environment';
import { devEnvironment } from 'environment/environment.dev';
import { prodEnvironment } from 'environment/environment.prod';
import { stagingEnvironment } from 'environment/environment.staging';

@Component({
  selector: 'debug-menu',
  templateUrl: './debug-menu.component.html',
  styleUrls: ['./debug-menu.component.scss']
})
export class DebugMenuComponent {
  public get currentEnv(): string {
    return environment.ID;
  }

  public setProdEnv() {
    this._setEnv(prodEnvironment.ID);
  }

  public setStagingEnv() {
    this._setEnv(stagingEnvironment.ID);
  }

  public setDevEnv() {
    this._setEnv(devEnvironment.ID);
  }

  private _setEnv(newEnvironment: string) {
    localStorage.setItem('environment', newEnvironment);
    location.reload();
  }
}
