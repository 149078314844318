<div
  class="title"
  (click)="toggle()"
>
  <strong>{{title}}</strong>
  <ion-icon
    [name]="isOpen ? 'arrow-up' : 'arrow-down'"
  ></ion-icon>
</div>
<div
  *ngIf="isOpen"
  class="content"
>
  <ng-content></ng-content>
</div>
