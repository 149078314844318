import { Injectable } from '@angular/core';

import { v4 as uuidv4 } from 'uuid';

import { nowNaive, sleep, visitRatingSerializer, visitSerializer } from '@housekeep/infra';

import { OfflineCacheUpdate, RequestOptsWithOfflineHandling } from 'util/http';

import { OfflineEventActionTypes, OfflineEventObjectTypes } from 'var/offline-events';

import { ErrorService } from './error-service';
import { OFFLINE_EVENTS_KEY, StorageService } from './storage-service';
import { ToastService } from './toast-service';

interface OfflineEvent {
  endpoint: string;
  eventRegisteredAt: number;
  expiryTimestamp?: number;
  method: 'patch' | 'post' | 'put';
  objectId: string; // external ID
  objectType: OfflineEventObjectTypes; // model name (eg Visit)
  offlineResponsePayload: any;
  offlineCachePayload: OfflineCacheUpdate[];
  opts: RequestOptsWithOfflineHandling;
  payload: any;
  raiseOfflineError?: boolean;
  type: OfflineEventActionTypes;
  uuid: string;
}

interface OfflineEventQueue {
  events: OfflineEvent[];
}

interface EventDeletionResponse {
  queue: OfflineEventQueue;
  itemsDeleted: number;
}

const DEFAULT_QUEUE_STATE = { events: [] };

@Injectable({ providedIn: 'root' })
class OfflineEventService {
  public queueAccessLock = false;

  constructor(
    private errorService: ErrorService,
    private toastService: ToastService,
    private storageService: StorageService
  ) {}

  /**
   * Load the current offline events queued up in storage
   */
  public async loadOfflineQueue(): Promise<OfflineEventQueue> {
    const queue = await this.storageService.get(OFFLINE_EVENTS_KEY);
    return queue ? JSON.parse(queue) : DEFAULT_QUEUE_STATE;
  }

  /**
   * Returns true if the offline event queue has entries.
   */
  public async offlineQueuePopulated(): Promise<boolean> {
    const queue = await this.loadOfflineQueue();
    return queue.events.length !== 0;
  }

  /**
   * Set the queue on disk with the queue provided
   */
  public async setOfflineQueue(queue: OfflineEventQueue): Promise<OfflineEventQueue> {
    await this.storageService.set(OFFLINE_EVENTS_KEY, JSON.stringify(queue));
    return queue;
  }

  /**
   * Return the event at the top of the queue - FIFO
   */
  public async getNextEventFromQueue(): Promise<OfflineEvent | undefined> {
    await this.getQueueLock();
    try {
      const queue = await this.loadOfflineQueue();

      if (queue.events.length === 0) {
        return undefined;
      }

      const offlineEvent = queue.events[0];
      if (offlineEvent.expiryTimestamp) {
        if (offlineEvent.expiryTimestamp < nowNaive().unix()) {
          // Expired
          await this.deleteEventByUUID(offlineEvent.uuid);
          return this.getNextEventFromQueue();
        }
      }

      return this.fixEventForDispatch(offlineEvent);
    } finally {
      this.releaseQueueLock();
    }
  }

  /**
   * Add event to the bottom of the queue - FIFO
   */
  public async pushToQueue(offlineEvent: OfflineEvent): Promise<OfflineEventQueue> {
    await this.getQueueLock();
    try {
      const queue = await this.loadOfflineQueue();
      queue.events.push(offlineEvent);
      return this.setOfflineQueue(queue);
    } finally {
      this.releaseQueueLock();
    }
  }

  /**
   * Add a new offline event to the queue for processing
   */
  public async registerOfflineEvent(
    method: 'patch' | 'post' | 'put',
    endpoint: string,
    serialized: any,
    opts: RequestOptsWithOfflineHandling
  ): Promise<OfflineEvent> {
    const mockOfflineResponse = opts.offline.offlineResponsePayload || serialized || {};
    mockOfflineResponse._offline = { isOfflineEvent: true };

    let offlineEvent: OfflineEvent = {
      endpoint,
      eventRegisteredAt: nowNaive().unix(),
      expiryTimestamp: opts.offline.expiryTimestamp,
      method,
      objectId: opts.offline.objectId,
      objectType: opts.offline.objectType,
      offlineCachePayload: opts.offline.offlineCachePayload || [],
      offlineResponsePayload: mockOfflineResponse,
      opts,
      payload: serialized,
      type: opts.offline.type,
      uuid: uuidv4()
    };

    try {
      offlineEvent = await this.preProcessEvent(offlineEvent);
    } catch (err) {
      // Do not actually enqueue if error raised in pre-process
      return offlineEvent;
    }

    await this.pushToQueue(offlineEvent);

    return offlineEvent;
  }

  /**
   * Delete any queued events matching ObjectType and ObjectId
   */
  public async deleteOfflineEventsForObject(
    objectType: OfflineEventObjectTypes,
    objectId: string
  ): Promise<EventDeletionResponse> {
    await this.getQueueLock();
    try {
      const queue = await this.loadOfflineQueue();
      const filteredEvents = queue.events.filter(ev => ev.objectType !== objectType && ev.objectId !== objectId);
      const itemsDeleted = (queue.events.length || 0) - (filteredEvents.length || 0);

      queue.events = filteredEvents;
      await this.setOfflineQueue(queue);
      return { queue, itemsDeleted };
    } finally {
      this.releaseQueueLock();
    }
  }

  /**
   * Delete any queued events matching UUID
   */
  public async deleteEventByUUID(uuid: string): Promise<EventDeletionResponse> {
    await this.getQueueLock();
    try {
      const queue = await this.loadOfflineQueue();
      const filteredEvents = queue.events.filter(ev => ev.uuid !== uuid);
      const itemsDeleted = (queue.events.length || 0) - (filteredEvents.length || 0);

      queue.events = filteredEvents;
      await this.setOfflineQueue(queue);
      return { queue, itemsDeleted };
    } finally {
      this.releaseQueueLock();
    }
  }

  /**
   * Reset the queue to default state
   */
  public async resetOfflineEventQueue(): Promise<OfflineEventQueue> {
    await this.setOfflineQueue(DEFAULT_QUEUE_STATE);
    return DEFAULT_QUEUE_STATE;
  }

  /**
   * Handle error raised when attempting to sync OfflineEvent to backend
   */
  public async onOfflineRequestError(err: Error, offlineEvent: OfflineEvent): Promise<boolean> {
    let message = offlineEvent.opts.offline.requestFailedMessage || null;
    const theme = 'warning';

    const systemError = this.errorService.getErrorMessage(err);
    if (systemError) {
      message = message ? `${message} - ${systemError}` : `${systemError}`;
    }

    if (message) {
      this.toastService.showToast({ message, theme });
    }

    return !!message;
  }

  /**
   * Some events will have side-effects or require modification before being
   * saved to the queue. This function provides a hook to modify the event body
   * or to action side-effects.
   * @param offlineEvent
   */
  private async preProcessEvent(offlineEvent: OfflineEvent): Promise<OfflineEvent> {
    if (offlineEvent.type === OfflineEventActionTypes.UnstartVisit) {
      const deletionResponse = await this.deleteOfflineEventsForObject(offlineEvent.objectType, offlineEvent.objectId);
      if (deletionResponse.itemsDeleted > 0) {
        throw Error('DoNotEnqueueUnstartIfExistingEvents');
      }
    }

    return offlineEvent;
  }

  /**
   * Make event modifications before its sent to the backend and deleted from
   * the queue.
   *
   * When events are stored to disk complex properties like serializers are
   * stripped, and need to be re-attached before dispatch to backend.
   * @param offlineEvent
   */
  private fixEventForDispatch(offlineEvent: OfflineEvent): OfflineEvent {
    if (offlineEvent.objectType === OfflineEventObjectTypes.Visit) {
      offlineEvent.opts.responseSerializer = visitSerializer;
    }
    if (offlineEvent.objectType === OfflineEventObjectTypes.JobRating) {
      offlineEvent.opts.responseSerializer = visitRatingSerializer;
    }

    return offlineEvent;
  }

  /**
   * Get a lock for queue access or wait and poll until queue is available
   *
   * WARNING!: Ensure you release this as soon as you're done!
   */
  private async getQueueLock(): Promise<boolean> {
    if (!this.queueAccessLock) {
      this.queueAccessLock = true;
      return true;
    }

    await sleep(500);
    return await this.getQueueLock();
  }

  /**
   * Release the lock on the queue once operation complete
   */
  private releaseQueueLock(): void {
    this.queueAccessLock = false;
  }
}

export { OfflineEvent, OfflineEventQueue, OfflineEventService };
