<div class="hk-accordion" [class.hk-accordion--lines]="lines">
  <div
    class="title"
    (click)="toggle()"
    tappable
  >
    {{ title }}
    <ion-icon
      [name]="isOpen ? 'arrow-up' : 'arrow-down'"
    ></ion-icon>
  </div>
  <div
    *ngIf="isOpen"
    class="content"
  >
    <ng-content></ng-content>
  </div>
</div>
