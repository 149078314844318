<div
  class="content__no-content-message ion-padding"
  *ngIf="loadError && !scheduleByWeek.length"
>
  Could not load your schedule
</div>

<ion-list
  class="schedule-list ion-no-padding"
  *ngFor="let week of scheduleByWeek"
>
  <ion-item-group>
    <ion-item-divider class="week-divider" color="light">
      {{ week.beginning | asWeek }}
    </ion-item-divider>
    <schedule-list-day
      *ngFor="let day of week.days"
      [day]="day"
      [schedule]="schedule"
      [menuToggle]="menuToggle"
    ></schedule-list-day>
  </ion-item-group>
</ion-list>
