<form (ngSubmit)="onFormSubmit()" #nationalInsuranceNumberForm="ngForm">
  <p class="mar-t-0 mar-b-10">
    You'll find it on your National Insurance card, benefit letters, payslips or your P60
    from a past employer.
  </p>
  <ion-item class="item--no-padding item--input-border">
    <ion-label position="stacked">National Insurance number</ion-label>
    <ion-input
      name="nationalInsuranceNumber"
      placeholder="QQ 12 34 56 C"
      [spellcheck]="false"
      [required]="true"
      [maxlength]="NATIONAL_INSURANCE_NUMBER_LENGTH_WITH_SPACES"
      [(ngModel)]="nationalInsuranceNumber"
      (blur)="
        nationalInsuranceNumberForm.form.controls.nationalInsuranceNumber?.markAsTouched()
      "
      nationalInsuranceValidator
    ></ion-input>
    <p
      class="text--danger mar-t-10"
      *ngIf="
        nationalInsuranceNumberForm.form.controls.nationalInsuranceNumber?.touched &&
        !nationalInsuranceNumberForm.form.controls.nationalInsuranceNumber?.valid
      "
    >
      National Insurance numbers should appear in the following combination of letters and
      numbers - two letters, six numbers, one letter.
    </p>
  </ion-item>

  <ion-button
    expand="block"
    type="submit"
    [disabled]="!nationalInsuranceNumberForm.form.valid"
  >
    <ng-container *ngIf="isOnboardingFlow">Confirm</ng-container>
    <ng-container *ngIf="!isOnboardingFlow">Save number</ng-container>
  </ion-button>
  <ion-button
    *ngIf="isOnboardingFlow"
    class="mar-t-10"
    fill="outline"
    expand="block"
    type="button"
    (click)="onSkip.emit()"
  >
    Skip
  </ion-button>
  <hk-alert class="mar-t-15 mar-b-0" type="info" icon="exclamation-mark-circle">
    <p>
      If you have forgotten or lost your National Insurance number you can find it online
      at
      <a class="strong" (click)="openBrowser()">gov.uk</a>
    </p>
  </hk-alert>
</form>
