import { cloneDeep } from 'lodash-es';

import { combine, dateToStr, toDate, toMoment, toTime } from '@housekeep/infra';

// The dates here will be replaced below:
const PAGED_RATINGS_BASE: any = {
  num_pages: 1,
  current_page: 1,
  results: [
    {
      rating: 5.0,
      compliment: 'service',
      compliment_display: 'Excellent Service',
      visit_id: 'jo8wj0lp38ok',
      visit: {
        tip_amount_pounds: null,
        tasks: [
          {
            type: 'cleaning',
            hours: 3.0
          }
        ],
        any_worker: false,
        finished_at: '2017-05-04T14:00:00Z',
        account_id: 'vzq8jhejkjz6',
        frequency: 7.0,
        started_at: '2017-05-04T11:00:00Z',
        id: 'jo8wj0lp38ok',
        property_id: '3ba2jcqqykoe',
        property: {
          postcode: {
            inward: '3CF',
            outward: 'WC1'
          }
        },
        job_id: '9oygmtpjvlox',
        access: 'keysafe',
        time_slot: {
          start: '12:00:00',
          end: '17:00:00'
        },
        skipped: false,
        is_first_visit: false,
        finished: true,
        scheduled_date: '2017-05-04',
        actual_date: '2017-05-04',
        week_beginning: '2017-05-01',
        access_display: 'Key safe',
        customer_can_alter: false,
        is_reviewable: false,
        worker_property_access: 'can_access'
      },
      tip_pounds: 10,
      customer: {
        account_id: 'vzq8jhejkjz6',
        preferred_name: 'Declan',
        id: 'jze87sp952zn'
      },
      customer_id: 'jze87sp952zn',
      id: 'vzqe5u3ypgz6',
      more_info: 'Wow! House is looking amazing, thanks so much!'
    },
    {
      rating: 5.0,
      compliment: 'made-day',
      compliment_display: 'Made My Day',
      visit_id: 'kz3yes75rvbr',
      visit: {
        tip_amount_pounds: null,
        tasks: [
          {
            type: 'products',
            hours: null
          },
          {
            type: 'cleaning',
            hours: 3.0
          }
        ],
        any_worker: false,
        finished_at: '2017-05-03T17:20:00Z',
        account_id: 'nb7adhmk98be',
        frequency: 7.0,
        started_at: '2017-05-03T14:20:00Z',
        id: 'kz3yes75rvbr',
        property_id: 'yzdracmm8mol',
        property: {
          postcode: {
            inward: '4RE',
            outward: 'SW1'
          }
        },
        job_id: 'vz9p7tlwmabq',
        access: 'keyhiddn',
        time_slot: {
          start: '09:00:00',
          end: '17:00:00'
        },
        skipped: false,
        is_first_visit: false,
        finished: true,
        scheduled_date: '2017-05-03',
        actual_date: '2017-05-03',
        week_beginning: '2017-05-01',
        access_display: 'Key hidden',
        customer_can_alter: false,
        is_reviewable: false,
        worker_property_access: 'can_access'
      },
      tip_pounds: 5,
      customer: {
        account_id: 'nb7adhmk98be',
        preferred_name: 'Matthew',
        id: 'xowx4syer5z2'
      },
      customer_id: 'xowx4syer5z2',
      id: 'jovyqiy7akop',
      more_info: 'You are an absolute star'
    },
    {
      rating: 5.0,
      visit_id: '8zpvr18ar3zk',
      visit: {
        tip_amount_pounds: null,
        tasks: [
          {
            type: 'cleaning',
            hours: 3.0
          }
        ],
        any_worker: false,
        finished_at: '2017-04-14T15:00:00Z',
        account_id: 'dzn29hr4ylz5',
        frequency: 7.0,
        started_at: '2017-04-14T12:00:00Z',
        id: '8zpvr18ar3zk',
        property_id: 'xowxyc992pz2',
        property: {
          postcode: {
            inward: '4RE',
            outward: 'SW1'
          }
        },
        job_id: 'rom4qtkr75oy',
        access: 'at home',
        time_slot: {
          start: '12:00:00',
          end: '13:00:00'
        },
        skipped: false,
        is_first_visit: false,
        finished: true,
        scheduled_date: '2017-04-14',
        actual_date: '2017-04-14',
        week_beginning: '2017-04-10',
        access_display: 'Client at home',
        customer_can_alter: false,
        is_reviewable: false,
        worker_property_access: 'can_access'
      },
      tip_pounds: null,
      customer: {
        account_id: 'dzn29hr4ylz5',
        preferred_name: 'Jayne',
        id: 'vzq8rs2xm4z6'
      },
      customer_id: 'vzq8rs2xm4z6',
      id: '2z4g2i5yq8z4',
      more_info: ''
    },
    {
      customer: {
        account_id: 'qqfme35j7yz2',
        preferred_name: 'Peter',
        id: 'krow9s9lesc2'
      },
      rating: 2.0,
      problemCategory: 'quality',
      message_to_housekeeper: 'Please vacuum and empty the bins next time!',
      visit: {
        actual_date: '2017-04-13',
        week_beginning: '2017-04-10',
        property_id: 'xb6y5c44sdop',
        property: {
          postcode: {
            inward: '4RA',
            outward: 'SW1'
          }
        },
        started_at: '2017-05-04T11:00:00Z',
        time_slot: {
          start: '12:00:00',
          end: '12:00:00'
        },
        tasks: [
          {
            type: 'cleaning',
            hours: 2.0
          }
        ]
      },
      tip_pounds: null,
      id: 'qolxpf4kpqo2'
    },
    {
      rating: 5.0,
      visit_id: 'noreq06rxqb2',
      visit: {
        tip_amount_pounds: 20.0,
        tasks: [
          {
            type: 'tip',
            hours: null
          },
          {
            type: 'cleaning',
            hours: 3.5
          }
        ],
        any_worker: false,
        finished_at: '2017-04-10T17:13:00Z',
        account_id: 'qolmehmj7yz2',
        frequency: 7.0,
        started_at: '2017-04-10T13:43:00Z',
        id: 'noreq06rxqb2',
        property_id: 'xb6y5c44alop',
        property: {
          postcode: {
            inward: '4EQ',
            outward: 'SW1'
          }
        },
        job_id: 'wbkjxt93nnz7',
        access: 'supervsr',
        time_slot: {
          start: '09:00:00',
          end: '17:00:00'
        },
        skipped: false,
        is_first_visit: false,
        finished: true,
        scheduled_date: '2017-04-10',
        actual_date: '2017-04-10',
        week_beginning: '2017-04-10',
        access_display: 'Supervisor on site',
        customer_can_alter: false,
        is_reviewable: false,
        worker_property_access: 'cannot_access'
      },
      tip_pounds: 5,
      customer: {
        account_id: 'qolmehmj7yz2',
        preferred_name: 'Paul',
        id: 'norw9s9lewb2'
      },
      customer_id: 'norw9s9lewb2',
      id: 'dznvdhlje7o5',
      more_info: ''
    },
    {
      rating: 5.0,
      visit_id: 'yzdylsjm8mzl',
      visit: {
        tip_amount_pounds: null,
        tasks: [
          {
            type: 'cleaning',
            hours: 3.0
          }
        ],
        any_worker: false,
        finished_at: '2017-04-04T16:24:00Z',
        account_id: '8zpglhap45zk',
        frequency: 7.0,
        started_at: '2017-04-04T13:24:00Z',
        id: 'yzdylsjm8mzl',
        property_id: 'rom4pc44vjoy',
        property: {
          postcode: {
            inward: '2BY',
            outward: 'SW1'
          }
        },
        job_id: 'norwatg2n7b2',
        access: 'keys',
        time_slot: {
          start: '09:00:00',
          end: '17:00:00'
        },
        skipped: false,
        is_first_visit: false,
        finished: true,
        scheduled_date: '2017-04-04',
        actual_date: '2017-04-04',
        week_beginning: '2017-04-03',
        access_display: 'Have keys',
        customer_can_alter: false,
        is_reviewable: false,
        worker_property_access: 'cannot_access'
      },
      tip_pounds: null,
      customer: {
        account_id: '8zpglhap45zk',
        preferred_name: 'Tracy',
        id: 'gzgn7s238jb9'
      },
      customer_id: 'gzgn7s238jb9',
      id: 'xowvlhg3plb2',
      more_info: 'Loving the ironed shirts, thanks!'
    },
    {
      rating: 5.0,
      compliment: 'pet-friendly',
      compliment_display: "Pet's Best Friend",
      visit_id: 'xb6jqfg49lop',
      visit: {
        tip_amount_pounds: 6.0,
        tasks: [
          {
            type: 'tip',
            hours: null
          },
          {
            type: 'cleaning',
            hours: 2.0
          }
        ],
        any_worker: false,
        finished_at: '2017-03-29T18:00:00Z',
        account_id: 'yzdrvhmg5aol',
        frequency: 7.0,
        started_at: '2017-03-29T16:00:00Z',
        id: 'xb6jqfg49lop',
        property_id: 'jze86caa3ezn',
        property: {
          postcode: {
            inward: '7GF',
            outward: 'WC1'
          }
        },
        job_id: '2z4l6twj9lo4',
        access: 'keys',
        time_slot: {
          start: '09:00:00',
          end: '17:00:00'
        },
        skipped: false,
        is_first_visit: false,
        finished: true,
        scheduled_date: '2017-03-29',
        actual_date: '2017-03-29',
        week_beginning: '2017-03-27',
        access_display: 'Have keys',
        customer_can_alter: false,
        is_reviewable: false,
        worker_property_access: 'cannot_access'
      },
      tip_pounds: 10,
      customer: {
        account_id: 'yzdrvhmg5aol',
        preferred_name: 'Dennis',
        id: 'wbkjds5lppz7'
      },
      customer_id: 'wbkjds5lppz7',
      id: 'nb7weurgw2oe',
      more_info: 'You are simply the best!'
    },
    {
      rating: 5.0,
      visit_id: 'nb5p9s94qaz4',
      visit: {
        tip_amount_pounds: null,
        tasks: [
          {
            type: 'products',
            hours: null
          },
          {
            type: 'cleaning',
            hours: 3.0
          }
        ],
        any_worker: false,
        finished_at: '2017-03-28T14:00:00Z',
        account_id: '3ba2yhq7xnoe',
        frequency: 7.0,
        started_at: '2017-03-28T11:00:00Z',
        id: 'nb5p9s94qaz4',
        property_id: '8zpgpcaa53zk',
        property: {
          postcode: {
            inward: '3CF',
            outward: 'WC1'
          }
        },
        job_id: 'jo8lpt4navzk',
        access: 'at home',
        time_slot: {
          start: '12:00:00',
          end: '12:00:00'
        },
        skipped: false,
        is_first_visit: false,
        finished: true,
        scheduled_date: '2017-03-28',
        actual_date: '2017-03-28',
        week_beginning: '2017-03-27',
        access_display: 'Client at home',
        customer_can_alter: false,
        is_reviewable: false,
        worker_property_access: 'can_access'
      },
      tip_pounds: null,
      customer: {
        account_id: '3ba2yhq7xnoe',
        preferred_name: 'Dr. Clark',
        id: 'kbj9mse2mdz4'
      },
      customer_id: 'kbj9mse2mdz4',
      id: 'xb6j30egpaop',
      more_info: ''
    },
    {
      rating: 5.0,
      compliment: 'thoroughness',
      compliment_display: 'Incredibly Thorough',
      visit_id: 'kbjnd0kay6o4',
      visit: {
        tip_amount_pounds: null,
        tasks: [
          {
            type: 'products',
            hours: null
          },
          {
            type: 'cleaning',
            hours: 2.5
          }
        ],
        any_worker: false,
        finished_at: '2017-03-24T19:17:00Z',
        account_id: 'qzxw8hla56ok',
        frequency: 7.0,
        started_at: '2017-03-24T16:47:00Z',
        id: 'kbjnd0kay6o4',
        property_id: 'nb5q2c44pab4',
        property: {
          postcode: {
            inward: '2BY',
            outward: 'WC1'
          }
        },
        job_id: 'kbj9et75gmz4',
        access: 'at home',
        time_slot: {
          start: '16:00:00',
          end: '17:00:00'
        },
        skipped: false,
        is_first_visit: false,
        finished: true,
        scheduled_date: '2017-03-24',
        actual_date: '2017-03-24',
        week_beginning: '2017-03-20',
        access_display: 'Client at home',
        customer_can_alter: false,
        is_reviewable: false,
        worker_property_access: 'can_access'
      },
      tip_pounds: 5,
      customer: {
        account_id: 'qzxw8hla56ok',
        preferred_name: 'Paula',
        id: 'nb7ams4jpwbe'
      },
      customer_id: 'nb7ams4jpwbe',
      id: '8zpv2hg89qzk',
      more_info: 'The oven has not looked so good since new!'
    },
    {
      rating: 4.0,
      visit_id: 'jo8wj0lpv8ok',
      visit: {
        tip_amount_pounds: null,
        tasks: [
          {
            type: 'cleaning',
            hours: 3.0
          }
        ],
        any_worker: false,
        finished_at: '2017-03-21T12:00:00Z',
        account_id: '8zpglhap45zk',
        frequency: 7.0,
        started_at: '2017-03-21T09:00:00Z',
        id: 'jo8wj0lpv8ok',
        property_id: 'rom4pc44vjoy',
        property: {
          postcode: {
            inward: '1QD',
            outward: 'WC1'
          }
        },
        job_id: 'norwatg2n7b2',
        access: 'keys',
        time_slot: {
          start: '09:00:00',
          end: '17:00:00'
        },
        skipped: false,
        is_first_visit: false,
        finished: true,
        scheduled_date: '2017-03-21',
        actual_date: '2017-03-21',
        week_beginning: '2017-03-20',
        access_display: 'Have keys',
        customer_can_alter: false,
        is_reviewable: false,
        worker_property_access: 'cannot_access'
      },
      tip_pounds: null,
      customer: {
        account_id: '8zpglhap45zk',
        preferred_name: 'Tracy',
        id: 'gzgn7s238jb9'
      },
      customer_id: 'gzgn7s238jb9',
      id: 'gzgyai6a7qz9',
      more_info: 'Missed a spot in the bathroom, otherwise great, thanks!'
    },
    {
      rating: 5.0,
      visit_id: 'vzqe90ye98z6',
      visit: {
        tip_amount_pounds: null,
        tasks: [
          {
            type: 'cleaning',
            hours: 3.5
          }
        ],
        any_worker: false,
        finished_at: '2017-03-13T12:30:00Z',
        account_id: 'qolmehmj7yz2',
        frequency: 7.0,
        started_at: '2017-03-13T09:00:00Z',
        id: 'vzqe90ye98z6',
        property_id: 'xb6y5c44alop',
        property: {
          postcode: {
            inward: '6GR',
            outward: 'SW1'
          }
        },
        job_id: 'wbkjxt93nnz7',
        access: 'supervsr',
        time_slot: {
          start: '09:00:00',
          end: '17:00:00'
        },
        skipped: false,
        is_first_visit: false,
        finished: true,
        scheduled_date: '2017-03-13',
        actual_date: '2017-03-13',
        week_beginning: '2017-03-13',
        access_display: 'Supervisor on site',
        customer_can_alter: false,
        is_reviewable: false,
        worker_property_access: 'cannot_access'
      },
      tip_pounds: 5,
      customer: {
        account_id: 'qolmehmj7yz2',
        preferred_name: 'Paul',
        id: 'norw9s9lewb2'
      },
      customer_id: 'norw9s9lewb2',
      id: 'kbjnwuqk8lo4',
      more_info: ''
    },
    {
      rating: 5.0,
      visit_id: 'noreq06r2qb2',
      visit: {
        tip_amount_pounds: 24.0,
        tasks: [
          {
            type: 'tip',
            hours: null
          },
          {
            type: 'products',
            hours: null
          },
          {
            type: 'cleaning',
            hours: 3.0
          }
        ],
        any_worker: false,
        finished_at: '2017-02-14T15:00:00Z',
        account_id: '3ba2yhq7xnoe',
        frequency: 7.0,
        started_at: '2017-02-14T12:00:00Z',
        id: 'noreq06r2qb2',
        property_id: '8zpgpcaa53zk',
        property: {
          postcode: {
            inward: '1QD',
            outward: 'SW1'
          }
        },
        job_id: 'jo8lpt4navzk',
        access: 'at home',
        time_slot: {
          start: '12:00:00',
          end: '12:00:00'
        },
        skipped: false,
        is_first_visit: false,
        finished: true,
        scheduled_date: '2017-02-14',
        actual_date: '2017-02-14',
        week_beginning: '2017-02-13',
        access_display: 'Client at home',
        customer_can_alter: false,
        is_reviewable: false,
        worker_property_access: 'can_access'
      },
      tip_pounds: null,
      customer: {
        account_id: '3ba2yhq7xnoe',
        preferred_name: 'Dr. Clark',
        id: 'kbj9mse2mdz4'
      },
      customer_id: 'kbj9mse2mdz4',
      id: '3baw9ua932oe',
      more_info: ''
    }
  ],
  per_page: 50,
  url_previous_page: null,
  num_results: 12,
  url_next_page: null
};

/**
 * Replace the dates above so that some data will appear under 'Last 60 days'
 */
function getPagedRatings(demoState) {
  const { today } = demoState;
  const baseDate = toDate('2017-05-12');

  const pagedRatings = cloneDeep(PAGED_RATINGS_BASE);

  pagedRatings.results.forEach(visitRating => {
    const date = toDate(visitRating.visit.actual_date);
    const daysAgo = baseDate.diff(date, 'days');
    const newDate = today().subtract(daysAgo, 'days');
    const newWeekBeginning = newDate.clone().startOf('isoWeek');

    visitRating.visit.actual_date = dateToStr(newDate);
    visitRating.visit.week_beginning = dateToStr(newWeekBeginning);

    if (visitRating.visit.scheduled_date) {
      visitRating.visit.scheduled_date = dateToStr(newDate);
    }

    if (visitRating.visit.started_at) {
      const start = toMoment(visitRating.visit.started_at);
      const startTime = toTime(start);
      const newStart = combine(newDate, startTime);
      visitRating.visit.started_at = newStart.toISOString();
    }

    if (visitRating.visit.finished_at) {
      const end = toMoment(visitRating.visit.started_at);
      const endTime = toTime(end);
      const newEnd = combine(newDate, endTime);
      visitRating.visit.finished_at = newEnd.toISOString();
    }
  });

  return pagedRatings;
}

export { getPagedRatings };
