import { DemoEndpoint } from '../../../demo-service';

const requestCallEndpoint: DemoEndpoint = {
  path: 'request-call',
  handlers: [
    {
      method: 'post',
      handle() {
        return {
          category: 'misc_call_request',
          phone: '0208 887887'
        };
      }
    }
  ]
};

export { requestCallEndpoint };
