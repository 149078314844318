import { Pipe, PipeTransform } from '@angular/core';

import { capitalize } from '@housekeep/infra';

import { AVAILABILITY_OVERRIDE_CATEGORIES } from 'services/availability-service';

/**
 * Pipe to show one of a choice of strings depending on whether an array has
 * only one item or a number is not one.
 * @param {any[]|number} arrayOrLength Either the number to use or the array
 *   whose length will be used
 * @param {string} [pluralString] The string to show when the number is not
 *   one (default: 's')
 * @param {string} [singularString] The string to show when the number is one
 *   (default: '')
 * @example
 *   <!-- Both give 'bedrooms' or 'bedroom': -->
 *   bedroom{{ bedroomCount|plural }}
 *   {{ bedroomArray|plural:'bedrooms':'bedroom' }}
 */
@Pipe({ name: 'plural' })
export class Plural implements PipeTransform {
  public transform(arrayOrLength: any[] | number, pluralString: string = 's', singularString: string = ''): string {
    let length;

    if (typeof arrayOrLength === 'number') {
      length = arrayOrLength;
    } else {
      length = arrayOrLength.length;
    }

    return length === 1 ? singularString : pluralString;
  }
}

/**
 * Pipe that makes the first letter of a given string capitalised
 * The remainder of the string is lowercase.
 * @param  {string} String to be capitalised
 * @return       First-letter capitalised string
 */
@Pipe({ name: 'capitalise' })
export class Capitalise implements PipeTransform {
  public transform(input: string): string {
    if (input.length > 0) {
      input = input.toLowerCase();
      return capitalize(input);
    } else {
      return input;
    }
  }
}

/**
 * Pipe that makes the first letter of each sentence in a string capitalised.
 * Impacts first char only. An all caps string will return all caps.
 * @param  {string} String to be capitalised
 * @return       First-letter per sentence capitalised string
 */
@Pipe({ name: 'capitaliseSentence' })
export class CapitaliseSentence implements PipeTransform {
  public transform(input: string): string {
    if (input.length > 0) {
      input = input.substring(0, 1).toUpperCase() + input.substring(1);
      return input.replace(/.+?[\.\?\!](\s|$)/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1);
      });
    } else {
      return input;
    }
  }
}

@Pipe({ name: 'asReadableTimeOffReason' })
export class AsReadableTimeOffReason implements PipeTransform {
  public transform(input: string): string {
    if (input) {
      const categoryDisplay = AVAILABILITY_OVERRIDE_CATEGORIES.find(category => category.key === input);
      if (categoryDisplay) {
        return categoryDisplay.displayName;
      }
    }

    return 'Other';
  }
}

@Pipe({ name: 'enumerateArrayToStr' })
export class EnumerateArrayToStr implements PipeTransform {
  public transform(input: Array<string | number>, separator?: string, lastSeparator?: string): string {
    const inputCopy = [...input]; // We don't want to mutate the original array
    separator = separator || ', ';
    lastSeparator = lastSeparator || ' and ';

    if (inputCopy.length === 0) {
      return '';
    } else if (inputCopy.length === 1) {
      return inputCopy[0].toString();
    } else {
      const finalElement = inputCopy.pop().toString();
      return inputCopy.join(separator) + lastSeparator + finalElement;
    }
  }
}

export const stringPipes = [Plural, Capitalise, CapitaliseSentence, AsReadableTimeOffReason, EnumerateArrayToStr];
