import { DemoEndpoint } from '../../../demo-service';
import { SURROUNDING_AREAS } from '../../data/surrounding-areas';

const surroundingAreasEndpoint: DemoEndpoint = {
  path: 'surrounding-areas/[sS]*',
  handlers: [
    {
      method: 'get',
      handle() {
        return SURROUNDING_AREAS;
      }
    }
  ]
};

export { surroundingAreasEndpoint };
