import { DemoEndpoint } from '../../../demo-service';

const workerAreasEndpoint: DemoEndpoint = {
  path: 'areas',
  handlers: [
    {
      method: 'get',
      handle() {
        return [
          { code: 'SW1', broad_area_id: '4mxb6pmteopy', id: '7yzdxqcd5ol2' },
          { code: 'W1', broad_area_id: 'pe2z4xwh3o4v', id: 'nqzxkvcp2oky' }
        ];
      }
    }
  ]
};

export { workerAreasEndpoint };
