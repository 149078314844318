import { DemoEndpoint } from '../../../demo-service';

const workerAchievementsEndpoint: DemoEndpoint = {
  path: 'achievements',
  handlers: [
    {
      method: 'get',
      handle() {
        return {
          five_star_ratings: 9,
          completed_visits: 18,
          compliments: {
            aboveBeyond: 0,
            bestCleaner: 0,
            cleaning: 0,
            efficiency: 0,
            madeDay: 1,
            petFriendly: 1,
            politeness: 0,
            rightOnTime: 0,
            service: 1,
            thoroughness: 1,
            tidying: 0
          }
        };
      }
    }
  ]
};

export { workerAchievementsEndpoint };
