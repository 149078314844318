import { Area, Date, dayOfWeek, max, min, PaginatedResponse, toDate, Visit } from '@housekeep/infra';

import { AssignableJob } from 'models/assignable-job';

import { CONFIG, createOldRequestError, findArea, validOneOffDateRange } from './extra-jobs-service.util';
import { TimeService } from './time-service';
import { VisitService } from './visit-service';

let CURRENT_ONE_OFF_STATE_ID = 0;
let CURRENT_REGULAR_STATE_ID = 0;

/**
 * An abstract class handling common functionality for results states.
 *
 * The state functions and transitions for both one-off and regular extra-jobs
 * share many features:
 *
 * 1) They request paginated data from the server.
 * 2) They load their results in batches, so as to deliver results to the user
 *    quickly. The front-end uses infinite scrolling to request more results.
 * 3) Their results are sorted by closeness to the user's existing areas.
 *
 * A global state counter ensures that as soon as a new state is created, all
 * old states stop making additional server requests immediately.
 */
abstract class ResultsState<T> {
  public id: number;
  public areas: Area[];

  public loading = true;
  public hasMore = false;

  public page?: PaginatedResponse<T>;
  public results: T[] = [];
  public resultsBatch: T[] = [];
  private _batchLimit = CONFIG.BATCH_LIMIT;

  constructor() {
    this.id = this._getCurrentStateId();
  }

  /**
   * Retrieve the first/next batch of results from the server.
   */
  public getResults(): Promise<T[]> {
    this.loading = true;

    return this._getResultsBatch().then(hasMore => {
      this.hasMore = hasMore;
      this.loading = false;
      return this.results;
    });
  }

  public isEndOfResults(): boolean {
    return this._isEndOfResults();
  }

  // Abstract interfaces to be implemented by sub-classes.
  protected abstract _incrementDayState(): void;
  protected abstract _isEndOfResults(): boolean;
  protected abstract _getNextDayPage(): Promise<PaginatedResponse<T>>;
  protected abstract _sortResults(a: T, b: T): number;
  protected abstract _getCurrentStateId(): number;
  protected abstract _incrementCurrentStateId(): number;

  /**
   * Return the next batch of results.
   *
   * This is called recursively until we've either exhausted all possibilities,
   * or we've retrieved a sufficiently large number of results.
   */
  private _getResultsBatch(): Promise<boolean> {
    // We can't get results without any areas
    if (this.areas.length === 0) {
      return Promise.resolve(false);
    }

    let request;

    // More pages for the current day/day-of-week exist
    if (this.page && this.page.hasNext()) {
      request = this.page.getNext();
    } else {
      if (this._isEndOfResults()) {
        return Promise.resolve(false);
      } else {
        request = this._getNextDayPage();
      }
    }

    return request.then(page => this._parseResults(page)).catch(err => this._onGetResultsError(err));
  }

  /**
   * Sort the results, and make a further request if we've not exceeded the batch limit.
   */
  private _parseResults(page: PaginatedResponse<T>): Promise<boolean> {
    this._throwIfOldRequest();

    const results = page.results.sort(this._sortResults.bind(this));
    this.page = page;
    this.results.push(...results);
    this.resultsBatch.push(...results);

    // Increment the day[of-week] if the page indicates no further results
    if (!page.hasNext()) {
      this._incrementDayState();
    }

    // Get further results / resolve the current batch as necessary
    if (this.resultsBatch.length < this._batchLimit) {
      return this._getResultsBatch();
    } else {
      const hasMore = page.hasNext() || !this._isEndOfResults();
      this.resultsBatch = [];
      return Promise.resolve(hasMore);
    }
  }

  private _onGetResultsError(err): never {
    this._throwIfOldRequest();
    throw err;
  }

  private _throwIfOldRequest(): void {
    if (this.id !== this._getCurrentStateId()) {
      throw createOldRequestError();
    }
  }
}

/**
 * Results state for requests for one-off extra jobs.
 *
 * These requests are made based on a given week of results. The state iterates
 * through each day of the week (e.g. 1st Jan - 8th Jan) requesting one-offs
 * until all days have been searched.
 *
 * @fixme code duplication between OneOffsState and RegularsState
 */
class OneOffsState extends ResultsState<Visit> {
  public currentDay: Date;
  private firstDay: Date;
  private lastDay: Date;
  private weekStart: Date;

  constructor(public areas: Area[], public timeService: TimeService, public visitService: VisitService) {
    super();
    this.weekStart = toDate(this.timeService.today()).startOf('week');
    const [firstDay, lastDay] = this._getCurrentOneOffDateRange();
    this.firstDay = firstDay;
    this.lastDay = lastDay;
    this.currentDay = firstDay;
  }

  public getNextDay(): void {
    this._incrementDayState();
    this.getResults();
  }

  protected _isEndOfResults(): boolean {
    return this.currentDay.isAfter(this.lastDay);
  }

  protected _incrementDayState(): void {
    this.currentDay.add(1, 'days');
  }

  protected _getNextDayPage(): Promise<PaginatedResponse<Visit>> {
    const areaCodes = this.areas.map(a => a.code).sort();
    return this.visitService.getReassignableVisits(this.currentDay, areaCodes);
  }

  /**
   * A sort function, to allow visits to be ordered by first by date, then by
   * their closeness to the worker's currently assigned areas
   */
  protected _sortResults(a: Visit, b: Visit): number {
    const dateA = a.actualDate;
    const dateB = b.actualDate;

    if (dateA === null) {
      return -1;
    }
    if (dateB === null) {
      return 1;
    }

    if (dateA.isSame(dateB)) {
      const areaIndexA = this.areas.indexOf(findArea(a, this.areas));
      const areaIndexB = this.areas.indexOf(findArea(b, this.areas));
      return areaIndexA < areaIndexB ? -1 : 1;
    } else {
      return dateA.isBefore(dateB) ? -1 : 1;
    }
  }

  protected _getCurrentStateId(): number {
    return CURRENT_ONE_OFF_STATE_ID;
  }

  protected _incrementCurrentStateId(): number {
    return ++CURRENT_ONE_OFF_STATE_ID;
  }

  private _getCurrentOneOffDateRange(): [Date, Date] {
    const fortnightEnd = this.weekStart.clone().add(13, 'days');
    const [firstValidDay, lastValidDay] = validOneOffDateRange(this.timeService.today());
    return [max(firstValidDay, this.weekStart), min(lastValidDay, fortnightEnd)];
  }
}

/**
 * Results state for requests for regular extra jobs.
 *
 * The state iterates through each week-day (e.g. Monday-Sunday) requesting
 * regular jobs until all days have been searched. The current week-day is
 * searched first.
 */
class RegularsState extends ResultsState<AssignableJob> {
  private dayOfWeekStart: number;
  private currentDayI: number = 0;

  constructor(public areas: Area[], public timeService: TimeService, public visitService: VisitService) {
    super();
    this.dayOfWeekStart = dayOfWeek(this.timeService.today());
    this.currentDayI = 0;
  }

  protected _incrementDayState(): void {
    this.currentDayI++;
  }

  protected _isEndOfResults(): boolean {
    return this.currentDayI > 6;
  }

  protected _getNextDayPage(): Promise<PaginatedResponse<AssignableJob>> {
    const areaCodes = this.areas.map(a => a.code).sort();
    const dayOfWeek = this._getDayOfWeek();
    return this.visitService.getReassignableRegularJobs(dayOfWeek, areaCodes);
  }

  protected _sortResults(a: AssignableJob, b: AssignableJob): number {
    const jobA = a.job;
    const jobB = b.job;
    const dateA = jobA.nextVisitDate;
    const dateB = jobB.nextVisitDate;

    if (dateA === null) {
      return -1;
    }
    if (dateB === null) {
      return 1;
    }

    if (dateA.isSame(dateB)) {
      const areaIndexA = this.areas.indexOf(findArea(jobA, this.areas));
      const areaIndexB = this.areas.indexOf(findArea(jobB, this.areas));
      return areaIndexA < areaIndexB ? -1 : 1;
    } else {
      return dateA.isBefore(dateB) ? -1 : 1;
    }
  }

  protected _getCurrentStateId(): number {
    return CURRENT_REGULAR_STATE_ID;
  }

  protected _incrementCurrentStateId(): number {
    return ++CURRENT_REGULAR_STATE_ID;
  }

  private _getDayOfWeek(): number {
    return (this.dayOfWeekStart + this.currentDayI) % 7;
  }
}

export { OneOffsState, RegularsState };
