import { Injectable } from '@angular/core';

import { WorkerInterventionResponse, WorkerMood } from '@housekeep/infra';

import { RequestService } from './request-service';

const MOOD_SAMPLING_ENDPOINT = 'workers/submit-sample/';
const INTERVENTION_SAMPLING_ENDPOINT = 'workers/submit-intervention-sample/';

@Injectable({ providedIn: 'root' })
export class MoodSamplingService {
  constructor(private requestService: RequestService) {}

  public submitMoodSample(sampleId: string, mood: WorkerMood): Promise<WorkerMood> {
    return this.requestService.put(`${MOOD_SAMPLING_ENDPOINT}${sampleId}/`, {
      mood: mood
    });
  }

  public submitInterventionSample(
    sampleId: string,
    response: WorkerInterventionResponse
  ): Promise<WorkerInterventionResponse> {
    return this.requestService.put(`${INTERVENTION_SAMPLING_ENDPOINT}${sampleId}/`, {
      response: response
    });
  }
}
