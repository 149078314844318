import { Date } from '@housekeep/infra';

import { availabilityPeriods, AvailabilityPeriods } from './mixin.availability';

export interface WorkerAvailabilityOverride extends AvailabilityPeriods {
  day: Date;
  reason: string;
}

/**
 * Models a worker's availability as differing from normal on a particular date.
 */
export const workerAvailabilityOverride: WorkerAvailabilityOverride = availabilityPeriods.extend({
  modelName: 'workerAvailabilityOverride'
});
