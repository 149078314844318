import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { NationalInsuranceValidatorDirective } from './national-insurance-number.validator';

@NgModule({
  exports: [NationalInsuranceValidatorDirective],
  declarations: [NationalInsuranceValidatorDirective],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class HousekeeperAppDirectivesModule {}
