import { Injectable } from '@angular/core';

import { Area, dateToStr, Job, Visit } from '@housekeep/infra';

import { AssignableJob } from 'models/assignable-job';
import { WorkerExtraJobEligibility } from 'models/worker-extra-job-eligibility';
import { WorkerExtraJobSignalAvailability } from 'models/worker-extra-job-signal-availability';

import { AreasService } from './areas-service';
import { ErrorService } from './error-service';
import { OneOffsState, RegularsState } from './extra-jobs-service.states';
import { findArea, isOldRequestError, SURROUNDING_CONTEXT } from './extra-jobs-service.util';
import { HKAnalyticsService } from './hk-analytics-service';
import { SentryService } from './sentry-service';
import { TimeService } from './time-service';
import { UserService } from './user-service';
import { VisitService } from './visit-service';

const SHOW_AREA_CHANGE_REMINDER_EVERY_X_ALIEN_VISITS = 8;

const TAB_ONE_OFF = 'one-off';
const TAB_REGULAR = 'regular';
type ExtraJobsTabName = 'one-off' | 'regular';

const ANALYTICS_EXTRA_JOBS_SIGNAL_LOADED = 'extra-jobs-signal-initial-load';
const ANALYTICS_EXTRA_JOBS_SIGNAL_MENU = 'extra-jobs-signal-menu-press';
const ANALYTICS_EXTRA_JOBS_SIGNAL_JOB_ASSIGN = 'extra-jobs-signal-job-assign';
const ANALYTICS_EXTRA_JOBS_SIGNAL_VISIT_ASSIGN = 'extra-jobs-signal-visit-assign';

interface AreasByFrequency {
  oneOff: Area[];
  regular: Area[];
}

interface Areas {
  home?: Area;
  worksInHome?: boolean;
  assigned?: Area[];
  surrounding?: AreasByFrequency;
  all?: AreasByFrequency;
}

type ResultsByFrequency = [Visit[] | null, AssignableJob[] | null];

/**
 * Service to handle shared communication/state between Extra Jobs components.
 *
 * The results state handling can be found within a separate module.
 */
@Injectable({ providedIn: 'root' })
class ExtraJobsService {
  // Areas state
  public areas: Areas;
  public areasLoading: boolean = true;
  public _areasRequest: Promise<any>;

  // Loading state of areas and visits
  public loadError: boolean = false;

  // One-off/Regular states
  public oneOffState: OneOffsState;
  public regularState: RegularsState;
  public availabilitySignal: WorkerExtraJobSignalAvailability;
  public eligibility: WorkerExtraJobEligibility;
  public TAB_ONE_OFF: ExtraJobsTabName = TAB_ONE_OFF;
  public TAB_REGULAR: ExtraJobsTabName = TAB_REGULAR;

  private _currentTab: ExtraJobsTabName = 'one-off';

  private extraJobSignalInterval: any = null;
  private extraJobSignalIntervalMs: number = 0;

  public constructor(
    private areasService: AreasService,
    private hKAnalyticsService: HKAnalyticsService,
    private errorService: ErrorService,
    private sentryService: SentryService,
    private timeService: TimeService,
    private userService: UserService,
    private visitService: VisitService
  ) {}

  /**
   * Return the name of the currently active tab (one-off/regular).
   */
  public getTab(): ExtraJobsTabName {
    return this._currentTab;
  }

  /**
   * Change between one-off/regular jobs.
   */
  public setTab(tabName: ExtraJobsTabName): void {
    if (this._currentTab === tabName) {
      return;
    }
    this._currentTab = tabName;
  }

  // Analytics tracking on initial signal request
  public async trackInitialExtraJobsSignal(availabilitySignal: WorkerExtraJobSignalAvailability): Promise<void> {
    if (availabilitySignal && availabilitySignal.appSignalEnabled) {
      this.hKAnalyticsService.createHKAnalyticsRecord(ANALYTICS_EXTRA_JOBS_SIGNAL_LOADED, {
        availabilitySignal
      });
    }
  }

  // Analytics tracking to track menu with extra job badge was tapped
  public async trackSignalAnalyticsMenuPress(): Promise<void> {
    if (this.availabilitySignal && this.availabilitySignal.appSignalEnabled) {
      this.hKAnalyticsService.createHKAnalyticsRecord(ANALYTICS_EXTRA_JOBS_SIGNAL_MENU, {
        availabilitySignal: this.availabilitySignal
      });
    }
  }

  // Analytics tracking to track visit with extra job badge was tapped
  public async trackSignalAnalyticsVisitAssign(
    withinRegularHours: boolean,
    withinRegularAreas: boolean
  ): Promise<void> {
    if (this.availabilitySignal && this.availabilitySignal.appSignalEnabled) {
      this.hKAnalyticsService.createHKAnalyticsRecord(ANALYTICS_EXTRA_JOBS_SIGNAL_VISIT_ASSIGN, {
        availabilitySignal: this.availabilitySignal,
        withinRegularHours,
        withinRegularAreas
      });
    }
  }

  // Analytics tracking to track job with extra job badge was tapped
  public async trackSignalAnalyticsJobAssign(withinRegularHours: boolean, withinRegularAreas: boolean): Promise<void> {
    if (this.availabilitySignal && this.availabilitySignal.appSignalEnabled) {
      this.hKAnalyticsService.createHKAnalyticsRecord(ANALYTICS_EXTRA_JOBS_SIGNAL_JOB_ASSIGN, {
        availabilitySignal: this.availabilitySignal,
        withinRegularHours,
        withinRegularAreas
      });
    }
  }

  /**
   * Refresh the user's areas and the list of jobs.
   *
   * If requested, the user can force the areas to be retrieve from the server.
   * Currently this occurs after a "pull down to refresh" event.
   */
  public refreshAll(clearCache: boolean = false): Promise<any> {
    return this._getAreas(clearCache)
      .then(() => {
        if (this.loadError) {
          return;
        }
        return this.refreshEligibility();
      })
      .then(() => {
        if (this.loadError) {
          return;
        }
        this.getUserExtraJobSignal();
        return this.refreshJobs();
      });
  }

  /**
   * Refresh the worker's eligibility for extra jobs.
   */
  public refreshEligibility() {
    return this.userService
      .getExtraJobEligibility()
      .then(eligibility => (this.eligibility = eligibility))
      .catch(() => (this.eligibility = null));
  }

  /**
   * Refresh the list of jobs, ensuring that the areas have finished loading first.
   */
  public refreshJobs(tabName?: ExtraJobsTabName): Promise<any> {
    const areasRequest = this._areasRequest || this._getAreas();

    return areasRequest
      .then(() => {
        this._resetResultsState(tabName);
        return this._fetchResultsState(tabName) as any;
      })
      .catch(err => {
        if (isOldRequestError(err)) {
          return;
        }
        if (tabName === TAB_ONE_OFF) {
          return this._allowOutOfRangeErrors(err);
        } else {
          throw err;
        }
      })
      .catch(err => this._onLoadError(err, tabName));
  }

  public async getUserExtraJobSignal(): Promise<void | WorkerExtraJobSignalAvailability> {
    return this.userService
      .getExtraJobAvailability()
      .then(response => {
        if (!this.availabilitySignal) {
          this.trackInitialExtraJobsSignal(response);
        }
        this.availabilitySignal = response;
        this.handleExtraJobSignalInterval(response.appSignalRefreshIntervalSeconds);
      })
      .catch(() => (this.availabilitySignal = null));
  }

  /**
   * Load more jobs from the server.
   *
   * The results states retrieve jobs in batches, and provide a `hasMore` flag
   * to expose whether further results may exist. When this is true, the
   * front-end can use an infinite scroll approach to retrieve jobs as needed.
   */
  public loadMore(tabName?: ExtraJobsTabName): Promise<ResultsByFrequency> {
    return this._fetchResultsState(tabName);
  }

  /**
   * Return whether the given visit/job is within the user's assigned areas.
   */
  public inAssignedArea(visitOrJob: Visit | Job): boolean {
    return !!findArea(visitOrJob, this.areas.assigned);
  }

  /**
   * Returns a promise resolving to true if the user should be reminded they can request
   * a change of working area, or false otherwise.
   */
  public async shouldShowAreaReminder(): Promise<boolean> {
    try {
      const user = await this.userService.getUser();
      const metrics = user.last60DaysMetrics;
      if (!metrics || !metrics.visitsOutsideNormalArea) {
        return false;
      }
      const alienVisits = metrics.visitsOutsideNormalArea;
      return (
        metrics.hasSignificantVisitsOutsideOfArea &&
        alienVisits > 0 &&
        alienVisits % SHOW_AREA_CHANGE_REMINDER_EVERY_X_ALIEN_VISITS === 0
      );
    } catch (err) {
      return false;
    }
  }

  private handleExtraJobSignalInterval(intervalSecs: number) {
    // If no interval provided - clear any existing and return
    if (!intervalSecs) {
      clearInterval(this.extraJobSignalInterval);
      return;
    }
    // If interval has changed - update the current interval
    const intervalMs = intervalSecs * 1000;
    if (this.extraJobSignalIntervalMs !== intervalMs) {
      this.extraJobSignalIntervalMs = intervalMs;
      clearInterval(this.extraJobSignalInterval);
      this.extraJobSignalInterval = setInterval(() => this.getUserExtraJobSignal(), intervalMs);
    }
  }

  /**
   * Retrieve the user's areas details.
   *
   * Here we collect the user's:
   * - Assigned areas
   * - Home area
   * - Surrounding areas
   *
   * Surrounding areas are those close to the user's assigned & home areas,
   * ordered by their distance. The threshold for "close" is determined by the
   * server and can differ for one-off/regular jobs.
   */
  private _getAreas(refresh: boolean = false): Promise<Areas> {
    this.loadError = false;
    this.areasLoading = true;

    const areas: Areas = {};

    this._areasRequest = this.areasService
      .getHomeArea()
      .then(homeArea => {
        areas.home = homeArea;
        return this.areasService.getAreas(refresh);
      })
      .then(assignedAreas => {
        areas.assigned = assignedAreas;

        if (areas.home) {
          areas.worksInHome = assignedAreas.some(a => a.code === areas.home.code);
        }

        return Promise.all([
          this._getSurroundingAreas(areas, SURROUNDING_CONTEXT.ONE_OFF, refresh),
          this._getSurroundingAreas(areas, SURROUNDING_CONTEXT.REGULAR, refresh)
        ]);
      })
      .then(([surroundingOneOff, surroundingRegular]) => {
        areas.surrounding = {
          oneOff: surroundingOneOff,
          regular: surroundingRegular
        };

        areas.all = {
          oneOff: [...areas.assigned, ...surroundingOneOff],
          regular: [...areas.assigned, ...surroundingRegular]
        };

        if (areas.home && !areas.worksInHome) {
          areas.all.oneOff.push(areas.home);
          areas.all.regular.push(areas.home);
        }

        this.areas = areas;
        this.areasLoading = false;
        return this.areas;
      })
      .catch(err => this._onLoadError(err));

    return this._areasRequest;
  }

  /**
   * Retrieve the areas surrounding the user's home and assigned areas.
   */
  private _getSurroundingAreas(areas: Areas, context: string, refresh: boolean = false): Promise<any> {
    const fromAreas = areas.assigned.slice();

    if (areas.home && !areas.worksInHome) {
      fromAreas.push(areas.home);
    }

    if (fromAreas.length) {
      return this.areasService.getSurroundingAreas(fromAreas, context, refresh);
    } else {
      return Promise.resolve([]);
    }
  }

  private _fetchResultsState(tabName?: ExtraJobsTabName): Promise<ResultsByFrequency> {
    if (tabName === TAB_ONE_OFF) {
      return Promise.all([this.oneOffState.getResults(), Promise.resolve(null)]);
    } else if (tabName === TAB_REGULAR) {
      return Promise.all([Promise.resolve(null), this.regularState.getResults()]);
    } else {
      return Promise.all([this.oneOffState.getResults(), this.regularState.getResults()]);
    }
  }

  private _resetOneOffState(): void {
    this.oneOffState = new OneOffsState(this.areas.all.oneOff, this.timeService, this.visitService);
  }

  private _resetRegularState(): void {
    this.regularState = new RegularsState(this.areas.all.regular, this.timeService, this.visitService);
  }
  /**
   * Create a new state to handle the extra-jobs results.
   *
   * The type of state created is determined by the current tab (one-off/regular).
   */
  private _resetResultsState(tabName?: ExtraJobsTabName): void {
    if (tabName === TAB_ONE_OFF) {
      this._resetOneOffState();
    } else if (tabName === TAB_REGULAR) {
      this._resetRegularState();
    } else {
      this._resetOneOffState();
      this._resetRegularState();
    }
  }

  /**
   * Handle "out of range" errors when requesting one-off jobs.
   *
   * Whilst the app attempts to request one-off jobs within a range, the server
   * is the single source of truth for the acceptable date range. If the app
   * requests one-offs on a date which the server believes to be out of date,
   * we gracefully handle the error by simply moving onto the next day within
   * the week and making another request.
   */
  private _allowOutOfRangeErrors(err: any) {
    if (!this.visitService.isDayOutOfRangeError(err)) {
      throw err;
    }

    const state = this.oneOffState;
    this.sentryService.log({
      message: 'Extra jobs requested day out of range',
      level: 'debug',
      extras: { date: dateToStr(state.currentDay) }
    });

    return state.getNextDay();
  }

  /**
   * When an error is encountered, set a flag to expose it to the user.
   * Rethrow any non-internet or Demo Mode errors so that they're logged in Sentry.
   */
  private _onLoadError(err: any, tabName?: ExtraJobsTabName) {
    console.error(err);
    this.areasLoading = false;
    this.loadError = true;

    if (tabName === TAB_ONE_OFF) {
      this.oneOffState.loading = false;
    } else if (tabName === TAB_REGULAR) {
      this.regularState.loading = false;
    } else {
      this.oneOffState.loading = false;
      this.regularState.loading = false;
    }

    if (this.errorService.isNoInternetError(err) || this.errorService.isDemoModeError(err)) {
      return;
    }

    throw err;
  }
}

export { ExtraJobsService, ExtraJobsTabName, OneOffsState, RegularsState };
