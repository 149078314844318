import { dateTimeField, modelSerializer, ModelSerializer } from '@housekeep/infra';

import { ticketComment, TicketComment } from 'models/ticket-comment';

export const ticketCommentSerializer = modelSerializer.extend({
  model: ticketComment,
  fields: {
    created_at: dateTimeField.create()
  }
}) as ModelSerializer<TicketComment>;
