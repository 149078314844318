import { DemoEndpoint } from '../../demo-service';
import { absencePeriodChangeRequestEndpoint } from './contact/absence-period-change-request';
import { cancelCallRequestsEndpoint } from './contact/cancel-call-requests';
import { chatIsAllowedEndpoint } from './contact/chat-is-allowed';
import { officeHoursEndpoint } from './contact/office-hours';
import { openCallRequestsEndpoint } from './contact/open-call-requests';
import { requestCallEndpoint } from './contact/request-call';
import { requestChatEndpoint } from './contact/request-chat';
import { requestChatTokenEndpoint } from './contact/request-chat-token';
import { customerCallEndpoint } from './contact/request-customer-call';
import { requestCustomerServiceChatTokenEndpoint } from './contact/request-customer-service-chat-token';
import { workerToWorkerCallEndpoint } from './contact/request-worker-to-worker-call';
import { userHasCommunicatedEndpoint } from './contact/user-has-communicated';

const contactEndpoint: DemoEndpoint = {
  path: 'contact',
  endpoints: [
    absencePeriodChangeRequestEndpoint,
    cancelCallRequestsEndpoint,
    chatIsAllowedEndpoint,
    customerCallEndpoint,
    officeHoursEndpoint,
    openCallRequestsEndpoint,
    requestCallEndpoint,
    requestChatEndpoint,
    requestChatTokenEndpoint,
    requestCustomerServiceChatTokenEndpoint,
    userHasCommunicatedEndpoint,
    workerToWorkerCallEndpoint
  ]
};

export { contactEndpoint };
