import { DemoEndpoint } from '../../../demo-service';

const officeHoursEndpoint: DemoEndpoint = {
  path: 'office-hours',
  handlers: [
    {
      method: 'get',
      handle() {
        return { start: '08:00:00', end: '18:00:00' };
      }
    }
  ]
};

export { officeHoursEndpoint };
