import { AuthService } from 'services/auth-service';

import { PageBase } from './page-base';

/**
 * Abstract class with auth guard to be subclassed by all authenticated app pages.
 *
 * Requires the child class to provide:
 * - AuthService as authService
 */
export abstract class AuthenticatedPage extends PageBase {
  protected authService: AuthService;

  ionViewCanEnter() {
    return this.authService.isAuthenticated;
  }
}
