<h3>Contact {{ remoteKeyTransfer.otherWorker.firstNames }}</h3>
<img *ngIf="remoteKeyTransfer.otherWorker.image"
     class="contact-worker__photo"
     [src]="remoteKeyTransfer.otherWorker.image"
     [alt]="remoteKeyTransfer.otherWorker.firstNames + remoteKeyTransfer.otherWorker.lastName" />
<div *ngIf="allowed" class="contact-worker">
  <div class="contact-worker__preview">
    <div class="contact-worker__preview__message">
      {{ message }}
    </div>
  </div>
  <div>
    <ion-button color="primary" data-test-id="chat-with-message" expand="block" (click)="goToChatWithMessage()">
      <hk-wc-icon name="chat" size="24" slot="start"></hk-wc-icon>
      Send this message
    </ion-button>
  </div>
</div>
<div *ngIf="allowed === false" class="chat-unavailable">
  Chat is not available
</div>
<ion-grid class="grid--negative-margin">
  <ion-row>
    <ion-col>
      <ion-button color="primary" expand="block"
              name="call-worker"
              (click)="requestWorkerCall()">
        <hk-wc-icon name="phone" size="24" slot="start"></hk-wc-icon> Call
      </ion-button>
    </ion-col>
    <ion-col>
      <ion-button color="primary"
              expand="block"
              data-test-id="chat-without-message"
              [disabled]="!allowed"
              (click)="goToChatWithoutMessage()">
        <hk-wc-icon name="key-hand" size="24" slot="start"></hk-wc-icon> Open Chat
      </ion-button>
    </ion-col>
  </ion-row>
</ion-grid>
