import { HttpErrorResponse } from '@angular/common/http';

import { DemoEndpoint } from '../../../demo-service';

const requestChatEndpoint: DemoEndpoint = {
  path: 'request-chat-v2$',
  handlers: [
    {
      method: 'post',
      handle() {
        throw new HttpErrorResponse({
          status: 403,
          statusText: 'Forbidden'
        });
      }
    }
  ]
};

export { requestChatEndpoint };
