import { Injectable, NgZone } from '@angular/core';

import { ModalController } from '@ionic/angular';

import { isNil } from 'lodash-es';

import { DateTime, nowNaive } from '@housekeep/infra';

import { ModalContent, ModalContentInputs } from 'components/modal-content/modal-content.component';

import { DemoService } from './demo-service';

/**
 * The priority of the message, P0 being the "highest" (most urgent) and P3 being the "lowest".
 * This range and naming convention was chosen to mirror how we describe priorities in PivotalTracker and our OKRs.
 *
 * A modal can also have no value for its priority, in which it is treated as dispensable and may not be shown at all
 * if there are other modals to show.
 */
export enum ModalContentPriority {
  P0 = 0,
  P1 = 1,
  P2 = 2,
  P3 = 3
}

/** The CTA in a content modal can trigger one or more actions, in addition to (or instead of) opening a URL. */
export enum ModalContentCtaAction {
  ExitDemoMode = 'exit_demo_mode',
  RefreshSchedule = 'refresh_schedule',
  CallRecordingConsent = 'call_recording_consent'
}

/** A comma-separated list of CTA actions. */
export type ModalContentCtaActionList = ModalContentCtaAction | `${ModalContentCtaAction},${ModalContentCtaAction}`;

interface PendingContentModal {
  inputs: ModalContentInputs;
  priority: ModalContentPriority;
  requested: DateTime;
  showInDemoMode: boolean;
  openCallback: () => void;
}

interface PresentedContentModal {
  modal: HTMLIonModalElement;
  priority: ModalContentPriority;
}

export const PENDING_MODAL_POLL_FREQUENCY = 500;

@Injectable({ providedIn: 'root' })
export class InAppMessagingService {
  private pendingModals: PendingContentModal[] = [];
  private presentedModals: PresentedContentModal[] = [];

  constructor(private demoService: DemoService, private modalCtrl: ModalController, private ngZone: NgZone) {
    setInterval(() => this.pollPendingModals(), PENDING_MODAL_POLL_FREQUENCY);
  }

  /**
   * Show a content modal after at least the given delay.
   *
   * Content modals are debounced, so it may take up to an additional 500ms (the polling frequency) before the app
   * attempts to show the modal. The modal still will not be shown after that time if there is another modal of equal or
   * higher priority already shown. The modal will be shown once equal/higher priority modals have been dismissed.
   *
   * Modals with null/undefined priority are treated as a special case: these will be discarded and never shown if there
   * was already a modal visible.
   *
   * The purpose of the priority and delay is to give us finer-grained control over the order in which modals are shown
   * to users. We don't want to spam them by opening several at once.
   *
   * @param inputs The inputs to pass to the ModalContent component
   * @param priority The priority of the modal
   * @param delay The delay in milliseconds
   * @param showInDemoMode `true` if the modal should show in Demo Mode; `false` (default value) otherwise
   * @param openCallback A callback to run if/when the modal is shown
   */
  public showContentModal(
    inputs: ModalContentInputs,
    priority?: ModalContentPriority,
    delay = 0,
    showInDemoMode = false,
    openCallback?: () => void
  ) {
    setTimeout(() => this.enqueueModal(inputs, priority, nowNaive(), showInDemoMode, openCallback), delay);
  }

  private enqueueModal(
    inputs: ModalContentInputs,
    priority: ModalContentPriority,
    requested: DateTime,
    showInDemoMode: boolean,
    openCallback: () => void
  ): void {
    const newPendingModal: PendingContentModal = {
      inputs,
      priority,
      requested,
      showInDemoMode,
      openCallback
    };

    // Add the new modal and sort by priority
    this.pendingModals = [...this.pendingModals, newPendingModal].sort(InAppMessagingService.compareModalPriority);
  }

  private async pollPendingModals(): Promise<void> {
    const pendingModal = this.pendingModals.shift();
    if (pendingModal) {
      let reenqueueDueToPriority = false;

      // If there are already modal(s) displayed, we will only show the new one if it is higher priority
      if (this.presentedModals.length) {
        // Modals with no priority get discarded rather than re-enqueued
        if (isNil(pendingModal.priority)) {
          return;
        }

        // If there is already a modal with equal or higher priority (i.e. numerically <=),
        // then re-enqueue the modal to display later
        if (this.presentedModals.some(presentedModal => presentedModal.priority <= pendingModal.priority)) {
          reenqueueDueToPriority = true;
        }
      }

      // Also re-enqueue the modal if Demo Mode is on and it should not be shown in Demo Mode
      if (reenqueueDueToPriority || (!pendingModal.showInDemoMode && this.demoService.isOn)) {
        this.enqueueModal(
          pendingModal.inputs,
          pendingModal.priority,
          pendingModal.requested,
          pendingModal.showInDemoMode,
          pendingModal.openCallback
        );
        return;
      }

      await this.presentModal(pendingModal.inputs, pendingModal.priority, pendingModal.openCallback);
    }
  }

  private async presentModal(
    inputs: ModalContentInputs,
    priority: ModalContentPriority,
    openCallback: () => void
  ): Promise<void> {
    await this.ngZone.run(async () => {
      const modal = await this.modalCtrl.create({
        component: ModalContent,
        componentProps: inputs,
        cssClass: ModalContent.CLASS_ALERT
      });

      // Remove this modal from the `presentedModals` array when dismissed
      modal.onDidDismiss().then(() => {
        this.presentedModals = this.presentedModals.filter(presentedModal => presentedModal.modal !== modal);
      });

      if (openCallback) {
        openCallback();
      }

      await modal.present();
      this.presentedModals.push({
        modal,
        priority
      });
    });
  }

  private static compareModalPriority(modal1: PendingContentModal, modal2: PendingContentModal): number {
    // Coerce priorities into numbers
    const priority1 = isNil(modal1.priority) ? Number.MAX_VALUE : modal1.priority;
    const priority2 = isNil(modal2.priority) ? Number.MAX_VALUE : modal2.priority;

    if (priority1 === priority2) {
      return modal1.requested.unix() - modal2.requested.unix();
    }
    return priority1 - priority2;
  }
}
