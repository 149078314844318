import { addWeekDays } from '@housekeep/infra';

const FRIDAY_TWO_WEEKS = addWeekDays(5, 2).format('YYYY-MM-DD');

export const PAGED_PAYMENT_PERIOD_TEMPLATES = {
  num_pages: 1,
  current_page: 1,
  results: [
    {
      payment_adjustments_for_invoice: [
        {
          amount_pounds: '50.00',
          type: 'refer',
          id: 'ejo8w5fv8rok',
          payment_period_id: '2z4gk13j5qz4',
          created: '{{ date }}T10:00:00Z'
        }
      ],
      amount_earned_pounds: 108.25,
      total_pounds: 310.5,
      worked_visits_for_invoice: [
        {
          charges_pounds: '0.00',
          visit: {
            customer_preferred_name: 'Dennis',
            includes_bonus: false,
            tip_amount_pounds: 5.0,
            tasks: [
              {
                type: 'cleaning',
                hours: 3.0
              }
            ],
            any_worker: false,
            finished_at: '{{ date }}T11:00:00Z',
            account_id: 'xb6ymh4r88op',
            frequency: 7.0,
            started_at: '{{ date }}T08:00:00Z',
            id: 'vzqe90ye88z6',
            property_id: 'nb7a3cmmqvbe',
            job_id: 'mz2p4t89gyok',
            access: 'keys',
            time_slot: {
              start: '09:00:00',
              end: '17:00:00'
            },
            skipped: false,
            is_first_visit: false,
            finished: true,
            scheduled_date: '{{ date }}',
            actual_date: '{{ date }}',
            access_display: 'Have keys',
            customer_can_alter: false,
            is_reviewable: false,
            worker_property_access: 'cannot_access',
            property: {
              city: '',
              bathrooms: 1,
              account_id: 'xb6ymh4r88op',
              bedrooms: 3,
              access: 'keys',
              postcode: {
                inward: '8DS',
                outward: 'SW1',
                location: {
                  latitude: '51.519958973',
                  longitude: '-0.0941286921321'
                }
              },
              line_1: 'Silk St',
              line_3: '',
              line_2: '',
              id: 'nb7a3cmmqvbe'
            }
          },
          pay_pounds: '25.50',
          payment_period_id: '2z4gk13j5qz4',
          id: 'jo8w812389ok',
          total_pounds: '25.50'
        },
        {
          charges_pounds: '2.00',
          visit: {
            customer_preferred_name: 'Ms Hughes',
            includes_bonus: false,
            tip_amount_pounds: 2.5,
            tasks: [
              {
                type: 'products',
                hours: null
              },
              {
                type: 'cleaning',
                hours: 4.0
              }
            ],
            any_worker: false,
            finished_at: '{{ date }}T12:00:00Z',
            account_id: '3ba2yhq7xnoe',
            frequency: 7.0,
            started_at: '{{ date }}T08:00:00Z',
            id: 'jo8wj0lpn8ok',
            property_id: '8zpgpcaa53zk',
            job_id: 'jo8lpt4navzk',
            access: 'at home',
            time_slot: {
              start: '12:00:00',
              end: '12:00:00'
            },
            skipped: false,
            is_first_visit: false,
            finished: true,
            scheduled_date: '{{ date }}',
            actual_date: '{{ date }}',
            access_display: 'Client at home',
            customer_can_alter: false,
            is_reviewable: false,
            worker_property_access: 'can_access',
            property: {
              city: '',
              bathrooms: 2,
              account_id: '3ba2yhq7xnoe',
              bedrooms: 2,
              access: 'at home',
              postcode: {
                inward: '4EB',
                outward: 'SW1',
                location: {
                  latitude: '51.5177966876',
                  longitude: '-0.0960927212812'
                }
              },
              line_1: '150 London Wall',
              line_3: '',
              line_2: '',
              id: '8zpgpcaa53zk'
            }
          },
          pay_pounds: '25.50',
          payment_period_id: '2z4gk13j5qz4',
          id: '8zpvlt3rgxzk',
          total_pounds: '36.50'
        },
        {
          charges_pounds: '2.00',
          visit: {
            customer_preferred_name: 'Matt',
            includes_bonus: false,
            tip_amount_pounds: null,
            tasks: [
              {
                type: 'products',
                hours: null
              },
              {
                type: 'cleaning',
                hours: 4.0
              }
            ],
            any_worker: false,
            finished_at: '{{ date }}T11:30:00Z',
            account_id: '3ba2yhq7xnoe',
            frequency: 7.0,
            started_at: '{{ date }}T07:30:00Z',
            id: 'jo8wj0lpn8ok',
            property_id: '8zpgpcaa53zk',
            job_id: 'jo8lpt4navzk',
            access: 'at home',
            time_slot: {
              start: '12:00:00',
              end: '12:00:00'
            },
            skipped: false,
            is_first_visit: false,
            finished: true,
            scheduled_date: '{{ date }}',
            actual_date: '{{ date }}',
            access_display: 'Client at home',
            customer_can_alter: false,
            is_reviewable: false,
            worker_property_access: 'can_access',
            property: {
              city: '',
              bathrooms: 2,
              account_id: '3ba2yhq7xnoe',
              bedrooms: 2,
              access: 'at home',
              postcode: {
                inward: '7HD',
                outward: 'WC1',
                location: {
                  latitude: '51.5177966876',
                  longitude: '-0.0960927212812'
                }
              },
              line_1: '150 London Wall',
              line_3: '',
              line_2: '',
              id: '8zpgpcaa53zk'
            }
          },
          pay_pounds: '25.50',
          payment_period_id: '2z4gk13j5qz4',
          id: '8zpvlt3rgxzk',
          total_pounds: '27.50'
        },
        {
          charges_pounds: '2.00',
          visit: {
            customer_preferred_name: 'Paul',
            includes_bonus: false,
            tip_amount_pounds: 10.0,
            tasks: [
              {
                type: 'products',
                hours: null
              },
              {
                type: 'cleaning',
                hours: 3.0
              }
            ],
            any_worker: false,
            finished_at: '{{ date - 1 }}T16:00:00Z',
            account_id: '3ba2yhq7xnoe',
            frequency: 7.0,
            started_at: '{{ date - 1 }}T13:00:00Z',
            id: 'jo8wj0lpn8ok',
            property_id: '8zpgpcaa53zk',
            job_id: 'jo8lpt4navzk',
            access: 'at home',
            time_slot: {
              start: '12:00:00',
              end: '12:00:00'
            },
            skipped: false,
            is_first_visit: false,
            finished: true,
            scheduled_date: '{{ date - 1 }}',
            actual_date: '{{ date - 1 }}',
            access_display: 'Client at home',
            customer_can_alter: false,
            is_reviewable: false,
            worker_property_access: 'can_access',
            property: {
              city: '',
              bathrooms: 2,
              account_id: '3ba2yhq7xnoe',
              bedrooms: 2,
              access: 'at home',
              postcode: {
                inward: '4ER',
                outward: 'SW1',
                location: {
                  latitude: '51.5177966876',
                  longitude: '-0.0960927212812'
                }
              },
              line_1: '150 London Wall',
              line_3: '',
              line_2: '',
              id: '8zpgpcaa53zk'
            }
          },
          pay_pounds: '25.50',
          payment_period_id: '2z4gk13j5qz4',
          id: '8zpvlt3rgxzk',
          total_pounds: '27.50'
        },
        {
          charges_pounds: '2.00',
          visit: {
            customer_preferred_name: 'Jason',
            includes_bonus: false,
            tip_amount_pounds: 5.0,
            tasks: [
              {
                type: 'products',
                hours: null
              },
              {
                type: 'cleaning',
                hours: 3.0
              }
            ],
            any_worker: false,
            finished_at: '{{ date - 1 }}T14:00:00Z',
            account_id: '3ba2yhq7xnoe',
            frequency: 7.0,
            started_at: '{{ date - 1 }}T11:00:00Z',
            id: 'jo8wj0lpn8ok',
            property_id: '8zpgpcaa53zk',
            job_id: 'jo8lpt4navzk',
            access: 'at home',
            time_slot: {
              start: '12:00:00',
              end: '12:00:00'
            },
            skipped: false,
            is_first_visit: false,
            finished: true,
            scheduled_date: '{{ date - 1 }}',
            actual_date: '{{ date - 1 }}',
            access_display: 'Client at home',
            customer_can_alter: false,
            is_reviewable: false,
            worker_property_access: 'can_access',
            property: {
              city: '',
              bathrooms: 2,
              account_id: '3ba2yhq7xnoe',
              bedrooms: 2,
              access: 'at home',
              postcode: {
                inward: '4ER',
                outward: 'SW1',
                location: {
                  latitude: '51.5177966876',
                  longitude: '-0.0960927212812'
                }
              },
              line_1: '150 London Wall',
              line_3: '',
              line_2: '',
              id: '8zpgpcaa53zk'
            }
          },
          pay_pounds: '25.50',
          payment_period_id: '2z4gk13j5qz4',
          id: '8zpvlt3rgxzk',
          total_pounds: '27.50'
        },
        {
          charges_pounds: '2.00',
          visit: {
            customer_preferred_name: 'Veeve',
            includes_bonus: false,
            tip_amount_pounds: 10.0,
            tasks: [
              {
                type: 'products',
                hours: null
              },
              {
                type: 'cleaning',
                hours: 2.0
              }
            ],
            any_worker: false,
            finished_at: '{{ date - 1 }}T18:30:00Z',
            account_id: '3ba2yhq7xnoe',
            frequency: 7.0,
            started_at: '{{ date - 1 }}T16:30:00Z',
            id: 'jo8wj0lpn8ok',
            property_id: '8zpgpcaa53zk',
            job_id: 'jo8lpt4navzk',
            access: 'at home',
            time_slot: {
              start: '12:00:00',
              end: '12:00:00'
            },
            skipped: false,
            is_first_visit: false,
            finished: true,
            scheduled_date: '{{ date - 1 }}',
            actual_date: '{{ date - 1 }}',
            access_display: 'Client at home',
            customer_can_alter: false,
            is_reviewable: false,
            worker_property_access: 'can_access',
            property: {
              city: '',
              bathrooms: 2,
              account_id: '3ba2yhq7xnoe',
              bedrooms: 2,
              access: 'at home',
              postcode: {
                inward: '8PO',
                outward: 'SW1',
                location: {
                  latitude: '51.5177966876',
                  longitude: '-0.0960927212812'
                }
              },
              line_1: '150 London Wall',
              line_3: '',
              line_2: '',
              id: '8zpgpcaa53zk'
            }
          },
          pay_pounds: '26.50',
          payment_period_id: '2z4gk13j5qz4',
          id: '8zpvlt3rgxzk',
          total_pounds: '27.50'
        },
        {
          charges_pounds: '2.00',
          visit: {
            customer_preferred_name: 'Jayne',
            includes_bonus: false,
            tip_amount_pounds: 2.5,
            tasks: [
              {
                type: 'products',
                hours: null
              },
              {
                type: 'cleaning',
                hours: 2.0
              }
            ],
            any_worker: false,
            finished_at: '{{ date - 2 }}T14:00:00Z',
            account_id: '3ba2yhq7xnoe',
            frequency: 7.0,
            started_at: '{{ date - 2 }}T12:00:00Z',
            id: 'jo8wj0lpn8ok',
            property_id: '8zpgpcaa53zk',
            job_id: 'jo8lpt4navzk',
            access: 'at home',
            time_slot: {
              start: '12:00:00',
              end: '12:00:00'
            },
            skipped: false,
            is_first_visit: false,
            finished: true,
            scheduled_date: '{{ date - 2 }}',
            actual_date: '{{ date - 2 }}',
            access_display: 'Client at home',
            customer_can_alter: false,
            is_reviewable: false,
            worker_property_access: 'can_access',
            property: {
              city: '',
              bathrooms: 2,
              account_id: '3ba2yhq7xnoe',
              bedrooms: 2,
              access: 'at home',
              postcode: {
                inward: 'EA4',
                outward: 'WC1',
                location: {
                  latitude: '51.5177966876',
                  longitude: '-0.0960927212812'
                }
              },
              line_1: '150 London Wall',
              line_3: '',
              line_2: '',
              id: '8zpgpcaa53zk'
            }
          },
          pay_pounds: '25.50',
          payment_period_id: '2z4gk13j5qz4',
          id: '8zpvlt3rgxzk',
          total_pounds: '27.50'
        },
        {
          charges_pounds: '0.00',
          visit: {
            customer_preferred_name: 'Molly',
            includes_bonus: false,
            tip_amount_pounds: 10.0,
            tasks: [
              {
                type: 'cleaning',
                hours: 3.5
              }
            ],
            any_worker: false,
            finished_at: '{{ date - 2 }}T18:00:00Z',
            account_id: 'kbj95haplaz4',
            frequency: 7.0,
            started_at: '{{ date - 2 }}T14:30:00Z',
            id: 'dznv31jr6qo5',
            property_id: 'gzgnwceedab9',
            job_id: 'thechangesvisit',
            access: 'at home',
            time_slot: {
              start: '14:30:00',
              end: '15:30:00'
            },
            skipped: false,
            is_first_visit: false,
            finished: true,
            scheduled_date: '{{ date - 2 }}',
            actual_date: '{{ date - 2 }}',
            access_display: 'Client at home',
            customer_can_alter: false,
            is_reviewable: false,
            worker_property_access: 'can_access',
            property: {
              city: '',
              bathrooms: 1,
              account_id: 'kbj95haplaz4',
              bedrooms: 4,
              access: 'at home',
              postcode: {
                inward: '5LR',
                outward: 'WC1',
                location: {
                  latitude: '51.5229653579',
                  longitude: '-0.155051270069'
                }
              },
              line_1: 'Marylebone Rd',
              line_3: '',
              line_2: 'Marylebone',
              id: 'gzgnwceedab9'
            }
          },
          pay_pounds: '29.75',
          payment_period_id: '2z4gk13j5qz4',
          id: 'kz3ywcxm5pbr',
          total_pounds: '29.75'
        },
        {
          charges_pounds: '0.00',
          visit: {
            customer_preferred_name: 'Tracy',
            includes_bonus: false,
            tip_amount_pounds: 5.0,
            tasks: [
              {
                type: 'cleaning',
                hours: 3.0
              }
            ],
            any_worker: false,
            finished_at: '{{ date - 3 }}T19:00:00Z',
            account_id: 'vzq8jhejkjz6',
            frequency: 7.0,
            started_at: '{{ date - 3 }}T16:00:00Z',
            id: 'mz2e30k6x8zk',
            property_id: '3ba2jcqqykoe',
            job_id: '9oygmtpjvlox',
            access: 'keysafe',
            time_slot: {
              start: '12:00:00',
              end: '17:00:00'
            },
            skipped: false,
            is_first_visit: false,
            finished: true,
            scheduled_date: '{{ date - 3 }}',
            actual_date: '{{ date - 3 }}',
            access_display: 'Key safe',
            customer_can_alter: false,
            is_reviewable: false,
            worker_property_access: 'can_access',
            property: {
              city: '',
              bathrooms: 2,
              account_id: 'vzq8jhejkjz6',
              bedrooms: 2,
              access: 'keysafe',
              postcode: {
                inward: '2DB',
                outward: 'WC1',
                location: {
                  latitude: '51.5299847959',
                  longitude: '-0.127014156831'
                }
              },
              line_1: '96 Euston Rd',
              line_3: '',
              line_2: 'Kings Cross',
              id: '3ba2jcqqykoe'
            }
          },
          pay_pounds: '25.50',
          payment_period_id: '2z4gk13j5qz4',
          id: 'nb7wd1p5m5oe',
          total_pounds: '25.50'
        }
      ],
      last_day: '{{ date + 9 }}',
      date_paid: null,
      payment_due_date: FRIDAY_TWO_WEEKS,
      state: 'incomplete',
      worker_id: '59oykr0krvzx',
      first_day: '{{ date - 4 }}',
      id: '2z4gk13j5qz4'
    },
    {
      payment_adjustments_for_invoice: [
        {
          amount_pounds: '50.00',
          type: 'refer',
          id: 'dmz2exfr82zk',
          payment_period_id: '3bawdipyamoe',
          created: '{{ date - 13 }}T10:00:00Z'
        },
        {
          created: '{{ date - 14 }}T10:00:00Z',
          visit: {
            customer_preferred_name: 'Jason'
          },
          payment_period_id: 'jovyj1weyrop',
          type: 'customercancel',
          id: 'jo8w5fp4plok',
          amount_pounds: '10.00'
        }
      ],
      amount_earned_pounds: '408.25',
      total_pounds: '501.50',
      worked_visits_for_invoice: [
        {
          charges_pounds: '0.00',
          visit: {
            customer_preferred_name: 'Elizabeth',
            includes_bonus: false,
            tip_amount_pounds: null,
            tasks: [
              {
                type: 'cleaning',
                hours: 2.0
              }
            ],
            any_worker: false,
            finished_at: '{{ date - 15 }}T10:00:00Z',
            account_id: 'yzdrvhmg5aol',
            frequency: 7.0,
            started_at: '{{ date - 15 }}T08:00:00Z',
            id: '3bawr09qwkoe',
            property_id: 'jze86caa3ezn',
            job_id: '2z4l6twj9lo4',
            access: 'keys',
            time_slot: {
              start: '09:00:00',
              end: '17:00:00'
            },
            skipped: false,
            is_first_visit: false,
            finished: true,
            scheduled_date: '{{ date - 15 }}',
            actual_date: '{{ date - 15 }}',
            access_display: 'Have keys',
            customer_can_alter: false,
            is_reviewable: false,
            worker_property_access: 'cannot_access',
            property: {
              city: '',
              bathrooms: 1,
              account_id: 'yzdrvhmg5aol',
              bedrooms: 1,
              access: 'keys',
              postcode: {
                inward: '3DG',
                outward: 'WC1B',
                location: {
                  latitude: '51.5193623987',
                  longitude: '-0.126873431403'
                }
              },
              line_1: 'Great Russell St',
              line_3: '',
              line_2: 'Bloomsbury',
              id: 'jze86caa3ezn'
            }
          },
          pay_pounds: '17.00',
          payment_period_id: '3bawdipyamoe',
          id: 'noren1exgeb2',
          total_pounds: '17.00'
        },
        {
          charges_pounds: '2.00',
          visit: {
            customer_preferred_name: 'Dennis',
            includes_bonus: false,
            tip_amount_pounds: null,
            tasks: [
              {
                type: 'products',
                hours: null
              },
              {
                type: 'cleaning',
                hours: 2.5
              }
            ],
            any_worker: false,
            finished_at: '{{ date - 6 }}T18:03:00Z',
            account_id: 'qzxw8hla56ok',
            frequency: 7.0,
            started_at: '{{ date - 6 }}T15:33:00Z',
            id: 'vzqe90yed8z6',
            property_id: 'nb5q2c44pab4',
            job_id: 'kbj9et75gmz4',
            access: 'at home',
            time_slot: {
              start: '16:00:00',
              end: '17:00:00'
            },
            skipped: false,
            is_first_visit: false,
            finished: true,
            scheduled_date: '{{ date - 6 }}',
            actual_date: '{{ date - 6 }}',
            access_display: 'Client at home',
            customer_can_alter: false,
            is_reviewable: false,
            worker_property_access: 'can_access',
            property: {
              city: '',
              bathrooms: 2,
              account_id: 'qzxw8hla56ok',
              bedrooms: 1,
              access: 'at home',
              postcode: {
                inward: '7BB',
                outward: 'EC4Y',
                location: {
                  latitude: '51.5134590453',
                  longitude: '-0.109792206147'
                }
              },
              line_1: 'Temple',
              line_3: '',
              line_2: '',
              id: 'nb5q2c44pab4'
            }
          },
          pay_pounds: '21.25',
          payment_period_id: '3bawdipyamoe',
          id: 'jo8w812349ok',
          total_pounds: '23.25'
        },
        {
          charges_pounds: '2.00',
          visit: {
            customer_preferred_name: 'Ms Hughes',
            includes_bonus: false,
            tip_amount_pounds: null,
            tasks: [
              {
                type: 'products',
                hours: null
              },
              {
                type: 'cleaning',
                hours: 3.0
              }
            ],
            any_worker: false,
            finished_at: '{{ date - 8 }}T19:00:00Z',
            account_id: 'nb7adhmk98be',
            frequency: 7.0,
            started_at: '{{ date - 8 }}T16:00:00Z',
            id: 'jzeyjsna3eon',
            property_id: 'yzdracmm8mol',
            job_id: 'vz9p7tlwmabq',
            access: 'keyhiddn',
            time_slot: {
              start: '09:00:00',
              end: '17:00:00'
            },
            skipped: false,
            is_first_visit: false,
            finished: true,
            scheduled_date: '{{ date - 8 }}',
            actual_date: '{{ date - 8 }}',
            access_display: 'Key hidden',
            customer_can_alter: false,
            is_reviewable: false,
            worker_property_access: 'can_access',
            property: {
              city: '',
              bathrooms: 2,
              account_id: 'nb7adhmk98be',
              bedrooms: 2,
              access: 'keyhiddn',
              postcode: {
                inward: '9DD',
                outward: 'WC2E',
                location: {
                  latitude: '51.5130455409',
                  longitude: '-0.122679254841'
                }
              },
              line_1: 'Bow St',
              line_3: '',
              line_2: '',
              id: 'yzdracmm8mol'
            }
          },
          pay_pounds: '25.50',
          payment_period_id: '3bawdipyamoe',
          id: 'qzx68i4y27zk',
          total_pounds: '27.50'
        },
        {
          charges_pounds: '0.00',
          visit: {
            customer_preferred_name: 'Paul',
            includes_bonus: false,
            tip_amount_pounds: null,
            tasks: [
              {
                type: 'cleaning',
                hours: 3.0
              }
            ],
            any_worker: false,
            finished_at: '{{ date - 9 }}T18:23:00Z',
            account_id: '8zpglhap45zk',
            frequency: 7.0,
            started_at: '{{ date - 9 }}T15:23:00Z',
            id: 'romy5s84vjoy',
            property_id: 'rom4pc44vjoy',
            job_id: 'norwatg2n7b2',
            access: 'keys',
            time_slot: {
              start: '09:00:00',
              end: '17:00:00'
            },
            skipped: false,
            is_first_visit: false,
            finished: true,
            scheduled_date: '{{ date - 9 }}',
            actual_date: '{{ date - 9 }}',
            access_display: 'Have keys',
            customer_can_alter: false,
            is_reviewable: false,
            worker_property_access: 'cannot_access',
            property: {
              city: '',
              bathrooms: 2,
              account_id: '8zpglhap45zk',
              bedrooms: 2,
              access: 'keys',
              postcode: {
                inward: '7HH',
                outward: 'EC2V',
                location: {
                  latitude: '51.5155664373',
                  longitude: '-0.0916600851062'
                }
              },
              line_1: 'Gresham St',
              line_3: '',
              line_2: '',
              id: 'rom4pc44vjoy'
            }
          },
          pay_pounds: '25.50',
          payment_period_id: '3bawdipyamoe',
          id: 'xowvdtrw87b2',
          total_pounds: '25.50'
        },
        {
          charges_pounds: '0.00',
          visit: {
            customer_preferred_name: 'Jayne',
            includes_bonus: false,
            tip_amount_pounds: null,
            tasks: [
              {
                type: 'cleaning',
                hours: 3.0
              }
            ],
            any_worker: false,
            finished_at: '{{ date - 13 }}T18:30:00Z',
            account_id: 'jovgmhv8m6op',
            frequency: 7.0,
            started_at: '{{ date - 13 }}T15:30:00Z',
            id: 'nb7wl0gmqvoe',
            property_id: 'qzxwpclleaok',
            job_id: 'jze8dt2geqzn',
            access: 'keys',
            time_slot: {
              start: '15:30:00',
              end: '16:30:00'
            },
            skipped: false,
            is_first_visit: false,
            finished: true,
            scheduled_date: '{{ date - 13 }}',
            actual_date: '{{ date - 13 }}',
            access_display: 'Have keys',
            customer_can_alter: false,
            is_reviewable: false,
            worker_property_access: 'cannot_access',
            property: {
              city: '',
              bathrooms: 2,
              account_id: 'jovgmhv8m6op',
              bedrooms: 2,
              access: 'keys',
              postcode: {
                inward: '0AA',
                outward: 'SW1A',
                location: {
                  latitude: '51.4998402617',
                  longitude: '-0.124662731546'
                }
              },
              line_1: 'Westminster',
              line_3: '',
              line_2: '',
              id: 'qzxwpclleaok'
            }
          },
          pay_pounds: '25.50',
          payment_period_id: '3bawdipyamoe',
          id: '2z4gwcpdwkz4',
          total_pounds: '25.50'
        },
        {
          charges_pounds: '0.00',
          visit: {
            customer_preferred_name: 'Veeve',
            includes_bonus: false,
            tip_amount_pounds: null,
            tasks: [
              {
                type: 'cleaning',
                hours: 3.0
              }
            ],
            any_worker: false,
            finished_at: '{{ date - 14 }}T11:00:00Z',
            account_id: 'xb6ymh4r88op',
            frequency: 7.0,
            started_at: '{{ date - 14 }}T08:00:00Z',
            id: 'kbjnd0ka56o4',
            property_id: 'nb7a3cmmqvbe',
            job_id: 'mz2p4t89gyok',
            access: 'keys',
            time_slot: {
              start: '09:00:00',
              end: '17:00:00'
            },
            skipped: false,
            is_first_visit: false,
            finished: true,
            scheduled_date: '{{ date - 14 }}',
            actual_date: '{{ date - 14 }}',
            access_display: 'Have keys',
            customer_can_alter: false,
            is_reviewable: false,
            worker_property_access: 'cannot_access',
            property: {
              city: '',
              bathrooms: 1,
              account_id: 'xb6ymh4r88op',
              bedrooms: 3,
              access: 'keys',
              postcode: {
                inward: '8DS',
                outward: 'SW1',
                location: {
                  latitude: '51.519958973',
                  longitude: '-0.0941286921321'
                }
              },
              line_1: 'Silk St',
              line_3: '',
              line_2: '',
              id: 'nb7a3cmmqvbe'
            }
          },
          pay_pounds: '25.50',
          payment_period_id: '3bawdipyamoe',
          id: 'nb5pwcr8k9z4',
          total_pounds: '25.50'
        },
        {
          charges_pounds: '0.00',
          visit: {
            customer_preferred_name: 'Matt',
            includes_bonus: false,
            tip_amount_pounds: null,
            tasks: [
              {
                type: 'cleaning',
                hours: 3.0
              }
            ],
            any_worker: false,
            finished_at: '{{ date - 7 }}T11:00:00Z',
            account_id: 'xb6ymh4r88op',
            frequency: 7.0,
            started_at: '{{ date - 7 }}T08:00:00Z',
            id: 'qolx2ckmexo2',
            property_id: 'nb7a3cmmqvbe',
            job_id: 'mz2p4t89gyok',
            access: 'keys',
            time_slot: {
              start: '09:00:00',
              end: '17:00:00'
            },
            skipped: false,
            is_first_visit: false,
            finished: true,
            scheduled_date: '{{ date - 7 }}',
            actual_date: '{{ date - 7 }}',
            access_display: 'Have keys',
            customer_can_alter: false,
            is_reviewable: false,
            worker_property_access: 'cannot_access',
            property: {
              city: '',
              bathrooms: 1,
              account_id: 'xb6ymh4r88op',
              bedrooms: 3,
              access: 'keys',
              postcode: {
                inward: '8DS',
                outward: 'SW1',
                location: {
                  latitude: '51.519958973',
                  longitude: '-0.0941286921321'
                }
              },
              line_1: 'Silk St',
              line_3: '',
              line_2: '',
              id: 'nb7a3cmmqvbe'
            }
          },
          pay_pounds: '25.50',
          payment_period_id: '3bawdipyamoe',
          id: 'mz2ew1px5nzk',
          total_pounds: '25.50'
        },
        {
          charges_pounds: '2.00',
          visit: {
            customer_preferred_name: 'Jason',
            includes_bonus: false,
            tip_amount_pounds: null,
            tasks: [
              {
                type: 'products',
                hours: null
              },
              {
                type: 'cleaning',
                hours: 3.0
              }
            ],
            any_worker: false,
            finished_at: '{{ date - 16 }}T14:00:00Z',
            account_id: '3ba2yhq7xnoe',
            frequency: 7.0,
            started_at: '{{ date - 16 }}T11:00:00Z',
            id: 'mz2e30k698zk',
            property_id: '8zpgpcaa53zk',
            job_id: 'jo8lpt4navzk',
            access: 'at home',
            time_slot: {
              start: '12:00:00',
              end: '12:00:00'
            },
            skipped: false,
            is_first_visit: false,
            finished: true,
            scheduled_date: '{{ date - 16 }}',
            actual_date: '{{ date - 16 }}',
            access_display: 'Client at home',
            customer_can_alter: false,
            is_reviewable: false,
            worker_property_access: 'can_access',
            property: {
              city: '',
              bathrooms: 2,
              account_id: '3ba2yhq7xnoe',
              bedrooms: 2,
              access: 'at home',
              postcode: {
                inward: '5HN',
                outward: 'EC2Y',
                location: {
                  latitude: '51.5177966876',
                  longitude: '-0.0960927212812'
                }
              },
              line_1: '150 London Wall',
              line_3: '',
              line_2: '',
              id: '8zpgpcaa53zk'
            }
          },
          pay_pounds: '25.50',
          payment_period_id: '3bawdipyamoe',
          id: 'nb7wd1p5r5oe',
          total_pounds: '27.50'
        },
        {
          charges_pounds: '2.00',
          visit: {
            customer_preferred_name: 'Ms Hughes',
            includes_bonus: false,
            tip_amount_pounds: null,
            tasks: [
              {
                type: 'products',
                hours: null
              },
              {
                type: 'cleaning',
                hours: 3.0
              }
            ],
            any_worker: false,
            finished_at: '{{ date - 16 }}T12:42:00Z',
            account_id: '2z4lwhknvwo4',
            frequency: 7.0,
            started_at: '{{ date - 16 }}T09:42:00Z',
            id: 'qzx6mt3lqazk',
            property_id: 'jovgpcvve2op',
            job_id: 'yzdrmtr737ol',
            access: 'concierg',
            time_slot: {
              start: '09:00:00',
              end: '17:00:00'
            },
            skipped: false,
            is_first_visit: false,
            finished: true,
            scheduled_date: '{{ date - 16 }}',
            actual_date: '{{ date - 16 }}',
            access_display: 'Concierge/Porter',
            customer_can_alter: false,
            is_reviewable: false,
            worker_property_access: 'can_access',
            property: {
              city: '',
              bathrooms: 1,
              account_id: '2z4lwhknvwo4',
              bedrooms: 3,
              access: 'concierg',
              postcode: {
                inward: '5DN',
                outward: 'WC2N',
                location: {
                  latitude: '51.5090968834',
                  longitude: '-0.127683588377'
                }
              },
              line_1: 'Trafalgar Square',
              line_3: '',
              line_2: '',
              id: 'jovgpcvve2op'
            }
          },
          pay_pounds: '25.50',
          payment_period_id: '3bawdipyamoe',
          id: 'gzgydc8l64z9',
          total_pounds: '27.50'
        },
        {
          charges_pounds: '2.00',
          visit: {
            customer_preferred_name: 'Lauren',
            includes_bonus: false,
            tip_amount_pounds: null,
            tasks: [
              {
                type: 'products',
                hours: null
              },
              {
                type: 'cleaning',
                hours: 3.0
              }
            ],
            any_worker: false,
            finished_at: '{{ date - 9 }}T19:00:00Z',
            account_id: '2z4lwhknvwo4',
            frequency: 7.0,
            started_at: '{{ date - 9 }}T16:00:00Z',
            id: 'wbkq8hja8kb7',
            property_id: 'jovgpcvve2op',
            job_id: 'yzdrmtr737ol',
            access: 'concierg',
            time_slot: {
              start: '09:00:00',
              end: '17:00:00'
            },
            skipped: false,
            is_first_visit: false,
            finished: true,
            scheduled_date: '{{ date - 9 }}',
            actual_date: '{{ date - 9 }}',
            access_display: 'Concierge/Porter',
            customer_can_alter: false,
            is_reviewable: false,
            worker_property_access: 'can_access',
            property: {
              city: '',
              bathrooms: 1,
              account_id: '2z4lwhknvwo4',
              bedrooms: 3,
              access: 'concierg',
              postcode: {
                inward: '5DN',
                outward: 'WC2N',
                location: {
                  latitude: '51.5090968834',
                  longitude: '-0.127683588377'
                }
              },
              line_1: 'Trafalgar Square',
              line_3: '',
              line_2: '',
              id: 'jovgpcvve2op'
            }
          },
          pay_pounds: '25.50',
          payment_period_id: '3bawdipyamoe',
          id: 'xb6jmuj2e6op',
          total_pounds: '27.50'
        },
        {
          charges_pounds: '0.00',
          visit: {
            customer_preferred_name: 'Matt',
            includes_bonus: false,
            tip_amount_pounds: null,
            tasks: [
              {
                type: 'cleaning',
                hours: 3.0
              }
            ],
            any_worker: false,
            finished_at: '{{ date - 13 }}T15:00:00Z',
            account_id: 'dzn29hr4ylz5',
            frequency: 7.0,
            started_at: '{{ date - 13 }}T12:00:00Z',
            id: '8zpvr18ar3zk',
            property_id: 'xowxyc992pz2',
            job_id: 'rom4qtkr75oy',
            access: 'at home',
            time_slot: {
              start: '12:00:00',
              end: '13:00:00'
            },
            skipped: false,
            is_first_visit: false,
            finished: true,
            scheduled_date: '{{ date - 13 }}',
            actual_date: '{{ date - 13 }}',
            access_display: 'Client at home',
            customer_can_alter: false,
            is_reviewable: false,
            worker_property_access: 'can_access',
            property: {
              city: '',
              bathrooms: 2,
              account_id: 'dzn29hr4ylz5',
              bedrooms: 2,
              access: 'at home',
              postcode: {
                inward: '8EP',
                outward: 'EC3A',
                location: {
                  latitude: '51.5144844709',
                  longitude: '-0.0804635333519'
                }
              },
              line_1: '30 St Mary Axe',
              line_3: '',
              line_2: '',
              id: 'xowxyc992pz2'
            }
          },
          pay_pounds: '25.50',
          payment_period_id: '3bawdipyamoe',
          id: 'dznv9t26lxo5',
          total_pounds: '25.50'
        },
        {
          charges_pounds: '0.00',
          visit: {
            customer_preferred_name: 'Tracy',
            includes_bonus: false,
            tip_amount_pounds: null,
            tasks: [
              {
                type: 'cleaning',
                hours: 3.5
              }
            ],
            any_worker: false,
            finished_at: '{{ date - 15 }}T18:00:00Z',
            account_id: 'kbj95haplaz4',
            frequency: 7.0,
            started_at: '{{ date - 15 }}T14:30:00Z',
            id: '2z4grsykd6z4',
            property_id: 'gzgnwceedab9',
            job_id: 'thechangesvisit',
            access: 'at home',
            time_slot: {
              start: '14:30:00',
              end: '15:30:00'
            },
            skipped: false,
            is_first_visit: false,
            finished: true,
            scheduled_date: '{{ date - 15 }}',
            actual_date: '{{ date - 15 }}',
            access_display: 'Client at home',
            customer_can_alter: false,
            is_reviewable: false,
            worker_property_access: 'can_access',
            property: {
              city: '',
              bathrooms: 1,
              account_id: 'kbj95haplaz4',
              bedrooms: 4,
              access: 'at home',
              postcode: {
                inward: '5LR',
                outward: 'NW1',
                location: {
                  latitude: '51.5229653579',
                  longitude: '-0.155051270069'
                }
              },
              line_1: 'Marylebone Rd',
              line_3: '',
              line_2: 'Marylebone',
              id: 'gzgnwceedab9'
            }
          },
          pay_pounds: '29.75',
          payment_period_id: '3bawdipyamoe',
          id: 'jzeyxc5yaxon',
          total_pounds: '29.75'
        },
        {
          charges_pounds: '0.00',
          visit: {
            customer_preferred_name: 'Elizabeth',
            includes_bonus: false,
            tip_amount_pounds: null,
            tasks: [
              {
                type: 'cleaning',
                hours: 3.0
              }
            ],
            any_worker: false,
            finished_at: '{{ date - 14 }}T17:21:00Z',
            account_id: 'vzq8jhejkjz6',
            frequency: 7.0,
            started_at: '{{ date - 14 }}T14:21:00Z',
            id: 'kz3yes75mvbr',
            property_id: '3ba2jcqqykoe',
            job_id: '9oygmtpjvlox',
            access: 'keysafe',
            time_slot: {
              start: '12:00:00',
              end: '17:00:00'
            },
            skipped: false,
            is_first_visit: false,
            finished: true,
            scheduled_date: '{{ date - 14 }}',
            actual_date: '{{ date - 14 }}',
            access_display: 'Key safe',
            customer_can_alter: false,
            is_reviewable: false,
            worker_property_access: 'can_access',
            property: {
              city: '',
              bathrooms: 2,
              account_id: 'vzq8jhejkjz6',
              bedrooms: 2,
              access: 'keysafe',
              postcode: {
                inward: '2DB',
                outward: 'WC1',
                location: {
                  latitude: '51.5299847959',
                  longitude: '-0.127014156831'
                }
              },
              line_1: '96 Euston Rd',
              line_3: '',
              line_2: 'Kings Cross',
              id: '3ba2jcqqykoe'
            }
          },
          pay_pounds: '25.50',
          payment_period_id: '3bawdipyamoe',
          id: 'wbkq3sp8ayb7',
          total_pounds: '25.50'
        },
        {
          charges_pounds: '0.00',
          visit: {
            customer_preferred_name: 'Charles',
            includes_bonus: false,
            tip_amount_pounds: null,
            tasks: [
              {
                type: 'cleaning',
                hours: 3.0
              }
            ],
            any_worker: false,
            finished_at: '{{ date - 7 }}T19:00:00Z',
            account_id: 'vzq8jhejkjz6',
            frequency: 7.0,
            started_at: '{{ date - 7 }}T16:00:00Z',
            id: 'nb5p9s948az4',
            property_id: '3ba2jcqqykoe',
            job_id: '9oygmtpjvlox',
            access: 'keysafe',
            time_slot: {
              start: '12:00:00',
              end: '17:00:00'
            },
            skipped: false,
            is_first_visit: false,
            finished: true,
            scheduled_date: '{{ date - 7 }}',
            actual_date: '{{ date - 7 }}',
            access_display: 'Key safe',
            customer_can_alter: false,
            is_reviewable: false,
            worker_property_access: 'can_access',
            property: {
              city: '',
              bathrooms: 2,
              account_id: 'vzq8jhejkjz6',
              bedrooms: 2,
              access: 'keysafe',
              postcode: {
                inward: '2DB',
                outward: 'NW1',
                location: {
                  latitude: '51.5299847959',
                  longitude: '-0.127014156831'
                }
              },
              line_1: '96 Euston Rd',
              line_3: '',
              line_2: 'Kings Cross',
              id: '3ba2jcqqykoe'
            }
          },
          pay_pounds: '25.50',
          payment_period_id: '3bawdipyamoe',
          id: '9oyv2tn4ejox',
          total_pounds: '25.50'
        }
      ],
      last_day: '{{ date - 5 }}',
      date_paid: null,
      payment_due_date: '{{ date }}',
      state: 'complete',
      worker_id: '59oykr0krvzx',
      first_day: '{{ date - 18 }}',
      id: '3bawdipyamoe'
    },
    {
      payment_adjustments_for_invoice: [
        {
          amount_pounds: '50.00',
          type: 'refer',
          id: 'xvz9w3fxlgoq',
          payment_period_id: 'jovyj1weyrop',
          created: '{{ date - 21 }}T11:00:00Z'
        },
        {
          created: '{{ date - 27 }}T10:00:00Z',
          visit: {
            customer_preferred_name: 'Jayne'
          },
          payment_period_id: 'jovyj1weyrop',
          type: 'workercancel',
          id: 'jo8w5fp4plok',
          amount_pounds: '-10.00'
        }
      ],
      amount_earned_pounds: '162.00',
      total_pounds: '492.00',
      worked_visits_for_invoice: [
        {
          charges_pounds: '0.00',
          visit: {
            customer_preferred_name: 'Dennis',
            includes_bonus: false,
            tip_amount_pounds: null,
            tasks: [
              {
                type: 'cleaning',
                hours: 3.0
              }
            ],
            any_worker: false,
            finished_at: '{{ date - 26 }}T12:00:00Z',
            account_id: '8zpglhap45zk',
            frequency: 7.0,
            started_at: '{{ date - 26 }}T09:00:00Z',
            id: 'nb5p9s94paz4',
            property_id: 'rom4pc44vjoy',
            job_id: 'norwatg2n7b2',
            access: 'keys',
            time_slot: {
              start: '09:00:00',
              end: '17:00:00'
            },
            skipped: false,
            is_first_visit: false,
            finished: true,
            scheduled_date: '{{ date - 26 }}',
            actual_date: '{{ date - 26 }}',
            access_display: 'Have keys',
            customer_can_alter: false,
            is_reviewable: false,
            worker_property_access: 'cannot_access',
            property: {
              city: '',
              bathrooms: 2,
              account_id: '8zpglhap45zk',
              bedrooms: 2,
              access: 'keys',
              postcode: {
                inward: '7HH',
                outward: 'EC2V',
                location: {
                  latitude: '51.5155664373',
                  longitude: '-0.0916600851062'
                }
              },
              line_1: 'Gresham St',
              line_3: '',
              line_2: '',
              id: 'rom4pc44vjoy'
            }
          },
          pay_pounds: '25.50',
          payment_period_id: 'jovyj1weyrop',
          id: '9oyv2tn4pjox',
          total_pounds: '25.50'
        },
        {
          charges_pounds: '0.00',
          visit: {
            customer_preferred_name: 'Ms Hughes',
            includes_bonus: true,
            tip_amount_pounds: null,
            tasks: [
              {
                type: 'cleaning',
                hours: 3.0
              }
            ],
            any_worker: false,
            finished_at: '{{ date - 28 }}T16:34:00Z',
            account_id: 'xb6ymh4r88op',
            frequency: 7.0,
            started_at: '{{ date - 28 }}T13:34:00Z',
            id: 'jovy4s7ve2op',
            property_id: 'nb7a3cmmqvbe',
            job_id: 'mz2p4t89gyok',
            access: 'keys',
            time_slot: {
              start: '09:00:00',
              end: '17:00:00'
            },
            skipped: false,
            is_first_visit: false,
            finished: true,
            scheduled_date: '{{ date - 28 }}',
            actual_date: '{{ date - 28 }}',
            access_display: 'Have keys',
            customer_can_alter: false,
            is_reviewable: false,
            worker_property_access: 'cannot_access',
            property: {
              city: '',
              bathrooms: 1,
              account_id: 'xb6ymh4r88op',
              bedrooms: 3,
              access: 'keys',
              postcode: {
                inward: '8DS',
                outward: 'EC2Y',
                location: {
                  latitude: '51.519958973',
                  longitude: '-0.0941286921321'
                }
              },
              line_1: 'Silk St',
              line_3: '',
              line_2: '',
              id: 'nb7a3cmmqvbe'
            }
          },
          pay_pounds: '27.00',
          payment_period_id: 'jovyj1weyrop',
          id: 'kbjn51md7po4',
          total_pounds: '27.00'
        },
        {
          charges_pounds: '26.00',
          visit: {
            customer_preferred_name: 'Elizabeth',
            includes_bonus: true,
            tip_amount_pounds: 24.0,
            tasks: [
              {
                type: 'tip',
                hours: null
              },
              {
                type: 'products',
                hours: null
              },
              {
                type: 'cleaning',
                hours: 3.0
              }
            ],
            any_worker: false,
            finished_at: '{{ date - 29 }}T15:00:00Z',
            account_id: '3ba2yhq7xnoe',
            frequency: 7.0,
            started_at: '{{ date - 29 }}T12:00:00Z',
            id: 'noreq06r2qb2',
            property_id: '8zpgpcaa53zk',
            job_id: 'jo8lpt4navzk',
            access: 'at home',
            time_slot: {
              start: '12:00:00',
              end: '12:00:00'
            },
            skipped: false,
            is_first_visit: false,
            finished: true,
            scheduled_date: '{{ date - 29 }}',
            actual_date: '{{ date - 29 }}',
            access_display: 'Client at home',
            customer_can_alter: false,
            is_reviewable: false,
            worker_property_access: 'can_access',
            property: {
              city: '',
              bathrooms: 2,
              account_id: '3ba2yhq7xnoe',
              bedrooms: 2,
              access: 'at home',
              postcode: {
                inward: '5HN',
                outward: 'EC2Y',
                location: {
                  latitude: '51.5177966876',
                  longitude: '-0.0960927212812'
                }
              },
              line_1: '150 London Wall',
              line_3: '',
              line_2: '',
              id: '8zpgpcaa53zk'
            }
          },
          pay_pounds: '27.00',
          payment_period_id: 'jovyj1weyrop',
          id: 'romyrcd5gaoy',
          total_pounds: '53.00'
        },
        {
          charges_pounds: '0.00',
          visit: {
            customer_preferred_name: 'Lauren',
            includes_bonus: true,
            tip_amount_pounds: null,
            tasks: [
              {
                type: 'cleaning',
                hours: 3.5
              }
            ],
            any_worker: false,
            finished_at: '{{ date - 30 }}T18:56:00Z',
            account_id: 'kbj95haplaz4',
            frequency: 7.0,
            started_at: '{{ date - 30 }}T15:26:00Z',
            id: 'xowva139wpb2',
            property_id: 'gzgnwceedab9',
            job_id: 'thechangesvisit',
            access: 'at home',
            time_slot: {
              start: '14:30:00',
              end: '15:30:00'
            },
            skipped: false,
            is_first_visit: false,
            finished: true,
            scheduled_date: '{{ date - 30 }}',
            actual_date: '{{ date - 30 }}',
            access_display: 'Client at home',
            customer_can_alter: false,
            is_reviewable: false,
            worker_property_access: 'can_access',
            property: {
              city: '',
              bathrooms: 1,
              account_id: 'kbj95haplaz4',
              bedrooms: 4,
              access: 'at home',
              postcode: {
                inward: '5LR',
                outward: 'NW1',
                location: {
                  latitude: '51.5229653579',
                  longitude: '-0.155051270069'
                }
              },
              line_1: 'Marylebone Rd',
              line_3: '',
              line_2: 'Marylebone',
              id: 'gzgnwceedab9'
            }
          },
          pay_pounds: '31.50',
          payment_period_id: 'jovyj1weyrop',
          id: 'qolxe052m6o2',
          total_pounds: '31.50'
        },
        {
          charges_pounds: '0.00',
          visit: {
            customer_preferred_name: 'Jayne',
            includes_bonus: true,
            tip_amount_pounds: null,
            tasks: [
              {
                type: 'cleaning',
                hours: 3.5
              }
            ],
            any_worker: false,
            finished_at: '{{ date - 31 }}T18:55:00Z',
            account_id: 'qolmehmj7yz2',
            frequency: 7.0,
            started_at: '{{ date - 31 }}T15:25:00Z',
            id: 'kbjnd0kad6o4',
            property_id: 'xb6y5c44alop',
            job_id: 'wbkjxt93nnz7',
            access: 'supervsr',
            time_slot: {
              start: '09:00:00',
              end: '17:00:00'
            },
            skipped: false,
            is_first_visit: false,
            finished: true,
            scheduled_date: '{{ date - 31 }}',
            actual_date: '{{ date - 31 }}',
            access_display: 'Supervisor on site',
            customer_can_alter: false,
            is_reviewable: false,
            worker_property_access: 'cannot_access',
            property: {
              city: '',
              bathrooms: 2,
              account_id: 'qolmehmj7yz2',
              bedrooms: 3,
              access: 'supervsr',
              postcode: {
                inward: '8AD',
                outward: 'EC4M',
                location: {
                  latitude: '51.5142495195',
                  longitude: '-0.0991663547935'
                }
              },
              line_1: "St. Paul's Churchyard",
              line_3: '',
              line_2: '',
              id: 'xb6y5c44alop'
            }
          },
          pay_pounds: '31.50',
          payment_period_id: 'jovyj1weyrop',
          id: 'nb5pwcr849z4',
          total_pounds: '31.50'
        }
      ],
      last_day: '{{ date - 19 }}',
      date_paid: '{{ date -14 }}',
      payment_due_date: '{{ date - 14 }}',
      state: 'complete',
      worker_id: '59oykr0krvzx',
      first_day: '{{ date - 32 }}',
      id: 'jovyj1weyrop'
    },
    {
      payment_adjustments_for_invoice: [
        {
          amount_pounds: '50.00',
          type: 'refer',
          id: 'xvz9w3fxlgoq',
          payment_period_id: 'dvjzey31pona',
          created: '{{ date - 35 }}T11:00:00Z'
        },
        {
          amount_pounds: '-59.00',
          type: 'backgroundcheck',
          id: 'rnore8fyggb2',
          payment_period_id: 'dvjzey31pona',
          created: '{{ date - 19 }}T11:00:00Z'
        }
      ],
      amount_earned_pounds: '162.00',
      total_pounds: '472.50',
      worked_visits_for_invoice: [
        {
          charges_pounds: '0.00',
          visit: {
            customer_preferred_name: 'Matt',
            includes_bonus: false,
            tip_amount_pounds: null,
            tasks: [
              {
                type: 'cleaning',
                hours: 3.0
              }
            ],
            any_worker: false,
            finished_at: '{{ date - 35 }}T12:00:00Z',
            account_id: '8zpglhap45zk',
            frequency: 7.0,
            started_at: '{{ date - 35 }}T09:00:00Z',
            id: 'nb5p9s94paz4',
            property_id: 'rom4pc44vjoy',
            job_id: 'norwatg2n7b2',
            access: 'keys',
            time_slot: {
              start: '09:00:00',
              end: '17:00:00'
            },
            skipped: false,
            is_first_visit: false,
            finished: true,
            scheduled_date: '{{ date - 35 }}',
            actual_date: '{{ date - 35 }}',
            access_display: 'Have keys',
            customer_can_alter: false,
            is_reviewable: false,
            worker_property_access: 'cannot_access',
            property: {
              city: '',
              bathrooms: 2,
              account_id: '8zpglhap45zk',
              bedrooms: 2,
              access: 'keys',
              postcode: {
                inward: '7HH',
                outward: 'EC2V',
                location: {
                  latitude: '51.5155664373',
                  longitude: '-0.0916600851062'
                }
              },
              line_1: 'Gresham St',
              line_3: '',
              line_2: '',
              id: 'rom4pc44vjoy'
            }
          },
          pay_pounds: '25.50',
          payment_period_id: 'dvjzey31pona',
          id: '9oyv2tn4pjox',
          total_pounds: '25.50'
        },
        {
          charges_pounds: '0.00',
          visit: {
            customer_preferred_name: 'Elizabeth',
            includes_bonus: false,
            tip_amount_pounds: null,
            tasks: [
              {
                type: 'cleaning',
                hours: 3.0
              }
            ],
            any_worker: false,
            finished_at: '{{ date - 37 }}T16:34:00Z',
            account_id: 'xb6ymh4r88op',
            frequency: 7.0,
            started_at: '{{ date - 37 }}T13:34:00Z',
            id: 'jovy4s7ve2op',
            property_id: 'nb7a3cmmqvbe',
            job_id: 'mz2p4t89gyok',
            access: 'keys',
            time_slot: {
              start: '09:00:00',
              end: '17:00:00'
            },
            skipped: false,
            is_first_visit: false,
            finished: true,
            scheduled_date: '{{ date - 37 }}',
            actual_date: '{{ date - 37 }}',
            access_display: 'Have keys',
            customer_can_alter: false,
            is_reviewable: false,
            worker_property_access: 'cannot_access',
            property: {
              city: '',
              bathrooms: 1,
              account_id: 'xb6ymh4r88op',
              bedrooms: 3,
              access: 'keys',
              postcode: {
                inward: '8DS',
                outward: 'EC2Y',
                location: {
                  latitude: '51.519958973',
                  longitude: '-0.0941286921321'
                }
              },
              line_1: 'Silk St',
              line_3: '',
              line_2: '',
              id: 'nb7a3cmmqvbe'
            }
          },
          pay_pounds: '25.50',
          payment_period_id: 'dvjzey31pona',
          id: 'kbjn51md7po4',
          total_pounds: '25.50'
        },
        {
          charges_pounds: '26.00',
          visit: {
            customer_preferred_name: 'Avin',
            includes_bonus: false,
            tip_amount_pounds: 24.0,
            tasks: [
              {
                type: 'tip',
                hours: null
              },
              {
                type: 'products',
                hours: null
              },
              {
                type: 'cleaning',
                hours: 3.0
              }
            ],
            any_worker: false,
            finished_at: '{{ date - 40 }}T15:00:00Z',
            account_id: '3ba2yhq7xnoe',
            frequency: 7.0,
            started_at: '{{ date - 40 }}T12:00:00Z',
            id: 'noreq06r2qb2',
            property_id: '8zpgpcaa53zk',
            job_id: 'jo8lpt4navzk',
            access: 'at home',
            time_slot: {
              start: '12:00:00',
              end: '12:00:00'
            },
            skipped: false,
            is_first_visit: false,
            finished: true,
            scheduled_date: '{{ date - 40 }}',
            actual_date: '{{ date - 40 }}',
            access_display: 'Client at home',
            customer_can_alter: false,
            is_reviewable: false,
            worker_property_access: 'can_access',
            property: {
              city: '',
              bathrooms: 2,
              account_id: '3ba2yhq7xnoe',
              bedrooms: 2,
              access: 'at home',
              postcode: {
                inward: '5HN',
                outward: 'EC2Y',
                location: {
                  latitude: '51.5177966876',
                  longitude: '-0.0960927212812'
                }
              },
              line_1: '150 London Wall',
              line_3: '',
              line_2: '',
              id: '8zpgpcaa53zk'
            }
          },
          pay_pounds: '25.50',
          payment_period_id: 'dvjzey31pona',
          id: 'romyrcd5gaoy',
          total_pounds: '51.50'
        },
        {
          charges_pounds: '0.00',
          visit: {
            customer_preferred_name: 'Veeve',
            includes_bonus: false,
            tip_amount_pounds: null,
            tasks: [
              {
                type: 'cleaning',
                hours: 3.5
              }
            ],
            any_worker: false,
            finished_at: '{{ date - 42 }}T18:56:00Z',
            account_id: 'kbj95haplaz4',
            frequency: 7.0,
            started_at: '{{ date - 42 }}T15:26:00Z',
            id: 'xowva139wpb2',
            property_id: 'gzgnwceedab9',
            job_id: 'thechangesvisit',
            access: 'at home',
            time_slot: {
              start: '14:30:00',
              end: '15:30:00'
            },
            skipped: false,
            is_first_visit: false,
            finished: true,
            scheduled_date: '{{ date - 42 }}',
            actual_date: '{{ date - 42 }}',
            access_display: 'Client at home',
            customer_can_alter: false,
            is_reviewable: false,
            worker_property_access: 'can_access',
            property: {
              city: '',
              bathrooms: 1,
              account_id: 'kbj95haplaz4',
              bedrooms: 4,
              access: 'at home',
              postcode: {
                inward: '5LR',
                outward: 'NW1',
                location: {
                  latitude: '51.5229653579',
                  longitude: '-0.155051270069'
                }
              },
              line_1: 'Marylebone Rd',
              line_3: '',
              line_2: 'Marylebone',
              id: 'gzgnwceedab9'
            }
          },
          pay_pounds: '29.75',
          payment_period_id: 'dvjzey31pona',
          id: 'qolxe052m6o2',
          total_pounds: '29.75'
        },
        {
          charges_pounds: '0.00',
          visit: {
            customer_preferred_name: 'Paul',
            includes_bonus: false,
            tip_amount_pounds: null,
            tasks: [
              {
                type: 'cleaning',
                hours: 3.5
              }
            ],
            any_worker: false,
            finished_at: '{{ date - 45 }}T18:55:00Z',
            account_id: 'qolmehmj7yz2',
            frequency: 7.0,
            started_at: '{{ date - 45 }}T15:25:00Z',
            id: 'kbjnd0kad6o4',
            property_id: 'xb6y5c44alop',
            job_id: 'wbkjxt93nnz7',
            access: 'supervsr',
            time_slot: {
              start: '09:00:00',
              end: '17:00:00'
            },
            skipped: false,
            is_first_visit: false,
            finished: true,
            scheduled_date: '{{ date - 45 }}',
            actual_date: '{{ date - 45 }}',
            access_display: 'Supervisor on site',
            customer_can_alter: false,
            is_reviewable: false,
            worker_property_access: 'cannot_access',
            property: {
              city: '',
              bathrooms: 2,
              account_id: 'qolmehmj7yz2',
              bedrooms: 3,
              access: 'supervsr',
              postcode: {
                inward: '8AD',
                outward: 'EC4M',
                location: {
                  latitude: '51.5142495195',
                  longitude: '-0.0991663547935'
                }
              },
              line_1: "St. Paul's Churchyard",
              line_3: '',
              line_2: '',
              id: 'xb6y5c44alop'
            }
          },
          pay_pounds: '29.75',
          payment_period_id: 'dvjzey31pona',
          id: 'nb5pwcr849z4',
          total_pounds: '29.75'
        }
      ],
      last_day: '{{ date - 33 }}',
      date_paid: '{{ date - 28 }}',
      payment_due_date: '{{ date - 28}}',
      state: 'complete',
      worker_id: '59oykr0krvzx',
      first_day: '{{ date - 46 }}',
      id: 'dvjzey31pona'
    }
  ],
  per_page: 10,
  url_previous_page: null,
  num_results: 3,
  url_next_page: null
};
