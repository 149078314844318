import { DemoEndpoint } from '../../../demo-service';

const workerToWorkerCallEndpoint: DemoEndpoint = {
  path: 'request-worker-to-worker-call',
  handlers: [
    {
      method: 'post',
      handle(state, path) {
        return { telephone_number: '07700900000' }; // Ofcom reserved fake #
      }
    }
  ]
};

export { workerToWorkerCallEndpoint };
