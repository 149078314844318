import { defineModelProperties, model, Model, Time } from '@housekeep/infra';

export interface AvailabilityPeriod {
  start: Time;
  end: Time;
}

export interface AvailabilityPeriods extends Model {
  availability: AvailabilityPeriod[];
  isContiguous: boolean;
  isFourHours: boolean;
  isWorking: boolean;
  totalHours: number;
  workFrom?: Time;
  workTo?: Time;
}

export const availabilityPeriods: AvailabilityPeriods = model.extend({});

defineModelProperties<AvailabilityPeriods>(availabilityPeriods, {
  isContiguous: {
    get(): boolean {
      return this.availability && this.availability.length === 1;
    }
  },

  isWorking: {
    get(): boolean {
      return this.availability.length !== 0;
    }
  },

  totalHours: {
    get(): number {
      if (!this.availability || !this.availability.length) {
        return 0;
      }

      return this.availability.reduce((sum: number, period: AvailabilityPeriod) => {
        return sum + period.end.diff(period.start, 'hours', true);
      }, 0);
    }
  },

  workFrom: {
    get(): Time {
      return this.isContiguous ? this.availability[0].start : null;
    },

    set(start: Time): void {
      const end = this.workTo;
      this.availability = [{ start, end }];
    }
  },

  workTo: {
    get(): Time {
      return this.isContiguous ? this.availability[0].end : null;
    },

    set(end: Time): void {
      const start = this.workFrom;
      this.availability = [{ start, end }];
    }
  }
});
