import { Injectable } from '@angular/core';

import { Date, dateToStr, Duration } from '@housekeep/infra';

import { WorkerAvailability } from 'models/worker-availability';
import { WorkerAvailabilityOverride } from 'models/worker-availability-override';
import { WorkerBreak, WorkerBreakType } from 'models/worker-break';
import { WorkerBreakEffects } from 'models/worker-break-effects';

import { ChangeTimeOffData } from 'pages/profile-time-off/change-booking-modal.page';

import {
  workerAvailabilityOverrideSerializer,
  workerAvailabilitySerializer,
  workerBreakSerializer
} from 'serializers/availability';
import { noticeCategoryField, workerBreakEffectsSerializer } from 'serializers/worker-break-effects';

import { CacheService } from './cache-service';
import { RequestService } from './request-service';
import { TimeService } from './time-service';
import { UserService } from './user-service';

export interface CategoryDisplay {
  displayName: string;
  key: string;
}

export const NORMAL_CACHE_KEY = 'AVAILABILITY';
const OVERRIDE_CACHE_KEY = 'AVAILABILITY_OVERRIDE';
export const WORKER_BREAKS_CACHE_KEY = 'AVAILABILITY_OVERRIDES';

export enum AvailabilityCategoryTypes {
  APPOINTMENT = 'appointment',
  CHILDCARE = 'childcare',
  EXTRA_HOURS = 'extraavailability',
  HOLIDAY = 'holiday',
  ILL = 'ill',
  NO_JOBS_SCHEDULED = 'no-jobs-scheduled',
  OTHER = 'other-worker',
  PERSONAL_REASONS = 'personalreasons',
  SELF_ISOLATION = 'self-isolation'
}

export enum TimeOffChangeCategoryTypes {
  CANCEL = 'Cancel',
  CHANGE = 'Change'
}

export enum TimeOffChangeReasonCategoryTypes {
  NO_LONGER_REQUIRED = 'No longer required',
  WRONG_DATES = 'Wrong Dates',
  LONGER = 'Need longer break',
  SHORTER = 'Need shorter break'
}

enum ReinstateCleansCategoryTypes {
  YES = 'Yes',
  NO = 'No'
}

// Availability override categories that are selectable when booking time off
const WORKER_BREAK_CATEGORIES: CategoryDisplay[] = [
  { key: AvailabilityCategoryTypes.HOLIDAY, displayName: 'Holiday' },
  { key: AvailabilityCategoryTypes.ILL, displayName: 'Sick' },
  {
    key: AvailabilityCategoryTypes.SELF_ISOLATION,
    displayName: 'Coronavirus self-isolation'
  },
  { key: AvailabilityCategoryTypes.APPOINTMENT, displayName: 'Appointment' },
  { key: AvailabilityCategoryTypes.CHILDCARE, displayName: 'Childcare' },
  { key: AvailabilityCategoryTypes.NO_JOBS_SCHEDULED, displayName: 'No jobs scheduled' },
  { key: AvailabilityCategoryTypes.OTHER, displayName: 'Other' }
];

export const WORKER_BREAK_CATEGORY_MAP = new Map<WorkerBreakType, CategoryDisplay[]>([
  [WorkerBreakType.PartDay, WORKER_BREAK_CATEGORIES],
  [WorkerBreakType.SingleDay, WORKER_BREAK_CATEGORIES],
  [
    WorkerBreakType.MultiDay,
    WORKER_BREAK_CATEGORIES.filter(
      categoryDisplay => categoryDisplay.key !== AvailabilityCategoryTypes.NO_JOBS_SCHEDULED
    )
  ]
]);

// Other availability override categories include extra hours and deprecated categories
const OTHER_AVAILABILITY_OVERRIDE_CATEGORIES: CategoryDisplay[] = [
  { key: AvailabilityCategoryTypes.EXTRA_HOURS, displayName: 'Extra hours' },
  { key: AvailabilityCategoryTypes.PERSONAL_REASONS, displayName: 'Personal reasons' }
];

export const AVAILABILITY_OVERRIDE_CATEGORIES: CategoryDisplay[] = [
  ...WORKER_BREAK_CATEGORIES,
  ...OTHER_AVAILABILITY_OVERRIDE_CATEGORIES
];

export const TIME_OFF_CHANGE_CATEGORIES: CategoryDisplay[] = [
  { key: TimeOffChangeCategoryTypes.CANCEL, displayName: 'Cancel time off' },
  { key: TimeOffChangeCategoryTypes.CHANGE, displayName: 'Change time off' }
];

export const TIME_OFF_CHANGE_REASON_CATEGORIES: CategoryDisplay[] = [
  {
    key: TimeOffChangeReasonCategoryTypes.NO_LONGER_REQUIRED,
    displayName: 'Time off no longer required'
  },
  {
    key: TimeOffChangeReasonCategoryTypes.WRONG_DATES,
    displayName: 'Wrong time off dates selected'
  },
  {
    key: TimeOffChangeReasonCategoryTypes.LONGER,
    displayName: 'Time off longer than originally planned'
  },
  {
    key: TimeOffChangeReasonCategoryTypes.SHORTER,
    displayName: 'Time off shorter than originally planned'
  }
];

export const REINSTATE_VISIT_CATEGORIES: CategoryDisplay[] = [
  { key: ReinstateCleansCategoryTypes.YES, displayName: 'Yes' },
  { key: ReinstateCleansCategoryTypes.NO, displayName: 'No' }
];

/**
 * Service to handle retrieving visit information from the public API.
 */
@Injectable({ providedIn: 'root' })
export class AvailabilityService {
  constructor(
    private cacheService: CacheService,
    private requestService: RequestService,
    private timeService: TimeService,
    private userService: UserService
  ) {}

  public getNormalAvailability(): Promise<WorkerAvailability[]> {
    return this.userService.getUser().then(user => {
      return this.requestService.getList(`workers/${user.id}/availability/`, workerAvailabilitySerializer, {
        cache: {
          duration: new Duration({ days: 7 }),
          key: NORMAL_CACHE_KEY
        }
      });
    });
  }

  public getCachedNormalAvailability(): Promise<WorkerAvailability[]> {
    return this.cacheService.get(NORMAL_CACHE_KEY).then(availability => {
      return workerAvailabilitySerializer.deserialize(availability, { many: true });
    });
  }

  public async getDayAvailability(date: Date): Promise<WorkerAvailabilityOverride> {
    let dayAvailability: WorkerAvailabilityOverride;

    try {
      const user = await this.userService.getUser();
      dayAvailability = await this.requestService.getInstance(
        `workers/${user.id}/availability/${dateToStr(date)}/`,
        workerAvailabilityOverrideSerializer,
        {
          cache: {
            key: OVERRIDE_CACHE_KEY,
            merge: true,
            trackBy: 'day',
            duration: new Duration({ days: 1 })
          }
        }
      );
    } catch {
      const cachedAvailability = await this.cacheService.get(OVERRIDE_CACHE_KEY);
      const dayAvailabilities = workerAvailabilityOverrideSerializer.deserialize(cachedAvailability, {
        many: true
      }) as WorkerAvailabilityOverride[];
      dayAvailability = dayAvailabilities.find(availabilityOverride => availabilityOverride.day.isSame(date));
    }
    return dayAvailability;
  }

  public getWorkerBreaks(): Promise<WorkerBreak[]> {
    return this.userService.getUser().then(user => {
      return this.requestService
        .getList(`workers/${user.id}/availability/changes/`, workerBreakSerializer, {
          cache: {
            duration: new Duration({ days: 7 }),
            key: WORKER_BREAKS_CACHE_KEY
          }
        })
        .then(workerBreaks => {
          return workerBreaks.map(workerBreak => {
            if (workerBreak.endDate.isAfter(workerBreak.startDate)) {
              workerBreak.type = WorkerBreakType.MultiDay;
            } else if (workerBreak.availability && workerBreak.availability.length > 0) {
              workerBreak.type = WorkerBreakType.PartDay;
            } else {
              workerBreak.type = WorkerBreakType.SingleDay;
            }

            return workerBreak;
          });
        });
    });
  }

  public getCachedWorkerBreaks(): Promise<WorkerBreak[]> {
    return this.cacheService.get(WORKER_BREAKS_CACHE_KEY).then(availability => {
      return workerBreakSerializer.deserialize(availability, { many: true });
    });
  }

  public getWorkerBreakEffects(booking: WorkerBreak): Promise<WorkerBreakEffects> {
    return this.userService.getUser().then(user => {
      const params = this.getTimeOffParams(booking);
      return this.requestService.get(`workers/${user.id}/breaks/`, {
        params,
        serializer: workerBreakEffectsSerializer
      });
    });
  }

  public submitTimeOffBooking(booking: WorkerBreak, expectedEffects: WorkerBreakEffects): Promise<WorkerBreak> {
    return this.userService.getUser().then(user => {
      const params = this.getTimeOffParams(booking, expectedEffects);
      return this.requestService.post(
        `workers/${user.id}/breaks/`,
        {},
        { params, serializer: workerBreakEffectsSerializer, noTransform: true }
      );
    });
  }

  public submitTimeOffBookingChangeRequest(booking: WorkerBreak, data: ChangeTimeOffData): Promise<WorkerBreak> {
    const reportData = {
      startDate: booking.startDate.format('YYYY-MM-DD'),
      endDate: booking.endDate.format('YYYY-MM-DD'),
      requestType: data.cancelOrChange,
      reinstate: data.reinstatingCleans,
      reason: data.reason,
      category: 'cancel_break',
      breakType: booking.type
    };
    return this.requestService.post(`contact/absence-period-change-request/`, reportData);
  }

  private getTimeOffParams(booking: WorkerBreak, effects?: WorkerBreakEffects) {
    const params = {
      start_day: booking.startDate.format('YYYY-MM-DD'),
      end_day: booking.endDate.format('YYYY-MM-DD'),
      availability:
        booking.type !== WorkerBreakType.PartDay || !booking.workFrom || !booking.workTo
          ? '[]'
          : `[["${booking.workFrom.format('HH:mm')}",` + `"${booking.workTo.format('HH:mm')}"]]`,
      reason: booking.reason,
      note: booking.note,
      expected_fine: null,
      expected_notice: null,
      expected_reliability: null
    };

    if (effects) {
      params.expected_fine = effects.hasFines() ? effects.totalFineAmount() : 0;
      params.expected_notice = noticeCategoryField.create().serialize(effects.noticeCategory);
      params.expected_reliability = effects.reliability;
    }

    return params;
  }
}
