<ion-menu-toggle menu="menu-schedule" *ngIf="menuToggle; else scheduleListDay">
  <ng-container *ngTemplateOutlet="scheduleListDay"></ng-container>
</ion-menu-toggle>

<ng-template #scheduleListDay>
  <ion-item
    lines="none"
    [class.unavailable]="!day.working"
    [class.current]="isCurrentDay(day)"
    tappable
    (click)="changeDay(day)"
  >
    <ng-container>
      <span class="text-medium mar-r-5">{{ day.day | asDay }}</span>
      <ion-badge
        *ngIf="unreadChatMessagesCount$ | async as unreadMessages"
        color="warning"
        class="badge-circle text--no-wrap"
      >
        <hk-wc-icon name="chat" size="20"></hk-wc-icon>
      </ion-badge>
    </ng-container>

    <div slot="end">
      <!-- Circle for unread chats -->

      <ion-badge
        *ngIf="day.working || day.hasVisits"
        class="badge badge--visit-count"
        [class.badge--not-working]="!day.working"
      >
        {{ day.visits.length }}
      </ion-badge>
      <ion-badge
        *ngIf="day.working || day.hasVisits"
        class="badge badge--visit-hours"
        [class.badge--not-working]="!day.working"
      >
        {{ day.totalHours | asHours }}hrs
      </ion-badge>

      <ion-badge
        *ngIf="!day.working && !day.hasVisits"
        class="badge badge-right badge--not-working"
      >
        Not working
      </ion-badge>
    </div>
  </ion-item>
</ng-template>
