import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Subject, Subscription } from 'rxjs';

import { ApiUrlService, cookieUtil, Deferred } from '@housekeep/infra';

import { APP_CONFIG, AppConfig } from './app-config';
import { CacheService } from './cache-service';
import { DemoService } from './demo-service';
import { ErrorService } from './error-service';
import { LoadingService } from './loading-service';
import { OnlineService } from './online-service';
import { HkSentryBreadcrumbCategory, SentryService } from './sentry-service';
import { DEVICE_EXTERNAL_ID, DEVICE_FCM_TOKEN, StorageService } from './storage-service';
import { VersionService } from './version-service';

type AuthEventArgs = [string] | [string, any];

interface AuthResponse {
  token: string;
}

const HAS_SESSION_STORAGE_KEY = 'has_session';
const IS_IMPERSONATE_MODE_STORAGE_KEY = 'impersonate';

@Injectable({ providedIn: 'root' })
class AuthService {
  private deviceExternalId: string;
  private _authEventsSubject: Subject<any> = new Subject();
  private _authToken: string;
  private _csrfToken: string;
  private _hasSession: boolean;
  private _isAuthStateReadyDeferred: Deferred<void> = new Deferred();
  private _isImpersonateMode: boolean;
  private _isReadyDeferred: Deferred<void> = new Deferred();
  private _logoutPromise: Promise<void>;

  constructor(
    private http: HttpClient,
    private apiUrlService: ApiUrlService,
    private cacheService: CacheService,
    private demoService: DemoService,
    private errorService: ErrorService,
    private loadingService: LoadingService,
    private onlineService: OnlineService,
    private sentryService: SentryService,
    private storageService: StorageService,
    private versionService: VersionService,
    @Inject(APP_CONFIG) private config: AppConfig
  ) {
    this._initializeAuth();
    this._loadDeviceExternalId();
  }

  /**
   * Return a promise that is resolved once the return value of the `isAuthenticated` method has stabilised.
   * Callers should await this promise rather than the promise returned by `ready` if they need to avoid a possible
   * race condition when the web app is loaded in impersonate mode (see `AppComponent.ngOnInit` method).
   */
  public authStateReady(): Promise<void> {
    return this._isAuthStateReadyDeferred.promise;
  }

  /**
   * Return a promise that is resolved once the AuthService is initialized,
   * which happens after the StorageService is ready.
   *
   * At that point, the auth token or session state will have been read from storage.
   * The user's auth state is still not known for certain, however, until the AppComponent has completed initialization.
   */
  public ready(): Promise<void> {
    return this._isReadyDeferred.promise;
  }

  /**
   * Resolve the promise returned by `authStateReady`.
   * This should only be called from `AppComponent.ngOnInit`, after it has checked if impersonate mode is enabled.
   */
  public markAuthStateReady(): void {
    this._isAuthStateReadyDeferred.resolve();
  }

  /**
   * `true` if the user has a login session (not using an auth token);
   * `false` if the user is authenticated via token or is not logged in.
   */
  public get hasSession(): boolean {
    return !!this._hasSession;
  }

  /**
   * Return a boolean to represent the user's authenticated state.
   * @return {boolean}
   */
  public get isAuthenticated(): boolean {
    return !!(this._authToken || this._hasSession);
  }

  /**
   * `true` if the user is being impersonated by staff, `false` otherwise.
   */
  public get isImpersonateMode(): boolean {
    return !!this._isImpersonateMode;
  }

  /**
   * Subscribe to auth service update events.
   * @param  {Function} fn
   * @return {Subscription}
   */
  public subscribe(fn: (event: AuthEventArgs) => void): Subscription {
    return this._authEventsSubject.subscribe(fn);
  }

  public async endImpersonation(): Promise<void> {
    try {
      this.loadingService.showLoader({
        message: 'Ending impersonation'
      });

      // Make a POST request to the release-hijack endpoint
      const result: { next?: string } = await this.http
        .post(
          this.apiUrlService.normaliseUrl(this.config.API_ENDPOINTS.RELEASE_HIJACK),
          {},
          {
            withCredentials: true
          }
        )
        .toPromise();

      this._csrfToken = null;
      this._hasSession = false;
      this._isImpersonateMode = false;
      try {
        await this.storageService.ready();
        await Promise.all([
          this.storageService.remove(HAS_SESSION_STORAGE_KEY),
          this.storageService.remove(IS_IMPERSONATE_MODE_STORAGE_KEY)
        ]);
      } catch (error) {
        // Ignore
      }

      if ('next' in result) {
        window.location.href = result['next'];
      }
    } catch (error) {
      this.errorService.showError(error);
    }
  }

  /**
   * Attempt to log the user in with the given credentials.
   *
   * @param  {string}   email
   * @param  {string}   password
   * @param  {boolean}  onboarding - if true, use the "start onboarding" API endpoint
   * @param  {boolean}  emitEvent - if true, emit a "login" auth event after successful login
   * @return {Promise} that will resolve/reject after authentication.
   */
  public login(email: string, password: string, onboarding = false, emitEvent = true): Promise<any> {
    const endpoint = this.apiUrlService.normaliseUrl(
      onboarding ? this.config.API_ENDPOINTS.START_ONBOARDING : this.config.API_ENDPOINTS.OBTAIN_TOKEN
    );
    if (this._authToken) {
      this._authToken = null;
    }

    const opts = { headers: this.requestHeaders };
    cookieUtil.deleteCookie('csrftoken');

    return this.http
      .post<AuthResponse>(endpoint, { username: email, password }, opts)
      .toPromise()
      .then(authResponse => this._storeToken(authResponse.token))
      .then(() => {
        this.onlineService.setOnlineState(true);
        this.sentryService.captureBreadcrumb({
          message: `Login successful`,
          category: HkSentryBreadcrumbCategory.Auth
        });
        if (emitEvent) {
          this._authEventsSubject.next(['login']);
        }
      })
      .catch(err => {
        if (this.errorService.isHttp401UnauthorizedError(err)) {
          this.sentryService.captureBreadcrumb({
            message: `Login failure`,
            category: HkSentryBreadcrumbCategory.Auth
          });
        }

        throw err;
      });
  }

  /**
   * Attempt to log the user out by clearing all stored data.
   */
  public logout(reason?: string): Promise<any> {
    // If the user isn't currently logged in, do nothing.
    if (!this._authToken) {
      return Promise.resolve();
    }

    // Throttle multiple requests
    if (!this._logoutPromise) {
      this.disablePush().then();
      const cachePromise = this.cacheService.clear();
      const storagePromise = this.storageService.clearSessionStorage();
      this.loadingService.showLoader({ message: 'Logging out' });

      this._logoutPromise = Promise.all([cachePromise, storagePromise]).then(() => {
        cookieUtil.deleteCookie('csrftoken');
        this._authEventsSubject.next(['logout', { reason }]);
        this._authToken = null;
        this._csrfToken = null;
        this._hasSession = false;
        this._isImpersonateMode = false;
        this._logoutPromise = null;
        this.demoService.endDemoMode();
        this.loadingService.dismissLoader();
        this.sentryService.captureBreadcrumb({
          message: `Logout`,
          category: HkSentryBreadcrumbCategory.Auth
        });
      });
    }

    return this._logoutPromise;
  }

  /**
   * Request a reset password email to be sent
   */
  public resetPassword(email: string): Promise<any> {
    const endpoint = this.apiUrlService.normaliseUrl(`${this.config.API_ENDPOINTS.RESET_PASSWORD}`);
    const opts = { headers: this.requestHeaders };

    return this.http.post(endpoint, { email }, opts).toPromise();
  }

  /**
   * Set a new password using the reset password token which the user will have gotten
   * from a reset password email.
   */
  public setNewPassword(password: string, token: string): Promise<any> {
    const endpoint = this.apiUrlService.normaliseUrl(`${this.config.API_ENDPOINTS.RESET_PASSWORD}complete/`);
    const opts = { headers: this.requestHeaders };

    return this.http.patch(endpoint, { password, token }, opts).toPromise();
  }

  /**
   * Set the user's initial password and re-authenticate.
   */
  public setInitialPassword(email: string, password: string): Promise<any> {
    const endpoint = this.apiUrlService.normaliseUrl(this.config.API_ENDPOINTS.SET_PASSWORD);
    return this.http
      .post(endpoint, { password })
      .toPromise()
      .then(() => {
        this._authToken = null;
        return this.login(email, password);
      });
  }

  public setCsrfToken(csrfToken: string): void {
    this._csrfToken = csrfToken;
  }

  public async setImpersonateState(isImpersonateMode: boolean): Promise<void> {
    this._isImpersonateMode = isImpersonateMode;
    try {
      await this.storageService.ready();
      await this.storageService.set(IS_IMPERSONATE_MODE_STORAGE_KEY, isImpersonateMode);
      if (isImpersonateMode) {
        await this.cacheService.clear();
      }
    } catch (error) {
      // Ignore
    }
  }

  /**
   * Sets whether the client has a session cookie.
   * This is used by the webapp for Housekeeper impersonation.
   * The `sessionid` cookie is HttpOnly so we cannot detect it automatically:
   * instead, a query string parameter `session=true` is passed to the app.
   * @param hasSession
   */
  public async setSessionState(hasSession: boolean): Promise<void> {
    this._hasSession = hasSession;

    try {
      if (hasSession) {
        this._authToken = null;
        await this.storageService.ready();
        await Promise.all([
          this.storageService.set(HAS_SESSION_STORAGE_KEY, hasSession),
          this.storageService.unsetAuthToken()
        ]);
      } else {
        await this.storageService.ready();
        await this.storageService.remove(HAS_SESSION_STORAGE_KEY);
      }
    } catch (error) {
      // Ignore
    }
  }

  /**
   * Return the HTTP headers to use, including authorization if the user
   * is authenticated.
   * @return {HttpHeaders}
   */
  public get requestHeaders(): HttpHeaders {
    // Ideally this function would be in the RequestService, but we would end up with a
    // circular dependency, so it needs to be here.
    let headers = new HttpHeaders();

    if (this._authToken) {
      headers = headers.set('Authorization', `Token ${this._authToken}`);
    }

    const version = this.versionService.getAppVersion();
    if (version) {
      headers = headers.set('Hk-App-Version', version);
    }

    if (!this.deviceExternalId) {
      this._loadDeviceExternalId();
    } else {
      headers = headers.set('Hk-Device-Id', this.deviceExternalId);
    }

    return headers;
  }

  /*
   * Load Device External ID from storage, used as extra auth
   * Has to be done in this way to avoid circular dependency.
   */
  private _loadDeviceExternalId(): void {
    this.storageService.get(DEVICE_EXTERNAL_ID).then(externalId => {
      this.deviceExternalId = externalId;
    });
  }

  /**
   * Store the given token using the storage service
   * @param  {string}       token
   * @return {Promise}
   */
  private _storeToken(token: string): Promise<any> {
    return this.ready()
      .then(() => this.storageService.setAuthToken(token))
      .then(() => (this._authToken = token));
  }

  /**
   * Attempt to retrieve an existing authentication token from storage.
   */
  private async _initializeAuth(): Promise<void> {
    await this.storageService.ready();

    try {
      this._authToken = await this.storageService.getAuthToken();
    } catch (error) {
      this._authToken = null;
    }

    if (!this._authToken) {
      try {
        // If there is no auth token, check for a session
        this._hasSession = await this.storageService.get(HAS_SESSION_STORAGE_KEY);

        // We currently assume that impersonate mode cannot be enabled if the
        // user is logged in via an auth token.
        this._isImpersonateMode = await this.storageService.get(IS_IMPERSONATE_MODE_STORAGE_KEY);
      } catch (error) {
        // Ignore
      }
    }

    await this.versionService.ready();

    this._isReadyDeferred.resolve();
    this._authEventsSubject.next(['ready']);
  }

  /**
   * Set the device as inactive in the backend
   * Prevents logged out devices from being pushed messaging
   */
  private async disablePush(): Promise<void> {
    const endpoint = this.apiUrlService.normaliseUrl('device/disable-push/');
    const token = await this.storageService.get(DEVICE_FCM_TOKEN);
    if (token) {
      const opts = { headers: this.requestHeaders };
      await this.http.post(endpoint, { fcm_token: token }, opts).toPromise();
    }
  }
}

export { AuthService, AuthEventArgs };
