import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { InfiniteScrollCustomEvent, MenuController, RefresherCustomEvent } from '@ionic/angular';

import { noop } from 'lodash-es';

import { ColorBrandBlue500, ColorGreen500, ColorRed500 } from '@housekeep/design-tokens';
import {
  Date,
  dedupeArray,
  RequestOpts,
  VisitRating,
  visitRatingSerializer,
  Worker,
  WorkerMetrics
} from '@housekeep/infra';

import { AuthenticatedPage } from 'components/page-base/authenticated-page';

import { AnalyticsService } from 'services/analytics-service';
import { AuthService } from 'services/auth-service';
import { ErrorService } from 'services/error-service';
import { TimeService } from 'services/time-service';
import { UserService } from 'services/user-service';
import { VisitRatingService } from 'services/visit-rating-service';

import { RatingPeriod } from './rating-period';

const RECENT_DAY_COUNT = 60;
const RECENTLY_CONFIRMED_DAYS = 7;

export const TARGET_DAYS_RELIABLE = RECENT_DAY_COUNT;

const BONUS_RELIABILITY_THRESHOLD = 1.0;

const COLOR_BAD = ColorRed500;
export const COLOR_GOOD = ColorBrandBlue500;
const COLOR_SUCCESS = ColorGreen500;

// Ratings are adjusted by this amount to effectively show a range of 2 to 5 or 3.5 to 5
// in the round progressbar
const RATING_PROGRESS_MIN_TRIALLING = 2;
const RATING_PROGRESS_MIN = 3.5;

@Component({
  selector: 'page-performance',
  templateUrl: 'performance.page.html',
  styleUrls: ['performance.page.scss']
})
export class PerformancePage extends AuthenticatedPage {
  public readonly RECENT_DAY_COUNT: number = RECENT_DAY_COUNT;
  public readonly TARGET_DAYS_RELIABLE = TARGET_DAYS_RELIABLE;
  public readonly BONUS_RELIABILITY_THRESHOLD = BONUS_RELIABILITY_THRESHOLD;

  public readonly COLOR_BAD = COLOR_BAD;
  public readonly COLOR_GOOD = COLOR_GOOD;
  public readonly COLOR_SUCCESS = COLOR_SUCCESS;

  public pageReady: boolean = false;
  public loadError: boolean = false;

  public segment: 'all' | 'recent' = 'recent';

  public worker: Worker;

  protected readonly PAGE_NAME = 'Performance';

  private recentPeriod = new RatingPeriod(RECENT_DAY_COUNT);
  private sinceActivationPeriod: RatingPeriod;
  private allTimePeriod = new RatingPeriod();

  private payNeedleAnimation = null;

  get currentPeriod(): RatingPeriod {
    return this.segment === 'all' ? this.allTimePeriod : this.recentPeriod;
  }

  get metrics(): WorkerMetrics | null {
    return this.currentPeriod ? this.currentPeriod.summary : null;
  }

  get sinceActivationMetrics(): WorkerMetrics | null {
    return this.sinceActivationPeriod ? this.sinceActivationPeriod.summary : null;
  }

  get allTimeMetrics(): WorkerMetrics | null {
    return this.allTimePeriod ? this.allTimePeriod.summary : null;
  }

  constructor(
    protected analyticsService: AnalyticsService,
    protected authService: AuthService,
    protected errorService: ErrorService,
    public menuCtrl: MenuController,
    private route: ActivatedRoute,
    private router: Router,
    protected timeService: TimeService,
    protected userService: UserService,
    protected visitRatingService: VisitRatingService
  ) {
    super();
  }

  public pageWillEnter() {
    if (this.route.snapshot.params.tab === 'all') {
      this._changeSegment('all');
    } else {
      this._changeSegment('recent');
    }
  }

  public isRecentTab() {
    return this.segment === 'recent';
  }

  public isAllTimeTab() {
    return this.segment === 'all';
  }

  /**
   * Returns `true` if the worker has not yet been rated.
   */
  public isUnrated() {
    return this.pageReady && this.metrics.ratingCount === 0;
  }

  /**
   * Returns `true` if the worker was recently "confirmed" (i.e. passed their trial).
   */
  public isRecentlyConfirmed() {
    const today = this.timeService.today();
    return (
      this.worker &&
      this.worker.lastConfirmationDt &&
      today.diff(this.worker.lastConfirmationDt, 'days') <= RECENTLY_CONFIRMED_DAYS
    );
  }

  /**
   * Returns `true` if we know the status of the worker's background check.
   */
  public hasBackgroundCheckStatus() {
    return this.worker && this.worker.backgroundCheck !== undefined;
  }

  /**
   * Returns `true` if the worker has completed their background check.
   */
  public hasCompletedBackgroundCheck() {
    return this.worker && this.worker.backgroundCheck === true;
  }

  /**
   * Returns `true` if the worker has completed enough visits to
   * become a confirmed Housekeeper.
   */
  public hasCompletedEnoughJobs() {
    return (
      this.sinceActivationMetrics &&
      this.sinceActivationMetrics.visitCount >= this.sinceActivationMetrics.triallingTargetVisitCount
    );
  }

  /**
   * Returns `true` if the worker has a sufficiently high average rating
   * to be become a confirmed Housekeeper.
   */
  public hasSufficientRatingForTrial() {
    return this.pageReady && this.metrics.ratingIsAboveTriallingTargetAverage;
  }

  /**
   * Returns `true` if the worker has a sufficiently high average rating
   * to earn a bonus.
   */
  public hasSufficientRatingForBonus() {
    return this.pageReady && this.metrics.ratingIsAboveTargetAverage;
  }

  /**
   * Returns `true` if the worker has a sufficient reliability for bonus.
   */
  public hasSufficientReliabilityForBonus() {
    return this.pageReady && this.metrics?.reliability >= BONUS_RELIABILITY_THRESHOLD;
  }

  /**
   * Returns `true` if the worker has an average rating below the
   * minimum acceptable threshold set by Housekeep.
   */
  public hasBadRating() {
    return this.pageReady && !this.metrics.ratingIsAboveAverage;
  }

  /**
   * Returns `true` if the worker has an average rating above the
   * minimum acceptable threshold set by Housekeep, but less than the
   * "target" threshold.
   */
  public hasGoodRating() {
    return this.pageReady && this.metrics.ratingIsAboveAverage && !this.metrics.ratingIsAboveTargetAverage;
  }

  /**
   * Returns `true` if the worker has an average rating above the
   * target threshold set by Housekeep, but less than 5.0.
   */
  public hasGreatRating() {
    return this.pageReady && this.metrics.ratingIsAboveTargetAverage && this.metrics.ratingAverage < 5;
  }

  /**
   * Returns `true` if the worker has an average rating of exactly 5.0.
   */
  public hasPerfectRating() {
    return this.pageReady && this.metrics.ratingAverage === 5;
  }

  /**
   * Given a rating out of 5, returns an adjusted rating in the range
   * `0..{5-RATING_PROGRESS_MIN}` for display in the round progressbar.
   */
  public getAdjustedRating(rating) {
    const ratingProgressMin =
      this.worker && this.worker.isTrialling() ? RATING_PROGRESS_MIN_TRIALLING : RATING_PROGRESS_MIN;

    return Math.max(0, rating - ratingProgressMin);
  }

  /**
   * Returns `true` if the worker has an upcoming 1 year pay increase.
   */
  public hasUpcoming1YearPayIncrease() {
    if (!this.worker || !this.worker.lastActivationDt || !this.worker.payIncreaseDate) {
      return false;
    }

    // We specifically want to display the 1 year pay increase date,
    // so we won't display `worker.payIncreaseDate` if the worker has been active for
    // more than a year. We may extend this in future if we add more pay tiers.
    return this._daysSinceLastActivationDate() < 365;
  }

  /**
   * Returns the date of the worker's first annual pay increase.
   */
  public getPayIncreaseDate(): Date {
    return this.worker.payIncreaseDate;
  }

  /**
   * Get the CSS style of the pay gauge needle.
   * The needle is rotated 45 degrees clockwise if the worker is on bonus,
   * 45 degrees anticlockwise if the worker is not on bonus, and 0 degrees while loading.
   */
  public getPayNeedleStyle() {
    const deg = this.worker ? (this.worker.isEligibleForBonus ? 45 : -45) : 0;
    const animation = this.payNeedleAnimation ? `wobble${deg} 1.5s infinite` : 'none';
    const transform = `rotate(${deg}deg)`;

    return {
      animation,
      transform,
      '-ms-transform': transform,
      '-webkit-transform': transform
    };
  }

  /**
   * Enable the pay guage needle animation.
   */
  public animatePayNeedle() {
    if (!this.payNeedleAnimation) {
      this.payNeedleAnimation = setTimeout(() => {
        this.payNeedleAnimation = null;
      }, 1500);
    }
  }

  /**
   * Opens the `PerformanceReliabilityPage` if allowed.
   */
  public async openFulfilmentPage(): Promise<void> {
    await this.router.navigateByUrl('/app-housekeepers/performance/fulfilment', {
      state: {
        metrics: JSON.stringify(this.metrics)
      }
    });
  }

  /**
   * Opens the `PerformanceReliabilityPage` if allowed.
   */
  public async openReliabilityPage(): Promise<void> {
    await this.router.navigateByUrl('/app-housekeepers/performance/reliability', {
      state: {
        metrics: JSON.stringify(this.metrics)
      }
    });
  }

  /**
   * Opens the `PerformanceRatingDetailPage`
   */
  public async openRatingDetailPage(visitRating: VisitRating): Promise<void> {
    await this.router.navigate(['/app-housekeepers/performance/rating', visitRating.id], {
      state: {
        visitRating: visitRatingSerializer.serialize(visitRating)
      }
    });
  }

  /**
   * Handle scrolling to the bottom of the page.
   */
  public async doInfinite(event: InfiniteScrollCustomEvent): Promise<void> {
    if (this.currentPeriod.hasMore) {
      try {
        await this._getVisitRatings(this.currentPeriod, { extendExisting: true });
      } catch (err) {
        // ignore
      }
    }
    await event.target.complete();
  }

  /**
   * Handle scrolling up past the top of the page.
   */
  protected doRefresh(event?: RefresherCustomEvent) {
    this.userService.refreshUser().then(worker => (this.worker = worker));

    this._getData()
      .then(() => this._completeRefresher(event))
      .catch(err => {
        this._completeRefresher(event);
        throw err;
      });
  }

  /**
   * Handle navigation to the 'All' segment.
   */
  protected selectedAll() {
    this._changeSegment('all');
  }

  /**
   * Handle navigation to the 'Recent' segment.
   */
  protected selectedRecent() {
    this._changeSegment('recent');
    this.animatePayNeedle();
  }

  /**
   * Gets the days since the workers last activation date.
   */
  private _daysSinceLastActivationDate() {
    // Accommodate the edge-case of a worker not having a last activation date.
    if (!this.worker.lastActivationDt) {
      return 0;
    }
    const today = this.timeService.today();
    const activationDate = this.worker.lastActivationDt.startOf('day');
    return today.diff(activationDate, 'days');
  }

  /**
   * Handle changing segments.
   * @param {string} segment The name of the segment to which to switch
   */
  private _changeSegment(segment: 'all' | 'recent') {
    this.segment = segment;

    this.userService
      .getUser()
      .then(worker => (this.worker = worker))
      .then(() => {
        if (!this.sinceActivationPeriod) {
          const daysSinceLastActivationDate = this._daysSinceLastActivationDate();
          this.sinceActivationPeriod = new RatingPeriod(daysSinceLastActivationDate);
        }
      })
      .then(() => {
        if (this.currentPeriod.visitRatings.length === 0 && this.currentPeriod.hasMore) {
          this._getCached();
          this._getData();
        }
      });
  }

  /**
   * Hide a refresher's loading message.
   */
  private _completeRefresher(event?: RefresherCustomEvent) {
    // Only complete the refresher if the user has not left the page.
    // Otherwise, the refresher will try to scroll the page, which may not
    // exist, causing an error. This seems to be an Ionic bug.
    if (event && this.isActive) {
      event.detail.complete();
    }
  }

  /**
   * A fire and forget function that will try to get any existing ratings and summaries
   * from the cache
   */
  private _getCached() {
    this.visitRatingService
      .getCachedVisitRatings(this.recentPeriod.dayCount)
      .then(recentRatings => {
        // Only use the cached data if the server hasn't responded yet
        if (!this.recentPeriod.visitRatings.length) {
          this._onRatingsUpdated(
            {
              recentRatings,
              moreExist: true
            },
            this.recentPeriod,
            false
          );
        }
      })
      .catch(noop);

    this.visitRatingService
      .getCachedVisitRatings(this.allTimePeriod.dayCount)
      .then(allTimeRatings => {
        if (!this.recentPeriod.visitRatings.length) {
          this._onRatingsUpdated(
            {
              allTimeRatings,
              moreExist: true
            },
            this.allTimePeriod,
            false
          );
        }
      })
      .catch(noop);

    this.visitRatingService
      .getCachedVisitRatingSummary(this.recentPeriod.dayCount)
      .then(recentSummary => {
        if (!this.recentPeriod.summary) {
          this.recentPeriod.summary = recentSummary;
          this.pageReady = true;
        }
      })
      .catch(noop);

    this.visitRatingService
      .getCachedVisitRatingSummary(this.sinceActivationPeriod.dayCount)
      .then(sinceActivationSummary => {
        if (!this.sinceActivationPeriod.summary) {
          this.sinceActivationPeriod.summary = sinceActivationSummary;
        }
      })
      .catch(noop);

    this.visitRatingService
      .getCachedVisitRatingSummary(this.allTimePeriod.dayCount)
      .then(allTimeSummary => {
        if (!this.allTimePeriod.summary) {
          this.allTimePeriod.summary = allTimeSummary;
        }
      })
      .catch(noop);
  }

  /**
   * Retrieve the ratings and summary.
   * @param {RequestOpts} [opts]
   * @return {Promise} Which resolves when the ratings and summary are retrieved
   */
  private _getData(opts: RequestOpts = {}): Promise<any> {
    const promises = [
      this._getVisitRatings(this.recentPeriod, { extendExisting: false }, opts),
      this._getVisitRatings(this.allTimePeriod, { extendExisting: false }, opts),
      this._getVisitRatingSummary(this.recentPeriod, opts),
      this._getVisitRatingSummary(this.sinceActivationPeriod, opts),
      this._getVisitRatingSummary(this.allTimePeriod, opts)
    ];

    return Promise.all(promises)
      .then(() => {
        this.pageReady = true;
      })
      .catch(err => {
        this.loadError = true;

        if (!this.errorService.isNoInternetError(err)) {
          throw err;
        }
      });
  }

  /**
   * Get a list of visit ratings for a period.
   *
   * The ratings are paged. If `extendExisting` is set, the new ratings are
   * added to those already retrieved.
   *
   * @param {RatingPeriod} period
   * @param {Object} [options]
   * @param {boolean} [options.extendExisting]
   * @param {RequestOpts} [requestOpts]
   * @return {Promise} A promise that resolves when the ratings are fetched
   */
  private _getVisitRatings(
    period: RatingPeriod,
    { extendExisting = false } = {},
    requestOpts: RequestOpts = {}
  ): Promise<any> {
    let lastRatingId;

    if (extendExisting && period.visitRatings.length > 0) {
      const lastRatingIndex = period.visitRatings.length - 1;
      const lastRating = period.visitRatings[lastRatingIndex];
      lastRatingId = lastRating.id;
    }

    return this.visitRatingService
      .getVisitRatings(period.dayCount, lastRatingId, requestOpts)
      .then(response => this._onRatingsUpdated(response, period, extendExisting));
  }

  /**
   * Process any changes to the ratings
   *
   * @param {any}          response
   * @param {RatingPeriod} period   The period that these ratings relate to. We need this
   *                                rather than using this.period, because the selected
   *                                period could have changed before this is called.
   * @param {boolean}      extendExisting Set to true to add these ratings to existing
   *                                      ratings, or false to overwrite others.
   */
  private _onRatingsUpdated(response: any, period: RatingPeriod, extendExisting: boolean = false) {
    const { moreExist, visitRatings } = response;

    if (extendExisting) {
      const merged = [...period.visitRatings, ...visitRatings];
      period.visitRatings = [...dedupeArray(merged, 'id')];
    } else {
      period.visitRatings = visitRatings || [];
    }

    period.hasMore = moreExist;
    period.weeks = this.visitRatingService.groupByWeek(period.visitRatings);
  }

  /**
   * Set a rating period's summary, which contains information such as the
   * number of ratings and average rating.
   *
   * @param {RatingPeriod} period
   * @param {RequestOpts} [opts]
   * @return {Promise} A promise that resolves when the summary is fetched and set
   */
  private _getVisitRatingSummary(period: RatingPeriod, opts?: RequestOpts) {
    return this.visitRatingService
      .getVisitRatingSummary(period.dayCount, opts)
      .then(summary => (period.summary = summary));
  }
}
