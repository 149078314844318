import { Platform } from '@ionic/angular';
import { Platforms } from '@ionic/core';

const BROWSER_PLATFORMS: Platforms[] = ['desktop', 'mobileweb'];

/*
 * The Ionic team does not officially support the browser platform.
 * For this reason it can be tricky to detect when the session is in a browser
 * This method combines known workarounds for in browser detection.
 */
export function isDeviceBrowser(platform: Platform): boolean {
  return BROWSER_PLATFORMS.some(platformType => platform.is(platformType));
}
