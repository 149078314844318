export enum OfflineEventObjectTypes {
  AnalyticsRecord = 'analytics-record',
  JobRating = 'job-rating',
  Visit = 'visit',
  VisitGeoLocation = 'visit-geolocation'
}

export enum OfflineEventActionTypes {
  AnalyticsRecordCreate = 'create-analytics-record',
  FinishVisit = 'finish-visit',
  RegisterVisitGeoLocation = 'register-visit-geolocation',
  StartVisit = 'start-visit',
  RateJob = 'rate-job',
  UnstartVisit = 'unstart-visit'
}
