import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';

import * as Clipboard from 'clipboard';

import { Duration, Property, TimeSlot } from '@housekeep/infra';

import { VisitChanges } from 'models/visit-changes';

import { AnalyticsService, TapElementName } from 'services/analytics-service';
import { ToastService } from 'services/toast-service';

const CLIPBOARD_SUCCESS_MESSAGE = 'Address copied to clipboard!';
const CLIPBOARD_ERROR_MESSAGE = 'Failed to copy address to clipboard';

@Component({
  selector: 'hk-visit-summary',
  templateUrl: './hk-visit-summary.component.html',
  styleUrls: ['./hk-visit-summary.component.scss']
})
export class HkVisitSummary implements AfterViewInit, OnDestroy {
  @Input() public bringCleaningProducts = false;
  @Input() public duration: Duration;
  @Input() public preferredName: string;
  @Input() public property: Property;
  @Input() public showSensitive: boolean = false;
  @Input() public showVisitInfo: boolean;
  @Input() public timeSlot: TimeSlot;
  @Input() public recommendedStartTimeSlot?: TimeSlot;
  @Input() public visitChanges: VisitChanges;
  @Output() public openCleaningProductsModal = new EventEmitter<void>();
  @ViewChild('copyButton', { read: ElementRef, static: false })
  copyButton: ElementRef<HTMLIonButtonElement>;

  private clipboard: Clipboard;

  constructor(
    private analyticsService: AnalyticsService,
    private renderer: Renderer2,
    private toastService: ToastService
  ) {}

  public ngAfterViewInit() {
    // Initialise the clipboard
    if (this.isClipboardSupported() && this.copyButton) {
      this.clipboard = new Clipboard(this.copyButton.nativeElement, {
        text: () => this.property.fullAddress
      })
        .on('success', () => {
          this._showClipboardCopyToast(CLIPBOARD_SUCCESS_MESSAGE, true);
        })
        .on('error', () => {
          this._showClipboardCopyToast(CLIPBOARD_ERROR_MESSAGE, false);
        });
    }
  }

  public ngOnDestroy() {
    if (this.clipboard) {
      this.clipboard.destroy();
    }
  }

  /**
   * Return if the current device supports clipboard access
   * @return {boolean}
   */
  public isClipboardSupported(): boolean {
    return Clipboard.isSupported();
  }

  /**
   * Called upon tapping the address or clipboard icon.
   *
   * If clipboard.js is supported, a `shake` animation is added to the icon to provide
   * immediate feedback to the user.
   *
   * Whether or not clipboard.js is supported, a `tap` analytics event is tracked.
   */
  public addressTapped() {
    if (Clipboard.isSupported()) {
      if (this.copyButton) {
        const el = this.copyButton.nativeElement;
        this.renderer.addClass(el, 'animate--shake');
        setTimeout(() => {
          this.renderer.removeClass(el, 'animate--shake');
        }, 1000);
      }

      this.analyticsService.trackTap(TapElementName.CopyAddress);
    } else {
      this.analyticsService.trackTap(TapElementName.CopyAddressUnsupported);
    }
  }

  public showBookedArrivalTime(): boolean {
    return !this.recommendedStartTimeSlot || this.recommendedStartTimeSlot.equalsTime(this.timeSlot);
  }

  private _showClipboardCopyToast(message: string, success: boolean) {
    this.toastService.showToast({
      buttons: [],
      duration: 2000,
      message: message,
      theme: success ? 'info' : 'warning'
    });
  }
}
