import { DemoEndpoint } from '../../demo-service';
import { workerAchievementsEndpoint } from './workers/achievements';
import { workerAreasEndpoint } from './workers/areas';
import { workerAvailabilityEndpoint } from './workers/availability';
import { workerAvailabilityChangesEndpoint } from './workers/availability';
import { workerDayAvailabilityEndpoint } from './workers/availability';
import { workerBreaksEndpoint } from './workers/breaks';
import { customerKeysEndpoint } from './workers/customer-keys';
import { dailyStatsEndpoint } from './workers/daily-stats';
import { extraJobAvailabilitySignalEndpoint } from './workers/extra-job-availability-signal';
import { extraJobEligibilityEndpoint } from './workers/extra-job-eligibility';
import { paymentEndpoint } from './workers/payment';
import { paymentDetailsEndpoint } from './workers/payment-details';
import { paymentsEndpoint } from './workers/payments';
import { ratingsEndpoint } from './workers/ratings';
import { workerReferralsEndpoint } from './workers/referrals';
import { statsEndpoint } from './workers/stats';
import { workingDayEndpoint } from './workers/working-day';
import { workingDaysEndpoint } from './workers/working-days';

const workersEndpoint: DemoEndpoint = {
  path: 'workers/[a-z0-9]+',
  endpoints: [
    customerKeysEndpoint,
    extraJobAvailabilitySignalEndpoint,
    extraJobEligibilityEndpoint,
    workerAvailabilityChangesEndpoint,
    workerBreaksEndpoint,
    paymentDetailsEndpoint,
    paymentEndpoint,
    paymentsEndpoint,
    ratingsEndpoint,
    dailyStatsEndpoint,
    statsEndpoint,
    workerAchievementsEndpoint,
    workerAreasEndpoint,
    workerAvailabilityEndpoint,
    workerDayAvailabilityEndpoint,
    workerReferralsEndpoint,
    workingDayEndpoint,
    workingDaysEndpoint
  ]
};

export { workersEndpoint };
