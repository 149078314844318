import { DemoEndpoint } from '../../../demo-service';

const settingValueEndpoint: DemoEndpoint = {
  path: 'setting-value/[a-z0-9-_]+',
  handlers: [
    {
      method: 'get',
      handle() {
        return {
          value: null
        };
      }
    }
  ]
};

export { settingValueEndpoint };
