import { jobSerializer, ModelSerializer, modelSerializer, serializerField } from '@housekeep/infra';

import { assignableJob, AssignableJob } from 'models/assignable-job';

import { availabilitySerializer } from './availability';

export const assignableJobSerializer = modelSerializer.extend({
  model: assignableJob,
  fields: {
    availability_changes: serializerField.create({
      serializer: availabilitySerializer,
      serializerKwargs: { many: true },
      optional: true
    }),
    job: serializerField.create({ serializer: jobSerializer })
  }
}) as ModelSerializer<AssignableJob>;
