import { Component, Input, OnInit } from '@angular/core';

import { Worker } from '@housekeep/infra';

import { FromParty, TicketComment } from 'models/ticket-comment';

import { UserService } from 'services/user-service';

@Component({
  selector: 'help-request-ticket-comment',
  templateUrl: './help-request-ticket-comment.component.html',
  styleUrls: ['./help-request-ticket-comment.component.scss']
})
export class HelpRequestTicketCommentComponent implements OnInit {
  @Input() public comment: TicketComment;
  public ready = false;
  public user: Worker;
  public readonly FROM_PARTY_HOUSEKEEP = FromParty.Housekeep;

  constructor(private userService: UserService) {}

  ngOnInit(): void {
    this.userService
      .getUser()
      .then(user => {
        this.user = user;
        this.ready = true;
      })
      .catch(() => {
        this.user = null;
        this.ready = true;
      });
  }

  public commenterDisplay(): string {
    if (this.comment.fromParty === FromParty.Housekeep) {
      if (this.comment.agentName) {
        return this.comment.agentName;
      } else {
        return 'Housekeep Support';
      }
    } else {
      if (this.user) {
        return this.user.fullName;
      } else {
        return 'Me';
      }
    }
  }
}
