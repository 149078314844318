import { DemoEndpoint } from '../../../demo-service';
import { getExtraJobsOneOffData } from '../../util';

const jobsEndpoint: DemoEndpoint = {
  path: 'jobs/[a-z0-9]{12}',
  handlers: [
    {
      method: 'patch',
      handle(state) {
        return getExtraJobsOneOffData(state).results[0];
      }
    }
  ]
};

export { jobsEndpoint };
