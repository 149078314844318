<div class="hk-arrival-time__time-slot">
  <p>
    You are booked to arrive for this job
    <span *ngIf="visit.timeSlot.isInstant">
      at {{ visit.timeSlot.start | asTime }} sharp.
    </span>
    <span *ngIf="!visit.timeSlot.isInstant">
      between {{ visit.timeSlot.start | asTime }}
      and {{ visit.timeSlot.end | asTime }}.
    </span>
  </p>
</div>
<p *ngIf="isLate()" class="hk-arrival-time--late">
  <strong>You are {{ humanReadableLateness() }} late</strong>
</p>
