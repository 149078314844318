import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Subscription } from 'rxjs';

import { Worker } from '@housekeep/infra';

import { DemoService } from 'services/demo-service';
import { UserService } from 'services/user-service';

@Component({
  selector: 'profile-summary',
  templateUrl: './profile-summary.component.html',
  styleUrls: ['./profile-summary.component.scss']
})
export class ProfileSummaryComponent implements OnDestroy, OnInit {
  public user: Worker;

  private _cachedUser: Worker;
  private _demoServiceObserver: Subscription;

  constructor(private demoService: DemoService, private router: Router, private userService: UserService) {}

  ngOnInit() {
    this._refreshUser();
    this._demoServiceObserver = this.demoService.subscribe(event => this._onDemoEvent(event));
  }

  ngOnDestroy() {
    if (this._demoServiceObserver) {
      this._demoServiceObserver.unsubscribe();
    }
  }

  public async goToProfilePage(): Promise<void> {
    await this.router.navigateByUrl('/app-housekeepers/profile');
  }

  public async goToBadgePage(): Promise<void> {
    await this.router.navigateByUrl('/app-housekeepers/id');
  }

  public async goToRatingsPage(): Promise<void> {
    await this.router.navigateByUrl('/app-housekeepers/performance');
  }

  /**
   * Refresh the user when Demo Mode turns on or off.
   */
  private _onDemoEvent(event) {
    const [eventName] = event;

    if (eventName === 'on') {
      this._cachedUser = this.user;
      this._refreshUser();
    } else if (eventName === 'off') {
      if (this._cachedUser) {
        this.user = this._cachedUser;
        this._cachedUser = null;
      } else {
        this._refreshUser();
      }
    }
  }

  /**
   * Get the user.
   */
  private _refreshUser() {
    this.userService
      .getUser()
      .then(user => (this.user = user))
      .catch(() => (this.user = null));
  }
}
