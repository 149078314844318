import { Injectable, Injector, Renderer2, RendererFactory2 } from '@angular/core';

import { ToastController } from '@ionic/angular';
import { ToastOptions } from '@ionic/core';

import { isEqual } from 'lodash-es';

import { Subject } from 'rxjs';

import { Deferred } from '@housekeep/infra';

import { AnalyticsService } from './analytics-service';

// Debounce (i.e. rate limit) all requests by the given number of MS
const TOAST_DEBOUNCE_MS = 500;
enum DebounceCategory {
  NonPersistent,
  Demo,
  Offline,
  InCSChat,
  InCSChatWithMessage
}

// The duration of the CSS transition when repositioning toasts
const TOAST_TRANSITION = '0.3s';

// Interfaces
type AlertTheme = 'danger' | 'default' | 'info' | 'success' | 'warning';
export type ToastType = 'persistent' | 'non-persistent';

export interface HkToastOptions extends ToastOptions {
  theme?: AlertTheme;
}

interface ToastEntry {
  promise: Promise<HTMLIonToastElement>;
  options: ToastOptions;
}

// Default toast options
const DEFAULTS: { [toastType: string]: HkToastOptions } = {
  persistent: {
    theme: 'info',
    animated: true,
    position: 'bottom'
  },
  'non-persistent': {
    theme: 'info',
    animated: true,
    position: 'bottom',
    buttons: [
      {
        text: 'Close',
        role: 'cancel'
      }
    ]
  }
};

// Map of valid persistent toasts and their associated messages
enum PersistentTypes {
  Offline,
  DemoMode,
  InCSChat,
  InCSChatWithMessage
}
const PERSISTENT_MESSAGES = {
  [PersistentTypes.Offline]: 'No internet connection',
  [PersistentTypes.DemoMode]: 'YOU ARE IN DEMO MODE',
  [PersistentTypes.InCSChat]: 'You are chatting to Housekeep',
  [PersistentTypes.InCSChatWithMessage]: 'New message from Housekeep'
};

const BODY_CLASS_HAS_PERSISTENT_TOAST = 'has-persistent-toast';

type DebounceableMethod =
  | ToastService['_showToast']
  | ToastService['_dismissToast']
  | ToastService['_dismissPersistentToast'];

/**
 * A service to simplify toast creation.
 *
 * This creates a lightweight wrapper around the Ionic Toast creation, but
 * ensures that only one toast exists at one time, and adds the ability to
 * theme a toast.
 */
@Injectable({ providedIn: 'root' })
export class ToastService {
  private renderer: Renderer2;

  private toastEventsSubject: Subject<string> = new Subject();
  private toasts: { [toastType: string]: ToastEntry } = {};

  // Variables to allow debouncing all show/hide requests
  private _toastTimeouts: { [debounceCategory: string]: number } = {};
  private _toastDeferreds: {
    [debounceCategory: string]: Deferred<HTMLIonToastElement | void>;
  } = {};

  constructor(
    private injector: Injector,
    private rendererFactory: RendererFactory2,
    private toastCtrl: ToastController
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  /**
   * Show a non-persistent toast with the given options
   *
   * Returns a promise that will be resolved with the Toast object.
   * Note that this service will have registered an `onDidDismiss`
   * callback to handle its own tear down logic. If you need your own
   * callback behaviour, use `onWillDismiss`, or find a way to call
   * the existing callback as well as your own.
   */
  public showToast(toastOpts: HkToastOptions = {}): Promise<HTMLIonToastElement> {
    return this._debounce(DebounceCategory.NonPersistent, this._showToast, ['non-persistent', toastOpts]);
  }

  /**
   * Show a persistent toast notifying the user that demo-mode is active.
   */
  public showDemoToast(): Promise<HTMLIonToastElement> {
    return this._debounce(DebounceCategory.Demo, this._showToast, [
      'persistent',
      {
        message: PERSISTENT_MESSAGES[PersistentTypes.DemoMode],
        buttons: [
          {
            text: 'Exit',
            role: 'cancel'
          }
        ],
        cssClass: 'toast--demo-mode'
      }
    ]);
  }

  /**
   * Show a persistent toast notifying the user that they're offline.
   */
  public showOfflineToast(): Promise<HTMLIonToastElement> {
    return this._debounce(DebounceCategory.Offline, this._showToast, [
      'persistent',
      {
        message: PERSISTENT_MESSAGES[PersistentTypes.Offline],
        theme: 'danger'
      }
    ]);
  }

  /**
   * Show persistent toast reminding Housekeeper that they're chatting.
   */
  public showInCSChatToast(): Promise<HTMLIonToastElement> {
    return this._debounce(DebounceCategory.InCSChat, this._showToast, [
      'persistent',
      {
        message: PERSISTENT_MESSAGES[PersistentTypes.InCSChat],
        theme: 'info',
        buttons: [
          {
            text: 'View',
            handler: () => this.toastEventsSubject.next('open-housekeep-chat')
          }
        ]
      }
    ]);
  }

  /**
   * Show a persistent toast telling them they have a message.
   */
  public showInCSChatWithMessageToast(): Promise<HTMLIonToastElement> {
    return this._debounce(DebounceCategory.InCSChatWithMessage, this._showToast, [
      'persistent',
      {
        message: PERSISTENT_MESSAGES[PersistentTypes.InCSChatWithMessage],
        theme: 'warning',
        buttons: [
          {
            text: 'View',
            handler: () => this.toastEventsSubject.next('open-housekeep-chat')
          }
        ]
      }
    ]);
  }

  public dismissToast(): Promise<void> {
    return this._debounce(DebounceCategory.NonPersistent, this._dismissToast, ['non-persistent']);
  }

  public dismissDemoToast(): Promise<void> {
    return this._debounce(DebounceCategory.Demo, this._dismissPersistentToast, [PersistentTypes.DemoMode]);
  }

  public dismissOfflineToast(): Promise<void> {
    return this._debounce(DebounceCategory.Offline, this._dismissPersistentToast, [PersistentTypes.Offline]);
  }

  public dismissInCSChatToast(): Promise<void> {
    return this._debounce(DebounceCategory.InCSChat, this._dismissPersistentToast, [PersistentTypes.InCSChat]);
  }

  public dismissInCSChatWithMessageToast(): Promise<void> {
    return this._debounce(DebounceCategory.InCSChatWithMessage, this._dismissPersistentToast, [
      PersistentTypes.InCSChatWithMessage
    ]);
  }

  /**
   * Subscribe to toast service updates (toasts being shown / dismissed).
   */
  public subscribe(fn: (eventName: string) => void) {
    return this.toastEventsSubject.subscribe(fn);
  }

  /**
   * Debounce (i.e. rate limit) the given function for the given category.
   *
   * When a user makes a successful request, the RequestService tells the
   * ToastService to hide the offline toast. This is just one example which
   * can cause nasty race conditions of toasts being shown and dismissed
   * simultaneously, resulting in some toasts never being shown at all.
   *
   * Instead, we debounce each category of requests for a short period of time,
   * and only perform a request after no other one has been made in that period.
   */
  private _debounce<T extends DebounceableMethod>(
    category: DebounceCategory,
    fn: T,
    args: Parameters<T>
  ): ReturnType<T> {
    // Cancel any pending requests
    if (this._toastTimeouts[category]) {
      window.clearTimeout(this._toastTimeouts[category]);
      this._toastDeferreds[category].resolve();
    }

    this._toastDeferreds[category] = new Deferred();

    // Create a new request
    this._toastTimeouts[category] = window.setTimeout(() => {
      this._toastTimeouts[category] = null;
      this._toastDeferreds[category].resolve(fn.apply(this, args));
    }, TOAST_DEBOUNCE_MS);

    return this._toastDeferreds[category].promise as ReturnType<T>;
  }

  /**
   * Show the given toast type with the given options.
   * If a matching toast already exists, we do nothing.
   * Always teardown an existing toast before creating a new one.
   */
  private _showToast(toastType: ToastType, options: HkToastOptions): Promise<HTMLIonToastElement> {
    const existing = this.toasts[toastType];

    // Apply the defaults
    options = Object.assign({}, DEFAULTS[toastType], options);

    // Ignore duplicate requests
    if (existing && isEqual(options, existing.options)) {
      return existing.promise;
    }

    // Dismiss an existing toast before creating the new one
    const promise = this._dismissToast(toastType).then(async () => {
      // Copy the options so we can translate them into vanilla ToastOptions
      // Translate the type and theme to CSS classes
      const newOptions = Object.assign({}, options);
      const { cssClass = '', theme } = options;
      newOptions.cssClass = `${cssClass} toast--${toastType} toast--${theme}`.trim();
      delete newOptions.theme;

      // Create the toast
      const instance = await this.toastCtrl.create(newOptions);
      this.toastEventsSubject.next(`show-${toastType}`);
      instance.onDidDismiss().then(() => this._onToastDismissed(toastType));
      await instance.present();

      // Reset the toast positions without awaiting
      this.resetToastPositions().then();

      const analyticsService = this.injector.get(AnalyticsService);
      analyticsService.trackToast(options.message as string).then();

      return instance;
    });
    this.toasts[toastType] = { promise, options };
    return promise;
  }

  /**
   * Dismiss the toast with the given type if it exists.
   */
  private _dismissToast(toastType: ToastType): Promise<void> {
    const toastEntry = this.toasts[toastType];

    if (toastEntry) {
      return this._safeDismiss(toastEntry);
    } else {
      return Promise.resolve();
    }
  }

  /**
   * Dismiss the persistent toast with the given type if currently showing.
   */
  private _dismissPersistentToast(persistentType: PersistentTypes): Promise<void> {
    const toastEntry = this.toasts.persistent;
    const persistentMessage = PERSISTENT_MESSAGES[persistentType];

    if (toastEntry && toastEntry.options.message === persistentMessage) {
      return this._safeDismiss(toastEntry);
    } else {
      return Promise.resolve();
    }
  }

  /**
   * When a toast is dismissed, notify the subscribers and remove its reference.
   */
  private async _onToastDismissed(toastType: ToastType): Promise<void> {
    this.toasts[toastType] = null;
    this.toastEventsSubject.next(`hide-${toastType}`);
    this.resetToastPositions().then();
  }

  /**
   * Dismissing toasts is subject to odd timing effects, and multiple dismiss
   * calls as well as page change events can lead to unintelligable uncaught
   * promises.
   */
  private async _safeDismiss(entry: ToastEntry): Promise<void> {
    try {
      await (await entry.promise).dismiss();
    } catch (err) {
      if (err !== false && err !== 'removeView was not found') {
        throw err;
      }
    }
  }

  /*
   * Ensure non-persistent toasts are displayed above persistent toasts.
   * Persistent toasts should be displayed at the very bottom of the screen.
   */
  private async resetToastPositions(): Promise<void> {
    const persistentToast = this.toasts['persistent'];
    const nonPersistentToast = this.toasts['non-persistent'];
    const nonPersistentToastBase = 8; // bottom should be 8px + ion-safe-area-bottom

    if (persistentToast) {
      const instance = await persistentToast.promise;

      document.body.classList.add(BODY_CLASS_HAS_PERSISTENT_TOAST);

      if (instance.shadowRoot) {
        this.overrideToastStyle(instance.shadowRoot);
      }
    } else {
      document.body.classList.remove(BODY_CLASS_HAS_PERSISTENT_TOAST);
    }

    if (nonPersistentToast) {
      const instance = await nonPersistentToast.promise;

      if (instance.shadowRoot) {
        this.overrideToastStyle(instance.shadowRoot, nonPersistentToastBase);
      }
    }
  }

  /*
   * Add a `<style>` element to the specified shadow root and remove the inline style from the `.toast-wrapper`.
   */
  private overrideToastStyle(shadowRoot: ShadowRoot, basePx?: number) {
    const style = this.renderer.createElement('style');
    style.innerHTML = `
      :host(.toast--persistent.ios) .toast-wrapper {
        transition: none;
        // On iOS, toasts animate to translateY(-10px). If we change the translateY to 0,
        // we lose the animation. We want the persistent message to be at the very bottom,
        // so setting bottom to -10px counters the -10px on translateY.
        bottom: -10px;

        // Notched iOS devices (e.g. iPhone X) have a "safe area" at the bottom,
        // in portrait mode. We want our persistent toasts to occupy this area.
        @supports (padding-bottom: constant(safe-area-inset-bottom)) {
          padding-bottom: constant(safe-area-inset-bottom);
        }
        @supports (padding-bottom: env(safe-area-inset-bottom)) {
          padding-bottom: env(safe-area-inset-bottom);
        }
        margin: 0 !important;
      }

      .toast-wrapper {
        transition: ${TOAST_TRANSITION};
        bottom: ${basePx ? `calc(${basePx}px + var(--ion-safe-area-bottom, 0px))` : '0'};
      }
    `;
    shadowRoot.appendChild(style);
    this.renderer.removeStyle(shadowRoot.querySelector('.toast-wrapper'), 'bottom');
  }
}
