import {
  baseField,
  dateField,
  Duration,
  Field,
  ModelSerializer,
  modelSerializer,
  postcodeSerializer,
  serializerField
} from '@housekeep/infra';

import { WorkerBreakNotice } from 'models/worker-break';
import { affectedVisit, workerBreakEffects, WorkerBreakEffects } from 'models/worker-break-effects';

/**
 * Duration In Hours field.
 * A Duration instance is serialized as a number of hours (with precision=2)
 */
const durationInHoursField: Field = baseField.extend({
  getSerialized(duration: Duration) {
    return duration.toHours(2);
  },

  getDeserialized(hours) {
    return new Duration({ hours });
  }
});

/**
 * Notice Category field
 *
 * TODO this could be simplified if the WorkerBreakNotice enum had string initializers
 * (requires TypeScript 2.4+)
 */
export const noticeCategoryField: Field = baseField.extend({
  getSerialized(noticeCategory: WorkerBreakNotice) {
    switch (noticeCategory) {
      case WorkerBreakNotice.Good:
        return 'good';
      case WorkerBreakNotice.Short:
        return 'short';
      case WorkerBreakNotice.Late:
        return 'late';
      default:
        return null;
    }
  },

  getDeserialized(str) {
    switch (str) {
      case 'good':
        return WorkerBreakNotice.Good;
      case 'short':
        return WorkerBreakNotice.Short;
      case 'late':
      case 'very late':
      case 'extremely late':
        return WorkerBreakNotice.Late;
      default:
        return null;
    }
  }
});

export const affectedVisitSerializer = modelSerializer.extend({
  model: affectedVisit,
  fields: {
    notice_category: noticeCategoryField.create(),
    required_duration: durationInHoursField.create(),
    postcode: serializerField.create({
      serializer: postcodeSerializer,
      optional: false
    }),
    visit_date: dateField.create()
  }
});

/**
 * Worker Break Effects serializer
 */
export const workerBreakEffectsSerializer = modelSerializer.extend({
  model: workerBreakEffects,
  fields: {
    notice_category: noticeCategoryField.create(),
    affected_visits: serializerField.create({
      serializer: affectedVisitSerializer,
      serializerKwargs: { many: true }
    })
  }
}) as ModelSerializer<WorkerBreakEffects>;
