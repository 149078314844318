import { model, Model } from '@housekeep/infra';

export interface WorkerExtraJobEligibility extends Model {
  isEligible: boolean;
  isEligibleForOneOffJobs: boolean;
  isEligibleForRegularJobs: boolean;
  regularJobEligibilityThreshold: number;
  regularJobEligibilityRemainingVisits: number;
  isZeroHours: boolean;
  minRating: number;
  reason: string;
}

export const workerExtraJobEligibility: WorkerExtraJobEligibility = model.extend({
  modelName: 'workerExtraJobEligibility'
});
