import { Date } from '@housekeep/infra';

import { availabilityPeriods, AvailabilityPeriods } from './mixin.availability';

enum WorkerBreakNotice {
  Good,
  Short,
  Late
}

enum WorkerBreakType {
  PartDay = 'Part-day',
  SingleDay = 'Single day',
  MultiDay = 'Multiple days'
}

interface WorkerBreak extends AvailabilityPeriods {
  noticeCategory?: WorkerBreakNotice;
  type: WorkerBreakType;
  startDate: Date;
  endDate: Date;
  reason: string;
  note?: string;
  requiresOps: boolean;
  durationInDays: () => number;
  extendsAvailability: boolean;
  fines: boolean;
  totalAffectedHours: number;
  totalAffectedCleans: number;
}

/**
 * Models a worker's availability as differing from normal, between a start
 * and end date (inclusive).
 */
const workerBreak: WorkerBreak = availabilityPeriods.extend({
  modelName: 'workerBreak',

  durationInDays() {
    return this.endDate.diff(this.startDate, 'days');
  }
});

export { workerBreak, WorkerBreak, WorkerBreakNotice, WorkerBreakType };
