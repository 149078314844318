// ONBOARDING
export const SETTING_DEFAULT_END_TIME = 'worker_onboarding_default_end_time';
export const SETTING_DEFAULT_START_TIME = 'worker_onboarding_default_start_time';
export const SETTING_EARLIEST_START_TIME = 'worker_onboarding_earliest_start_time';
export const SETTING_LATEST_END_TIME = 'worker_onboarding_latest_end_time';

// CORONAVIRUS
export const SETTING_CORONAVIRUS_DISINFECTANT_MESSAGE = 'coronavirus_hkapp_schedule_disinfectant_message';
export const SETTING_CORONAVIRUS_PPE_MESSAGE = 'coronavirus_hkapp_schedule_ppe_message';
export const SETTING_CORONAVIRUS_SELF_ISOLATION_MESSAGE = 'coronavirus_hkapp_self_isolation_message';

// HOUSEKEEP ACADEMY
export const SETTING_HOUSEKEEP_ACADEMY_URL = 'housekeep_academy_url';
export const SETTING_HOUSEKEEPER_ONBOARDING_QUIZ_URL = 'housekeeper_onboarding_quiz_url';
