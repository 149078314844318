// Visits for the working days in the `working-days` file with placeholder
// dates and as many expanded fields as needed:
export const VISIT_TEMPLATES_BY_JOB_ID = {
  jze8dt2geqzn: {
    tip_amount_pounds: null,
    tasks: [
      {
        hours: 3.0,
        type: 'cleaning'
      }
    ],
    any_worker: false,
    finished_at: null,
    frequency: 7.0,
    started_at: null,
    id: null,
    property_id: 'qzxwpclleaok',
    account_id: 'jovgmhv8m6op',
    job_id: 'jze8dt2geqzn',
    access: 'keys',
    time_slot: {
      start: '15:30:00',
      end: '16:30:00'
    },
    skipped: false,
    is_first_visit: false,
    week_beginning: '{{ weekBeginning }}',
    finished: false,
    scheduled_date: '{{ date }}',
    actual_date: '{{ date }}',
    instructions: [
      {
        task_type: 'cleaning',
        instruction: 'Please take extra care with the shower.',
        id: '2z4qvfk8ryb4'
      }
    ],
    account: {
      id: 'jovgmhv8m6op',
      name: 'Molly Parry',
      initial_customer: {
        preferred_name: 'Molly',
        account_id: 'jovgmhv8m6op',
        id: 'xb6yxsvnj3op'
      }
    },
    access_display: 'Have keys',
    customer_can_alter: true,
    is_reviewable: false,
    worker_property_access: 'cannot_access',
    worker_can_call_customer: true,
    property: {
      city: '',
      access_information: {
        opening_instructions: 'You need to unlock the bottom lock first.',
        locking_instructions: 'Please lock both locks.',
        alarm_entry_instructions: '',
        key_nest_location: '',
        alarm: null,
        concierge_location: '',
        num_keys: '1',
        access: 'keys',
        hidden_key_location: '',
        key_safe_code: '',
        key_collection_state: {
          key_id: null,
          state: 'none',
          visit: null
        },
        key: {
          code: '6383',
          location: 'worker'
        },
        key_nest_code: '',
        alarm_exit_instructions: '',
        finding_entrance_instructions: '',
        key_transfers: [],
        property_id: 'qzxwpcllac7k',
        key_safe_location: ''
      },
      key: {
        code: '6383',
        location: 'worker'
      },
      bathrooms: 2,
      account_id: 'jovgmhv8m6op',
      bedrooms: 2,
      access: 'keys',
      cleaning_equipment: {
        hoover_location: 'In the airing cupboard.',
        iron_location: 'In the office, cupboard by the window.',
        cleaning_products_location: 'Underneath the sink.',
        bin_bags_location: 'Underneath the sink',
        rubbish_location: 'Please use bin by front door',
        ironing_board_location: 'Against the wall in the office.',
        property_id: 'qzxwpclleaok'
      },
      perks: '',
      postcode: {
        inward: '7BJ',
        outward: 'W1J',
        location: {
          latitude: '51.504557',
          longitude: '-0.0147628'
        }
      },
      pets: {
        other_pets: true,
        other_pets_type: 'Parrot',
        pet_names: 'Polly',
        pet_instructions: "Don't listen to her. She lies.",
        property_id: 'qzxwpclleaok'
      },
      line_1: '116 Picadilly',
      line_3: '',
      line_2: 'Mayfair',
      id: 'qzxwpclleaok'
    },
    remote_key_transfer_type: '',
    required_keys: [
      {
        code: '6383',
        location: 'worker'
      }
    ],
    remote_key_transfers: [],
    remote_key_transfer_keys: []
  },
  nb7axtdqn6be: {
    tip_amount_pounds: null,
    tasks: [
      {
        hours: 3.0,
        type: 'cleaning'
      },
      {
        type: 'products'
      }
    ],
    any_worker: false,
    finished_at: null,
    frequency: 7.0,
    started_at: null,
    id: null,
    property_id: '2z4l8ckkj6o4',
    account_id: 'vz9p2h28gebq',
    job_id: 'nb7axtdqn6be',
    access: 'keynest',
    time_slot: {
      start: '12:00:00',
      end: '17:00:00'
    },
    skipped: false,
    is_first_visit: false,
    week_beginning: '{{ weekBeginning }}',
    finished: false,
    scheduled_date: '{{ date }}',
    actual_date: '{{ date }}',
    instructions: [
      {
        task_type: 'cleaning',
        instruction: 'Please ignore the master bedroom.',
        id: 'mz2rvf6m7gbk'
      },
      {
        task_type: 'cleaning',
        instruction: 'Extra attention to the cobwebs in the corners.',
        id: 'nb5jvf42w2z4'
      }
    ],
    account: {
      id: 'vz9p2h28gebq',
      name: 'Elizabeth Windsor',
      initial_customer: {
        preferred_name: 'Elizabeth',
        account_id: 'vz9p2h28gebq',
        id: 'kz3p4sljxabr'
      }
    },
    access_display: 'KeyNest',
    customer_can_alter: true,
    is_reviewable: false,
    worker_can_call_customer: true,
    worker_property_access: 'cannot_access',
    property: {
      city: '',
      access_information: {
        opening_instructions: 'Please use the keynest.',
        locking_instructions: '',
        alarm_entry_instructions: '',
        key_nest_location: 'Newsagents across the road',
        alarm: null,
        concierge_location: '',
        num_keys: '1',
        access: 'keynest',
        hidden_key_location: '',
        key_safe_code: '',
        key_collection_state: {
          key_id: null,
          state: 'none',
          visit: null
        },
        key: null,
        key_nest_code: '7589',
        alarm_exit_instructions: '',
        finding_entrance_instructions: '',
        key_transfers: [],
        property_id: '2z4l8ckkj6o4',
        key_safe_location: 'On the wall'
      },
      bathrooms: 1,
      account_id: 'vz9p2h28gebq',
      bedrooms: 3,
      access: 'keynest',
      cleaning_equipment: {
        hoover_location: 'Under the stairs.',
        iron_location: '',
        cleaning_products_location: '',
        bin_bags_location: '',
        rubbish_location: '',
        ironing_board_location: '',
        property_id: '2z4l8ckkj6o4'
      },
      perks: '',
      postcode: {
        inward: '7BJ',
        outward: 'W1J',
        location: {
          latitude: '51.504557',
          longitude: '-0.0147628'
        }
      },
      pets: {
        cats: true,
        pet_names: 'Peanut, Butter and Jelly',
        pet_instructions: 'Ignore them, they are relaxed.',
        property_id: '2z4l8ckkj6o4'
      },
      line_1: '116 Picadilly',
      line_3: '',
      line_2: 'Mayfair',
      id: '2z4l8ckkj6o4'
    },
    remote_key_transfer_type: '',
    required_keys: [],
    remote_key_transfers: [],
    remote_key_transfer_keys: []
  },
  '2z4l6twj9lo4': {
    tip_amount_pounds: null,
    tasks: [
      {
        hours: 2.5,
        type: 'cleaning'
      },
      {
        type: 'products'
      }
    ],
    any_worker: false,
    finished_at: null,
    frequency: 7.0,
    started_at: null,
    id: null,
    property_id: 'jze86caa3ezn',
    account_id: 'yzdrvhmg5aol',
    job_id: '2z4l6twj9lo4',
    access: 'at home',
    time_slot: {
      start: '09:00:00',
      end: '09:00:00'
    },
    skipped: false,
    is_first_visit: false,
    week_beginning: '{{ weekBeginning }}',
    finished: false,
    scheduled_date: '{{ date }}',
    actual_date: '{{ date }}',
    instructions: [
      {
        task_type: 'cleaning',
        instruction: 'Wipe down the skirting boards in every room.',
        id: '3ba56uqrj3oe'
      },
      {
        task_type: 'cleaning',
        instruction: 'Do not clean the computer desk in the bedroom.',
        id: 'xb6avf45xdop'
      },
      {
        task_type: 'collect',
        instruction: 'Please take my house keys today.',
        id: 'xb6avf45xdox'
      }
    ],
    account: {
      id: 'yzdrvhmg5aol',
      name: 'Mr. Dennis Chapman',
      initial_customer: {
        preferred_name: 'Dennis',
        account_id: 'yzdrvhmg5aol',
        id: 'wbkjds5lppz7'
      }
    },
    access_display: 'Client at home',
    customer_can_alter: true,
    is_reviewable: false,
    worker_can_call_customer: true,
    worker_property_access: 'can_access',
    property: {
      city: '',
      access_information: {
        opening_instructions: '',
        locking_instructions: '',
        alarm_entry_instructions: '',
        key_nest_location: '',
        alarm: null,
        concierge_location: '',
        num_keys: '',
        access: 'at home',
        hidden_key_location: '',
        key_safe_code: '',
        key_collection_state: {
          key_id: null,
          state: 'none',
          visit: null
        },
        key: null,
        key_nest_code: '',
        alarm_exit_instructions: '',
        finding_entrance_instructions: '',
        key_transfers: [],
        property_id: 'jze86caa3ezn',
        key_safe_location: ''
      },
      key: null,
      bathrooms: 1,
      account_id: 'yzdrvhmg5aol',
      bedrooms: 1,
      access: 'at home',
      cleaning_equipment: {
        hoover_location: 'The hoover is in the cupboard by the front door.',
        iron_location: '',
        cleaning_products_location: '',
        bin_bags_location: 'Bin bags are under the sink.',
        rubbish_location: 'Black bin out front, put recycling in green bin.',
        ironing_board_location: '',
        property_id: 'jze86caa3ezn'
      },
      perks: 'Help yourself to tea and coffee by the kettle.',
      postcode: {
        inward: '1AA',
        outward: 'SW3',
        location: {
          latitude: '51.5013',
          longitude: '-0.1606'
        }
      },
      pets: {
        cats: true,
        pet_names: 'Catthew, or Cat for short',
        pet_instructions: 'Do not let Cat out of the house.',
        property_id: 'jze86caa3ezn'
      },
      line_1: '2 Basil Street',
      line_3: 'London',
      line_2: 'Knightsbridge',
      id: 'jze86caa3ezn'
    },
    remote_key_transfer_type: '',
    required_keys: [],
    remote_key_transfers: [],
    remote_key_transfer_keys: []
  },
  norwatg2n7b2: {
    tip_amount_pounds: null,
    tasks: [
      {
        hours: 3.0,
        type: 'cleaning'
      }
    ],
    any_worker: false,
    finished_at: null,
    frequency: 7.0,
    started_at: null,
    id: null,
    property_id: 'rom4pc44vjoy',
    account_id: '8zpglhap45zk',
    job_id: 'norwatg2n7b2',
    access: 'keys',
    time_slot: {
      start: '09:00:00',
      end: '17:00:00'
    },
    skipped: false,
    is_first_visit: false,
    week_beginning: '{{ weekBeginning }}',
    finished: false,
    scheduled_date: '{{ date }}',
    actual_date: '{{ date }}',
    instructions: [],
    account: {
      id: '8zpglhap45zk',
      name: 'Tracy Farrell',
      initial_customer: {
        preferred_name: 'Tracy',
        account_id: '8zpglhap45zk',
        id: 'gzgn7s238jb9'
      }
    },
    access_display: 'Have keys',
    customer_can_alter: true,
    is_reviewable: false,
    worker_can_call_customer: true,
    worker_property_access: 'cannot_access',
    property: {
      city: '',
      access_information: {
        opening_instructions: 'Unlock top lock first.',
        locking_instructions: 'Lock both locks.',
        alarm_entry_instructions: '',
        key_nest_location: '',
        alarm: null,
        concierge_location: '',
        num_keys: '1',
        access: 'keys',
        hidden_key_location: '',
        key_safe_code: '',
        key_collection_state: {
          key_id: null,
          state: 'none',
          visit: null
        },
        key: {
          code: '1234',
          location: 'worker'
        },
        key_nest_code: '',
        alarm_exit_instructions: '',
        finding_entrance_instructions: '',
        key_transfers: [],
        property_id: 'rom4pc44vjoy',
        key_safe_location: ''
      },
      key: {
        code: '1234',
        location: 'worker'
      },
      bathrooms: 2,
      account_id: '8zpglhap45zk',
      bedrooms: 2,
      access: 'keys',
      cleaning_equipment: {
        hoover_location: '',
        iron_location: '',
        cleaning_products_location: 'In the bathroom cupboard',
        bin_bags_location: '',
        rubbish_location: '',
        ironing_board_location: '',
        property_id: 'rom4pc44vjoy'
      },
      perks: '',
      postcode: {
        inward: '7HH',
        outward: 'SW15',
        location: {
          latitude: '51.5155664373',
          longitude: '-0.0916600851062'
        }
      },
      pets: {
        dogs: true,
        pet_names: 'Max',
        pet_instructions: 'Please fill the water bowl if empty',
        property_id: 'rom4pc44vjoy'
      },
      line_1: 'Gresham St',
      line_3: '',
      line_2: '',
      id: 'rom4pc44vjoy'
    },
    remote_key_transfer_type: '',
    required_keys: [
      {
        code: '1234',
        location: 'worker'
      }
    ],
    remote_key_transfers: [],
    remote_key_transfer_keys: []
  },
  mz2p4t89gyok: {
    tip_amount_pounds: null,
    tasks: [
      {
        hours: 4.5,
        type: 'cleaning'
      }
    ],
    any_worker: false,
    finished_at: null,
    frequency: 7.0,
    started_at: null,
    id: null,
    property_id: 'nb7a3cmmqvbe',
    account_id: 'xb6ymh4r88op',
    job_id: 'mz2p4t89gyok',
    access: 'keys',
    time_slot: {
      start: '12:00:00',
      end: '17:00:00'
    },
    skipped: false,
    is_first_visit: false,
    week_beginning: '{{ weekBeginning }}',
    finished: false,
    scheduled_date: '{{ date }}',
    actual_date: '{{ date }}',
    instructions: [],
    account: {
      id: 'xb6ymh4r88op',
      name: 'Gregory Dean',
      initial_customer: {
        preferred_name: 'Gregory',
        account_id: 'xb6ymh4r88op',
        id: 'dzn25sx329z5'
      }
    },
    access_display: 'Have keys',
    customer_can_alter: true,
    is_reviewable: false,
    worker_can_call_customer: true,
    worker_property_access: 'cannot_access',
    property: {
      city: '',
      access_information: {
        opening_instructions: 'The bottom lock must be opened first.',
        locking_instructions: '',
        alarm_entry_instructions: '',
        key_nest_location: '',
        alarm: null,
        concierge_location: '',
        num_keys: '2',
        access: 'keys',
        hidden_key_location: '',
        key_safe_code: '',
        key_collection_state: {
          key_id: null,
          state: 'none',
          visit: null
        },
        key: null,
        key_nest_code: '',
        alarm_exit_instructions: '',
        finding_entrance_instructions: '',
        key_transfers: [],
        property_id: 'nb7a3cmmqvbe',
        key_safe_location: ''
      },
      bathrooms: 1,
      account_id: 'xb6ymh4r88op',
      bedrooms: 3,
      access: 'keys',
      cleaning_equipment: {
        hoover_location: '',
        iron_location: '',
        cleaning_products_location: 'Cupboard by the bathroom',
        bin_bags_location: 'Underneath the sink',
        rubbish_location: '',
        ironing_board_location: '',
        property_id: 'nb7a3cmmqvbe'
      },
      perks: '',
      postcode: {
        inward: '8DS',
        outward: 'SW15',
        location: {
          latitude: '51.519958973',
          longitude: '-0.0941286921321'
        }
      },
      pets: {
        cats: true,
        pet_names: 'Sonny and Cher',
        pet_instructions: 'Please do not feed them, they will ask!',
        property_id: 'nb7a3cmmqvbe'
      },
      line_1: 'Silk St',
      line_3: '',
      line_2: '',
      id: 'nb7a3cmmqvbe'
    },
    remote_key_transfer_type: '',
    required_keys: [],
    remote_key_transfers: [],
    remote_key_transfer_keys: []
  },
  thechangesvisit: {
    tip_amount_pounds: null,
    tasks: [
      {
        hours: 2.0,
        type: 'cleaning'
      },
      {
        hours: 0.5,
        type: 'oven'
      },
      {
        hours: 0.5,
        type: 'ironing'
      }
    ],
    any_worker: false,
    finished_at: null,
    frequency: 7.0,
    started_at: null,
    id: null,
    property_id: 'gzgnwceedab9',
    account_id: 'kbj95haplaz4',
    job_id: 'thechangesvisit',
    access: 'keys',
    time_slot: {
      start: '12:00:00',
      end: '17:00:00'
    },
    skipped: false,
    changes: true,
    is_first_visit: false,
    week_beginning: '{{ weekBeginning }}',
    finished: false,
    scheduled_date: '{{ date }}',
    actual_date: '{{ date }}',
    instructions: [
      {
        id: 'jfu58s6dvpbq',
        task_type: 'cleaning',
        instruction: 'Only hand wash the dishes'
      },
      {
        id: 'thechangesvisit',
        task_type: 'cleaning',
        instruction: 'Do not clean the bedrooms'
      }
    ],
    account: {
      id: 'kbj95haplaz4',
      name: 'Ms. Yvonne Hughes',
      initial_customer: {
        preferred_name: 'Ms. Hughes',
        account_id: 'kbj95haplaz4',
        id: 'vz9p8s6dvpbq'
      }
    },
    access_display: 'Have keys',
    customer_can_alter: true,
    is_reviewable: false,
    worker_can_call_customer: true,
    worker_property_access: 'can_access',
    property: {
      city: '',
      access_information: {
        opening_instructions: 'You need to unlock the bottom lock first.',
        locking_instructions: 'Please lock both locks.',
        alarm_entry_instructions: '',
        key_nest_location: '',
        alarm: null,
        concierge_location: '',
        num_keys: '1',
        access: 'keys',
        hidden_key_location: '',
        key_safe_code: '',
        key_collection_state: {
          key_id: null,
          state: 'none',
          visit: null
        },
        key: {
          code: '6383',
          location: 'worker'
        },
        key_nest_code: '',
        alarm_exit_instructions: '',
        finding_entrance_instructions: '',
        key_transfers: [],
        property_id: 'gzgnwceedab9',
        key_safe_location: ''
      },
      bathrooms: 1,
      account_id: 'kbj95haplaz4',
      bedrooms: 3,
      access: 'keys',
      cleaning_equipment: {
        hoover_location: 'The hoover is in the upstairs cupboard.',
        iron_location: 'The iron is in the office cupboard by the door.',
        cleaning_products_location: 'Underneath the sink.',
        bin_bags_location: 'Underneath the sink.',
        rubbish_location: 'Use bins at front of property',
        ironing_board_location: 'Behind the office door.',
        property_id: 'gzgnwceedab9'
      },
      perks: '',
      postcode: {
        inward: '2BA',
        outward: 'WC1A',
        location: {
          latitude: '51.5177',
          longitude: '-0.1233'
        }
      },
      pets: {
        other_pets: true,
        other_pets_type: 'Turtle',
        pet_names: 'Donatello',
        pet_instructions: 'Please ignore Donatello in his cage.',
        property_id: 'gzgnwceedab9'
      },
      line_1: '1a Bury Place',
      line_3: 'London',
      line_2: 'Holborn',
      id: 'gzgnwceedab9'
    },
    remote_key_transfer_type: '',
    required_keys: [
      {
        code: '6383',
        location: 'worker'
      }
    ],
    remote_key_transfers: [],
    remote_key_transfer_keys: []
  },
  rom4qtkr75oy: {
    tip_amount_pounds: null,
    tasks: [
      {
        hours: 3.0,
        type: 'cleaning'
      }
    ],
    any_worker: false,
    finished_at: null,
    frequency: 7.0,
    started_at: null,
    id: null,
    property_id: 'xowxyc992pz2',
    account_id: 'dzn29hr4ylz5',
    job_id: 'rom4qtkr75oy',
    access: 'at home',
    time_slot: {
      start: '09:00:00',
      end: '15:00:00'
    },
    skipped: false,
    is_first_visit: false,
    week_beginning: '{{ weekBeginning }}',
    finished: false,
    scheduled_date: '{{ date }}',
    actual_date: '{{ date }}',
    instructions: [],
    account: {
      id: 'dzn29hr4ylz5',
      name: 'Jayne Jones',
      initial_customer: {
        preferred_name: 'Jayne',
        account_id: 'dzn29hr4ylz5',
        id: 'vzq8rs2xm4z6'
      }
    },
    access_display: 'Client at home',
    customer_can_alter: true,
    is_reviewable: false,
    worker_can_call_customer: true,
    worker_property_access: 'can_access',
    property: {
      city: '',
      access_information: {
        opening_instructions: 'Door is stiff so push hard',
        locking_instructions: '',
        alarm_entry_instructions: '',
        key_nest_location: '',
        alarm: null,
        concierge_location: '',
        num_keys: '',
        access: 'at home',
        hidden_key_location: '',
        key_safe_code: '',
        key_collection_state: {
          key_id: null,
          state: 'none',
          visit: null
        },
        key: null,
        key_nest_code: '',
        alarm_exit_instructions: '',
        finding_entrance_instructions: '',
        key_transfers: [],
        property_id: 'xowxyc992pz2',
        key_safe_location: ''
      },
      bathrooms: 2,
      account_id: 'dzn29hr4ylz5',
      bedrooms: 2,
      access: 'at home',
      cleaning_equipment: {
        hoover_location: '',
        iron_location: '',
        cleaning_products_location: 'In the cupboard by the bathroom.',
        bin_bags_location: 'Underneath the sink.',
        rubbish_location: 'Use the bins by the front door.',
        ironing_board_location: '',
        property_id: 'xowxyc992pz2'
      },
      perks: '',
      postcode: {
        inward: '8AQ',
        outward: 'WC3A',
        location: {
          latitude: '51.514488',
          longitude: '-0.080956'
        }
      },
      pets: {
        cats: true,
        pet_names: 'Fish and Chip',
        pet_instructions: 'They must stay inside the house.',
        property_id: 'xowxyc992pz2'
      },
      line_1: '30 St Mary Axe',
      line_3: '',
      line_2: 'London',
      id: 'xowxyc992pz2'
    },
    remote_key_transfer_type: '',
    required_keys: [],
    remote_key_transfers: [],
    remote_key_transfer_keys: []
  },
  vz9p7tlwmabq: {
    tip_amount_pounds: null,
    tasks: [
      {
        hours: 1.5,
        type: 'cleaning'
      },
      {
        hours: null,
        type: 'products'
      },
      {
        hours: 1.0,
        type: 'laundry'
      },
      {
        hours: 0.5,
        type: 'ironing'
      },
      {
        hours: 0.0,
        details: null,
        price: 0.0,
        type: 'remote-key-transfer'
      }
    ],
    any_worker: false,
    finished_at: null,
    frequency: 7.0,
    started_at: null,
    id: null,
    property_id: 'yzdracmm8mol',
    account_id: 'nb7adhmk98be',
    job_id: 'vz9p7tlwmabq',
    access: 'keynest',
    time_slot: {
      start: '09:00:00',
      end: '17:00:00'
    },
    skipped: false,
    is_first_visit: false,
    week_beginning: '{{ weekBeginning }}',
    finished: false,
    scheduled_date: '{{ date }}',
    actual_date: '{{ date }}',
    instructions: [],
    account: {
      id: 'nb7adhmk98be',
      name: 'Mr. Matthew Glover',
      initial_customer: {
        preferred_name: 'Matt',
        account_id: 'nb7adhmk98be',
        id: 'xowx4syer5z2'
      }
    },
    access_display: 'KeyNest',
    customer_can_alter: false,
    is_reviewable: false,
    worker_can_call_customer: true,
    worker_property_access: 'can_access',
    property: {
      city: '',
      access_information: {
        opening_instructions: 'Use the larger key in the bottom lock.',
        locking_instructions: 'Lock both locks.',
        alarm_entry_instructions: 'Alarm code is "2580"',
        key_nest_location: 'Brother Newsagents - Across the street from property',
        alarm: true,
        concierge_location: '',
        num_keys: '',
        access: 'keynest',
        hidden_key_location: '',
        key_safe_code: '5335',
        key_collection_state: {
          key_id: null,
          state: 'none',
          visit: null
        },
        key: null,
        key_nest_code: '999',
        alarm_exit_instructions: 'Enter 1234 and press ARM in lower left',
        finding_entrance_instructions: '',
        key_transfers: [],
        property_id: 'yzdracmm8mol',
        key_safe_location: ''
      },
      bathrooms: 2,
      account_id: 'nb7adhmk98be',
      bedrooms: 2,
      access: 'keynest',
      cleaning_equipment: {
        hoover_location: 'Cupboard under the stairs.',
        iron_location: 'Cupboard under the stairs.',
        cleaning_products_location: 'Under the sink',
        bin_bags_location: 'Under the sink.',
        rubbish_location: 'Round the back of the property.',
        ironing_board_location: 'Cupboard under the stairs',
        property_id: 'yzdracmm8mol'
      },
      perks: 'Feel free to take some fruit from the bowl or juice from the fridge',
      postcode: {
        inward: '1LB',
        outward: 'SW15',
        location: {
          latitude: '51.4699',
          longitude: '-0.2273'
        }
      },
      pets: {
        dogs: true,
        cats: true,
        pet_names: 'Ben and Jerry',
        pet_instructions: 'They can go into the garden but not outside.',
        property_id: 'yzdracmm8mol'
      },
      line_1: '10 Putney Embankment',
      line_3: 'London',
      line_2: 'Putney',
      id: 'yzdracmm8mol'
    },
    remote_key_transfer_type: 'anchor-holder',
    required_keys: [
      {
        code: '2345',
        location: 'worker'
      }
    ],
    remote_key_transfers: [
      {
        other_worker: {
          first_names: 'Leah',
          image:
            'https://housekeep-static.s3-eu-west-1.amazonaws.com/housekeepers-app/demo/housekeep-profile-picture-female.jpg'
        },
        keys: [
          {
            code: '2345',
            location: 'worker'
          }
        ],
        keys_to_transfer: [
          {
            code: '2345',
            location: 'worker'
          }
        ],
        id: 'xvz944h6g3bq',
        internal_id: 715
      }
    ],
    remote_key_transfer_keys: [
      {
        code: '2345',
        location: 'worker'
      }
    ]
  },
  wbkjxt93nnz7: {
    tip_amount_pounds: null,
    tasks: [
      {
        hours: 3.5,
        type: 'cleaning'
      },
      {
        hours: 0.5,
        type: 'oven'
      },
      {
        hours: 0.5,
        type: 'fridge'
      },
      {
        hours: 1.0,
        type: 'indoor'
      },
      {
        type: 'products'
      }
    ],
    any_worker: false,
    finished_at: null,
    frequency: 1.0,
    started_at: null,
    id: 'dznv31jrneo5',
    property_id: 'xb6y5c44alop',
    account_id: 'qolmehmj7yz2',
    job_id: 'wbkjxt93nnz7',
    access: 'at home',
    time_slot: {
      start: '09:00:00',
      end: '14:30:00'
    },
    skipped: false,
    is_first_visit: false,
    week_beginning: '{{ weekBeginning }}',
    finished: false,
    scheduled_date: '{{ date }}',
    actual_date: '{{ date }}',
    instructions: [
      {
        id: 'dj28dy2hd81s',
        task_type: 'cleaning',
        instruction: `
          We're moving out and so need a really thorough clean of the entire
          house
        `
      }
    ],
    account: {
      id: 'qolmehmj7yz2',
      name: 'Paul',
      initial_customer: {
        preferred_name: 'Paul',
        account_id: 'qolmehmj7yz2',
        id: 'norw9s9lewb2'
      }
    },
    access_display: 'At Home',
    customer_can_alter: false,
    is_reviewable: false,
    worker_can_call_customer: true,
    worker_property_access: 'cannot_access',
    property: {
      city: '',
      access_information: {
        opening_instructions: '',
        locking_instructions: 'Lock the back door too.',
        alarm_entry_instructions: 'Enter the code and press ENTER.',
        key_nest_location: '',
        alarm: true,
        concierge_location: '',
        num_keys: '',
        access: 'supervsr',
        hidden_key_location: '',
        key_safe_code: '',
        key_collection_state: {
          key_id: null,
          state: 'none',
          visit: null
        },
        key: null,
        key_nest_code: '',
        alarm_exit_instructions: 'Leave the alarm disarmed.',
        finding_entrance_instructions: '',
        key_transfers: [],
        property_id: 'xb6y5c44alop',
        key_safe_location: ''
      },
      bathrooms: 2,
      account_id: 'qolmehmj7yz2',
      bedrooms: 3,
      access: 'supervsr',
      cleaning_equipment: {
        hoover_location: 'Upstairs cupboard.',
        iron_location: 'Upstairs cupboard',
        cleaning_products_location: '',
        bin_bags_location: 'Next to the sink.',
        rubbish_location: 'Outside the back door.',
        ironing_board_location: 'Upstairs cupboard.',
        property_id: 'xb6y5c44alop'
      },
      perks: "There's tea in the cupboard above the kettle",
      postcode: {
        inward: '8AD',
        outward: 'WC1',
        location: {
          latitude: '51.5142495195',
          longitude: '-0.0991663547935'
        }
      },
      pets: {
        property_id: 'xb6y5c44alop'
      },
      line_1: '2a Grays Inn Road',
      line_3: '',
      line_2: 'Camden',
      id: 'xb6y5c44alop'
    },
    remote_key_transfer_type: '',
    required_keys: [],
    remote_key_transfers: [],
    remote_key_transfer_keys: []
  },
  '84kdxt93wnz7': {
    tip_amount_pounds: null,
    tasks: [
      {
        type: 'return-keys'
      }
    ],
    any_worker: false,
    finished_at: null,
    frequency: 1.0,
    started_at: null,
    id: 'wbkexs93nnze',
    property_id: 'xb6y5c44aeop',
    account_id: 'qolmehmj7yb2',
    job_id: '84kdxt93wnz7',
    access: 'keys',
    time_slot: {
      start: '15:00:00',
      end: '16:00:00'
    },
    skipped: false,
    is_first_visit: false,
    week_beginning: '{{ weekBeginning }}',
    finished: false,
    scheduled_date: '{{ date }}',
    actual_date: '{{ date }}',
    instructions: [
      {
        id: '84kdxt93wnz7',
        task_type: 'return-keys',
        instruction: 'Please post keys through the letterbox'
      }
    ],
    account: {
      id: 'qolmehmj7yz2',
      name: 'Jason',
      initial_customer: {
        preferred_name: 'Jason',
        account_id: 'qoldehmj7yz2',
        id: 'norw9s9lewe2'
      }
    },
    access_display: 'Have Keys',
    customer_can_alter: false,
    is_reviewable: false,
    worker_can_call_customer: true,
    worker_property_access: 'cannot_access',
    property: {
      city: '',
      access_information: {
        opening_instructions: 'You need to unlock the bottom lock first.',
        locking_instructions: 'Please lock both locks.',
        alarm_entry_instructions: '',
        key_nest_location: '',
        alarm: null,
        concierge_location: '',
        num_keys: '1',
        access: 'keys',
        hidden_key_location: '',
        key_safe_code: '',
        key_collection_state: {
          key_id: null,
          state: 'none',
          visit: null
        },
        key: {
          code: '4672',
          location: 'worker'
        },
        key_nest_code: '',
        alarm_exit_instructions: '',
        finding_entrance_instructions: '',
        key_transfers: [],
        property_id: 'xb6y5c44aeop',
        key_safe_location: ''
      },
      key: {
        code: '4672',
        location: 'worker'
      },
      bathrooms: 2,
      account_id: 'qolmehmj7yz2',
      bedrooms: 2,
      access: 'keys',
      cleaning_equipment: {
        hoover_location: 'In the airing cupboard.',
        iron_location: 'In the office, cupboard by the window.',
        cleaning_products_location: 'Underneath the sink.',
        bin_bags_location: 'Underneath the sink',
        rubbish_location: 'Please use bin by front door',
        ironing_board_location: 'Against the wall in the office.',
        property_id: 'qzxwpclleaok'
      },
      perks: '',
      postcode: {
        inward: '5SJ',
        outward: 'SM4',
        location: {
          latitude: '51.49543253',
          longitude: '-0.14172934'
        }
      },
      pets: {
        property_id: 'qzxwpclleaok'
      },
      line_1: '153 Central Road',
      line_3: '',
      line_2: 'Morden',
      id: 'qzxwpclleaok'
    },
    remote_key_transfer_type: '',
    required_keys: [
      {
        code: '4672',
        location: 'worker'
      }
    ],
    remote_key_transfers: [],
    remote_key_transfer_keys: []
  },
  '9oygmtpjvlox': {
    tip_amount_pounds: null,
    tasks: [
      {
        hours: 5.0,
        type: 'cleaning'
      },
      {
        type: 'products'
      },
      {
        hours: 0.0,
        details: null,
        price: 0.0,
        type: 'remote-key-transfer'
      }
    ],
    any_worker: false,
    finished_at: null,
    frequency: 7.0,
    started_at: null,
    id: null,
    property_id: '3ba2jcqqykoe',
    account_id: 'vzq8jhejkjz6',
    job_id: '9oygmtpjvlox',
    access: 'keynest',
    time_slot: {
      start: '09:00:00',
      end: '12:00:00'
    },
    skipped: false,
    is_first_visit: false,
    week_beginning: '{{ weekBeginning }}',
    finished: false,
    scheduled_date: '{{ date }}',
    actual_date: '{{ date }}',
    instructions: [
      {
        id: 'dk8dnchd7sjw',
        task_type: 'cleaning',
        instruction: `
          If supervisor is more than 5 minutes late, please call 07944623481.
          If you are running late or can't find a key please call Veeve.
        `
      },
      {
        id: 'dk8dnchd7sjw',
        task_type: 'cleaning',
        instruction: `
          Do not use flash or any cleaner on stone or marble - use for sinks,
          baths and glass only
        `
      },
      {
        id: 'dk8dnchd7sjw',
        task_type: 'cleaning',
        instruction: `
          Every clean will include a fridge clean and may include an oven
          clean. Customer will supply products.
        `
      },
      {
        id: 'dk8dnchd7sjw',
        task_type: 'cleaning',
        instruction: 'Do not use Viakal on taps, only use bathroom or kitchen cleaner'
      },
      {
        id: 'dk8dnchd7sjw',
        task_type: 'cleaning',
        instruction: `
          Supervisor will tell you what to do with the key. If they have not
          arrived, call 07943933251 before you leave to find out where to
          leave the key
        `
      },
      {
        id: 'dk8dnchd7sjw',
        task_type: 'cleaning',
        instruction: `
          Do not leave until the bed linen has been delivered, listen for the
          door bell and let them in. Put dirty  sheets in blue laundry bags
          ready for collection.
        `
      },
      {
        id: 'dk8dnchd7sjw',
        task_type: 'cleaning',
        instruction: `
          When changing the bed linen, do not take off the mattress protectors
          under the bed sheet
        `
      },
      {
        id: 'dk8dnchd7sjw',
        task_type: 'cleaning',
        instruction: `
          Please do not remove any food or drink from the fridge. If you are
          not sure, please wait for the supervisor to arrive and they will help
          you.
        `
      }
    ],
    account: {
      id: 'vzq8jhejkjz6',
      name: 'Veeve',
      initial_customer: {
        preferred_name: 'Veeve',
        account_id: 'vzq8jhejkjz6',
        id: 'jze87sp952zn'
      }
    },
    access_display: 'Keynest',
    customer_can_alter: true,
    is_reviewable: false,
    worker_can_call_customer: true,
    worker_property_access: 'can_access',
    property: {
      city: '',
      access_information: {
        opening_instructions: '',
        locking_instructions: 'Lock both locks.',
        alarm_entry_instructions: 'Enter code and press DISARM.',
        key_nest_location: 'Portobello store - Baker Street, 16 Flume Rd, NW1 2DC',
        alarm: true,
        concierge_location: '',
        num_keys: '',
        access: 'keynest',
        hidden_key_location: '',
        key_safe_code: '',
        key_collection_state: {
          key_id: null,
          state: 'none',
          visit: null
        },
        key: null,
        key_nest_code: '123456',
        alarm_exit_instructions: 'Code is 4567 and press ARM.',
        finding_entrance_instructions: '',
        key_transfers: [],
        property_id: '3ba2jcqqykoe',
        key_safe_location: 'Hidden behind the bush by the front door.'
      },
      bathrooms: 2,
      account_id: 'vzq8jhejkjz6',
      bedrooms: 2,
      access: 'keysafe',
      cleaning_equipment: {
        hoover_location: '',
        iron_location: 'In the laundry room.',
        cleaning_products_location: 'Under the sink',
        bin_bags_location: 'Under the sink.',
        rubbish_location: 'Under the sink.',
        ironing_board_location: 'In the laundry room',
        property_id: '3ba2jcqqykoe'
      },
      perks: '',
      postcode: {
        inward: '1UH',
        outward: 'NW1',
        location: {
          latitude: '51.5299847959',
          longitude: '-0.127014156831'
        }
      },
      pets: {
        dogs: true,
        pet_names: 'Tic, Tac and Toe',
        pet_instructions: 'Puppies love cuddles.',
        property_id: '3ba2jcqqykoe'
      },
      line_1: '9 Chenies Place',
      line_3: '',
      line_2: 'Kings Cross',
      id: '3ba2jcqqykoe'
    },
    remote_key_transfer_type: 'anchor-recipient',
    required_keys: [
      {
        code: '5134',
        location: 'worker'
      }
    ],
    remote_key_transfers: [
      {
        other_worker: {
          first_names: 'Helga',
          image:
            'https://housekeep-static.s3-eu-west-1.amazonaws.com/housekeepers-app/demo/housekeep-profile-picture-female.jpg'
        },
        keys: [
          {
            code: '5134',
            location: 'worker'
          }
        ],
        keys_to_transfer: [
          {
            code: '5134',
            location: 'worker'
          }
        ],
        id: 'cds954h3g3bt',
        internal_id: 724
      }
    ],
    remote_key_transfer_keys: [
      {
        code: '5134',
        location: 'worker'
      }
    ]
  },
  vz9p2hxl88bq: {
    tip_amount_pounds: null,
    tasks: [
      {
        hours: 0.25,
        details: null,
        price: 0.0,
        type: 'remote-key-transfer'
      }
    ],
    any_worker: false,
    finished_at: null,
    frequency: 1.0,
    started_at: null,
    id: 'vz9p2hxl88bq',
    property_id: 'jze86c42gxzn',
    account_id: '84kdxt93wnz7',
    job_id: 'vz9p2hxl88bq',
    access: 'at home',
    time_slot: {
      start: '16:30:00',
      end: '16:45:00'
    },
    skipped: false,
    is_first_visit: false,
    week_beginning: '{{ weekBeginning }}',
    finished: false,
    scheduled_date: '{{ date }}',
    actual_date: '{{ date }}',
    instructions: [],
    account: {
      id: '84kdxt93wnz7',
      name: 'Remote Key Transfer',
      initial_customer: {
        preferred_name: 'Key transfer',
        account_id: '84kdxt93wnz7',
        id: '9oyg6sre3nox'
      }
    },
    access_display: 'Client at home',
    customer_can_alter: false,
    is_reviewable: false,
    worker_can_call_customer: true,
    worker_property_access: 'can_access',
    property: {
      city: 'London',
      access_information: {},
      bathrooms: 1,
      account_id: 'qolmehmj7yz2',
      bedrooms: 1,
      access: 'at home',
      cleaning_equipment: {},
      perks: '',
      pets: {},
      postcode: {
        inward: '5SJ',
        outward: 'SM3',
        location: {
          latitude: '51.49543253',
          longitude: '-0.14172934'
        }
      },
      line_1: 'Outside 123 Midland Road',
      line_3: '',
      line_2: 'Morden',
      id: 'jze86c42gxzn'
    },
    remote_key_transfer_type: 'traveller-holder',
    required_keys: [
      {
        code: '6243',
        location: 'worker'
      }
    ],
    remote_key_transfers: [
      {
        other_worker: {
          first_names: 'Frank',
          image:
            'https://housekeep-static.s3-eu-west-1.amazonaws.com/housekeepers-app/demo/housekeep-profile-picture-male.jpg'
        },
        keys: [
          {
            code: '6243',
            location: 'worker'
          }
        ],
        keys_to_transfer: [
          {
            code: '6243',
            location: 'worker'
          }
        ],
        id: 'kvz954h4g3bq',
        internal_id: 724
      }
    ],
    remote_key_transfer_keys: [
      {
        code: '6243',
        location: 'worker'
      }
    ]
  },
  pe9k4hxp87nf: {
    tip_amount_pounds: null,
    tasks: [
      {
        hours: 0.25,
        details: null,
        price: 0.0,
        type: 'remote-key-transfer'
      }
    ],
    any_worker: false,
    finished_at: null,
    frequency: 1.0,
    started_at: null,
    id: 'pe9k4hxp87nf',
    property_id: 'jze86c42gxzn',
    account_id: '84kdxt93wnz7',
    job_id: 'pe9k4hxp87nf',
    access: 'at home',
    time_slot: {
      start: '16:30:00',
      end: '16:45:00'
    },
    skipped: false,
    is_first_visit: false,
    week_beginning: '{{ weekBeginning }}',
    finished: false,
    scheduled_date: '{{ date }}',
    actual_date: '{{ date }}',
    instructions: [],
    account: {
      id: '84kdxt93wnz7',
      name: 'Remote Key Transfer',
      initial_customer: {
        preferred_name: 'Key transfer',
        account_id: '84kdxt93wnz7',
        id: '9oyg6sre3nox'
      }
    },
    access_display: 'Client at home',
    customer_can_alter: false,
    is_reviewable: false,
    worker_can_call_customer: true,
    worker_property_access: 'can_access',
    property: {
      city: 'London',
      access_information: {},
      bathrooms: 1,
      account_id: '84kdxt93wnz7',
      bedrooms: 1,
      access: 'at home',
      cleaning_equipment: {},
      perks: '',
      pets: {},
      postcode: {
        inward: '8LR',
        outward: 'SW12',
        location: {
          latitude: '51.4453609',
          longitude: '-0.1645586'
        }
      },
      line_1: 'Outside 14 Calbourne Road',
      line_3: '',
      line_2: 'Balham',
      id: 'jkf82c42gzzn'
    },
    remote_key_transfer_type: 'traveller-recipient',
    required_keys: [],
    remote_key_transfers: [
      {
        other_worker: {
          first_names: 'Bertie',
          image:
            'https://housekeep-static.s3-eu-west-1.amazonaws.com/housekeepers-app/demo/housekeep-profile-picture-male.jpg'
        },
        keys: [
          {
            code: '3456',
            location: 'worker'
          }
        ],
        keys_to_transfer: [
          {
            code: '3456',
            location: 'worker'
          }
        ],
        id: 'kvz954h4g3bq',
        internal_id: 724
      }
    ],
    remote_key_transfer_keys: [
      {
        code: '3456',
        location: 'worker'
      }
    ]
  }
};
