import { Injectable } from '@angular/core';

import { Worker } from '@housekeep/infra';

import { UserService } from './user-service';
import { WhatsNewService } from './whats-new-service';

type OpeningPage =
  | '/app-housekeepers/login'
  | '/app-housekeepers/schedule'
  | '/app-housekeepers/onboarding/welcome'
  | '/app-housekeepers/whats-new';

@Injectable({ providedIn: 'root' })
export class NavigationService {
  constructor(private userService: UserService, private whatsNewService: WhatsNewService) {}

  /**
   * If there is a user logged in, this will navigate to the suitable opening page.
   * Otherwise it will go to the LoginPage.
   */
  public async getOpeningPage(getUserFromCache = false): Promise<OpeningPage> {
    try {
      const user = await this.userService.getUser(getUserFromCache);
      return this.getAuthedOpeningPage(user);
    } catch (err) {
      return '/app-housekeepers/login';
    }
  }

  /*
   * If a user is logged in then we wish to route their initial view
   */
  private async getAuthedOpeningPage(user: Worker): Promise<OpeningPage> {
    if (user.isOnboarding()) {
      return '/app-housekeepers/onboarding/welcome';
    }

    try {
      const showWhatsNew = await this.whatsNewService.shouldShowNewsOnInit();
      return showWhatsNew ? '/app-housekeepers/whats-new' : '/app-housekeepers/schedule';
    } catch (err) {
      return '/app-housekeepers/schedule';
    }
  }
}
