import { Injectable } from '@angular/core';

import { Platform } from '@ionic/angular';

import { toDate } from '@housekeep/infra';

import { environment } from 'environment/environment';

import { TimeService } from './time-service';

/**
 * Platform service provides platform level tools
 * This is distinguished from DeviceService as device relates to registration
 */
@Injectable({ providedIn: 'root' })
class PlatformService {
  constructor(private platform: Platform, private timeService: TimeService) {
    this.initialize();
  }

  public initialize(): Promise<string> {
    return this.platform.ready().catch(() => 'dev');
  }

  /**
   * Returns `true` if platform is iOS and date is before the next expected release date.
   * @since v1.9.1
   * @return {boolean}
   */
  public isPreReleaseIos(): boolean {
    return this.platform.is('ios') && this.timeService.today().isBefore(toDate(environment.NEXT_RELEASE_DATE));
  }
}

export { PlatformService };
