import { DemoEndpoint } from '../../../demo-service';

export const extraJobAvailabilitySignalEndpoint: DemoEndpoint = {
  path: 'extra-job-availability-signal',
  handlers: [
    {
      method: 'get',
      handle() {
        return {
          app_signal_days_lookahead: 0,
          app_signal_enabled: false,
          app_signal_refresh_interval_seconds: 0,
          one_offVisits_available: false,
          one_offVisits_out_of_area: 0,
          one_offVisits_out_of_availability: 0,
          regular_jobs_available: false,
          regular_jobs_out_of_area: 0,
          regular_jobs_out_of_availability: 0,
          work_available: false
        };
      }
    }
  ]
};
