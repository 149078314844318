import { Injectable } from '@angular/core';

import { Breadcrumb, Scope, SeverityLevel, User } from '@sentry/angular';
import * as Sentry from '@sentry/capacitor';

/**
 * Permitted values for the `category` property of a breadcrumb.
 * @see HkSentryBreadcrumb
 * @see Breadcrumb
 */
export enum HkSentryBreadcrumbCategory {
  Auth = 'auth',
  Cache = 'cache',
  Deeplink = 'deeplink',
  Misc = 'misc',
  UiNav = 'ui.nav'
}

/**
 * Subtype of `Breadcrumb` with a mandatory `message` property.
 */
export interface HkSentryBreadcrumb extends Breadcrumb {
  category?: HkSentryBreadcrumbCategory;
  message: string;
}

/**
 * Represents a message to log to Sentry, optionally annotated with "extras".
 */
export interface HkSentryMessage {
  level?: SeverityLevel;
  extras?: { [key: string]: any };
  message: string;
  tags?: { [key: string]: string };
}

const breadcrumbDefaults: Breadcrumb = {
  category: HkSentryBreadcrumbCategory.Misc,
  level: 'debug'
};

@Injectable({ providedIn: 'root' })
export class SentryService {
  /**
   * Capture a breadcrumb using Sentry.
   * If a category or severity level are not specified, these default to "misc" and
   * "debug", respectively.
   *
   * Breadcrumbs are not immediately logged to Sentry. Instead, they supplement errors on
   * Sentry by exposing the
   * events that led up to their occurrence.
   */
  public captureBreadcrumb(breadcrumb: HkSentryBreadcrumb): void {
    Sentry.addBreadcrumb({ ...breadcrumbDefaults, ...breadcrumb });
  }

  /**
   * Capture a message using Sentry.
   */
  public log({ message, level = 'debug', extras = null, tags = null }: HkSentryMessage): void {
    Sentry.withScope((scope: Scope) => {
      if (extras) {
        scope.setExtras(extras);
      }
      if (tags) {
        scope.setTags(tags);
      }
      Sentry.captureMessage(message, level);
    });
  }

  /**
   * Sets or clears the user.
   */
  public setUser(user: User | null): void {
    Sentry.withScope((scope: Scope) => {
      scope.setUser(user);
    });
  }
}
