import { Inject, Injectable } from '@angular/core';

import { Subject } from 'rxjs';

import {
  Date,
  Duration,
  InactivityReason,
  RequestOpts,
  TravelPreference,
  Worker,
  workerSerializer,
  WorkerStatus
} from '@housekeep/infra';

import { WorkerExtraJobEligibility } from 'models/worker-extra-job-eligibility';
import { WorkerExtraJobSignalAvailability } from 'models/worker-extra-job-signal-availability';

import { workerExtraJobEligibilitySerializer } from 'serializers/worker-extra-job-eligibility';
import { workerExtraJobSignalAvailabilitySerializer } from 'serializers/worker-extra-job-signal-availability';

import { Unauthorized } from 'util/error';

import { APP_CONFIG, AppConfig } from './app-config';
import { AuthEventArgs, AuthService } from './auth-service';
import { CacheService } from './cache-service';
import { DemoService } from './demo-service';
import { ErrorService } from './error-service';
import { RequestService } from './request-service';

const CACHE_KEY = 'USER';
const EXTRA_JOB_AVAILABILITY_SIGNAL_ENDPOINT = 'extra-job-availability-signal/';
/**
 * The timeout in ms for the request to the extra job availability signal endpoint.
 * This is slightly higher than the timeout imposed in the backend
 * to accommodate slow network speeds.
 */
const EXTRA_JOB_AVAILABILITY_SIGNAL_TIMEOUT = 35000;

/**
 * The default timeout in ms for requests to get the user.
 * This may be overridden by specifying a different timeout as a parameter to getUser().
 */
const DEFAULT_GET_USER_REQUEST_TIMEOUT = 8000;

export enum UserSubscriptionEvents {
  UpdatePaymentDetails = 'payment-details-updated'
}

/**
 * Service to return the current user.
 */
@Injectable({ providedIn: 'root' })
export class UserService {
  private _userPromise: Promise<Worker> | null;
  private userEventsSubject: Subject<string> = new Subject();

  constructor(
    public authService: AuthService,
    public cacheService: CacheService,
    public demoService: DemoService,
    public errorService: ErrorService,
    public requestService: RequestService,
    @Inject(APP_CONFIG) private config: AppConfig
  ) {
    authService.subscribe(event => this._onAuthServiceUpdate(event));
    demoService.subscribe(event => this._onDemoServiceUpdate(event));
  }

  /**
   * Return a promise which will resolve with the current user's details.
   *
   * The resulting promise is cached such that multiple calls to getUser()
   * do not trigger multiple requests.
   *
   * @param fromCache if true, return the user only if cached: don't make an API request
   * @param requestTimeout the request timeout in ms (ignored if `fromCache` is true)
   */
  public getUser(fromCache = false, requestTimeout?: number): Promise<Worker> {
    return this.authService.ready().then(() => {
      if (!this.authService.isAuthenticated) {
        throw 'Not authenticated';
      }

      if (!this._userPromise) {
        this._userPromise = fromCache
          ? this._getUserFromCache()
          : this._getUser(requestTimeout || DEFAULT_GET_USER_REQUEST_TIMEOUT);
      }

      return this._userPromise;
    });
  }

  /**
   * Return a promise which always resolve with the latest user details.
   */
  public refreshUser(): Promise<Worker> {
    this._userPromise = null;
    return this.getUser();
  }

  /**
   * Return a promise that will resolve to an object describing the user's eligibility
   * for extra jobs.
   * @return {Promise<WorkerExtraJobEligibility>}
   */
  public getExtraJobEligibility(): Promise<WorkerExtraJobEligibility> {
    return this.getUser().then(user =>
      this.requestService.getInstance(
        this._getWorkerEndpoint(user.id, 'extra-job-eligibility/'),
        workerExtraJobEligibilitySerializer
      )
    );
  }

  /**
   * Return a promise that will resolve to an object detailing if the user
   * has extra jobs available
   * @return {Promise<WorkerExtraJobEligibility>}
   */
  public getExtraJobAvailability(): Promise<WorkerExtraJobSignalAvailability> {
    return this.getUser().then(user =>
      this.requestService.getInstance(
        this._getWorkerEndpoint(user.id, EXTRA_JOB_AVAILABILITY_SIGNAL_ENDPOINT),
        workerExtraJobSignalAvailabilitySerializer,
        {
          timeout: EXTRA_JOB_AVAILABILITY_SIGNAL_TIMEOUT
        }
      )
    );
  }

  /**
   * Change a housekeeper's home address.
   * @return {Promise<any>} The user object with a new address values
   */
  public updateAddress(line1, line2, line3, city, postcode): Promise<Worker> {
    return this.getUser().then(user =>
      this.requestService.patch(this._getWorkerEndpoint(user.id), {
        line1: line1,
        line2: line2,
        line3: line3,
        city: city,
        postcode: postcode
      })
    );
  }

  /**
   * Change a housekeeper's registered emergency contact.
   * @return {Promise<any>} The user object with a emergency contact values
   */
  public updateEmergency(name, phone, relation): Promise<Worker> {
    return this.getUser().then(user =>
      this.requestService.patch(this._getWorkerEndpoint(user.id), {
        emergencyContactName: name,
        emergencyContactNumber: phone,
        emergencyContactRelation: relation
      })
    );
  }

  /**
   * Change a housekeeper's mobile phone number.
   * @return {Promise<any>} The user object with a new `mobile` property value
   */
  public updateMobile(phoneNumber): Promise<Worker> {
    return this.getUser().then(user =>
      this.requestService.patch(this._getWorkerEndpoint(user.id), {
        primaryTelephoneNumber: phoneNumber
      })
    );
  }

  /**
   * Change a workers status
   * This is used to toggle between NNA and Active
   * @return The user with the updated status
   */
  public updateWorkerStatus(
    status: WorkerStatus,
    reasonForInactivity: InactivityReason = null,
    reasonForInactivityDescription: string = null,
    noNewAccountsDate: Date = null
  ): Promise<Worker> {
    return this.getUser().then(user => {
      return this.requestService.patch(
        this._getWorkerEndpoint(user.id),
        {
          status,
          reasonForInactivity: reasonForInactivity || '',
          reasonForInactivityDescription,
          noNewAccountsDate
        },
        { serializer: workerSerializer }
      );
    });
  }

  /**
   * Change a workers travel preferences
   * @return The user with the updated travel preferences
   */
  public updateWorkerTravelPreferences(travelPreferences: TravelPreference[]): Promise<Worker> {
    return this.getUser().then(user => {
      return this.requestService.patch(this._getWorkerEndpoint(user.id), { travelPreferences });
    });
  }

  /**
   * Subscribe to user service updates (user payment details being updated).
   */
  public subscribe(fn: (eventName: UserSubscriptionEvents) => void) {
    return this.userEventsSubject.subscribe(fn);
  }

  /**
   * Notify subscribers to user service updates (user payment details being updated).
   */
  public next(updateType: UserSubscriptionEvents) {
    return this.userEventsSubject.next(updateType);
  }

  /**
   * Destroy the cached user on a log-out event.
   */
  private _onAuthServiceUpdate(event: AuthEventArgs) {
    const [eventName] = event;

    if (eventName === 'logout') {
      this._userPromise = null;
    }
  }

  /**
   * Destroy the cached user on a Demo Mode on/off event.
   */
  private _onDemoServiceUpdate(event) {
    const [eventName] = event;

    if (eventName === 'on' || eventName === 'off') {
      this._userPromise = null;
    }
  }

  /**
   * Attempt to retrieve the user's details from the server.
   * Fallback to the cache if required.
   */
  private _getUser(timeout: number): Promise<Worker> {
    const endpoint = this.config.API_ENDPOINTS.USER_DETAILS;
    const requestOpts: RequestOpts = {
      cache: {
        duration: new Duration({ days: 7 }),
        key: CACHE_KEY
      },
      params: {
        expand: ['background_check']
      },
      timeout
    };

    return this.requestService
      .getInstance(endpoint, workerSerializer, requestOpts)
      .then(user => {
        // The user is a customer, not a worker, log them out!
        if ('accountId' in user) {
          this.authService.logout('customer');
          throw new Unauthorized('customer');
        }
        return user;
      })
      .catch(async err => {
        // Attempt to retrieve the cached information if available
        if (this.errorService.isNoInternetError(err) || this.errorService.isTimeoutError(err)) {
          const cachedUser = await this._getUserFromCache();
          if (!cachedUser) {
            // Subsequent requests should try the server again if there is nothing cached
            this._userPromise = null;
          }
          return cachedUser;
        } else {
          // Subsequent requests should try the server again
          this._userPromise = null;
          throw err;
        }
      });
  }

  private async _getUserFromCache(): Promise<Worker> {
    const data = await this.cacheService.get(CACHE_KEY);
    return workerSerializer.deserialize(data);
  }

  /**
   * Return an endpoint for the worker.
   * @param {string} workerId the worker ID
   * @param {string} path an optional path to append to the root worker endpoint
   * @return {string} an endpoint for the worker
   */
  private _getWorkerEndpoint(workerId: string, path: string = ''): string {
    return `workers/${workerId}/${path}`;
  }
}
