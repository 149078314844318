import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'hk-connection-failure',
  templateUrl: './hk-connection-failure.component.html',
  styleUrls: ['./hk-connection-failure.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HkConnectionFailure {
  @Output() onRefresh: EventEmitter<null> = new EventEmitter();

  public doRefresh() {
    this.onRefresh.emit(null);
  }
}
