<ng-container *ngIf="showAlways || unread > 0">
  <span>
    <hk-wc-icon name="chat" [size]="iconSize"></hk-wc-icon>
    <span
      *ngIf="unread > 0 && isFirstVisitForCustomer"
      class="unread-indicator text--no-wrap"
    >
      {{ unread > 99 ? '9+' : unread }}
    </span>
  </span>
</ng-container>
