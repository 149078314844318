<main *ngIf="visit && property">
  <hk-property-map  [property]="property">
  </hk-property-map>

  <hk-visit-summary [preferredName]="visit.account.initialCustomer.preferredName"
                    [property]="property"
                    [showSensitive]="true"
                    [timeSlot]="visit.timeSlot"
                    [visitChanges]="visitChanges"
                    [showVisitInfo]="showVisitInfo">
  </hk-visit-summary>

  <rkt-key-handover [visit]="visit"></rkt-key-handover>

  <ion-card class="card--extra-precautions" *ngIf="showExtraPrecautions">
    <ion-card-header>
      <strong class="text--danger">Extra precautions</strong>
    </ion-card-header>
    <ion-card-content>
      <ul class="mar-t-0">
        <li>Avoid physical greetings</li>
        <li>Maintain distance of 2 metres from customers at all times</li>
        <li>Wash your hands for at least 20 seconds</li>
        <li>Remove shoes or use shoe covers</li>
      </ul>
    </ion-card-content>
  </ion-card>
</main>

